import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Header,
  Segment,
  Grid,
  Icon,
  Tab,
  Form,
  Message,
} from "semantic-ui-react";
import { CustomToast } from "../../settings/actions";
import { put } from "../../utils/api";
import { _getProfile } from "../../redux/userReducer";
import ReactFlagsSelect from "react-flags-select";

function EditCountry({ onLoading, close }) {
  const { setting, user } = useSelector((state) => state);
  const { library } = setting;
  const { data } = user;
  const [countryCode, setCountryCode] = useState(data.countryCode);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const _onSubmit = () => {
    if (!countryCode) {
      setError("Please select country");
    } else {
      setError(null);
      onLoading(true);
      put(
        `/user-service/user/country`,
        { countryCode },
        () => {
          onLoading(false);
          CustomToast("success", library.UPDATE_COUNTRY_SUCCESS);
          dispatch(_getProfile());
          close();
        },
        (error) => {
          setError(library[error.code]);
          onLoading(false);
        }
      );
    }
  };

  return (
    <Segment vertical inverted basic>
      <Form onSubmit={_onSubmit}>
        <label className="custom-label">{library.PLEASE_SELECT_COUNTRY}</label>
        <ReactFlagsSelect
          searchable
          onSelect={(e) => setCountryCode(e)}
          selected={countryCode}
        />
        {/* <div>{library.EDIT_NICKNAME_NOTE}</div> */}
        <div style={{ height: 80, display: "flex" }}>
          <Message
            hidden={error === null}
            content={error}
            negative
            style={{
              margin: "auto",
              width: "100%",
            }}
          />
        </div>
        <br />
        <Form.Button fluid>{library.UPDATE}</Form.Button>
      </Form>
    </Segment>
  );
}

function EditUsername({ onLoading, close }) {
  const { setting, user } = useSelector((state) => state);
  const { library } = setting;
  const { data } = user;
  const [nickname, setNickname] = useState(data.displayName);
  const [nicknameError, setNicknameError] = useState(null);
  const dispatch = useDispatch();

  const _handleCheckValue = () => {
    if (!nickname || nickname.length < 3) {
      setNicknameError(library.PLEASE_CHECK_NICKNAME_AGAIN);
    } else if (
      !/^[a-zA-z0-9]+$/g.test(nickname) ||
      parseInt(nickname[0]) >= 0
    ) {
      setNicknameError(library.PLEASE_CHECK_NICKNAME_AGAIN);
    } else {
      onLoading(true);
      setNicknameError(null);
      put(
        `/user-service/user/display-name`,
        { displayName: nickname },
        () => {
          onLoading(false);
          CustomToast("success", library.UPDATE_NICKNAME_SUCCESS);
          dispatch(_getProfile());
          close();
        },
        (error) => {
          console.log(error);
          setNicknameError(library[error.code]);
          onLoading(false);
        }
      );
    }
  };

  return (
    <Segment vertical inverted basic>
      <Form inverted onSubmit={_handleCheckValue}>
        <Form.Input
          label={library.PLEASE_TELL_US_YOUR_NICKNAME}
          placeholder={library.YOUR_NICKNAME}
          maxLength="32"
          onChange={(e, { value }) => setNickname(value)}
          value={nickname}
        />
        <div>{library.EDIT_NICKNAME_NOTE}</div>
        <div style={{ height: 80, display: "flex" }}>
          <Message
            hidden={nicknameError === null}
            content={nicknameError}
            negative
            style={{
              margin: "auto",
              width: "100%",
            }}
          />
        </div>
        <br />
        <Form.Button fluid>{library.UPDATE}</Form.Button>
      </Form>
    </Segment>
  );
}

function EditProfile({ close }) {
  const [loading, setLoading] = useState(false);
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const panes = [
    {
      menuItem: "Nickname",
      render: () => (
        <EditUsername onLoading={(e) => setLoading(e)} close={close} />
      ),
    },
    {
      menuItem: "Country",
      render: () => (
        <EditCountry onLoading={(e) => setLoading(e)} close={close} />
      ),
    },
  ];

  return (
    <Segment className="edit-profile" inverted loading={loading}>
      <Segment basic vertical>
        <Grid columns={2}>
          <Grid.Column>
            <Header>{library.EDIT_PROFILE}</Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Icon
              name="x"
              link
              inverted
              style={{ fontSize: "1.3em" }}
              onClick={close}
            />
          </Grid.Column>
        </Grid>
      </Segment>
      <Tab
        menu={{ secondary: true, pointing: true, inverted: true }}
        panes={panes}
      />
    </Segment>
  );
}

export default EditProfile;
