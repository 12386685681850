import { Drawer } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Flag, Icon, Segment, Label } from "semantic-ui-react";
import { LUS_SYMBOL } from "../../constant/CoinConstant";
import { RANK_LEVEL } from "../../settings/constant";
import { formatTime } from "../../settings/format";
import EditProfile from "./EditProfile";

function Info() {
  const { user, setting, lus } = useSelector((state) => state);
  const { data, activity } = user;
  const { library } = setting;
  const { LAST_LOGIN } = library;
  const { LusStatistic } = lus;
  const lastActivity = activity && activity.items[0];
  const [showEditProfile, setShowEditProfile] = useState(false);

  return (
    data &&
    lastActivity && (
      <Segment className="information">
        <div style={{ display: "flex" }}>
          <div
            style={{
              minWidth: 40,
              height: 40,
              color: "#fafafa",
              backgroundColor: "#bdbdbd",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "1em",
            }}
          >
            {data.email.slice(0, 2)}
          </div>
          <div>
            <div>
              <span style={{ marginRight: "1em" }}>
                <span style={{ fontWeight: 600 }}>{data.email}</span>
              </span>
              <span style={{ marginRight: "1em" }}>
                ID:<span style={{ fontWeight: 600 }}>#{data.id}</span>
              </span>
              {/* <span style={{ color: "#fbbd08" }}>
                <Icon name="gem outline" />
                <span style={{ fontWeight: 600 }}>VIP{data.level}</span>
              </span> */}
            </div>
            <div>
              <span style={{ marginRight: "1em" }}>
                Nickname:{" "}
                <span style={{ fontWeight: 600 }}>{data.displayName}</span>
              </span>
              {data.countryCode && (
                <Flag name={data.countryCode.toLowerCase()} />
              )}
            </div>
            <Link
              to="#"
              onClick={() => setShowEditProfile(true)}
              className="yellow-link"
            >
              Edit profile {">>"}
            </Link>
          </div>
        </div>
        <Segment vertical className="last-login">
          <span>
            {LAST_LOGIN}:{" "}
            <span style={{ fontWeight: 600 }}>
              {formatTime(lastActivity.time)}
            </span>
          </span>
          <span className="ip">
            {" "}
            IP: <span style={{ fontWeight: 600 }}>{lastActivity.ip}</span>
          </span>
        </Segment>
        <Segment vertical>
          <Label style={{ color: "#fbbd08", backgroundColor: "var(--gray)" }}>
            <Icon name="gem" />
            {LUS_SYMBOL} level: {RANK_LEVEL[LusStatistic.memberLevel]}
          </Label>
          <Label style={{ color: "#fff", backgroundColor: "var(--gray)" }}>
            <Icon name="star" />
            {LUS_SYMBOL} ranking: {RANK_LEVEL[LusStatistic.rankingLevel]}
          </Label>
          {/* <Button>Update Profile</Button> */}
        </Segment>
        <Drawer
          anchor={"left"}
          open={showEditProfile}
          className={"custom-modal-vk"}
        >
          <EditProfile close={() => setShowEditProfile(false)} />
        </Drawer>
      </Segment>
    )
  );
}

export default Info;
