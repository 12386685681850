module.exports = {
  makeID(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  },
  deviceInfo() {
    var navUserAgent = navigator.userAgent;
    var browserName = navigator.appName;
    var browserVersion = "" + parseFloat(navigator.appVersion);
    // var majorVersion = parseInt(navigator.appVersion, 10);
    var tempNameOffset, tempVersionOffset, tempVersion;

    if ((tempVersionOffset = navUserAgent.indexOf("Opera")) !== -1) {
      browserName = "Opera";
      browserVersion = navUserAgent.substring(tempVersionOffset + 6);
      if ((tempVersionOffset = navUserAgent.indexOf("Version")) !== -1)
        browserVersion = navUserAgent.substring(tempVersionOffset + 8);
    } else if ((tempVersionOffset = navUserAgent.indexOf("MSIE")) !== -1) {
      browserName = "Microsoft Internet Explorer";
      browserVersion = navUserAgent.substring(tempVersionOffset + 5);
    } else if ((tempVersionOffset = navUserAgent.indexOf("Chrome")) !== -1) {
      browserName = "Chrome";
      browserVersion = navUserAgent.substring(tempVersionOffset + 7);
    } else if ((tempVersionOffset = navUserAgent.indexOf("Safari")) !== -1) {
      browserName = "Safari";
      browserVersion = navUserAgent.substring(tempVersionOffset + 7);
      if ((tempVersionOffset = navUserAgent.indexOf("Version")) !== -1)
        browserVersion = navUserAgent.substring(tempVersionOffset + 8);
    } else if ((tempVersionOffset = navUserAgent.indexOf("Firefox")) !== -1) {
      browserName = "Firefox";
      browserVersion = navUserAgent.substring(tempVersionOffset + 8);
    } else if (
      (tempNameOffset = navUserAgent.lastIndexOf(" ") + 1) <
      (tempVersionOffset = navUserAgent.lastIndexOf("/"))
    ) {
      browserName = navUserAgent.substring(tempNameOffset, tempVersionOffset);
      browserVersion = navUserAgent.substring(tempVersionOffset + 1);
      if (browserName.toLowerCase() === browserName.toUpperCase()) {
        browserName = navigator.appName;
      }
    }

    // trim version
    if ((tempVersion = browserVersion.indexOf(";")) !== -1)
      browserVersion = browserVersion.substring(0, tempVersion);
    if ((tempVersion = browserVersion.indexOf(" ")) !== -1)
      browserVersion = browserVersion.substring(0, tempVersion);

    var OSName = "Unknown OS";
    if (navigator.appVersion.indexOf("Win") !== -1) OSName = "Windows";
    if (navigator.appVersion.indexOf("Mac") !== -1) OSName = "MacOS";
    if (navigator.appVersion.indexOf("X11") !== -1) OSName = "UNIX";
    if (navigator.appVersion.indexOf("Linux") !== -1) OSName = "Linux";

    return browserName + " V" + browserVersion + " (" + OSName + ")";
  },
};
