import React from "react";
import { useSelector } from "react-redux";
import { Header, Segment, Tab } from "semantic-ui-react";
import AirdropCommission from "./AirdropCommission";
import Gift from "./Gift";
import StakingCommission from "./StakingCommission";

function CommissionHistory() {
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const { YOUR_COMMISSION_HISTORY, AIRDROP, STAKING, GIFT } = library;
  const panes = [
    {
      menuItem: AIRDROP,
      render: () => <AirdropCommission />,
    },
    {
      menuItem: STAKING,
      render: () => <StakingCommission />,
    },
    {
      menuItem: GIFT,
      render: () => <Gift />,
    },
  ];
  return (
    <Segment>
      <Header textAlign="left">{YOUR_COMMISSION_HISTORY}</Header>
      <Tab
        panes={panes}
        menu={{ secondary: true, pointing: true, inverted: true }}
      />
    </Segment>
  );
}

export default CommissionHistory;
