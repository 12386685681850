import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  Button,
  Container,
  Form,
  Grid,
  Icon,
  Image,
  Segment,
  Statistic,
} from "semantic-ui-react";
import CoinChart from "../../components/portfolio/CoinChart";
import { LUS_V2_DEV, RETURN_SAFE_LUS_KEY, S3_URL } from "../../settings";
import {
  formatMoney,
  formatShortAmount,
  formatShortMoney,
} from "../../settings/format";
import { getAccessToken } from "../../utils/auth";

function Dashboard() {
  const history = useHistory();
  const { wallet, setting, user } = useSelector((state) => state);
  const { library } = setting;
  const { fundList } = wallet;
  let searchCoin;
  const [words, setWords] = useState("");

  let sum = fundList.reduce((a, b) => {
    return a + b.usdAmount;
  }, 0);

  if (words.trim().length > 0) {
    searchCoin = fundList.filter(
      (element) =>
        element.coin.toLowerCase().includes(words.toLowerCase()) ||
        element.fullName.toLowerCase().includes(words.toLowerCase())
    );
  } else {
    searchCoin = fundList;
  }

  searchCoin.sort(function (a, b) {
    return b.usdAmount - a.usdAmount;
  });

  return (
    <Container textAlign="center" className="dashboard">
      <LazyLoad>
        <div style={{ position: "relative" }}>
          <CoinChart />
          <Statistic
            size="small"
            inverted
            style={{
              position: "absolute",
              bottom: 0,
              top: 0,
              right: "50%",
              left: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 0,
            }}
          >
            <Statistic.Label style={{ color: "var(--green)" }}>
              {library.TOTAL}
            </Statistic.Label>
            <Statistic.Value
              style={
                user.data && user.data.email === "realbewhy@gmail.com"
                  ? { color: "pink" }
                  : null
              }
            >
              <span style={{ fontSize: 25 }}>$</span>
              <span style={{ fontSize: 35 }}>{formatShortMoney(sum)}</span>
            </Statistic.Value>
          </Statistic>
        </div>
      </LazyLoad>
      <LazyLoad>
        <Segment className="balances" padded>
          <Grid columns="equal" textAlign="right">
            <Grid.Row style={{ marginBottom: 0, paddingTop: "0.5em" }}>
              <Grid.Column
                computer="12"
                tablet="10"
                only="computer"
              ></Grid.Column>
              <Grid.Column
                mobile="16"
                computer="4"
                tablet="6"
                style={{ padding: 0 }}
              >
                <Form>
                  <Form.Input
                    placeholder={library.SEARCH_COIN_TOKEN}
                    type="text"
                    icon={<Icon name="search" inverted />}
                    onChange={(e, { value }) => setWords(value)}
                    fluid
                  />
                </Form>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row only="computer" style={{ fontWeight: 600 }}>
              <Grid.Column textAlign="left" width={4}>
                {library.COIN_TOKEN}
              </Grid.Column>
              <Grid.Column>{library.PRICE}</Grid.Column>
              <Grid.Column>{library.BALANCE}</Grid.Column>
              <Grid.Column>{library.VALUE}</Grid.Column>
              <Grid.Column></Grid.Column>
              <Grid.Column></Grid.Column>
            </Grid.Row>
            {searchCoin.map((w, index) => (
              <React.Fragment key={index}>
                <Grid.Row
                  only="computer"
                  onClick={(e) => {
                    if (!e.target.className.includes("button")) {
                      history.push(`/portfolio/${w.coin}`);
                    }
                  }}
                  className="portfolio-item"
                >
                  <Grid.Column
                    style={{ display: "flex" }}
                    textAlign="left"
                    width={4}
                  >
                    <Image
                      src={`${S3_URL}/coins/${w.coin.replace(" ", "_")}.png`}
                      verticalAlign="middle"
                      className="coin-image"
                    />
                    <div style={{ marginLeft: 20 }}>
                      <div style={{ fontWeight: 600, fontSize: 15 }}>
                        {w.coin}
                      </div>
                      <div>{w.fullName}</div>
                    </div>
                  </Grid.Column>
                  <Grid.Column verticalAlign="middle">
                    ${formatMoney(w.usdPrice)}
                  </Grid.Column>
                  <Grid.Column
                    verticalAlign="middle"
                    style={{ color: w.color, fontWeight: 600 }}
                  >
                    {formatShortAmount(w.amount)} <br />
                    {w.blockedAmount !== 0 && (
                      <p
                        style={{
                          opacity: 0.8,
                          color: "grey",
                        }}
                      >
                        {formatShortAmount(w.blockedAmount)}
                      </p>
                    )}
                  </Grid.Column>
                  <Grid.Column
                    verticalAlign="middle"
                    style={{ color: w.color, fontWeight: 600 }}
                  >
                    ${formatShortMoney(w.usdAmount)}
                  </Grid.Column>
                  <Grid.Column textAlign="right">
                    <Button
                      onClick={() => history.push("/deposit/" + w.coin)}
                      className="spwallet-button"
                      disabled={!w.depositEnable}
                    >
                      {library.DEPOSIT}
                    </Button>
                  </Grid.Column>
                  <Grid.Column textAlign="left">
                    <Button
                      onClick={() => history.push("/withdraw/" + w.coin)}
                      basic
                      inverted
                      disabled={!w.withdrawEnable}
                    >
                      {library.WITHDRAW}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row
                  only="mobile tablet"
                  onClick={(e) => {
                    if (!e.target.className.includes("button")) {
                      history.push(`/portfolio/${w.coin}`);
                    }
                  }}
                >
                  <Grid.Column
                    textAlign="left"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Grid.Column style={{ display: "flex" }} width={8}>
                      <Image
                        src={`${S3_URL}/coins/${w.coin.replace(" ", "_")}.png`}
                        verticalAlign="middle"
                        className="coin-image"
                      />
                      <div style={{ marginLeft: 20 }}>
                        <div style={{ fontWeight: 600, fontSize: 15 }}>
                          {w.coin}
                        </div>
                        <div>${formatMoney(w.usdPrice)}</div>
                        {w.blockedAmount !== 0 && (
                          <p
                            style={{
                              opacity: 0.8,
                              color: "grey",
                            }}
                          >
                            {formatShortAmount(w.blockedAmount)}
                          </p>
                        )}
                      </div>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <div
                        style={{
                          textAlign: "right",
                          color: w.color,
                          fontWeight: 600,
                        }}
                      >
                        {formatShortAmount(w.amount)} {w.coin}
                      </div>
                      <div
                        style={{
                          textAlign: "right",
                          color: w.color,
                          fontWeight: 600,
                        }}
                      >
                        ${formatShortMoney(w.usdAmount)}
                      </div>
                    </Grid.Column>
                  </Grid.Column>
                  <Grid.Column
                    width={16}
                    textAlign="center"
                    style={{ marginTop: "1em" }}
                  >
                    <Button
                      onClick={() => history.push("/deposit/" + w.coin)}
                      className="spwallet-button"
                      disabled={!w.depositEnable}
                    >
                      {library.DEPOSIT}
                    </Button>
                    <Button
                      onClick={() => history.push("/withdraw/" + w.coin)}
                      basic
                      inverted
                      disabled={!w.withdrawEnable}
                    >
                      {library.WITHDRAW}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </React.Fragment>
            ))}
          </Grid>
        </Segment>
      </LazyLoad>
    </Container>
  );
}

export default Dashboard;
