/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button, Drawer, Grid, Typography } from "@material-ui/core";
import { VerifiedUser } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import { useParams } from "react-router";
import "../../components/shopping/Shopping.scss";
import { Container } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { formatTotal } from "../../settings/format";
import Carousel from "react-multi-carousel";
import { get } from "../../utils/api";
import LusCheckoutForm from "./LusCheckoutForm";
import LusHeader from "./SubComponent/LusHeader";
import { AddTitle } from "../../actions";
import { LUS_SYMBOL } from "../../constant/CoinConstant";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
};

function ProductDetail() {
  const { wallet, setting } = useSelector((state) => state);
  const { fundList } = wallet;
  const [selectedImage, setSelectedImage] = useState(null);
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);
  const [mounted, setMounted] = useState(true);
  const { library } = setting;

  const lusFund =
    fundList.length > 0
      ? fundList.filter((item) => item.coin === LUS_SYMBOL)[0]
      : {};

  useEffect(() => {
    if (mounted) {
      get(`/shopping-service/lus-shopping/product?id=${id}`, (data) => {
        setProduct(data);
        AddTitle(data.productName + " | LuS Shopping");
        setSelectedImage(data.imageList.split(",")[0]);
      });
    }
    return () => setMounted(false);
  }, [id, mounted]);

  const isSoldOut = product && product.stockQty > 0 ? false : true;

  return (
    product && (
      <div id="product-detail">
        <LusHeader />
        <LazyLoad>
          <Container className="information">
            <Grid container spacing={4}>
              <Grid item md={8} xs={12}>
                <img
                  src={selectedImage}
                  alt=""
                  style={{ height: 400, objectFit: "contain", width: "100%" }}
                />
                <div className="product-album">
                  {product.imageList && (
                    <Carousel responsive={responsive} infinite={true}>
                      {product.imageList.split(",").map((item, index) => (
                        <img
                          src={item}
                          alt="product-detail"
                          key={index}
                          onClick={() => setSelectedImage(item)}
                        />
                      ))}
                    </Carousel>
                  )}
                </div>
              </Grid>
              <Grid item md={4} style={{ textAlign: "left" }} xs={12}>
                <Typography variant="h6">{product.productName}</Typography>
                <Typography variant="h6">
                  <span className="sale">
                    {formatTotal(product.promotionPrice)} {LUS_SYMBOL}
                  </span>{" "}
                  <span className="price">
                    {formatTotal(product.price)} {LUS_SYMBOL}
                  </span>{" "}
                  <span className="percent">
                    {library.SAVE}{" "}
                    {Math.round(
                      ((product.price - product.promotionPrice) /
                        product.price) *
                        100
                    )}
                    %
                  </span>
                </Typography>
                <Typography variant="subtitle1" align="right">
                  {library.AVAILABLE}: {formatTotal(lusFund.amount)}{" "}
                  {lusFund.coin}
                </Typography>
                <Typography variant="subtitle2">
                  ({library.SHOPPING_PRICE_NOTE})
                </Typography>
                <Button
                  fullWidth
                  onClick={() => setShowCheckoutForm(true)}
                  disabled={isSoldOut}
                >
                  {isSoldOut ? library.SOLD_OUT : library.BUY_NOW}
                </Button>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      minHeight: 40,
                      minWidth: 40,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#ceb994",
                      borderRadius: "50%",
                      marginRight: "1em",
                    }}
                  >
                    <VerifiedUser style={{ fontSize: "2em", fill: "#fff" }} />
                  </div>
                  <p>
                    {library.SHOPPING_NOTE_1},{" "}
                    <a href="#">{library.LEARN_MORE}</a>
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      height: 40,
                      width: 40,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#ceb994",
                      borderRadius: "50%",
                      marginRight: "1em",
                    }}
                  >
                    <VerifiedUser style={{ fontSize: "2em", fill: "#fff" }} />
                  </div>
                  <p>{library.SHOPPING_NOTE_2}</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <div
                    style={{
                      height: 40,
                      width: 40,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#ceb994",
                      borderRadius: "50%",
                      marginRight: "1em",
                    }}
                  >
                    <VerifiedUser style={{ fontSize: "2em", fill: "#fff" }} />
                  </div>
                  <p>
                    {library.SHOPPING_NOTE_3},{" "}
                    <a href="#">{library.LEARN_MORE}</a>
                  </p>
                </div>
              </Grid>
            </Grid>
            <Typography variant="h3">
              <span>{library.PRODUCT_DETAIL}</span>
            </Typography>
            <Typography as="p" align="left" style={{ paddingBottom: "2em" }}>
              {product.description}
            </Typography>
          </Container>
        </LazyLoad>
        <Drawer
          anchor="left"
          open={showCheckoutForm}
          className={"custom-modal-vk"}
        >
          <LusCheckoutForm
            product={product}
            close={() => {
              setShowCheckoutForm(false);
              get(`/shopping-service/lus-shopping/product?id=${id}`, (data) => {
                setProduct(data);
              });
            }}
          />
        </Drawer>
      </div>
    )
  );
}

export default ProductDetail;
