import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  Button,
  Container,
  Form,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import { deviceInfo, makeID } from "../../components/others/OtherFunction";
import { checkEmpty } from "../../components/others/Validate";
import { RETURN_SAFE_LUS_KEY } from "../../settings";
import { RETURN_TO_EASY_BUY } from "../../settings/constant";
import { post } from "../../utils/api";
import {
  getDeviceSecret,
  setAccessToken,
  setDeviceSecret,
} from "../../utils/auth";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Login() {
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showOTP, setShowOTP] = useState(false);
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const [gaCode, setGACode] = useState("");
  const [showGA, setShowGA] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  let query = useQuery();

  const handleLogin = () => {
    setLoading(true);
    setError(null);
    if (checkEmpty(email) || password.length === 0) {
      setError(library.PLEASE_ENTER_EMAIL_AND_PASSWORD);
      setLoading(false);
    } else {
      let deviceSecret = getDeviceSecret();
      if (!deviceSecret) {
        deviceSecret = makeID(64);
        setDeviceSecret(deviceSecret);
      }
      post(
        "/user-service/user/login",
        {
          email: email.trim(),
          password,
          device: deviceInfo(),
          gaCode,
          emailOtp: otp,
          secret: deviceSecret,
        },
        (data) => {
          setAccessToken(data.accessToken);
          setTimeout(() => {
            if (query.get("return") === RETURN_SAFE_LUS_KEY) {
              window.location.reload();
            } else {
              dispatch({ type: "LOGIN" });
              if (
                history.location.state &&
                history.location.state.return === RETURN_TO_EASY_BUY
              ) {
                history.goBack();
              } else {
                history.push("/");
              }
            }
          }, 100);
        },
        (error) => {
          const { code } = error;
          setLoading(false);
          if (code === "EMAIL_OTP_REQUIRED") {
            setShowOTP(true);
            setShowGA(false);
          } else if (code === "GACODE_REQUIRED") {
            setShowGA(true);
            setShowOTP(false);
          } else setError(library[code]);
        }
      );
    }
  };

  return (
    <LazyLoad>
      <Container className="custom-form">
        <Segment textAlign="left" loading={loading}>
          <Form onSubmit={handleLogin} error inverted>
            <Header as="h1" textAlign="center" className="blue-color">
              {library.LOGIN.toUpperCase()}
            </Header>
            <Form.Input
              label="Email"
              placeholder={library.PLEASE_ENTER_EMAIL}
              onChange={(e) => setEmail(e.target.value)}
              id="username"
              name="username"
              maxLength="128"
            />
            <Form.Input
              label={library.PASSWORD}
              placeholder={library.PLEASE_ENTER_PASSWORD}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "input" : "password"}
              id="password"
              name="password"
              action={{
                icon: showPassword ? "eye" : "eye slash",
                onClick: () => setShowPassword(!showPassword),
                type: "button",
              }}
              maxLength="16"
            />
            {showOTP && (
              <Form.Input
                label="OTP email"
                placeholder={library.PLEASE_ENTER_OTP}
                onChange={(e) => setOtp(e.target.value)}
                maxLength="6"
              />
            )}
            {showGA && (
              <Form.Input
                label={library.GA_CODE}
                placeholder={library.PLEASE_ENTER_GA_CODE}
                onChange={(e) => setGACode(e.target.value)}
                maxLength="6"
              />
            )}
            <Message error content={error} />
            <Button type="submit" fluid className="spwallet-button">
              {library.LOGIN}
            </Button>
            <br />
            <Link to="register">{library.CREATE_NEW_ACCOUNT}</Link>
            <br />
            <Link to="forgot-password">{library.FORGOT_PASSWORD}</Link>
            <br />
            <Link to="/">{library.HOMEPAGE}</Link>
          </Form>
        </Segment>
      </Container>
    </LazyLoad>
  );
}

export default Login;
