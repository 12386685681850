import React from "react";
import { Container, Header, Segment } from "semantic-ui-react";

function Policy() {
  return (
    <Container textAlign="justified">
      <Segment>
        <Header as="h1">Privacy and Policy</Header>
        <p>
          This Privacy Policy describes the policies and procedures of SPWallet
          (“we,” “our,” or “us”) pertaining to the collection, use, and
          disclosure of your information on{" "}
          <a href="https://spwallet.org/">www.spwallet.org</a> and related
          mobile applications and products we offer (the “Services” or
          “SPWallet”).
        </p>
        <Header>OVERVIEW</Header>
        <p>
          Your privacy is important to us. At SPWallet, we follow a few
          fundamental principles: We don’t ask you for personally identifiable
          information (defined below). That being said, your contact
          information, such as your phone number, social media handle, or email
          address (depending on how you contact us), may be collected when you
          communicate with us or if you report a bug or other error related to
          SPWallet. We don’t share your information with third parties except to
          deliver you our Services and products, comply with the law, make
          SPWallet better, protect our rights, or effectuate a business
          transfer. We’re not a huge, faceless corporation. We’re just
          developers trying to deliver an incredible product. If you have any
          questions or concerns about this policy, please reach out to us
          at support@spwallet.org. HOW YOU ACCEPT THIS POLICY By using SPWallet,
          including downloading one of our mobile applications, visiting our
          website, you agree to the use, disclosure, and procedures outlined in
          this Privacy Policy.
        </p>
        <Header>WHAT PERSONAL INFORMATION DO WE COLLECT FROM OUR USERS?</Header>
        <div>
          We may collect your Personal Information if you use the website,
          SPWallet applications, open an Account to use the Platform, or make
          any Transaction on the Platform. Types of Personal Information we
          collect may include:
          <br />
          Your name;
          <br />
          Identify your image;
          <br />
          Your address
          <br />
          Phone number
          <br />
          Your e-mail address
          <br />
          Your bank details include account number;
          <br />
          Your date of birth
          <br />
          Your industry.
          <br />
          We may use your personal information for the following purposes:
          <br />
          To enable you to open and operate an Account on the Platform;
          <br />
          To enable you to complete Transactions on the Platform;
          <br />
          Contact us for any questions;
          <br />
          Instructions for using our website;
          <br />
          As required for specified purposes;
          <br />
          Provide you with information about products and promotions that may
          interest you, from ourselves and a third party, although only if you
          have agreed to receive such information;
          <br />
          For example market research: Surveying Users' needs and opinions on
          issues, such as our performance, etc.
          <br />
          We will process your personal information only for the purpose it was
          provided to us.
          <br />
          Further, we may collect some Personal Information from you when you
          communicate with us, like other online services, we also collect a
          variety of Non-Personal Information, including: Information you create
          through the SPWallet’s website or mobile applications, including
          public wallet addresses. Various analytics data, such as: (i) the IP
          address of the computer you use to access SPWallet; (ii) the type of
          browser software you are using; (iii) the operating system you are
          using; (iv) the date and time you access or use SPWallet; (v) the
          website address, if any, that linked you to SPWallet; (vi) the website
          address, if any, you leave our website and travel to; and (vii) other
          non-personally identifiable traffic data.
        </div>
        <div>
          We will keep your Personal Information to the extent necessary to us,
          for the purpose described in this Privacy Policy and our legal and
          regulatory requirements. In accordance with our written commitment, we
          will keep the Account and Personal Information for at least 5 years
          after the User closes.
        </div>
        <Header>Information We Automatically Collect</Header>
        <div>
          Users who visit our website or use our application may have their
          device’s IP address logged for the purpose of generating anonymous
          statistics or troubleshooting the performance of our web servers. Your
          IP address will not be used to track or identify you, but may be used
          to determine your geographic location in order to determine which of
          our services you are presented with. Users of our website or mobile
          applications will receive an anonymous unique device id (“UDID”) for
          the purpose of identifying the device to SPWallet servers. This UDID
          will not be tied to users’ identities, but will be used for debugging
          purposes and to differentiate devices when users access our Services
          using multiple devices.
        </div>
        <Header>Third Party Services</Header>
        <div>
          Certain features on SPWallet rely on various third-party products and
          services (collectively “Third Party Services”), such as the Ethereum
          network, Google Analytics, Apple’s application platform, Coinbase,
          Changelly, Fabric, and Shapeshift. These services may collect certain
          Personal Information, such as your public Wallet addresses. SPWallet
          uses Google Analytics, a web analytics service provided by Google,
          Inc. (“Google”). Google uses cookies to help the website analyze how
          users use our website. The information generated by the cookie about
          your use of our website (including your IP address) will be
          transmitted to and stored by Google on servers in the United States.
          Google will use this information for the purpose of evaluating your
          use of the website, compiling reports on website activity for website
          operators and providing other services relating to website activity
          and internet usage. Google may also transfer this information to third
          parties where required to do so by law, or where such third parties
          process the information on Google’s behalf. Google will not associate
          your IP address with any other data held by Google. You may choose to
          accept the cookies by selecting the appropriate settings on your
          browser if you do this you may not be able to use the full
          functionality of our website. By using our website, you consent to the
          processing of data about you by Google in the manner and for the
          purposes set out above. Please note that your use of these Third Party
          Services is governed by their respective Terms of Service and Privacy
          Policies. We use and disclose any collected information in accordance
          with our own Privacy Policy.
        </div>
        <Header>HOW WE USE THE INFORMATION WE GATHER</Header>
        <div>
          We primarily use the limited information we collect to enhance
          SPWallet. Except if we sell all or a portion of our business, or as
          otherwise described below, we do not rent, trade, or sell your
          Personal Information. Use of Information to Provide SPWallet to You
          Some ways we may use your Personal Information are to: Contact you
          when necessary; Respond to your comments, questions, or issues related
          to bugs or errors with SPWallet; Provide you with additional
          information; Send you information and marketing materials about
          services and products available through SPWallet, using push
          notifications or other means; Train our team members; or Other
          internal business purposes. Aggregated Personal Data and Non-Personal
          Information We may share or disclose aggregated Personal Data or
          Non-Personal Information with service providers or with other persons
          we conduct business with, including but not limited potential
          third-parties for the purpose of showcasing the performance of the
          company. These service providers and other persons may also share with
          us aggregated Non-Personal Information that they have independently
          developed or acquired. Additionally, we may combine aggregate
          information from the pixel tags, web beacons, and cookies with similar
          data we collect from other visitors to help us improve our Services.
          When doing so, we do our best to ensure that the any aggregated
          information cannot be linked back to you.
        </div>
        <Header>Agents or Third Party Partners</Header>
        <div>
          We may provide your Personal Information to our employees,
          contractors, agents, service providers, and designees (“Agents”) to
          enable them to perform certain services for us exclusively, including:
          Improvement of website-related services and features; and Perform
          maintenance services. Business Transfers We may choose to buy or sell
          assets. In these types of transactions, customer information is
          typically one of the business assets that would be transferred. Also,
          if we (or our assets) are acquired, or if we go out of business, enter
          bankruptcy, or go through some other change of control, your Personal
          Information could be one of the assets transferred to or acquired by a
          third party. By accepting this Privacy Policy, as outlined above, you
          consent to any such transfer.
        </div>
        <Header>Protection of Us and Others</Header>
        <div>
          We reserve the right to access, read, preserve, and disclose any
          information that we reasonably believe is necessary to: comply with
          the law or a court order; cooperate with law enforcement; enforce or
          apply our Terms of Use and other agreements; or protect the rights,
          property, or safety of SPWallet, our employees, our users, or others.
        </div>
        <Header>WHAT PERSONAL INFORMATION CAN I ACCESS OR CHANGE?</Header>
        <div>
          You can request access to the information we have collected from you.
          You can do this by contacting us at support@spwallet.org. We will make
          sure to provide you with a copy of the data we process about you. To
          comply with your request, we may ask you to verify your identity. We
          will fulfill your request by sending your copy electronically. For any
          subsequent access request, we may charge you with an administrative
          fee. If you believe that the information we have collected is
          incorrect, you are welcome to contact us so we can update it and keep
          your data accurate. Any data that is no longer needed for purposes
          specified in the “How We Use the Information We Gather” section will
          be deleted after ninety (90) days. Wallet addresses created through
          the SPWallet application cannot be deleted from the Tron blockchain,
          therefore we are unable to delete this personal information. If at any
          point you wish for SPWallet to delete information about you, you may
          contact us at support@spwallet.org.
        </div>
        <Header>DATA RETENTION</Header>
        <div>
          If you delete your Wallet or addresses from the SPWallet mobile
          application, uninstall SPWallet mobile applications from your device,
          or request that your information be deleted, we still may retain some
          information that you have provided to us to maintain SPWallet or to
          comply with relevant laws.
        </div>
        <Header>DATA SECURITY</Header>
        <div>
          Support SPexchange.io account login. SPwallet.org support log in with
          your SPexchange.io account. With your authorization, you can log in
          both SPexchange.io exchange and your SPwallet.org with one account and
          enjoy a one-stop solution from storage, transfer to exchange.
        </div>
        <br />
        <div>
          We are committed to making sure your information is protected and have
          selected third-party vendors which use the Tron network, that help
          keep your Personal Information safe. Unfortunately, we do not control
          these third parties and therefore cannot guarantee complete security.
          We employ several physical and electronic safeguards to keep your
          information safe, including encrypted user passwords, two factor
          verification and authentication on passwords where possible, and
          securing all connections with industry standard transport layer
          security. Even with all these precautions, we cannot fully guarantee
          against the access, disclosure, alteration, or deletion of data
          through events, including but not limited to hardware or software
          failure or unauthorized use. Any information that you provide to us is
          done so entirely at your own risk.
        </div>
        <Header>CHILDREN</Header>
        <div>
          We are especially sensitive about children’s information. Our Services
          are not targeted towards children, and we don’t knowingly collect
          information from children under the age of 18. If you are a parent or
          legal guardian of a minor child, we will treat any information that
          you provide us while using SPWallet on behalf of your minor child as
          Personal Information as otherwise provided in this Privacy Policy. If
          you have questions concerning our information practices with respect
          to children, or if you learn that a child under the age of 18 has used
          SPWallet, created a user account, or provided us with personal
          information, please email us at support@spwallet.org
        </div>
        <Header>
          ONLINE TRACKING AND HOW WE RESPOND TO DO NOT TRACK SIGNALS
        </Header>
        <div>
          Online tracking is the collection of data about an individual’s
          Internet activity used to deliver targeted advertisements and for
          other purposes. Some web browsers (including Safari, Internet
          Explorer, Firefox, and Chrome) incorporate a “Do Not Track” (DNT) or
          similar feature that signals to websites that a visitor does not want
          to have his/her online activity and behavior tracked. If an online
          service elects to respond to a particular DNT signal, the service may
          refrain from collecting certain personal information about the
          browser’s user. Not all browsers offer a DNT option and there is
          currently no industry consensus as to what constitutes a DNT signal.
          For these reasons, many website operators, including SPWallet, do not
          take action to respond to DNT signals. For more information about DNT
          signals, visit{" "}
          <a href="http://allaboutdnt.com">http://allaboutdnt.com</a>.
        </div>
        <Header>CHANGES AND UPDATES TO PRIVACY POLICY</Header>
        <div>
          We reserve the right to update and revise this privacy policy at any
          time. We occasionally review this Privacy Policy to make sure it
          complies with applicable laws and conforms to changes in our business.
          We may need to update this Privacy Policy, and we reserve the right to
          do so at any time. If we do revise this Privacy Policy, we will update
          the “Effective Date” at the bottom of this page so that you can tell
          if it has changed since your last visit and will do our best to notify
          you. Please review this Privacy Policy regularly to ensure that you
          are aware of its terms. Any use of SPWallet after an amendment to our
          Privacy Policy constitutes your acceptance to the revised or amended
          agreement.
        </div>
        <Header>INTERNATIONAL USERS AND VISITORS</Header>
        <div>
          SPWallet is hosted in the England. If you are a user accessing the
          Services from the European Union, Asia, or any other region with laws
          or regulations governing personal data collection, use, and disclosure
          that differ from England laws, please be advised that through your
          continued use of the Services, which is governed by England law, you
          are transferring your Personal Information to the England and you
          consent to that transfer.
        </div>
        <Header>QUESTIONS</Header>
        <div>
          We’d be happy to answer them. Shoot us an email or send us a note:
          <br />
          Email: support@spwallet.org  <br />
          Thanks for reading our Privacy Policy!
        </div>
      </Segment>
    </Container>
  );
}

export default Policy;
