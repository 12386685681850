import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, Segment, Table } from "semantic-ui-react";
import { _getLusStakingHistory } from "../../redux/walletReducer";
import { LUSM_SYMBOL, LUS_SYMBOL, M_USD } from "../../constant/CoinConstant";
import { formatUSD, formatStakingTime } from "../../settings/format";
import { LUS_FIRST_PRICE } from "../../constant/PriceConstants";

function LusStakingHistory({ reload }) {
  const { wallet, setting } = useSelector((state) => state);
  const { library } = setting;
  const { lusStakingHistory } = wallet;
  const dispatch = useDispatch();
  const { AMOUNT, STATUS, NO_RECORDS_FOUND } = library;

  return (
    lusStakingHistory && (
      <>
        <Table
          unstackable
          basic="very"
          compact="very"
          celled
          textAlign="left"
          singleLine
          selectable
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>{AMOUNT}</Table.HeaderCell>
              <Table.HeaderCell>{library.PROFIT}</Table.HeaderCell>
              <Table.HeaderCell>Total Profit</Table.HeaderCell>
              <Table.HeaderCell>{library.START_TIME}</Table.HeaderCell>
              <Table.HeaderCell>{library.END_TIME}</Table.HeaderCell>
              <Table.HeaderCell>{STATUS}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {lusStakingHistory.items.length > 0 ? (
              lusStakingHistory.items.map((item, index) => (
                <Table.Row key={index}>
                  <Table.Cell className="id">#{item.id}</Table.Cell>
                  <Table.Cell>
                    {formatUSD(item.amount * item.price)} {LUSM_SYMBOL}
                  </Table.Cell>
                  <Table.Cell>
                    {item.estimateApy}
                    {item.quote === LUS_SYMBOL
                      ? `% / ${library.MONTH}`
                      : `% / ${library.YEAR}`}
                  </Table.Cell>
                  <Table.Cell>
                    $
                    {formatUSD(
                      item.yieldAmountV2 + item.yieldAmount * LUS_FIRST_PRICE
                    )}
                  </Table.Cell>
                  <Table.Cell>{formatStakingTime(item.time)}</Table.Cell>
                  <Table.Cell>
                    {formatStakingTime(
                      item.time + (item.duration + 1) * 24 * 60 * 60 * 1000
                    )}
                  </Table.Cell>
                  <Table.Cell>{library[item.status]}</Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6}>
                  <Segment basic textAlign="center">
                    {NO_RECORDS_FOUND}
                  </Segment>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        {lusStakingHistory.pageCount > 1 && (
          <Segment vertical textAlign="center">
            <Pagination
              activePage={lusStakingHistory.page}
              totalPages={lusStakingHistory.pageCount}
              onPageChange={(e, { activePage }) =>
                dispatch(
                  _getLusStakingHistory({ page: activePage, pageSize: 10 })
                )
              }
              secondary
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              siblingRange={1}
              boundaryRange={0}
            />
          </Segment>
        )}
      </>
    )
  );
}

export default LusStakingHistory;
