import { Drawer } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { Grid, GridColumn, Icon, Segment, Button } from "semantic-ui-react";

function ConfirmPopup({
  open = false,
  confirm = "",
  close = "",
  title = "",
  content = "",
}) {
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  return (
    <Drawer anchor={"left"} open={open} className={"custom-modal-vk"}>
      <Segment
        style={{
          minWidth: 300,
        }}
        className="spwallet-container"
      >
        <Segment vertical>
          <Grid columns={2}>
            <GridColumn>{title}</GridColumn>
            <GridColumn textAlign="right">
              <Icon name="x" link onClick={close} />
            </GridColumn>
          </Grid>
        </Segment>
        <Segment vertical>{content}</Segment>
        <Segment vertical textAlign="right">
          <Button onClick={close} className="btn-border" negative>
            {library.CLOSE}
          </Button>
          <Button className="btn-bg" onClick={confirm} positive>
            {library.CONFIRM}
          </Button>
        </Segment>
      </Segment>
    </Drawer>
  );
}

export default ConfirmPopup;
