import React from "react";
import LazyLoad from "react-lazyload";
import { Container } from "semantic-ui-react";
import SwapForm from "../../components/swap/NewSwapForm";
import SwapHistory from "../../components/swap/SwapHistory";
import "../../components/swap/Swap.scss";

function Swap() {
  return (
    <Container textAlign="left" id="swap">
      <LazyLoad scroll>
        <SwapForm />
        <SwapHistory />
      </LazyLoad>
    </Container>
  );
}

export default Swap;
