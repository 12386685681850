import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import { SET_MODAL_CONTENT } from "../../redux/settingReducer";
import { get } from "../../utils/api";

function ConfirmWithdraw() {
  const { token } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    get(
      `/fund-service/withdraw/confirm/${token}`,
      () => {
        dispatch({
          type: SET_MODAL_CONTENT,
          payload: {
            content: "CONFIRM_WITHDRAW_SUCCESS",
            callback: () => {
              window.location.replace("/");
            },
          },
        });
      },
      (error) => {
        console.error(error);
        dispatch({
          type: SET_MODAL_CONTENT,
          payload: {
            content: error.code,
            callback: () => {
              window.location.replace("/");
            },
          },
        });
      }
    );
  }, [dispatch, token]);

  return (
    <Dimmer active>
      <Loader />
    </Dimmer>
  );
}

export default ConfirmWithdraw;
