import { get } from "../utils/api";

const FETCH_LIST_F1_AFFILIATE = "FETCH_LIST_F1_AFFILIATE";
const FETCH_LUS_STATISTIC = "FETCH_LUS_STATISTIC";

const initialState = {
  ListF1Affiliate: null,
  LusStatistic: {},
};

export const _getListF1Affiliate = ({ page = 1, pageSize = 10 }) => (
  dispatch
) => {
  get(
    `/lus-user-service/lus-user/list-f1-affiliate?page=${page}&pageSize=${pageSize}`,
    (data) => {
      dispatch({ type: FETCH_LIST_F1_AFFILIATE, payload: data });
    }
  );
};

export const _getLusStatistic = () => (dispatch) => {
  get("/lus-user-service/lus-user/statistic", (data) => {
    dispatch({
      type: FETCH_LUS_STATISTIC,
      payload: data,
    });
  });
};

export const LusReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LIST_F1_AFFILIATE:
      return { ...state, ListF1Affiliate: action.payload };
    case FETCH_LUS_STATISTIC:
      return { ...state, LusStatistic: action.payload };
    default:
      return { ...state };
  }
};
