import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { Close, CloseOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatMoney, formatTotal } from "../../settings/format";
import { get, post, _delete } from "../../utils/api";
import { CustomToast } from "../../settings/actions";
import { Link } from "react-router-dom";
import { LUS_SYMBOL } from "../../constant/CoinConstant";
import { Dimmer, Loader } from "semantic-ui-react";
import ConfirmPopup from "../others/ConfirmPopup";

function LusCheckoutForm({ close, product }) {
  const [addresses, setAddresses] = useState([]);
  const [isNewAddress, setIsNewAddress] = useState(false);
  const { user, wallet } = useSelector((state) => state);
  const { fundList } = wallet;
  const { data } = user;
  const [step, setStep] = useState(0);
  const [address, setAddress] = useState(null);
  const [note, setNote] = useState("");
  const [checkTerm, setCheckTerm] = useState(false);
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const [newName, setNewName] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const [newAddress, setNewAddress] = useState("");
  const [newCountry, setNewCountry] = useState("");
  const [newCity, setNewCity] = useState("");
  const lusFund = fundList.filter((item) => item.coin === LUS_SYMBOL)[0];
  const [loading, setLoading] = useState(false);
  const [removeAddress, setRemoveAddress] = useState(null);

  useEffect(() => {
    get(`/shopping-service/lus-shopping/product/addresses`, (data) => {
      setAddresses(data);
      if (data.length > 0) {
        setAddress(data[0]);
      } else {
        setIsNewAddress(true);
      }
    });
  }, []);

  const _onSubmit = (e) => {
    e.preventDefault();
    if (!step) {
      setStep(1);
    } else {
      if (checkTerm) {
        if (lusFund.amount >= product.promotionPrice) {
          setLoading(true);
          if (isNewAddress) {
            post(
              `/shopping-service/lus-shopping/product/address`,
              {
                customerName: newName,
                customerPhone: newPhone,
                customerAddress: newAddress + " " + newCity + " " + newCountry,
              },
              (newAddress) => {
                post(
                  `/shopping-service/lus-shopping/product/createorder`,
                  {
                    customerId: data.id,
                    addressId: newAddress,
                    note,
                    orderItems: [
                      {
                        productId: product.id,
                        quantity: 1,
                      },
                    ],
                  },
                  () => {
                    CustomToast("success", "Create Order Successfully");
                    close();
                    setLoading(false);
                  }
                );
              },
              (error) => {
                CustomToast("error", library[error.code]);
                setLoading(false);
              }
            );
          } else {
            post(
              `/shopping-service/lus-shopping/product/createorder`,
              {
                customerId: data.id,
                addressId: address.id,
                note,
                orderItems: [
                  {
                    productId: product.id,
                    quantity: 1,
                  },
                ],
              },
              () => {
                CustomToast("success", "Create Order Successfully");
                close();
                setLoading(false);
              },
              (error) => {
                CustomToast("error", library[error.code]);
                setLoading(false);
              }
            );
          }
        } else {
          CustomToast("error", library["UNAVAILABLE_BALANCE"]);
        }
      } else {
        CustomToast("error", library["PLEASER_CHECK_TOS"]);
      }
    }
  };

  const _deleteAddress = () => {
    _delete(
      `/shopping-service/lus-shopping/product/address/${removeAddress}`,
      {},
      () => {
        CustomToast("success", "Delete address Successfully");
        setRemoveAddress(null);
        get(`/shopping-service/lus-shopping/product/addresses`, (data) => {
          setAddresses(data);
          setAddress(data.length > 0 ? data[0].id : null);
        });
      },
      (error) => console.log(error)
    );
  };

  return (
    <form
      autoComplete="off"
      onSubmit={_onSubmit}
      style={{
        backgroundColor: "#fff",
        padding: "1em 0",
      }}
      id="checkout-form"
    >
      <Dimmer active={loading} inverted>
        <Loader inverted />
      </Dimmer>
      <CloseOutlined
        style={{
          marginRight: "1em",
          float: "right",
          cursor: "pointer",
        }}
        onClick={close}
      />
      <Grid
        container
        style={{
          backgroundColor: "#fff",
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            padding: "1em",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <Typography variant="h5">{library.ORDER}</Typography>
            <br />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <img
                src={product.imageList && product.imageList.split(",")[0]}
                width={100}
                alt=""
                style={{ paddingRight: "1em" }}
              />
              <div>
                <p>{product.productName}</p>
                <p style={{ textAlign: "right" }}>
                  {formatMoney(product.promotionPrice)} {LUS_SYMBOL}
                </p>
              </div>
            </div>
          </div>
          <div>
            <hr />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span>{library.SUBTOTAL}</span>
              <span>
                {formatTotal(product.price)} {LUS_SYMBOL}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span>{library.DISCOUNT}</span>
              <span>
                -{formatTotal(product.price - product.promotionPrice)}{" "}
                {LUS_SYMBOL}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>{library.TOTAL}</span>
              <div style={{ textAlign: "right" }}>
                <div>
                  {formatTotal(product.promotionPrice)} {LUS_SYMBOL}
                </div>
                <div>
                  ~${formatTotal(product.promotionPrice * lusFund.usdPrice)}
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} style={{ padding: "1em" }}>
          {step ? (
            <>
              <Typography variant="h5">
                {library.CONFIRM_INFORMATION}
              </Typography>
              <br />
              <div className="address" style={{ height: 239 }}>
                <>
                  <div>
                    <div>{library.NAME}:</div>
                    <div style={{ textAlign: "right" }}>
                      {isNewAddress ? newName : address.customerName}
                    </div>
                  </div>
                  <div>
                    <div>{library.PHONE}:</div>
                    <div style={{ textAlign: "right" }}>
                      {isNewAddress ? newPhone : address.customerPhone}
                    </div>
                  </div>
                  <div>
                    <div>{library.ADDRESS}:</div>
                    <div style={{ textAlign: "right" }}>
                      {isNewAddress
                        ? newAddress + " " + newCity + " " + newCountry
                        : address.customerAddress}
                    </div>
                  </div>
                  <TextField
                    label="Note"
                    fullWidth
                    id="note"
                    multiline
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </>
              </div>
              <div className="group-btn">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkTerm}
                      onChange={(e) => setCheckTerm(e.target.checked)}
                      name="policy"
                      color="primary"
                    />
                  }
                  label={
                    <label>I agree to the Luxury outlet street's Term</label>
                  }
                />
                <div style={{ display: "flex" }}>
                  <Button
                    fullWidth
                    className="back-btn"
                    type="button"
                    onClick={() => {
                      setStep(0);
                    }}
                  >
                    {library.BACK}
                  </Button>
                  <Button
                    fullWidth
                    className="submit-btn"
                    type="submit"
                    disabled={isNewAddress === "chose" && !address}
                  >
                    {library.CONFIRM}
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div>
                <Typography variant="h5">
                  {library.SHIPPING_INFORMATION}
                </Typography>
                <br />
                <RadioGroup
                  aria-label="gender"
                  name="gender1"
                  style={{
                    flexDirection: "row",
                  }}
                  value={isNewAddress}
                  onChange={(e) => setIsNewAddress(e.target.value === "true")}
                >
                  <FormControlLabel
                    value={false}
                    control={<Radio />}
                    label="Chose address"
                  />
                  <FormControlLabel
                    value={true}
                    control={<Radio />}
                    label="Add new address"
                  />
                </RadioGroup>
                <div className="address">
                  {isNewAddress ? (
                    //add new address form
                    <div>
                      <TextField
                        label={library.NAME}
                        fullWidth
                        id="name"
                        required
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                      />
                      <TextField
                        label={library.PHONE}
                        fullWidth
                        id="phone"
                        required
                        value={newPhone}
                        onChange={(e) => setNewPhone(e.target.value)}
                      />
                      <TextField
                        label={library.ADDRESS}
                        fullWidth
                        id="address"
                        required
                        value={newAddress}
                        onChange={(e) => setNewAddress(e.target.value)}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <TextField
                          label={library.CITY}
                          id="city"
                          required
                          value={newCity}
                          onChange={(e) => setNewCity(e.target.value)}
                        />
                        <TextField
                          label={library.COUNTRY}
                          id="country"
                          required
                          value={newCountry}
                          onChange={(e) => setNewCountry(e.target.value)}
                        />
                      </div>
                    </div>
                  ) : (
                    //choose address list
                    <div className="address-list">
                      {addresses.map((item, index) => (
                        <div
                          key={index}
                          className={
                            address && address.id === item.id
                              ? "active item"
                              : "item"
                          }
                          onClick={() => setAddress(item)}
                        >
                          <div style={{ fontWeight: 600 }}>
                            {item.customerName}
                          </div>
                          <div>
                            {library.ADDRESS}: {item.customerAddress}
                          </div>
                          <div>
                            {library.PHONE}: {item.customerPhone}
                          </div>
                          <Link
                            style={{
                              position: "absolute",
                              top: "0.2em",
                              right: "0.2em",
                              color: "#000",
                            }}
                            onClick={() => setRemoveAddress(item.id)}
                            to="#"
                          >
                            <Close />
                          </Link>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="group-btn">
                <div></div>
                <Button
                  fullWidth
                  className="submit-btn"
                  type="submit"
                  disabled={!isNewAddress && !address}
                >
                  {library.CHECKOUT}
                </Button>
              </div>
            </>
          )}
        </Grid>
      </Grid>
      <ConfirmPopup
        open={removeAddress !== null}
        close={() => setRemoveAddress(null)}
        confirm={_deleteAddress}
        title={library.NOTIFICATION}
        content={library.CONFIRM_CANCEL_ORDER_ADDRESS}
      />
    </form>
  );
}

export default LusCheckoutForm;
