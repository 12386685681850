import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, Segment, Table } from "semantic-ui-react";
import { LUS_SYMBOL } from "../../constant/CoinConstant";
import { _getStakingHistory } from "../../redux/walletReducer";
import { formatAmount, formatStakingTime } from "../../settings/format";

function StakingHistory() {
  const { wallet, setting } = useSelector((state) => state);
  const { library } = setting;
  const { stakingHistory } = wallet;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(_getStakingHistory({}));
  }, [dispatch]);

  const { AMOUNT, STATUS, NO_RECORDS_FOUND } = library;

  return (
    stakingHistory && (
      <Segment basic vertical textAlign="center">
        <Segment basic vertical className="spwallet-history">
          <Table
            unstackable
            basic="very"
            compact="very"
            celled
            inverted
            textAlign="left"
            singleLine
            selectable
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>{AMOUNT}</Table.HeaderCell>
                <Table.HeaderCell>{library.PROFIT}</Table.HeaderCell>
                <Table.HeaderCell>{library.START_TIME}</Table.HeaderCell>
                <Table.HeaderCell>{library.END_TIME}</Table.HeaderCell>
                <Table.HeaderCell>{STATUS}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {stakingHistory.items.length > 0 ? (
                stakingHistory.items.map((item, index) => (
                  <Table.Row key={index}>
                    <Table.Cell className="id">#{item.id}</Table.Cell>
                    <Table.Cell>
                      {formatAmount(item.amount * item.price)} {item.coin}
                    </Table.Cell>
                    <Table.Cell>
                      {item.estimateApy}
                      {item.quote === LUS_SYMBOL
                        ? `% / ${library.MONTH}`
                        : `% / ${library.YEAR}`}
                    </Table.Cell>
                    <Table.Cell>{formatStakingTime(item.time)}</Table.Cell>
                    <Table.Cell>
                      {formatStakingTime(
                        item.time + (item.duration + 1) * 24 * 60 * 60 * 1000
                      )}
                    </Table.Cell>
                    <Table.Cell>{library[item.status]}</Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={6}>
                    <Segment basic textAlign="center">
                      {NO_RECORDS_FOUND}
                    </Segment>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Segment>
        {stakingHistory.pageCount > 1 && (
          <Pagination
            activePage={stakingHistory.page}
            totalPages={stakingHistory.pageCount}
            onPageChange={(e, { activePage }) =>
              dispatch(_getStakingHistory({ page: activePage }))
            }
            pointing
            secondary
            inverted
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
            boundaryRange={0}
          />
        )}
      </Segment>
    )
  );
}

export default StakingHistory;
