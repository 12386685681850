import React from "react";
import { Grid, Header, Icon, Image, Segment } from "semantic-ui-react";
import { S3_URL } from "../../settings";

function LusRankDetail({ onClose }) {
  return (
    <Segment id="rank-detail">
      <Icon name="x" link onClick={onClose} />
      <Segment vertical>
        <Header>Account rank requirements</Header>
        <br />
        <Grid stretched style={{ justifyContent: "center" }} centered>
          <Grid.Column
            computer={3}
            tablet={5}
            mobile={16}
            className="item"
            verticalAlign="top"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <Image
              src={`${S3_URL}/spwallet/rank-${"silver"
                .replace(" ", "-")
                .toLowerCase()}.png`}
            />
            <div className="info">
              <div className="container">
                <p
                  style={{
                    backgroundColor: "#929292",
                  }}
                >
                  SILVER
                </p>
                <div>
                  <p>DIRECT SPONSOR</p>
                  <p style={{ fontWeight: 600 }}>10 Agencies</p>
                </div>
                <div
                  style={{
                    height: 170,
                  }}
                >
                  <p>Total sales</p>
                  <p style={{ fontWeight: 600 }}>A: $25k - B: $25k</p>
                  <p
                    style={{
                      fontWeight: 300,
                      fontStyle: "italic",
                      fontSize: "0.9em",
                    }}
                  >
                    Total sales volume in 2 different lines is $25,000
                  </p>
                </div>
                <div>
                  <p>Receive</p>
                  <p style={{ fontWeight: 600 }}>2%</p>
                  <p>more on total sales</p>
                </div>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column
            computer={3}
            tablet={5}
            mobile={16}
            className="item"
            verticalAlign="top"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <Image
              src={`${S3_URL}/spwallet/rank-${"gold"
                .replace(" ", "-")
                .toLowerCase()}.png`}
            />
            <div className="info">
              <div className="container">
                <p
                  style={{
                    backgroundColor: "#BDA476",
                  }}
                >
                  GOLD
                </p>
                <div>
                  <p>DIRECT SPONSOR</p>
                  <p style={{ fontWeight: 600 }}>10 Agencies</p>
                </div>
                <div
                  style={{
                    height: 170,
                  }}
                >
                  <p>Total Affiliate</p>
                  <p style={{ fontWeight: 600 }}>Silver - Silver - Silver</p>
                  <p
                    style={{
                      fontWeight: 300,
                      fontStyle: "italic",
                      fontSize: "0.9em",
                    }}
                  >
                    Have a minimum of 3 Silver in 3 different line
                  </p>
                </div>
                <div>
                  <p>Receive</p>
                  <p style={{ fontWeight: 600 }}>3%</p>
                  <p>more on total sales</p>
                </div>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column
            computer={3}
            tablet={5}
            mobile={16}
            className="item"
            verticalAlign="top"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <Image
              src={`${S3_URL}/spwallet/rank-${"diamond"
                .replace(" ", "-")
                .toLowerCase()}.png`}
            />
            <div className="info">
              <div className="container">
                <p
                  style={{
                    backgroundColor: "#fff",
                  }}
                >
                  DIAMOND
                </p>
                <div>
                  <p>DIRECT SPONSOR</p>
                  <p style={{ fontWeight: 600 }}>10 Agencies</p>
                </div>
                <div>
                  <p>Total Affiliate</p>
                  <p style={{ fontWeight: 600 }}>Gold - Gold - Gold</p>
                </div>
                <div>
                  <p>Total Shopping</p>
                  <p style={{ fontWeight: 600 }}>$20,000/month</p>
                </div>
                <div>
                  <p>Receive</p>
                  <p style={{ fontWeight: 600 }}>5%</p>
                  <p>more on total sales</p>
                </div>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column
            computer={3}
            tablet={5}
            mobile={16}
            className="item"
            verticalAlign="top"
            style={{ paddingRight: 0, paddingLeft: 0 }}
          >
            <Image
              src={`${S3_URL}/spwallet/rank-${"master"
                .replace(" ", "-")
                .toLowerCase()}.png`}
            />
            <div className="info">
              <div className="container">
                <p
                  style={{
                    backgroundColor: "#FF644E",
                  }}
                >
                  MASTER
                </p>
                <div>
                  <p>YOUR RANKING</p>
                  <p style={{ fontWeight: 600 }}>DIAMOND</p>
                </div>
                <div>
                  <p>Total Shopping</p>
                  <p style={{ fontWeight: 600 }}>$20,000/month</p>
                </div>
                <div>
                  <p>Receive</p>
                  <p style={{ fontWeight: 600 }}>5%</p>
                  <p>more on total sales</p>
                </div>
                <div>
                  <p>Receive</p>
                  <p style={{ fontWeight: 600 }}>2%</p>
                  <p>total shopping volume</p>
                </div>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Segment>
    </Segment>
  );
}

export default LusRankDetail;
