export const vi = {
  HOMEPAGE_BANNER_HEADER: "Ví tiền mã hoá tin cậy và an toàn nhất",
  HOMEPAGE_SUB_BANNER_HEADER_1:
    "Lưu trữ, mua bán các loại tài sản số và theo dõi giá cả hoặc trao đổi ",
  HOMEPAGE_SUB_BANNER_HEADER_2: "các loại tiền mã hóa đơn giản, nhanh chóng",
  HOMEPAGE_STRONG_HEADER:
    "Ai cũng xứng đáng có quyền dễ dàng truy cập tiền mã hóa",
  HOMEPAGE_STRONG_SUB_HEADER: "Ví SP sẽ giải quyết các vấn đề sau đây cho bạn",
  HOMEPAGE_STRONG_1:
    "Sở hữu Bitcoin và các loại tiền mã hoá chỉ trong vài phút",
  HOMEPAGE_STRONG_2:
    "Gửi, nhận và lưu trữ các loại tiền mã hoá nhanh chóng, an toàn",
  HOMEPAGE_STRONG_3:
    "Chuyển đổi qua lại giữa các loại tiền mã hoá mà không cần sàn giao dịch",
  HOMEPAGE_STRONG_4: "Giao dịch P2P nhanh chóng, tiện lợi",
  HOMEPAGE_STRONG_5: "Kiếm thêm thu nhập khi đang nắm giữ các loại tiền mã hoá",
  HOMEPAGE_STRONG_6: "Mua tiền mã hoá bằng thẻ ngân hàng",
  HOMEPAGE_APPLICATION_HEADER: "Sử dụng Mọi lúc, Mọi nơi 24/7",
  HOMEPAGE_APPLICATION_SUB_HEADER:
    "Ứng dụng và trang web SP Wallet cung cấp cho bạn một cách dễ dàng và nhanh chóng để bắt đầu mua/bán, lưu trữ ở mọi lúc mọi nơi",
  INFORMATION: "Thông tin",
  COMMUNITY: "Cộng đồng",
  TERM_OF_USE: "Điều khoản sử dụng",
  SUPPORT: "Hỗ trợ",
  LOGIN: "Đăng nhập",
  REGISTER: "Đăng ký",
  PASSWORD: "Mật khẩu",
  REFERRAL: "Giới thiệu",
  CONFIRM: "Xác nhận",
  PLEASE_ENTER_EMAIL: "Vui lòng nhập email",
  PLEASE_ENTER_PASSWORD: "Vui lòng nhập mật khẩu",
  PLEASE_ENTER_REFERRAL: "Vui lòng nhập mã giới thiệu",
  CREATE_NEW_ACCOUNT: "Tạo tài khoản mới",
  HAVE_A_ACCOUNT: "Đăng nhập",
  INVALID_EMAIL: "Email không hợp lệ",
  PASSWORD_TOO_SHORT: "Mật khẩu phải dài hơn 8 ký tự",
  NEW_PASSWORD_TOO_SHORT: "Mật khẩu mới phải dài hơn 8 ký tự",
  EMAIL_ALREADY_EXIST: "Email đã tồn tại",
  REFERRAL_NOT_EXIST: "Mã mời không tồn tại",
  INVALID_TOKEN: "Token không hợp lệ",
  TOKEN_ALREADY_USED: "Token đã được sử dụng",
  WRONG_CREDENTIALS: "Email và mật khẩu không đúng",
  PLEASE_ENTER_EMAIL_AND_PASSWORD: "Vui lòng nhập Email và mật khẩu",
  UNVERIFIED_DEVICE: "Vui lòng kiểm tra email xác thực thiết bị",
  OTP_WRONG_OR_USED: "OTP sai hoặc hết hạn",
  PLEASE_CHECK_EMAIL_FOR_RESET_PASSWORD:
    "Vui lòng kiểm tra email đặt lại mật khẩu",
  RESET_PASSWORD_SUCCESSFUL: "Đặt lại mật khẩu thành công",
  TOKEN_EXPIRED: "Token hết hạn",
  PLEASE_ENTER_WALLET_ADDRESS: "Vui lòng nhập địa chỉ ví",
  INVALID_WALLET_ADDRESS: "Địa chỉ ví không hợp lệ",
  PLEASE_ENTER_AMOUNT_WITHDRAW: "Vui lòng nhập số tiền muốn rút",
  WITHDRAW_BELOW_MINIMUM: "Số tiền rút thấp hơn số tiền tối thiểu",
  OLD_PASSWORD: "Mật khẩu cũ",
  NEW_PASSWORD: "Mật khẩu mới",
  CHANGE_PASSWORD: "Đổi mật khẩu",
  PLEASER_ENTER_BASE_AMOUNT: "Vui lòng nhập số tiền muốn chuyển đổi",
  BASE_AMOUNT_GREATER_MIN: "Vui lòng nhập số tiền lớn hơn số tiền tối thiểu",
  BASE_AMOUNT_LESS_MAX: "Vui lòng nhập số tiền thấp hơn số tối đa",
  INSUFFICIENT_FUNDS: "Số dư không khả dụng",
  INVALID_PARAMETERS: "Parameters is invalid",
  INVALID_OPERATION: "Operation is invalid",
  NOTIFICATION: "Thông báo",
  SWAP_SUCCESS: "Chuyển đổi thành công",
  CONFIRM_SUCCESS: "Thiết bị được xác thực thành công",
  REGISTER_SUCCESS: "Đăng ký thành công",
  RESET_PASSWORD: "Đặt lại mật khẩu",
  ACTIVE_ACCOUNT: "Tài khoản được kích hoạt thành công, vui lòng đăng nhập",
  OTP_EXPIRED: "OTP hết hạn",
  KYC_SUCCESS: "Xác thực tài khoản thành công, vui lòng chờ xác nhận",
  KYC_NOTIFICATION: "Tài khoản đã được xác mình hoặc đang chờ xác mình",
  WITHDRAW_SUCCESSFUL: "Rút tiền thành công",
  CONFIRM_WITHDRAW_SUCCESS: "Xác nhận rút tiền thành công",
  CANCEL_WITHDRAW: "Lệnh rút tiền đã hủy",
  WAITING_CONFIRM: "Đang đợi xác nhận email",
  PENDING: "Đang chờ",
  CANCELLED: "Đã đóng",
  CANCELED: "Đã đóng",
  EXTERNAL: "Rút tiền",
  INTERNAL: "Chuyển tiền",
  CANCEL_STAKING_SUCCESS: "Staking đã bị hủy",
  CREATE_STAKING_SUCCESS: "Tạo staking thành công",
  EXTERNAL_WITHDRAW_BLOCKED: "Rút tiền đã bị vô hiệu hóa",
  USER_WAS_BLOCKED:
    "Tài khoản đã bị khóa. Vui lòng liên hệ chúng tôi để được hổ trợ.",
  USER_NOT_ACTIVE: "Tài khoản đã bị khóa hoặc chưa kích hoạt",
  MISSING_GACODE: "Thiếu mã Google authenticator",
  WRONG_PASSWORD: "Mật khẩu không đúng",
  COIN_NOT_SUPPORT: "Coin không hỗ trợ",
  NETWORK_NOT_SUPPORT: "Network không hỗ trợ",
  DEPOSIT_DISABLE: "Nạp tiền đã bị vô hiệu hóa",
  WITHDRAW_DISABLE: "Rút tiền đã bị vô hiệu hóa",
  DISABLE_ACCOUNT_SUCCESS: "Tài khoản đã bị vô hiệu hóa",
  PLEASE_ENTER_GA_CODE: "Vui lòng nhập mã Google authenticator",
  WRONG_GACODE: "Mã Google authenticator không đúng",
  ENABLE_GA_CODE: "Google authenticator đã được kích hoạt",
  DISABLE_GA_CODE: "Google authenticator đã bị vô hiệu",
  GACODE_REQUIRED: "Vui lòng nhập mã Google authenticator",
  CONFIRMED: "Thành công",
  PRICE_EXPIRED: "Giá giao dịch đã hết hạn",
  PLEASE_ENTER_AMOUNT_MORE_THAN: "Vui lòng nhập số tiền lớn hơn",
  PLEASE_ENTER_AMOUNT_FOR_STAKING: "Vui lòng nhập số tiền muốn staking",
  UNKNOWN: "Dịch vụ không khả dụng",
  INVALID_ADDRESS: "Địa chỉ ví không hợp lệ",
  PLEASE_ENTER_OLD_PASSWORD: "Vui lòng nhập mật khẩu cũ",
  PLEASE_ENTER_NEW_PASSWORD: "Vui lòng nhập mật khẩu mới",
  PLEASE_ENTER_CONFIRM_PASSWORD: "Vui lòng nhập lại mật khẩu mới",
  CONFIRM_NEW_PASSWORD: "Nhập lại mật khẩu mới",
  CONFIRM_PASSWORD_VS_NEW_PASSWORD: "Mật khẩu không trùng khớp",
  CHANGE_PASSWORD_SUCCESS: "Đổi mật khẩu thành công",
  PORTFOLIO: "Danh mục",
  SWAP: "Chuyển đổi",
  STAKING: "Staking",
  ACCOUNT: "Tài khoản",
  LOGOUT: "Đăng xuất",
  FORGOT_PASSWORD: "Quên mật khẩu",
  HOMEPAGE: "Trang chủ",
  BACK: "Quay lại",
  PLEASE_ENTER_OTP: "Vui lòng nhập OTP đã được gửi qua email",
  TOTAL: "Tổng",
  COIN: "Coin",
  PRICE: "Giá",
  BALANCE: "Số dư",
  VALUE: "Giá trị thực",
  DEPOSIT: "Nạp tiền",
  WITHDRAW: "Rút tiền",
  ESTIMATED_BALANCE: "Số dư ước tính",
  EXCHANGE: "Giao dịch",
  AMOUNT: "Số tiền",
  PLEASE_ENTER_AMOUNT: "Vui lòng nhập số tiền giao dịch",
  MIN: "Min",
  HALF: "Half",
  ALL: "All",
  RECEIVE: "Nhận lại",
  SWAP_HISTORY: "Lịch sử chuyển đổi",
  TIME: "Thời gian",
  BASE_AMOUNT: "Số tiền đổi",
  QUOTE_AMOUNT: "Số tiền nhận",
  DURATION: "Thời gian",
  DAYS: "Ngày",
  MINIMUM_AMOUNT: "Số tiền thấp nhất",
  HISTORY: "Lịch sử",
  REDEEMS: "Lợi nhuận",
  TYPE: "Loại",
  STATUS: "Trạng thái",
  STAKE: "Stake",
  LOCK: "Khóa",
  MINIMUM_LOCKED_AMOUNT: "Số tiền staking thấp nhất",
  MAXIMUM_LOCKED_AMOUNT: "Số tiền kỹ quỹ cao nhất",
  REDEMPTION_PERIOD: "Redemption period",
  LOCK_AMOUNT: "Số tiền muốn staking",
  CONFIRM_PURCHASE: "Xác nhận",
  CANCEL: "Hủy",
  GO_TO_DEPOSIT: "Nạp tiền với các tài sản khác",
  NETWORK: "Network",
  ADDRESS: "Địa chỉ",
  GO_TO_WITHDRAW: "Rút tiền với các tài sản khác",
  MINIMUM_WITHDRAW_AMOUNT: "Số tiền rút thấp nhất",
  TRANSACTION_FEE: "Phí giao dịch",
  YOU_WILL_GET: "Bạn sẽ nhận lại được",
  PROFILE: "Thông tin tài khoản",
  SETTING: "Cài đặt",
  DEVICE_LIST: "Danh sách thiết bị",
  LOGIN_ACTIVITY: "Lịch sử đăng nhập",
  TURN_OFF: "Tắt",
  TURN_ON: "Bật",
  DISABLE: "Vô hiệu",
  IDENTITY_VERIFICATION: "Xác minh danh tính",
  ENABLE: "Kích hoạt",
  LOGIN_PASSWORD: "Mật khẩu đăng nhập",
  CHANGE: "Đổi",
  INVITED_FRIEND: "Giới thiệu bạn bè, kiếm thêm thu nhập",
  INVITED_TIP:
    "Dùng đường dẫn giới thiệu cho bạn bè qua tin nhắn, hoặc email, mã mời có thể được gửi trực tiếp qua đường dẫn, hoặc chia sẻ ảnh chụp màn hình",
  COPIED: "Đã sao chép",
  REFERRAL_NUMBER: "Tổng số thành viên",
  F1_REFERRAL_NUMBER: "Tổng số thành viên F1",
  COMMISSION: "Hoa hồng",
  YOUR_REFERRAL_ACCOUNT: "Danh sách thành viên F1",
  YOUR_COMMISSION_HISTORY: "Lịch sử nhận hoa hồng",
  AIRDROP: "Airdrop",
  AFFILIATE: "Affiliate",
  PHOTOS: "Ảnh",
  FIRST_NAME: "Họ",
  LAST_NAME: "Tên",
  MIDDLE_NAME: "Tên đệm",
  DOB: "Ngày sinh",
  COUNTRY: "Quốc gia",
  SELECT_COUNTRY: "Chọn quốc gia",
  CITY: "Thành phố",
  POSTAL_CODE: "Mã bưu chính",
  NEXT: "Tiếp theo",
  PREVIOUS: "Quay lại",
  UPLOAD: "Tải lên",
  FRONT_PAGE: "Mặt trước ID/Hộ chiếu/Bằng lái xe",
  BACK_PAGE: "Mặt sau ID/Hộ chiếu/Bằng lái xe",
  UPLOAD_SELFIE_PHOTO: "Tải lên ảnh chân dung",
  FULL_NAME: "Tên đầy đủ",
  VERIFY: "Xác minh",
  LANGUAGE: "Ngôn ngữ",
  DRIVER_LICENSE: "Bằng lái xe",
  PASSPORT: "Hộ chiếu",
  USER_NOT_VERIFIED: "Vui lòng xác minh người dùng để thực hiện giao dịch",
  PLEASE_ENABLE_GA:
    "Vui lòng kích hoạt Google authenticator để thực hiện giao dịch",
  REFERRAL_PARENT: "Người giới thiệu",
  REFERRAL_ID: "Mã giới thiệu",
  ID_TYPE: "CMND/Hộ chiếu/Bằng lái",
  ID_CODE: "Số CMND/Hộ chiếu/Bằng lái",
  ID_CARD: "CMND",
  IMAGE_CORRECT: "Hình ảnh không hợp lệ",
  IMAGE_LARGE: "Dung lượng hình quá lớn",
  PRIVACY_AND_POLICY: "Điều khoản Bảo mật",
  AGREE_TOS: "Tôi đã đọc và đồng ý với",
  SPWALLET_TOS: "Điều khoản Dịch vụ của SPWallet",
  PLEASER_CHECK_TOS:
    "Vui lòng đọc và đồng ý với Điều khoản Dịch vụ của SPWallet.",
  WISH_YOU_LUCK: "Chúc bạn may mắn lần sau",
  CONGRATULATION: "Chúc mừng bạn đã dành được giải thưởng",
  PRIZE_VALUE: "Giá trị giải thưởng",
  LAST_LOGIN: "Đăng nhập lần cuối",
  CONTINUE: "Tiếp tục",
  INFO_BASIC: "Thông tin cá nhân",
  VERIFY_REASON: "Tại sao phải xác minh danh tính của bạn ?",
  VERIFY_REASON_1: "Để mở khóa chức năng rút tiền.",
  VERIFY_REASON_2: "Để mở khóa chức năng chuyển tiền nội bộ.",
  VERIFY_REASON_3: "Để đảm bảo an toàn cho tài khoản của bạn.",
  VERIFY_NOTE:
    "Thông tin này chỉ được sử dụng để xác minh danh tính và sẽ được bảo mật bởi SPWallet",
  SELFIE: "Ảnh chân dung",
  ARE_YOU_SURE: "Bạn đã chắc chắn thực hiện thao tác này!",
  GIFT: "Quà tặng",
  NO_RECORDS_FOUND: "Không tìm thấy dữ liệu",
  MEMBER: "Thành viên",
  EMAIL_NOT_FOUND: "Không tìm thấy email",
  MINIMUM_SWAP_AMOUNT: "Số tiền chuyển đổi tối thiểu",
  EST_APY: "APY ước tính",
  GA_CODE: "Mã Google authenticator",
  GA: "Google authenticator",
  SECURITY: "Bảo mật",
  PLEASE_ENTER_YOUR_FULL_INFORMATION: "Vui lòng nhập thông tin đầy đủ của bạn",
  COIN_TOKEN: "Tài sản",
  CHANGE_24H: "Biến động giá 24h",
  VOLUME: "Khối lượng giao dịch",
  NOTE_UPLOAD_PHOTO:
    "Chúng tôi chỉ chấp nhận .png, .jpg, .jpeg hoặc .HEIC và không vượt quá 5M",
  UPLOAD_PHOTO: "Tải ảnh lên",
  BLOCKED: "Bị khóa",
  LINK: "Liên kết",
  UPLOAD_PHOTO_WITH: "Tải ảnh lên với những thông tin sau",
  UPLOAD_PHOTO_NOTE: "Đính kèm giấy ghi chú theo CMND/CCCD của bạn.",
  UPLOAD_PHOTO_NOTE_1:
    "Trên giấy ghi chú cần có thông tin sau: Yêu cầu KYC, email, ngày yêu cầu, KYC cho SPWallet, chữ ký.",
  UPLOAD_PHOTO_NOTE_2:
    "Yêu cầu KYC có thể bị từ chối nếu: CMND/CCCD không hợp lệ, hoặc thiếu giấy ghi chú đính kèm.",
  KYC_REQUEST: "Yêu cầu KYC",
  SIGN: "Chữ ký",
  WELL_DONE: "Mọi thứ đã sẵn sàng!",
  I_AGREE_TO_THE: "Tôi đồng ý với",
  AND: "và",
  AGREE_AFTER: "mục đích thu thập thông tin cá nhân",
  KYC_CONFIRM_NOTE:
    "Mục đích thu thập thông tin Cá nhân: nhận dạng, tăng cấp độ xác minh",
  KYC_CONFIRM_NOTE_1:
    "Thông tin cá nhân bắt buộc: họ tên, ngày tháng năm sinh, Quốc tịch, CMND | hộ chiếu | ảnh bằng lái xe",
  KYC_CONFIRM_NOTE_2:
    "Thời gian duy trì: thời gian do đạo luật quy định khi thành viên bị rút lui hoặc cho đến khi chấm dứt liên hệ",
  KYC_CONFIRM_NOTE_3:
    "Do quá trình xác thực KYC là thủ công, sẽ mất nhiều thời gian, thông thường sẽ là 24h tuy nhiên sẽ mất nhiều thời gian hơn nếu lượng người dùng tăng đột biến. Quý thành viên vui lòng kiên nhẫn chờ đợi. Chúng tôi sẽ thông báo qua email ngay khi có kết quả xác thực KYC của quý vị.",
  VERIFIED: "Đã xác minh",
  OPEN: "Đang mở",
  CLOSED: "Đã đóng",
  DEPOSIT_HISTORY: "Lịch sử nạp tiền",
  WITHDRAW_HISTORY: "Lịch sử rút tiền",
  SWAP_CONFIRM: "Xác nhận chuyển đổi",
  RATIO: "Tỷ lệ",
  SWAP_FEE: "Phí giao dịch",
  DEPOSIT_TIP_0_BEGIN: "Chỉ gửi",
  DEPOSIT_TIP_0_END: "đến địa chỉ nạp này",
  DEPOSIT_TIP_1:
    "Gửi coin hay token khác với token bạn chọn đến địa chỉ này có thể dẫn đến nguy cơ mất tiền.",
  DEPOSIT_TIP_2:
    "Nếu bạn đã nạp tiền, hãy chú ý đến tin nhắn văn bản, thông báo trên trang web và email mà chúng tôi đã gửi cho bạn.",
  DEPOSIT_TIP_3: "Coin sẽ được nạp sau khi đã được mạng lưới xác nhận.",
  DEPOSIT_TIP_4:
    "Chúng tôi không chịu trách nhiệm về tài sản khi sai địa chỉ ví hoặc nền tảng mạng lưới khi bạn chuyển nhầm.",
  WITHDRAW_TIP_0:
    "Đừng rút trực tiếp về một địa chỉ ICO hoặc gọi vốn cộng đồng, bởi tài khoản của bạn sẽ không được ghi nhận token từ hoạt động này.",
  WITHDRAW_TIP_1:
    "Khi chuyển tiền đến địa chỉ người dùng trong cùng SPwallet, sẽ được miễn phí phí xử lý dịch vụ.",
  WITHDRAW_TIP_2:
    "Chúng tôi miễn trừ trách nhiệm khi bạn chuyển sai địa chỉ ví hoặc nền tảng blockchain khác nhau. SPwallet chưa hỗ trợ chuỗi chéo.",
  SWAP_TIP_0:
    "Giá và số tiền sau cùng được xác định bởi số lượng token có trong pool tại thời điểm chuyển đổi của bạn.",
  SWAP_TIP_1:
    "Giao dịch chuyển đổi chỉ được thực hiện khi trượt giá nằm trong phạm vi cho phép.",
  SWAP_TIP_2:
    "Việc thêm hoặc rút coin sẽ không bị hạn chế, nhưng sẽ phụ thuộc vào số token có trong pool.",
  SWAP_TIP_3:
    "Swap chỉ là một hình thức chuyển đổi giữa các loại tài sản khác nhau nhưng vẫn dựa theo giá thị trường và số lượng token trong pool. SPwallet miễn trừ trách nhiệm khi token trượt giá trong quá trình quý khách thực hiện chuyển đổi.",
  CHOOSE_NETWORK: "Chọn network",
  WITHDRAW_CONFIRM: "Xác nhận rút tiền",
  WALLET_ADDRESS: "Địa chỉ ví",
  WITHDRAW_FEE: "Phí rút tiền",
  STAKING_CONFIRM: "Xác nhận staking",
  AGREE_TO: "Tôi đã đọc và đồng ý với ",
  STAKING_POLICY: "Điều khoản staking",
  PLEASER_CHECK_STAKING_POLICY:
    "Vui lòng đọc và đồng ý với Điều khoản Dịch vụ của SPWallet và Điều khoản Staking.",
  STAKING_POLICY_0:
    "Tỷ suất lợi nhuận hàng năm dựa trên ước tính doanh thu thị trường lịch sử. Doanh thu thực tế tùy thuộc vào thực tế nhận.",
  STAKING_POLICY_1: "Số tiền staking tối thiểu là 100 mUSD.",
  STAKING_POLICY_2:
    "Trong thời gian staking, tài sản staking không thể được giao dịch trên thị trường giao dịch thanh khoản, và không được mở khóa trực tiếp (Trừ gói Staking linh hoạt).",
  STAKING_POLICY_3:
    "Khi độ dài thời gian staking của bạn đáp ứng yêu cầu của khoảng thời gian staking, gói staking sẽ được hủy tự động, doanh thu và số tiền gốc staking sẽ được chuyển vào ví lưu trữ của bạn",
  STAKING_POLICY_4:
    "Số tiền staking sẽ được trả lại sau mốc thời gian bạn chọn trong 1-2 ngày làm việc, phần thưởng staking cùng với POL đã khai thác được trả lãi hàng ngày.",
  STAKING_POLICY_5:
    "Chính sách Staking không thay đổi, nhưng SPwallet không chịu trách nhiệm về việc tài sản bị trượt giá trên thị trường khi khách hàng đang staking",
  STAKING_POLICY_6:
    "Lãi suất hàng năm (APY), chỉ để giúp thành viên hiểu rõ hơn về giá trị khoản lợi nhuận.",
  STAKING_TIME: "Thời gian staking",
  STAKING_START_DATE: "Ngày bắt đầu staking",
  STAKING_END_DATE: "Ngày kết thúc trả thưởng",
  ESTIMATED_APY: "APY ước tính",
  START_TIME: "Ngày bắt đầu",
  END_TIME: "Ngày kết thúc",
  LOCKED: "Locked",
  YOUR_TOTAL_AFFILIATE: "Total affiliate",
  YOUR_TOTAL_AFFILIATE_F1: "Total affiliate 1",
  YOUR_AFFILIATE_F1: "Your affiliate 1",
  MAXIMUM: "Tối đa",
  MINIMUM: "Tối thiểu",
  USE_MAX_AMOUNT: "Sử dụng số lượng tối đa",
  STAKING_TIP_0: "Kiếm tiền mã hoá mọi lúc với SPExchange Staking",
  STAKING_TIP_1:
    "Tạo thu nhập thụ động với SPExchange Staking bằng cách giữ tiền mã hoá trong ví. Chọn loại tiền muốn giữ từ tiền mã hoá của SPExchange (mUSD). Đưa tiềm năng đầu tư tiền mã hoá của bạn lên một tầm cao mới và tận hưởng mức phí thấp cùng với SPExchange.",
  DATE_REQUIRE: "Ngày yêu cầu",
  KYC_FOR: "KYC cho SPWallet",
  DOB_INVALID: "Ngày sinh không đúng",
  WITHDRAW_AMOUNT_TOO_SMALL: "Số tiền rút quá nhỏ",
  DISABLE_ACCOUNT: "Vô hiệu hóa tài khoản",
  CLOSE: "Đóng",
  SCAN_QR_CODE: "Quét mã QR code địa chỉ ví",
  DISABLE_ACCOUNT_NOTE:
    "Tài khoản SPWallet của bạn đang được yêu cầu khóa tạm thời. Liên hệ với bộ phận hỗ trợ SPWallet để mở khóa tài khoản.",
  PLEASE_ENTER_PASSWORD_MORE_THAN: "Vui lòng nhập mật khẩu nhiều hơn 8 ký tự",
  PLEASE_ENTER_PASSWORD_LESS_THAN: "Vui lòng nhập mật khẩu ít hơn 16 ký tự",
  PLEASE_ENTER_NEW_PASSWORD_MORE_THAN:
    "Vui lòng nhập mật khẩu mới nhiều hơn 8 ký tự",
  PLEASE_ENTER_NEW_PASSWORD_LESS_THAN:
    "Vui lòng nhập mật khẩu mới ít hơn 16 ký tự",
  FAILED: "Không thành công",
  SEARCH_COIN_TOKEN: "Tìm kiếm Coin/Token",
  SP_ACCOUNT_TITLE: "Hỗ trợ đăng nhập tài khoản SPexchange.io",
  SP_ACCOUNT:
    "SPwallet.org hỗ trợ đăng nhập bằng tài khoản SPexchange.io của bạn. Với sự ủy quyền của mình, bạn có thể đăng nhập vào cả sàn SPexchange.io và SPwallet.org của mình bằng một tài khoản và tận hưởng giải pháp một cửa từ lưu trữ, chuyển đổi và giao dịch.",
  EASY_BUY: "Mua nhanh",
  YOU_HAVE: "Bạn có",
  BUY: "Mua",
  AVAILABLE_BALANCE: "Số dư khả dụng",
  COMPLETED: "Thành công",
  SESSION_SUPPLY: "Tổng",
  SOLD: "Đã bán",
  BY: "bằng",
  START_DATE: "Ngày bắt đầu",
  END_DATE: "Ngày kết thúc",
  WEBSITE: "Trang chủ",
  WHITEPAPER: "Whitepaper",
  BUY_TOKEN_SUCCESS: "Mua token thành công",
  FEE: "Phí",
  BUY_TOKEN_CONFIRM: "Xác nhận mua token",
  PROJECT_INTRODUCTION: "Thông tin dự án",
  EASY_BUY_SLOGAN: `Easy exchange with "Easy-Buy"`,
  EASY_BUY_SUB_SLOGAN:
    "Support instant exchange of popular cryptocurrencies around the world",
  FAST: "Fast",
  EFFICIENT: "Efficient",
  ZERO_CHANGES: "0 Changes",
  USER_OVERVIEW_MEMBER: "Số lượng thành viên của SPwallet",
  USER_OVERVIEW_ASSETS: "Hỗ trợ quản lý nhiều loại tài sản mã hóa",
  USER_OVERVIEW_SOCIAL: "Tổng thành viên theo dõi trên mạng xã hội",
  SPEXCHANGE_KYC_NOTE:
    "sử dụng tài liệu xác minh danh tính này, đồng thời cho cả SPWallet và SPExchange.",
  YOU_PAY: "Bạn phải trả",
  OPTION: "Không bắt buộc",
  MEMO_NOTE:
    "Vui lòng xác nhận xem địa chỉ nhận có yêu cầu Thẻ (Tag) không, nếu không điền hay điền sai, tài sản sẽ bị mất. Các sàn giao dịch hay ví khác còn gọi Thẻ dưới cái tên Memo, ID kỹ thuật số, nhãn và ghi chú.",
  TRANSACTION_HASH: "Mã giao dịch",
  EXCHANGE_DETAIL: "Chi tiết giao dịch",
  TO: "đến",
  FROM: "từ",
  SEND: "Gửi",
  HOURS_AGO: "giờ trước",
  MINUTES_AGO: "phút trước",
  TRANSACTION_HISTORY: "Lịch sử giao dịch",
  PAY: "Trả",
  GET: "Nhận",
  STAKING_REFERRAL: "Hoa hồng",
  PRESALE_PRODUCT_OUT_OF_SUPPLY:
    "Số lượng mua đã vượt quá số lượng cho phép. Vui lòng thử lại sau.",
  PRESALE_PRODUCT_NOT_OPEN: "Coin/Token vẫn chưa mở bán.",
  PRESALE_PRODUCT_ENDED: "Coin/Token đã quá thời gian mở bán.",
  EASY_BUY_HISTORY: "Lịch sử mua nhanh",
  SELECT_COIN: "Chọn Coin/Token",
  COMING_SOON: "Sắp ra mắt",
  SUMMARY: "Summary",
  LOCK_AMOUNT_LIMITATION: "Lock amount limitation",
  VALUE_DATE: "Value date",
  INTEREST_PERIOD: "Interest period",
  INTEREST_END_DATE: "Interest end date",
  REDEMPTION_DATE: "Redemption date",
  ESTIMATE_INTEREST: "Estimate interest",
  UNAVAILABLE_BALANCE: "Số dư không khả dụng",
  AMOUNT_TOO_LARGE: "Your amount of staking is too large",
  AMOUNT_TOO_LOW: "Your amount of staking is too low",
  STAKING_PRODUCT_NOT_OPEN: "Staking product is not open",
  STAKING_PRODUCT_ENDED: "Staking product is ended",
  PLEASE_TRY_AGAIN: "Vui lòng thử lại",
  STAKING_PRODUCT_OUT_OF_SUPPLY: "Your staking amount out of remain",
  REDEEM: "Redeem",
  PROFIT: "Lợi nhuận",
  YEAR: "năm",
  MONTH: "tháng",
  CONFIRM_STAKING: "Xác nhận Staking",
  PARTNER: "Đối tác",
  DISPLAY_NAME: "Tên hiển thị",
  UPDATE: "Cập nhật",
  ITEM_ALREADY_EXIST: "Tên hiện thị đã tồn tại",
  PLEASE_ENTER_YOUR_DISPLAY_NAME: "Vui lòng nhập tên hiển thị",
  DISPLAY_NAME_WAS_UPDATED: "Cập nhật tên hiển thị thành công",
  MARCH_PROMOTION: "Promotion March",
  APRIL_REWARD: "April reward",
  ORDER_DETAIL: "Order detail",
  RECEIVER_ADDRESS: "Receiver's address",
  PHONE: "Phone",
  CHECKOUT_METHOD: "Checkout method",
  CHECKOUT_BY_LUS: "Payment by LuS on SPWallet (Free of charge).",
  PAYMENT_SUCCESS: "Payment success",
  DISCOUNT: "Discount",
  PRODUCT: "Product",
  SUBTOTAL: "Subtotal",
  BACK_TO_ORDER: "Back to my orders",
  MY_ORDERS: "My orders",
  PRODUCTS: "Products",
  SAVE: "Save",
  AVAILABLE: "Available",
  BUY_NOW: "Buy now",
  PRODUCT_DETAIL: "Product detail",
  LEARN_MORE: "learn more",
  SHOPPING_PRICE_NOTE:
    "Price is inclusive of duties and taxes for all US bound orders",
  SHOPPING_NOTE_1: "Verified & authenticated by our experts",
  SHOPPING_NOTE_2: "Delivery time : 2-4 business days",
  SHOPPING_NOTE_3: "30 days easy return",
  ORDER: "Order",
  SHIPPING_INFORMATION: "Shipping information",
  CHOSE_ADDRESS: "Chose address",
  ADD_NEW_ADDRESS: "Add new address",
  CHECKOUT: "Checkout",
  CONFIRM_INFORMATION: "Confirm information",
  SWAP_FROM: "From",
  CREATE_ORDER_DATE: "Created order date",
  BACK_TO_SPWALLET: "Back to SPwallet",
  NAME: "Name",
  NEW: "New",
  SHIPPING: "Shipping",
  INPROGRESS: "In progress",
  CANCEL_ORDER: "Cancel order",
  ARE_YOU_SURE_CANCEL_ORDER: "Are you sure you want cancel this order ?",
  ORDER_ID: "Order ID",
  SELECT_A_TOKEN: "Select a token",
  CANCEL_ORDER_SUCCESS: "Cancel order success",
  EDIT_PROFILE: "Edit profile",
  EDIT_NICKNAME_NOTE:
    "We accept names from 3 to 32 characters in length, without special characters and does not begin with a number.",
  PLEASE_TELL_US_YOUR_NICKNAME: "Please tell us your nickname",
  YOUR_NICKNAME: "Your nickname",
  PLEASE_CHECK_NICKNAME_AGAIN: "Please check your nickname again.",
  PLEASE_SELECT_COUNTRY: "Please select your country",
  UPDATE_COUNTRY_SUCCESS: "Update country success",
  UPDATE_NICKNAME_SUCCESS: "Update nickname success",
  CONFIRM_CANCEL_ORDER_ADDRESS: "Confirming for cancel order address",
  SHOPPING_ORDER_OUT_OF_STOCK: "Your order products out of remain",
  SOLD_OUT: "Sold out",
  RANKING_BONUS: "Bonus",
  UNBLOCK: "Unlock",
  VERIFICATION: "Xác minh",
  CODE_WILL_BE_SENT_TO: "Code sẽ được gửi về",
  EMAIL_VERIFICATION_CODE: "Email verification code",
  ENTER_GA_CODE: "Nhập Google verification code",
  SUBMIT: "Đồng ý",
  SEND_CODE: "Gửi OTP",
  SP_INTERNAL: "Chuyển tiền nội bộ",
  OR: "hoặc",
  INTERNAL_WITHDRAW: "Chuyển tiền nội bộ",
  EXTERNAL_WITHDRAW: "Chuyển tiền ra ngoài",
  AFFILIATE_COMMISSION: "Affiliate commission",
  DAILY_RELEASE: "Daily release",
  REWARD: "Pool reward",
  BA_REWARD: "Pool promotion reward",
  HOLDER_COMMISSION: "Pool holder",
  RELEASE: "Release",
  WITHDRAW_HOLDER_COMMISSION: "Profit recovery",
  WITHDRAW_REWARD: "Profit recovery",
};
