import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, Segment, Table } from "semantic-ui-react";
import { _getAirdrop } from "../../redux/userReducer";
import { formatTime } from "../../settings/format";

function AirdropCommission() {
  const { user, setting } = useSelector((state) => state);
  const { airdropRewards } = user;
  const { library } = setting;
  const dispatch = useDispatch();
  const { TIME, AMOUNT, NO_RECORDS_FOUND, MEMBER } = library;
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(_getAirdrop(page));
  }, [dispatch, page]);

  return (
    airdropRewards && (
      <>
        <Segment className="spwallet-history" basic vertical>
          <Table
            unstackable
            basic="very"
            compact="very"
            celled
            inverted
            singleLine
            selectable
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{TIME}</Table.HeaderCell>
                <Table.HeaderCell>{MEMBER}</Table.HeaderCell>
                <Table.HeaderCell>{AMOUNT}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {airdropRewards.items.length > 0 ? (
                airdropRewards.items.map((item, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{formatTime(item.time)}</Table.Cell>
                    <Table.Cell>{item.note}</Table.Cell>
                    <Table.Cell>
                      {item.amount} {item.coin}
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={3}>
                    <Segment basic textAlign="center">
                      {NO_RECORDS_FOUND}
                    </Segment>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Segment>
        {airdropRewards.pageCount > 1 && (
          <Pagination
            totalPages={airdropRewards.pageCount}
            activePage={airdropRewards.page}
            pointing
            secondary
            inverted
            onPageChange={(e, { activePage }) => setPage(activePage)}
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
            boundaryRange={0}
          />
        )}
      </>
    )
  );
}

export default AirdropCommission;
