import React from "react";
import LazyLoad from "react-lazyload";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Container, Segment } from "semantic-ui-react";
import MainBar from "../../components/portfolio/MainBar";
import WithdrawForm from "../../components/withdraw/WithdrawForm";
import WithdrawHistory from "../../components/withdraw/WithdrawHistory";

function Withdraw() {
  const { wallet } = useSelector((state) => state);
  const { coin } = useParams();
  const { fundList } = wallet;
  const history = useHistory();
  const fund = fundList.find((f) => f.coin === coin);

  if (fund === undefined && fundList.length > 0) history.push("/");

  return fund ? (
    <Container textAlign="left" id="portfolio">
      <Segment textAlign="center">
        <MainBar coin={coin} fund={fund} />
      </Segment>
      <LazyLoad>
        <WithdrawForm />
      </LazyLoad>
      <br />
      <LazyLoad>
        <WithdrawHistory />
      </LazyLoad>
    </Container>
  ) : null;
}

export default Withdraw;
