import "./App.css";
import "./assets/style/animation.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Container, Dimmer } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { _getFundList } from "./redux/walletReducer";
import CustomMenu from "./components/others/CustomMenu";
import Profile from "./pages/me/Profile";
import KYC from "./pages/me/KYC";
import ChangePassword from "./pages/me/ChangePassword";
import Options from "./pages/me/Options";
import Deposit from "./pages/me/Deposit";
import Withdraw from "./pages/me/Withdraw";
import Swap from "./pages/me/Swap";
import Stacking from "./pages/me/Stacking";
import Active from "./pages/login/Active";
import ConfirmDevice from "./pages/login/ConfirmDevice";
import Register from "./pages/login/Register";
import ForgotPassword from "./pages/login/ForgotPassword";
import Login from "./pages/login/Login";
import ResetPassword from "./pages/login/ResetPassword";
import Dashboard from "./pages/me/Dashboard";
import CustomModal from "./components/others/CustomModal";
import ScrollToTop from "./components/others/ScrollToTop";
import { _checkEnableGA, _getProfile } from "./redux/userReducer";
import ConfirmWithdraw from "./pages/me/ConfirmWithdraw";
import DisableAccount from "./pages/me/DisableAccount";
import Referral from "./pages/me/Referral";
import Policy from "./pages/Policy";
import TOS from "./pages/TOS";
// import { _getGift } from "./redux/userReducer";
// import StakingGift from "./components/StakingGift";
// import Advertisement from "./components/others/Advertisement";
import ReactGA from "react-ga";
import EasyBuy from "./pages/me/EasyBuy";
import EasyBuyItem from "./pages/me/EasyBuyItem";
// import PreSaleHistory from "./pages/me/PreSaleHistory";
import Portfolio from "./pages/me/Portfolio";
import Homepage from "./pages/me/Homepage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Partner from "./pages/Partner";
import CustomSidebar from "./components/others/CustomSidebar";
import Shopping from "./pages/me/Shopping";
import ProductDetail from "./components/lus/ProductDetail";
import OrderHistory from "./pages/me/LusShopping/OrderHistory";
import OrderDetail from "./pages/me/LusShopping/OrderDetail";
import { _getLusStatistic } from "./redux/LusReducer";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import { isProduction } from "./settings";
import LoginReturnTo from "./pages/me/LoginReturnTo";
import "./styles/index.scss";

ReactGA.initialize("G-ZKLJ2ZVE80");

function App() {
  const { setting, user } = useSelector((state) => state);
  const { isLogin } = user;
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(true);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (mounted) {
      ReactGA.pageview(window.location.pathname + window.location.search);
      if (isLogin) {
        dispatch(_getFundList());
        dispatch(_getProfile());
        dispatch(_checkEnableGA());
        dispatch(_getLusStatistic());
        // dispatch(_getGift());
      }
    }
    return () => setMounted(!mounted);
  }, [dispatch, isLogin, mounted, user.isLogin]);

  //page only show at local
  const doingPage = [
    {
      path: `/lus/shopping/products/:id`,
      component: ProductDetail,
    },
    {
      path: `/lus/shopping/orders/:id`,
      component: OrderDetail,
    },
    {
      path: `/lus/shopping/orders`,
      component: OrderHistory,
    },
    {
      path: `/lus/shopping`,
      component: Shopping,
    },
  ];
  //page only show at local

  if (isLogin) {
    return (
      <Router>
        <ScrollToTop />
        <Dimmer.Dimmable as="div" blurring dimmed={visible}>
          <Dimmer active={visible} onClickOutside={() => setVisible(false)} />
          <CustomMenu onShowSidebar={() => setVisible(true)} />
          <Container
            className={setting.darkMode ? "App dark-mode" : "App"}
            fluid
          >
            <Container className="Content logged" fluid>
              <Switch>
                {!isProduction &&
                  doingPage.map((item, index) => (
                    <Route
                      path={item.path}
                      component={item.component}
                      key={index}
                    />
                  ))}
                <Route path="/lus/partner" component={Partner} />
                <Route path="/portfolio/:coin" component={Portfolio} />
                <Route path="/policy" component={Policy} />
                <Route path="/terms-of-service" component={TOS} />
                <Route path="/me" component={Profile} />
                <Route path="/verify" component={KYC} />
                <Route path="/change-password" component={ChangePassword} />
                <Route path="/options" component={Options} />
                <Route path="/deposit/:coin" component={Deposit} />
                <Route path="/withdraw/:coin" component={Withdraw} />
                <Route
                  path="/confirm-withdraw/:token"
                  component={ConfirmWithdraw}
                />
                <Route
                  path="/disable-account/:token"
                  component={DisableAccount}
                />
                <Route path="/affiliate" component={Referral} />
                <Route path="/swap" component={Swap} />
                <Route path="/staking" component={Stacking} />
                <Route path="/login" component={LoginReturnTo} />
                <Route path="*" component={Dashboard} />
              </Switch>
            </Container>
          </Container>
        </Dimmer.Dimmable>
        <CustomModal />
        <ToastContainer />
        {/* <StakingGift /> */}
        {/* <Advertisement /> */}
        <CustomSidebar
          visible={visible}
          _handleHide={() => setVisible(false)}
        />
      </Router>
    );
  } else {
    return (
      <Router>
        <ScrollToTop />
        <Dimmer.Dimmable as="div" blurring dimmed={visible}>
          <Dimmer active={visible} onClickOutside={() => setVisible(false)} />
          <CustomMenu onShowSidebar={() => setVisible(true)} />
          <Container
            className={setting.darkMode ? "App dark-mode" : "App"}
            fluid
          >
            <Container className="Content" fluid>
              <Switch>
                <Route path="/staking" component={Stacking} />
                <Route path="/easy-buy/:slug/:token" component={EasyBuyItem} />
                <Route path="/easy-buy" component={EasyBuy} />
                <Route path="/policy" component={Policy} />
                <Route path="/terms-of-service" component={TOS} />
                <Route
                  path="/reset-password/:token"
                  component={ResetPassword}
                />
                <Route path="/active/:token" component={Active} />
                <Route
                  path="/confirm-device/:token"
                  component={ConfirmDevice}
                />
                <Route path="/register" component={Register} exact />
                <Route path="/register/:id" component={Register} />
                <Route
                  path="/confirm-withdraw/:token"
                  component={ConfirmWithdraw}
                />
                <Route
                  path="/disable-account/:token"
                  component={DisableAccount}
                />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/login" component={Login} />
                <Route path="*" component={Homepage} />
              </Switch>
            </Container>
          </Container>
        </Dimmer.Dimmable>
        <CustomModal />
        <CustomSidebar
          visible={visible}
          _handleHide={() => setVisible(false)}
        />
      </Router>
    );
  }
}

export default App;
