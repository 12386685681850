import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Image, Menu, Sidebar, Dropdown } from "semantic-ui-react";
import { RETURN_SAFE_LUS_KEY } from "../../settings";
import { MenuOptions } from "../../settings/constant";
import { MENU_LOGO } from "../../settings/links";

function CustomSidebar({ visible, _handleHide }) {
  const { setting, user } = useSelector((state) => state);
  const { library, lang } = setting;
  const dispatch = useDispatch();
  const { isLogin } = user;
  const history = useHistory();

  if (isLogin) {
    return (
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        inverted
        vertical
        visible={visible}
        direction="right"
      >
        <Menu.Item
          as={Link}
          style={{ textAlign: "left" }}
          onClick={_handleHide}
          to=""
        >
          <Image src={MENU_LOGO} size="small" />
        </Menu.Item>
        {MenuOptions.map((item, index) => (
          <Menu.Item
            as={Link}
            style={{ textAlign: "left" }}
            key={index}
            onClick={_handleHide}
            to={item.link}
          >
            <Menu.Header>{library[item.name]}</Menu.Header>
          </Menu.Item>
        ))}
        <Menu.Item>
          <Menu.Header style={{ textAlign: "left" }}>
            {library.PARTNER}
          </Menu.Header>
          <Menu.Menu style={{ paddingLeft: "1em" }}>
            <Menu.Item
              as={Link}
              to="/lus/partner"
              onClick={_handleHide}
              style={{ textAlign: "left", fontSize: "1em" }}
            >
              Luxury Outlet Street
            </Menu.Item>
            <Menu.Item
              style={{ textAlign: "left", fontSize: "1em" }}
              onClick={() =>
                history.push(`/login?return=${RETURN_SAFE_LUS_KEY}`)
              }
            >
              SAFE LuS
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
        <Menu.Item>
          <Dropdown
            options={[
              { key: "en", text: "English", value: "en" },
              { key: "vi", text: "Tiếng Việt", value: "vi" },
            ]}
            value={lang}
            onChange={(e, { value }) =>
              dispatch({ type: "CHANGE_LANGUAGE", payload: value })
            }
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontWeight: 700,
            }}
            className="langue"
          />
        </Menu.Item>
      </Sidebar>
    );
  } else {
    return (
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        inverted
        vertical
        visible={visible}
        direction="right"
      >
        <Menu.Item
          as={Link}
          style={{ textAlign: "left" }}
          onClick={_handleHide}
          to=""
        >
          <Image src={MENU_LOGO} size="small" />
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/login"
          onClick={_handleHide}
          style={{ textAlign: "left" }}
        >
          <Menu.Header>{library.LOGIN}</Menu.Header>
        </Menu.Item>
        <Menu.Item
          as={Link}
          to="/register"
          onClick={_handleHide}
          style={{ textAlign: "left" }}
        >
          <Menu.Header>{library.REGISTER}</Menu.Header>
        </Menu.Item>
        {/* <Menu.Item
          as={Link}
          to="/staking"
          onClick={_handleHide}
          style={{ textAlign: "left" }}
        >
          <Menu.Header>{library.STAKING}</Menu.Header>
        </Menu.Item> */}
        <Menu.Item>
          <Dropdown
            options={[
              { key: "en", text: "English", value: "en" },
              { key: "vi", text: "Tiếng Việt", value: "vi" },
            ]}
            value={lang}
            onChange={(e, { value }) =>
              dispatch({ type: "CHANGE_LANGUAGE", payload: value })
            }
            style={{
              display: "flex",
              justifyContent: "space-between",
              fontWeight: 700,
            }}
            className="langue"
          />
        </Menu.Item>
      </Sidebar>
    );
  }
}

export default CustomSidebar;
