import {
  Drawer,
  Button,
  Avatar,
  Divider,
  Typography,
  FormControlLabel,
  Checkbox,
  Grid,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { Dimmer, Header, Loader, Segment } from "semantic-ui-react";
import { API, RETURN_SAFE_LUS_KEY, S3_URL, SAFE_LUS_URL } from "../../settings";
import { getAccessToken, logoutReturnTo } from "../../utils/auth";
import lusLogo from "../../images/logo.24f6e39c.png";
import CheckIcon from "@material-ui/icons/Check";
import { useDispatch, useSelector } from "react-redux";
import { _getProfile } from "../../redux/userReducer";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function LoginReturnTo() {
  const { user } = useSelector((state) => state);
  const { data } = user;
  const dispatch = useDispatch();

  const _handleConfirm = () => {
    window.location.replace(`${SAFE_LUS_URL}/login?token=${getAccessToken()}`);
  };

  const _handleLogout = () => {
    logoutReturnTo();
  };

  useEffect(() => {
    dispatch(_getProfile());
  }, [dispatch]);

  return (
    <Dimmer active page>
      <Drawer
        anchor="bottom"
        open={true}
        className={"custom-modal-vk choose-account-spwallet"}
      >
        {data && (
          <Segment
            style={{
              backgroundColor: "var(--light-blue)",
              minWidth: 300,
              maxWidth: 400,
              color: "#fff",
              textAlign: "center",
            }}
            className="spwallet-container"
          >
            <div
              vertical
              style={{
                margin: "-1em",
                padding: "0.5em",
                borderBottom: "1px solid rgba(255,255,255, 0.12)",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <Avatar
                src={`${S3_URL}/spwallet/logo-ico.png`}
                style={{ width: 20, height: 20 }}
              />
              <div style={{ margin: "0 10px" }}>SPwallet</div>
              <small style={{ opacity: 0.8 }}>
                Most trust and secure cryptocurrency wallet
              </small>
            </div>
            <Segment vertical>
              <Segment vertical>
                <Avatar
                  src={lusLogo}
                  style={{ width: 60, height: 60, margin: "auto" }}
                />
              </Segment>
              <Segment vertical>
                <Header as="h3">
                  <span style={{ opacity: 0.8 }}>Login to</span> SAFELuS
                </Header>
              </Segment>
              <Divider style={{ backgroundColor: "rgba(255,255,255,0.12)" }} />
              <Segment
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                vertical
                className="chose-email"
                onClick={_handleConfirm}
              >
                <div
                  style={{
                    width: 28,
                    height: 28,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(3, 168, 157, 0.9)",
                    borderRadius: "50%",
                    marginRight: "1em",
                    color: "#fff",
                    fontWeight: 600,
                  }}
                >
                  {data.displayName.charAt(0).toUpperCase()}
                </div>
                <div>
                  <Typography
                    align="left"
                    varian="h6"
                    style={{ fontWeight: 600 }}
                  >
                    {data.displayName}
                  </Typography>
                  <Typography
                    align="left"
                    variant="body2"
                    style={{ opacity: 0.8 }}
                  >
                    {data.email}
                  </Typography>
                </div>
              </Segment>
              <Divider style={{ backgroundColor: "rgba(255,255,255,0.12)" }} />
              <Segment
                vertical
                style={{ display: "flex", alignItems: "center" }}
                className="chose-email"
                onClick={_handleLogout}
              >
                <div style={{ width: 28, marginRight: "1em" }}>
                  <Avatar style={{ width: 20, height: 20, margin: "auto" }} />
                </div>
                Use another account
              </Segment>
              <Divider style={{ backgroundColor: "rgba(255,255,255,0.12)" }} />
              <Segment vertical textAlign="left">
                <Typography>SPwallet will share</Typography>
                <div
                  style={{ paddingLeft: 10, opacity: 0.8 }}
                  className="will-share"
                >
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0.5em 0",
                      }}
                    >
                      <CheckIcon
                        style={{ marginRight: "0.2em", fontWeight: 600 }}
                      />{" "}
                      Your username
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0.5em 0",
                      }}
                    >
                      <CheckIcon
                        style={{ marginRight: "0.2em", fontWeight: 600 }}
                      />{" "}
                      Your email
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0.5em 0",
                      }}
                    >
                      <CheckIcon
                        style={{ marginRight: "0.2em", fontWeight: 600 }}
                      />{" "}
                      Your balances
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: "0.5em 0",
                      }}
                    >
                      <CheckIcon
                        style={{ marginRight: "0.2em", fontWeight: 600 }}
                      />{" "}
                      Your transactions
                    </Grid>
                  </Grid>
                </div>
              </Segment>
              <Segment vertical textAlign="left">
                <p>
                  Before using this website, you can review and accept with:{" "}
                </p>
                <div>
                  <a
                    href="/policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#03a89d",
                      marginBottom: 10,
                    }}
                  >
                    1. SPwallet privacy and policy
                  </a>
                </div>
                <div>
                  <a
                    href="/terms-of-service"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#03a89d",
                      marginBottom: 10,
                    }}
                  >
                    2. SPwallet terms of service
                  </a>
                </div>
                <div>
                  <a
                    href="/documents/SAFE_LUS_DISCLAIMER.pdf"
                    target="_blank"
                    style={{
                      color: "#03a89d",
                      marginBottom: 10,
                    }}
                  >
                    3. SAFELuS’s disclaimer
                  </a>
                </div>
              </Segment>
              <Segment vertical>
                <Button
                  fullWidth
                  style={{
                    color: "#fff",
                    backgroundColor: "rgba(3, 168, 157, 1)",
                  }}
                  onClick={_handleConfirm}
                >
                  Accept
                </Button>
              </Segment>
            </Segment>
          </Segment>
        )}
      </Drawer>
    </Dimmer>
  );
}

export default LoginReturnTo;
