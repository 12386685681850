import React, { useState } from "react";
import {
  Container,
  Dimmer,
  Header,
  Loader,
  Segment,
  Tab,
} from "semantic-ui-react";
import { useSelector } from "react-redux";
import KYCInformation from "../../components/kyc/KYCInformation";
import KYCFrontPhoto from "../../components/kyc/KYCFrontPhoto";
import KYCBackPhoto from "../../components/kyc/KYCBackPhoto";
import KYCSelfiePhoto from "../../components/kyc/KYCSelfiePhoto";
import KYCConfirm from "../../components/kyc/KYCConfirm";
import LazyLoad from "react-lazyload";

function KYC() {
  const { user, setting } = useSelector((state) => state);
  const { library } = setting;
  const { data } = user;
  const [activeIndex, setActiveIndex] = useState(0);

  const { IDENTITY_VERIFICATION, INFORMATION, PHOTOS, CONFIRM } = library;

  const _next = () => {
    if (activeIndex < 5) setActiveIndex(activeIndex + 1);
  };

  const _previous = () => {
    if (activeIndex > 0) setActiveIndex(activeIndex - 1);
  };

  const panes = [
    {
      menuItem: INFORMATION,
      render: () => <KYCInformation _next={_next} />,
    },
    {
      menuItem: PHOTOS,
      render: () => <KYCFrontPhoto _next={_next} _previous={_previous} />,
    },
    {
      menuItem: "Back photo",
      render: () => <KYCBackPhoto _next={_next} _previous={_previous} />,
    },
    {
      menuItem: "Selfie photo",
      render: () => <KYCSelfiePhoto _next={_next} _previous={_previous} />,
    },
    {
      menuItem: CONFIRM,
      render: () => <KYCConfirm _previous={_previous} />,
    },
  ];

  if (data) {
    if (
      data.verifyStatusLv2 === "PENDING" ||
      data.verifyStatusLv2 === "VERIFIED"
    ) {
      return (
        <LazyLoad>
          <Container>
            <Segment>{library.KYC_NOTIFICATION}</Segment>
          </Container>
        </LazyLoad>
      );
    } else {
      return (
        <LazyLoad>
          <Container className="verification spwallet-container">
            <Header as="h1" textAlign="left">
              {IDENTITY_VERIFICATION}
            </Header>
            <Segment>
              <Tab
                menu={{ secondary: true, pointing: true, inverted: true }}
                panes={panes}
                activeIndex={activeIndex}
              />
            </Segment>
          </Container>
        </LazyLoad>
      );
    }
  } else {
    return (
      <Dimmer active>
        <Loader />
      </Dimmer>
    );
  }
}

export default KYC;
