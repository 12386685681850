import React from "react";
import { Container, Segment } from "semantic-ui-react";

function Options() {
  return (
    <Container>
      <Segment style={{ backgroundColor: "#eaeaea" }}>
        <Segment>Options</Segment>
      </Segment>
    </Container>
  );
}

export default Options;
