import { en } from "../languages/en";
import { vi } from "../languages/vi";
const CHANGE_MODE = "CHANGE_MODE";
const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";
const SET_LOADING = "SET_LOADING";
export const SET_MODAL_CONTENT = "SET_MODAL_CONTENT";

const initialState = {
  darkMode: true,
  library: en,
  lang: "en",
  loading: true,
  modalContent: null,
};

export const SettingReducer = (state = initialState, action) => {
  let darkMode = window.localStorage.getItem("DARK_MODE");
  switch (action.type) {
    case CHANGE_MODE:
      darkMode = action.payload;
      window.localStorage.setItem("DARK_MODE", darkMode);
      return { ...state, darkMode };
    case CHANGE_LANGUAGE:
      const lang = action.payload;
      window.localStorage.setItem("LANG", lang);
      switch (lang) {
        case "vi":
          return { ...state, library: vi, lang };
        default:
          return { ...state, library: en, lang };
      }
    case SET_LOADING:
      return { ...state, loading: false };
    case SET_MODAL_CONTENT:
      return { ...state, modalContent: action.payload };
    default:
      if (darkMode === "false") {
        darkMode = false;
      } else {
        darkMode = true;
      }
      let localStorageLanguage = window.localStorage.getItem("LANG");
      if (!localStorageLanguage) {
        localStorageLanguage = navigator.language.slice(0, 2);
      }
      let library;
      switch (localStorageLanguage) {
        case "vi":
          library = vi;
          localStorage.setItem("LANG", localStorageLanguage);
          break;
        default:
          localStorageLanguage = "en";
          library = en;
          localStorage.setItem("LANG", "en");
          break;
      }
      return { ...state, darkMode, library, lang: localStorageLanguage };
  }
};
