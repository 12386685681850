/* eslint-disable jsx-a11y/alt-text */
import { formatAmount } from "../../settings/format";
import { S3_URL } from "../../settings";
import { Grid, Icon, List, Segment, Dropdown } from "semantic-ui-react";
import "./Withdraw.scss";
import NewWithdrawForm from "./NewWithdrawForm";
const { useState, useEffect } = require("react");
const { useSelector } = require("react-redux");
const { useParams, useHistory } = require("react-router-dom");

function WithdrawForm() {
  const { setting, wallet } = useSelector((state) => state);
  const { coin } = useParams();
  const { fundList } = wallet;
  const [fund, setFund] = useState(null);
  const { library } = setting;
  const history = useHistory();

  useEffect(() => {
    const temp = fundList.find((f) => f.coin === coin);
    if (temp) {
      setFund(temp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coin]);

  const { WITHDRAW_TIP_0, WITHDRAW_TIP_2 } = library;

  const tips = [WITHDRAW_TIP_0, WITHDRAW_TIP_2];

  const selectFund = [];
  if (fundList) {
    fundList.forEach((element, index) => {
      selectFund.push({
        key: element.coin,
        value: element.coin,
        text:
          element.coin === coin
            ? element.coin
            : element.coin + " - " + formatAmount(element.amount),
        image: { avatar: true, src: `${S3_URL}/coins/${element.icon}` },
      });
    });
  }

  return (
    fund && (
      <Grid stretched className="spwallet-container">
        <Grid.Column computer={8} tablet={8} only="computer">
          <Segment>
            {selectFund.length > 0 && (
              <Dropdown
                options={selectFund}
                selection
                fluid
                onChange={(e, { value }) => {
                  history.push(`/withdraw/${value}`);
                }}
                value={history.location.pathname.replace("/withdraw/", "")}
                translate="no"
              />
            )}
            <Segment className="spwallet-tips">
              <Segment vertical basic>
                <Icon name="lightbulb" size="large" />
                Tips:
              </Segment>
              <List.Item as="ul">
                {tips.map((item, index) => (
                  <List.Item key={index} as="li">
                    {item}
                  </List.Item>
                ))}
              </List.Item>
            </Segment>
          </Segment>
        </Grid.Column>
        <Grid.Column computer={8} tablet={8} mobile={16}>
          <NewWithdrawForm />
        </Grid.Column>
      </Grid>
    )
  );
}

export default WithdrawForm;
