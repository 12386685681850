import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid, Header, Segment, Image, Icon, Input } from "semantic-ui-react";
import { S3_URL } from "../../settings";
import { formatShortAmount } from "../../settings/format";

function SelectCoin({ coins, changeBase, close }) {
  const { wallet, setting } = useSelector((state) => state);
  const { fundList } = wallet;
  const [searchCoins, setSearchCoins] = useState(coins);
  const { library } = setting;

  const _searchCoins = (e, { value }) => {
    setSearchCoins(
      coins.filter((item) =>
        item.value.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  return (
    <Segment
      inverted
      style={{
        width: "50vw",
        maxWidth: 420,
        minWidth: 300,
      }}
      className="select-coin"
      vertical
    >
      <Segment basic>
        <Grid columns={2}>
          <Grid.Column>
            <Header>{library.SELECT_A_TOKEN}</Header>
          </Grid.Column>
          <Grid.Column textAlign="right">
            <Icon
              name="x"
              link
              inverted
              style={{ fontSize: "1.3em" }}
              onClick={close}
            />
          </Grid.Column>
        </Grid>
      </Segment>
      <Segment basic>
        <Input
          fluid
          placeholder="Search token"
          onChange={_searchCoins}
          inverted
          className="search-coin-input"
        />
      </Segment>
      <div
        style={{
          maxHeight: "50vh",
          minHeight: "50vh",
          overflow: "auto",
        }}
      >
        {searchCoins.map((item, index) => {
          const coin = fundList.filter((fund) => fund.coin === item.value)[0];
          return (
            <div
              key={index}
              className="item"
              onClick={() => changeBase(item.value)}
            >
              <div className="item">
                <Image
                  src={`${S3_URL}/coins/${item.value}.png`}
                  className="coin-logo"
                />
                <div>
                  <div className="symbol">{coin.coin}</div>
                  <div className="full-name">{coin.fullName}</div>
                </div>
              </div>
              <div>
                <div className="symbol">{formatShortAmount(coin.amount)}</div>
                <div className="full-name" style={{ textAlign: "right" }}>
                  {library.AVAILABLE}
                </div>
              </div>
            </div>
          );
        })}
        {searchCoins.length === 0 && (
          <div style={{ padding: "0 1em" }}>{library.NO_RECORDS_FOUND}</div>
        )}
      </div>
    </Segment>
  );
}

export default SelectCoin;
