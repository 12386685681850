import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Button,
  Container,
  Form,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import {
  checkEmail,
  checkEmpty,
  checkLength,
} from "../../components/others/Validate";
import { SET_MODAL_CONTENT } from "../../redux/settingReducer";
import { post } from "../../utils/api";

function Register() {
  const { id } = useParams();
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [referral, setReferral] = useState(id ? id : "");
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [checked, setChecked] = useState(true);

  const _handleRegister = () => {
    let checkE = false;
    let checkP = false;
    setError(null);
    setLoading(true);
    if (checkEmpty(email)) setError(library.PLEASE_ENTER_EMAIL);
    else if (checkEmail(email)) setError(library.INVALID_EMAIL);
    else if (checkEmpty(password)) setError(library.PLEASE_ENTER_PASSWORD);
    else if (checkLength(password, 8))
      setError(library.PLEASE_ENTER_PASSWORD_MORE_THAN);
    else if (password.length > 16)
      setError(library.PLEASE_ENTER_PASSWORD_LESS_THAN);
    else {
      checkP = true;
      checkE = true;
    }
    if (checkE && checkP) {
      if (checkEmpty(referral)) {
        setLoading(false);
        setError(library.PLEASE_ENTER_REFERRAL);
      } else {
        if (checked) {
          post(
            `/user-service/user/register`,
            {
              email,
              password,
              referralId: referral,
            },
            () => {
              dispatch({
                type: SET_MODAL_CONTENT,
                payload: {
                  content: "REGISTER_SUCCESS",
                  callback: () => {
                    history.push("/login");
                  },
                },
              });
              setLoading(false);
            },
            (err) => {
              setLoading(false);
              setError(library[err.code]);
            }
          );
        } else {
          setLoading(false);
          setError(library.PLEASER_CHECK_TOS);
        }
      }
    } else {
      setLoading(false);
    }
  };

  return (
    <LazyLoad>
      <Container className="custom-form">
        <Segment textAlign="left" loading={loading}>
          <Form onSubmit={_handleRegister} error inverted>
            <Header as="h1" textAlign="center">
              {library.REGISTER}
            </Header>
            <Form.Input
              label="Email"
              placeholder={library.PLEASE_ENTER_EMAIL}
              onChange={(e) => setEmail(e.target.value)}
              id="email"
              maxLength="128"
            />
            <Form.Input
              label={library.PASSWORD}
              placeholder={library.PLEASE_ENTER_PASSWORD}
              action={{
                icon: showPassword ? "eye" : "eye slash",
                onClick: () => setShowPassword(!showPassword),
                type: "button",
              }}
              className={showPassword ? "" : "password"}
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              maxLength="128"
            />
            <Form.Input
              label={library.REFERRAL_ID}
              placeholder={library.PLEASE_ENTER_REFERRAL}
              onChange={(e) =>
                setReferral(e.target.value.replace(/[^\d]/g, ""))
              }
              value={referral}
              maxLength="128"
            />
            <div style={{ display: "flex", marginBottom: "1em" }}>
              <Form.Checkbox
                checked={checked}
                onChange={(e, { checked }) => setChecked(checked)}
              />
              <p style={{ marginLeft: 10, fontSize: "0.9em" }}>
                {library.AGREE_TOS}{" "}
                <a href="/terms-of-service" target="_blank">
                  {library.SPWALLET_TOS}
                </a>{" "}
                {"&"}{" "}
                <a href="/policy" target="blank">
                  {library.PRIVACY_AND_POLICY}
                </a>
                .
              </p>
            </div>
            <Message error content={error} positive />
            <Button type="submit" fluid className="spwallet-button">
              {library.CREATE_NEW_ACCOUNT}
            </Button>
            <br />
            <Link to="/login">{library.HAVE_A_ACCOUNT}</Link>
            <br />
            <Link to="/">{library.HOMEPAGE}</Link>
          </Form>
        </Segment>
      </Container>
    </LazyLoad>
  );
}

export default Register;
