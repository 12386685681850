import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Button,
  Container,
  Form,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import { checkEmpty, checkLength } from "../../components/others/Validate";
import { SET_MODAL_CONTENT } from "../../redux/settingReducer";
import { put } from "../../utils/api";

function deviceInfo() {
  var navUserAgent = navigator.userAgent;
  var browserName = navigator.appName;
  var browserVersion = "" + parseFloat(navigator.appVersion);
  // var majorVersion = parseInt(navigator.appVersion, 10);
  var tempNameOffset, tempVersionOffset, tempVersion;

  if ((tempVersionOffset = navUserAgent.indexOf("Opera")) !== -1) {
    browserName = "Opera";
    browserVersion = navUserAgent.substring(tempVersionOffset + 6);
    if ((tempVersionOffset = navUserAgent.indexOf("Version")) !== -1)
      browserVersion = navUserAgent.substring(tempVersionOffset + 8);
  } else if ((tempVersionOffset = navUserAgent.indexOf("MSIE")) !== -1) {
    browserName = "Microsoft Internet Explorer";
    browserVersion = navUserAgent.substring(tempVersionOffset + 5);
  } else if ((tempVersionOffset = navUserAgent.indexOf("Chrome")) !== -1) {
    browserName = "Chrome";
    browserVersion = navUserAgent.substring(tempVersionOffset + 7);
  } else if ((tempVersionOffset = navUserAgent.indexOf("Safari")) !== -1) {
    browserName = "Safari";
    browserVersion = navUserAgent.substring(tempVersionOffset + 7);
    if ((tempVersionOffset = navUserAgent.indexOf("Version")) !== -1)
      browserVersion = navUserAgent.substring(tempVersionOffset + 8);
  } else if ((tempVersionOffset = navUserAgent.indexOf("Firefox")) !== -1) {
    browserName = "Firefox";
    browserVersion = navUserAgent.substring(tempVersionOffset + 8);
  } else if (
    (tempNameOffset = navUserAgent.lastIndexOf(" ") + 1) <
    (tempVersionOffset = navUserAgent.lastIndexOf("/"))
  ) {
    browserName = navUserAgent.substring(tempNameOffset, tempVersionOffset);
    browserVersion = navUserAgent.substring(tempVersionOffset + 1);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
  }

  // trim version
  if ((tempVersion = browserVersion.indexOf(";")) !== -1)
    browserVersion = browserVersion.substring(0, tempVersion);
  if ((tempVersion = browserVersion.indexOf(" ")) !== -1)
    browserVersion = browserVersion.substring(0, tempVersion);

  var OSName = "Unknown OS";
  if (navigator.appVersion.indexOf("Win") !== -1) OSName = "Windows";
  if (navigator.appVersion.indexOf("Mac") !== -1) OSName = "MacOS";
  if (navigator.appVersion.indexOf("X11") !== -1) OSName = "UNIX";
  if (navigator.appVersion.indexOf("Linux") !== -1) OSName = "Linux";

  return browserName + " V" + browserVersion + " (" + OSName + ")";
}

function ResetPassword() {
  const { setting } = useSelector((state) => state);
  const { token } = useParams();
  const [password, setPassword] = useState("");
  const { library } = setting;
  const [passwordError, setPasswordError] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");

  const _handleResetPassword = () => {
    if (checkEmpty(password)) setPasswordError(library.PLEASE_ENTER_PASSWORD);
    else if (checkLength(password, 8)) {
      setPasswordError(library.PLEASE_ENTER_PASSWORD_MORE_THAN);
    } else if (password.length > 16) {
      setPasswordError(library.PLEASE_ENTER_PASSWORD_LESS_THAN);
    } else if (checkEmpty(confirmPassword)) {
      setPasswordError(library.PLEASE_ENTER_CONFIRM_PASSWORD);
    } else if (password !== confirmPassword) {
      setPasswordError(library.CONFIRM_PASSWORD_VS_NEW_PASSWORD);
    } else {
      setLoading(true);
      put(
        `/user-service/user/reset-pwd`,
        {
          token,
          password,
          device: deviceInfo(),
        },
        () => {
          dispatch({
            type: SET_MODAL_CONTENT,
            payload: {
              content: "RESET_PASSWORD_SUCCESSFUL",
              callback: () => {
                history.push("/login");
              },
            },
          });
        },
        (error) => {
          setLoading(false);
          setPasswordError(library[error.code]);
        }
      );
    }
  };

  return (
    <LazyLoad>
      <Container className="custom-form">
        <Segment textAlign="left" loading={loading}>
          <Form
            onSubmit={_handleResetPassword}
            error={passwordError !== null}
            inverted
          >
            <Header as="h1" textAlign="center" className="blue-color">
              {library.RESET_PASSWORD}
            </Header>
            <Form.Input
              label={library.NEW_PASSWORD}
              placeholder={library.PLEASE_ENTER_PASSWORD}
              onChange={(e) => setPassword(e.target.value)}
              type={show ? "text" : "password"}
              action={{
                icon: show ? "eye" : "eye slash",
                onClick: () => setShow(!show),
                type: "button",
              }}
              maxLength="128"
            />
            <Form.Input
              label={library.CONFIRM_NEW_PASSWORD}
              placeholder={library.PLEASE_ENTER_CONFIRM_PASSWORD}
              id="confirmPassword"
              type={showConfirmPassword ? "input" : "password"}
              action={{
                icon: showConfirmPassword ? "eye" : "eye slash",
                onClick: () => setShowConfirmPassword(!showConfirmPassword),
                type: "button",
              }}
              onChange={(e, { value }) => setConfirmPassword(value)}
              value={confirmPassword}
              maxLength="128"
            />
            <Message error content={passwordError} />
            <Button fluid className="spwallet-button">
              {library.RESET_PASSWORD}
            </Button>
            <br />
            <Link to="/">{library.HOMEPAGE}</Link>
          </Form>
        </Segment>
      </Container>
    </LazyLoad>
  );
}

export default ResetPassword;
