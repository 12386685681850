import { Drawer, Button as MaterialButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  Container,
  Grid,
  Image,
  Segment,
  Button,
  Tab,
  Header,
  Dimmer,
  Transition,
  Icon,
  Progress,
} from "semantic-ui-react";
import LusBonus from "../components/lus/LusBonus";
import LusF1Affiliate from "../components/lus/LusF1Affiliate";
import LusLevelDetail from "../components/lus/LusLevelDetail";
import LusRankDetail from "../components/lus/LusRankDetail";
import LusRedeems from "../components/lus/LusRedeems";
import LusStakingCommission from "../components/lus/LusStakingCommission";
import LusStakingForm from "../components/lus/LusStakingForm";
import LusStakingList from "../components/lus/LusStakingList";
import Advertisement from "../components/others/Advertisement";
import "../components/partner/Partner.scss";
import { LUS_SYMBOL, M_USD } from "../constant/CoinConstant";
import {
  _getLusStakingHistory,
  _getLusStakingProduct,
} from "../redux/walletReducer";
import { isProduction, S3_URL } from "../settings";
import { RANK_LEVEL } from "../settings/constant";
import { formatUSD } from "../settings/format";
import { get } from "../utils/api";
import { LUSM_SYMBOL } from "../constant/CoinConstant";
import {
  API_GET_LUS_STAKING_STATISTIC,
  API_GET_LUS_STATISTIC,
} from "../settings/apiEndpoint";
import { LUS_FIRST_PRICE } from "../constant/PriceConstants";

const ProgressExampleActive = ({ data }) => {
  const percent = Math.round((data.staked / data.poolSize) * 100);
  return (
    <Progress
      percent={percent}
      active
      className="lus-staking-progress-bar"
      progress
    ></Progress>
  );
};

function Partner() {
  const [open, setOpen] = useState(false);
  const { wallet, lus } = useSelector((state) => state);
  const { lusStakingProducts, lusStakingHistory, fundList } = wallet;
  const { LusStatistic } = lus;
  const [reload, setReload] = useState(true);
  const [visible, setVisible] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const dispatch = useDispatch();
  const [totalRankingBonus, setTotalRankingBonus] = useState(0);
  const [totalCommission, setTotalCommission] = useState(0);
  const [totalMUSDCommission, setTotalMUSDCommission] = useState(0);
  const [totalMUSDRankingBonus, setTotalMUSDRankingBonus] = useState(0);
  const [totalRedeem, setTotalRedeem] = useState(0);
  const [totalMUSDRedeem, setTotalMUSDRedeem] = useState(0);
  const [showLevelDetail, setShowLevelDetail] = useState(false);
  const [showRankDetail, setShowRankDetail] = useState(false);
  const [mounted, setMounted] = useState(true);
  const history = useHistory();
  // const LusPrice =
  //   fundList.length > 0
  //     ? fundList.filter((item) => item.coin === LUS_SYMBOL)[0].usdPrice
  //     : 0;

  const _fetchData = () => {
    get(API_GET_LUS_STATISTIC, (data) => {
      setTotalRankingBonus(data.totalLuSRankingBonus);
      setTotalMUSDRankingBonus(data.totalmUSDRankingBonus);
      setTotalCommission(data.totalLuSCommission);
      setTotalMUSDCommission(data.totalmUSDCommission);
    });
    get(API_GET_LUS_STAKING_STATISTIC, (data) => {
      setTotalRedeem(data.totalRedeem);
      setTotalMUSDRedeem(data.totalRedeemV2);
    });
    dispatch(_getLusStakingProduct());
    dispatch(_getLusStakingHistory({ pageSize: 10 }));
  };

  useEffect(() => {
    if (mounted) {
      _fetchData();
    }
    return () => setMounted(!mounted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted]);

  const _data = [];
  if (lusStakingProducts) {
    lusStakingProducts.sort((a, b) => {
      return a.duration - b.duration;
    });
    lusStakingProducts.forEach((element) => {
      const _index = _data.findIndex(
        (item) => item.base === element.base && item.quote === element.quote
      );
      if (_index < 0) {
        _data.push({
          base: element.base,
          quote: element.quote,
          status: element.status,
          list: [element],
          lusRewardLevels: element.lusRewardLevels,
        });
      } else {
        _data[_index].list.push(element);
      }
    });
  }

  const panes = [
    {
      menuItem: "Profit",
      render: () => (
        <Tab.Pane>
          <LusRedeems />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Affiliate Profit",
      render: () => (
        <Tab.Pane>
          <LusStakingCommission />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Ranking Bonus",
      render: () => (
        <Tab.Pane>
          <LusBonus />
        </Tab.Pane>
      ),
    },
    // {
    //   menuItem: "Shopping",
    //   render: () => <Tab.Pane className="spwallet-history">{library.COMING_SOON}</Tab.Pane>,
    // },
    // {
    //   menuItem: "Rewards",
    //   render: () => <Tab.Pane className="spwallet-history">{library.COMING_SOON}</Tab.Pane>,
    // },
  ];

  const totalUSD = formatUSD(
    (totalRedeem + totalCommission + totalRankingBonus) * LUS_FIRST_PRICE +
      totalMUSDCommission +
      totalMUSDRankingBonus +
      totalMUSDRedeem
  );
  const totalShareProfit =
    totalCommission * LUS_FIRST_PRICE + totalMUSDCommission;
  const totalPromotion =
    totalRankingBonus * LUS_FIRST_PRICE + totalMUSDRankingBonus;
  const totalProfit = totalRedeem * LUS_FIRST_PRICE + totalMUSDRedeem;

  return (
    <div id="partner">
      <LazyLoad>
        <Segment
          vertical
          basic
          style={{
            backgroundImage:
              "linear-gradient(to right, var(--blue), var(--green))",
          }}
        >
          <Header as="h1">Luxury Outlet Street</Header>
        </Segment>
        <div className="title">
          <Container>
            <Grid columns={2} verticalAlign="middle">
              <Grid.Column>
                <Image
                  src={`${S3_URL}/lus-landing-page/lus-shopping-logo.png`}
                />
              </Grid.Column>
              <Grid.Column textAlign="right">
                <div className="shop-notification">
                  <a
                    // eslint-disable-next-line react/jsx-no-target-blank
                    target="_blank"
                    href="https://thongbao.lus.shopping/promotions/promotion-thang-04"
                    style={{
                      textDecoration: "unset",
                    }}
                    className="notification"
                  >
                    <Icon name="bell" style={{ fontSize: "2em" }} inverted />
                    <span className="count">1</span>
                  </a>
                  <MaterialButton
                    className="shop-now"
                    onClick={() => history.push("/lus/shopping")}
                    disabled={isProduction}
                  >
                    <span className="hide-mobile">Shop now</span>
                    <img
                      src={`${S3_URL}/spwallet/shopnow-icon.png`}
                      alt="shopping-now"
                      className="icon"
                    />
                  </MaterialButton>
                </div>
              </Grid.Column>
            </Grid>
          </Container>
        </div>
        {!isProduction && _data[0] && false && (
          <Container textAlign="left" className="lus-statistic">
            <Grid>
              <Grid.Column computer="8" mobile={16}>
                <Segment basic vertical>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>Total</div>
                    <div className="value">
                      {formatUSD(_data[0].list[0].poolSize)} {LUS_SYMBOL}
                    </div>
                  </div>
                  <ProgressExampleActive data={_data[0].list[0]} />
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>Sold</div>
                    <div className="value">
                      {formatUSD(_data[0].list[0].staked)} {LUS_SYMBOL}
                    </div>
                  </div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>Remain</div>
                    <div className="value">
                      {formatUSD(
                        _data[0].list[0].poolSize - _data[0].list[0].staked
                      )}{" "}
                      {LUS_SYMBOL}
                    </div>
                  </div>
                </Segment>
              </Grid.Column>
            </Grid>
          </Container>
        )}
        <Container textAlign="left">
          <Segment
            vertical
            basic
            className="level-rank"
            style={{ paddingBottom: 0 }}
          >
            <Grid stretched>
              <Grid.Column computer={8} mobile={16}>
                <Segment>
                  <Grid>
                    <Grid.Column
                      width={12}
                      verticalAlign="middle"
                      className="rank-level"
                    >
                      <p>
                        My level:{" "}
                        <span>{RANK_LEVEL[LusStatistic.memberLevel]} </span>
                      </p>
                      <p>
                        <Button
                          onClick={() => setShowLevelDetail(!showLevelDetail)}
                        >
                          Account level achievements
                        </Button>
                      </p>
                      <p>
                        <Link
                          to="#"
                          onClick={() => {
                            setVisible1(false);
                            setVisible(!visible);
                          }}
                        >
                          Show staked list
                        </Link>
                      </p>
                    </Grid.Column>
                    <Grid.Column width={4} verticalAlign="middle">
                      {LusStatistic.memberLevel &&
                        LusStatistic.memberLevel !== "BASIC" && (
                          <Image
                            src={`${S3_URL}/spwallet/level-${RANK_LEVEL[
                              LusStatistic.memberLevel
                            ]
                              .replace(" ", "-")
                              .toLowerCase()}.png`}
                          />
                        )}
                    </Grid.Column>
                  </Grid>
                  <div className="partner-show-mobile">
                    <br />
                    <Transition.Group animation="slide down" duration={500}>
                      {visible && (
                        <Segment
                          className="spwallet-history lus-staking-history"
                          vertical
                          basic
                        >
                          <LusStakingList reload={reload} />
                        </Segment>
                      )}
                    </Transition.Group>
                  </div>
                </Segment>
              </Grid.Column>
              <Grid.Column computer={8} mobile={16}>
                <Segment>
                  <Grid>
                    <Grid.Column
                      width={12}
                      verticalAlign="middle"
                      className="rank-level"
                    >
                      <p>
                        My rank:{" "}
                        <span>{RANK_LEVEL[LusStatistic.rankingLevel]} </span>
                      </p>
                      <p>
                        <Button
                          onClick={() => setShowRankDetail(!showRankDetail)}
                        >
                          Account Rank Requirements
                        </Button>
                      </p>
                      <p className="value">
                        Affiliate 1 Agency:{" "}
                        <span
                          style={{
                            color: "#0076ba",
                            fontWeight: 900,
                          }}
                        >
                          {LusStatistic.totalF1Affiliate}
                        </span>
                        /10{" "}
                        <Link
                          to="#"
                          onClick={() => {
                            setVisible1(!visible1);
                            setVisible(false);
                          }}
                        >
                          Show list
                        </Link>
                      </p>
                    </Grid.Column>
                    <Grid.Column width={4} verticalAlign="middle">
                      {LusStatistic.rankingLevel &&
                        LusStatistic.rankingLevel !== "NO_RANK" && (
                          <Image
                            src={`${S3_URL}/spwallet/rank-${RANK_LEVEL[
                              LusStatistic.rankingLevel
                            ].toLowerCase()}.png`}
                          />
                        )}{" "}
                    </Grid.Column>
                  </Grid>
                  <div className="partner-show-mobile">
                    <br />
                    <Transition.Group animation="slide down" duration={500}>
                      {visible1 && (
                        <Segment
                          className="spwallet-history lus-staking-history"
                          vertical
                          basic
                        >
                          <LusF1Affiliate />
                        </Segment>
                      )}
                    </Transition.Group>
                  </div>
                </Segment>
              </Grid.Column>
            </Grid>
          </Segment>
          <div className="partner-hide-mobile">
            <Transition.Group
              animation="slide down"
              duration={500}
              style={{ marginTop: "2em" }}
            >
              {visible && (
                <Segment className="spwallet-history lus-staking-history">
                  <LusStakingList reload={reload} />
                </Segment>
              )}
            </Transition.Group>
          </div>
          <div className="partner-hide-mobile">
            <Transition.Group animation="slide down" duration={500}>
              {visible1 && (
                <Segment className="spwallet-history lus-staking-history">
                  <LusF1Affiliate />
                </Segment>
              )}
            </Transition.Group>
          </div>

          <Segment textAlign="center" vertical basic>
            <Grid stretched>
              <Grid.Column computer={8} mobile={16}>
                <Segment className="hide-mobile" style={{ marginBottom: 0 }}>
                  <Grid>
                    <Grid.Column width={8}>
                      <p>Total Staked</p>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <p>Total Earning</p>
                    </Grid.Column>
                  </Grid>
                  <Grid>
                    <Grid.Column width={8} className="col-left">
                      <p className="staked">
                        {lusStakingHistory &&
                          formatUSD(lusStakingHistory.meta.totalLusM)}{" "}
                        {LUSM_SYMBOL}
                      </p>
                      <p style={{ fontSize: "1.1em" }}>
                        (≈ <span style={{ fontSize: "0.8em" }}>$</span>
                        {lusStakingHistory &&
                          formatUSD(lusStakingHistory.meta.totalUSD)}
                        )
                      </p>
                    </Grid.Column>
                    <Grid.Column width={8} className="col-right">
                      <p className="total-earning">
                        {totalUSD} {M_USD}
                      </p>
                      <p style={{ fontSize: "1.1em" }}>
                        (≈ <span style={{ fontSize: "0.8em" }}>$</span>
                        {totalUSD})
                      </p>
                    </Grid.Column>
                  </Grid>
                  <Segment basic vertical style={{ paddingBottom: 0 }}>
                    <Button
                      onClick={() => setOpen(!open)}
                      className="stake-button"
                    >
                      Stake {LUSM_SYMBOL}
                    </Button>
                  </Segment>
                </Segment>
                <Segment className="show-mobile balance">
                  <p>
                    <span>Total Staked:</span>
                    <span className="staked">
                      {lusStakingHistory &&
                        formatUSD(lusStakingHistory.meta.totalLusM)}{" "}
                      {LUSM_SYMBOL}
                    </span>
                  </p>
                  <p>
                    <span></span>
                    <span style={{ fontSize: "1.1em" }}>
                      (≈ <span style={{ fontSize: "0.8em" }}>$</span>
                      {lusStakingHistory && lusStakingHistory.meta.totalUSD})
                    </span>
                  </p>
                  <p>
                    <span> Total Earning:</span>
                    <span className="total-earning">
                      {totalUSD} {M_USD}
                    </span>
                  </p>
                  <p>
                    <span></span>
                    <span style={{ fontSize: "1.1em" }}>
                      (≈ <span style={{ fontSize: "0.8em" }}>$</span>
                      {totalUSD})
                    </span>
                  </p>
                  <Button
                    onClick={() => setOpen(!open)}
                    className="stake-button"
                  >
                    Stake {LUSM_SYMBOL}
                  </Button>
                </Segment>
              </Grid.Column>
              <Grid.Column computer={8} mobile={16}>
                <Segment>
                  <Grid>
                    <Grid.Row style={{ paddingBottom: 0 }} columns={2}>
                      <Grid.Column textAlign="left" className="key">
                        Profit
                      </Grid.Column>
                      <Grid.Column textAlign="right">
                        <div className="value">${formatUSD(totalProfit)}</div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ paddingBottom: 0 }} columns={2}>
                      <Grid.Column textAlign="left" className="key">
                        Share profit
                      </Grid.Column>
                      <Grid.Column textAlign="right">
                        <div className="value">
                          <span style={{ fontSize: "0.8em" }}>$</span>
                          {formatUSD(totalShareProfit)}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ paddingBottom: 0 }} columns={2}>
                      <Grid.Column textAlign="left" className="key">
                        Shopping commission
                      </Grid.Column>
                      <Grid.Column textAlign="right">
                        <div className="value">
                          <span style={{ fontSize: "0.8em" }}>$</span>
                          {formatUSD(
                            LusStatistic.totalShoppingCommission *
                              LUS_FIRST_PRICE
                          )}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ paddingBottom: 0 }} columns={2}>
                      <Grid.Column textAlign="left" className="key">
                        Leadership
                      </Grid.Column>
                      <Grid.Column textAlign="right">
                        <div className="value">
                          <span style={{ fontSize: "0.8em" }}>$</span>0
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ paddingBottom: 0 }} columns={2}>
                      <Grid.Column textAlign="left" className="key">
                        Event/Promotion
                      </Grid.Column>
                      <Grid.Column textAlign="right">
                        <div className="value">
                          <span style={{ fontSize: "0.8em" }}>$</span>
                          {formatUSD(totalPromotion)}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row style={{ paddingBottom: 0 }} columns={2}>
                      <Grid.Column textAlign="left" className="key">
                        Total revenue
                      </Grid.Column>
                      <Grid.Column textAlign="right">
                        <div className="value">
                          <span style={{ fontSize: "0.8em" }}>$</span>
                          {formatUSD(LusStatistic.totalAffiliateRevenue)}
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Grid.Column>
            </Grid>
          </Segment>
          <Header as="h1" textAlign="center">
            Earning Detail
          </Header>
          <Tab panes={panes} className="partner-tab" />
        </Container>
      </LazyLoad>
      {/* <Advertisement /> */}
      {_data.length > 0 && (
        <Dimmer page active={open}>
          <LazyLoad>
            <LusStakingForm
              currentProduct={_data[0].list[0]}
              data={_data[0]}
              onClose={() => setOpen(false)}
              totalStaked={LusStatistic.totalStaked}
              _onComplete={() => {
                setTimeout(() => {
                  _fetchData();
                }, 1000);
                setOpen(false);
                setReload(!reload);
              }}
            />
          </LazyLoad>
        </Dimmer>
      )}
      <Drawer
        anchor="left"
        open={showLevelDetail}
        className={"custom-modal-vk"}
      >
        <LusLevelDetail onClose={() => setShowLevelDetail(!showLevelDetail)} />
      </Drawer>
      <Drawer anchor="left" open={showRankDetail} className={"custom-modal-vk"}>
        <LusRankDetail onClose={() => setShowRankDetail(!showRankDetail)} />
      </Drawer>
    </div>
  );
}

export default Partner;
