import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Checkbox,
  Dimmer,
  Grid,
  Header,
  Icon,
  Image,
  Label,
  Segment,
} from "semantic-ui-react";
import { _getGift } from "../../redux/userReducer";
import { _getStakingHistory, _getFundList } from "../../redux/walletReducer";
import { S3_URL } from "../../settings";
import { RETURN_TO_EASY_BUY } from "../../settings/constant";
import {
  formatAmount,
  deleteText,
  formatStakingTime,
} from "../../settings/format";
import { post } from "../../utils/api";
import AmountInput from "../others/AmountInput";
import { LUSM_SYMBOL } from "../../constant/CoinConstant";

function StakingDetail({
  data,
  onClose,
  _changeProduct,
  currentProduct,
  showStakingPolicy,
  _onComplete,
}) {
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const [amount, setAmount] = useState("");
  const [checked, setChecked] = useState(false);
  const [lusChecked, setLusChecked] = useState(false);
  const [now] = useState(Date.now());
  const [handling, setHandling] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const _changeAmount = (e) => {
    let { value } = e.target;
    value = parseInt(deleteText(value));
    if (parseFloat(value) > currentProduct.maxAmount) {
      setAmount(currentProduct.maxAmount);
    } else {
      setAmount(value);
    }
  };

  const _handleStaking = () => {
    let _error = null;
    if (!amount) {
      _error = "PLEASE_ENTER_AMOUNT_FOR_STAKING";
    } else if (parseFloat(amount) < currentProduct.minAmount) {
      _error = "AMOUNT_TOO_LOW";
    } else if (parseFloat(amount) > currentProduct.maxAmount) {
      _error = "AMOUNT_TOO_LARGE";
    } else if (!checked || !lusChecked) {
      _error = "PLEASER_CHECK_TOS";
    } else {
      _error = null;
    }
    if (_error) {
      toast(
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <div style={{ color: "#fe644e" }}>
            <Icon name="x" size="large" />
          </div>
          <div>
            {library[_error]}. {library.PLEASE_TRY_AGAIN}.
          </div>
        </div>
      );
    } else {
      setOpenConfirm(true);
    }
  };

  const _postStaking = () => {
    setHandling(true);
    post(
      `/staking-service/staking`,
      {
        productId: currentProduct.id,
        amount: amount,
      },
      () => {
        toast(
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <div style={{ color: "var(--green)" }}>
              <Icon name="checkmark" size="large" />
            </div>
            <div>
              <label className="title">{library.STAKING_SUCCESS}.</label>
            </div>
          </div>
        );
        setHandling(false);
        setOpenConfirm(false);
        _onComplete();
      },
      (error) => {
        setOpenConfirm(false);
        setHandling(false);
        onClose();
        toast(
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <div style={{ color: "#fe644e" }}>
              <Icon name="x" size="large" />
            </div>
            <div>
              {library[error.code]}. {library.PLEASE_TRY_AGAIN}.
            </div>
          </div>
        );
      }
    );
  };

  // const level = data.lusRewardLevels.filter(
  //   (element) =>
  //     parseFloat(deleteText(amount)) > element.minAmount &&
  //     (parseFloat(deleteText(amount)) <= element.maxAmount ||
  //       element.maxAmount === 0)
  // );

  return (
    <>
      <Segment className="staking-detail" textAlign="left">
        <Icon name="x" link onClick={onClose} />
        <Segment vertical>
          <Grid>
            <Grid.Column computer={9} tablet={9} mobile={16}>
              <Header>
                {library.LOCKED} {library.STAKING}
              </Header>
              <Segment vertical basic className="name">
                <Image src={`${S3_URL}/coins/${currentProduct.quote}.png`} />
                <Header>
                  {currentProduct.title} ({currentProduct.quote})
                </Header>
              </Segment>
              <Segment basic vertical>
                <p>{library.TYPE}</p>
                <div className="type">{currentProduct.type.toUpperCase()}</div>
              </Segment>
              <p>
                {library.DURATION} ({library.DAYS})
              </p>
              <Segment vertical basic className="duration">
                {data.list.map((item, index) => (
                  <div
                    className={item.id === currentProduct.id ? "active" : ""}
                    key={index}
                    onClick={() => _changeProduct(item)}
                  >
                    {item.duration}
                  </div>
                ))}
              </Segment>
              <Segment vertical basic>
                <p>{library.AMOUNT}</p>
                <AmountInput
                  placeholder={library.PLEASE_ENTER_AMOUNT_FOR_STAKING}
                  fluid
                  onChange={_changeAmount}
                  value={amount}
                  action={
                    <div className="">
                      <div>{currentProduct.base}</div>
                      <div onClick={() => setAmount(currentProduct.maxAmount)}>
                        MAX
                      </div>
                    </div>
                  }
                  className="amount-group"
                />
              </Segment>
              <Segment basic vertical>
                <p>{library.LOCK_AMOUNT_LIMITATION}</p>
                <div>
                  <span style={{ opacity: 0.6 }}>{library.MINIMUM}: </span>
                  {formatAmount(currentProduct.minAmount)} {currentProduct.base}{" "}
                  ~ <span style={{ opacity: 0.6 }}>{library.MAXIMUM}: </span>
                  {formatAmount(currentProduct.maxAmount)} {currentProduct.base}
                </div>
              </Segment>
            </Grid.Column>
            <Grid.Column computer={7} tablet={7} mobile={16}>
              <Header>{library.SUMMARY}</Header>
              <Segment vertical basic className="summary">
                <div>
                  <div>
                    <p>Stake</p>
                    <p>
                      <Image
                        src={`${S3_URL}/coins/${currentProduct.base}.png`}
                        style={{ width: 30, height: 30, marginRight: "0.5em" }}
                        inline
                      />
                      {data.base}
                    </p>
                  </div>
                  <div>
                    <p style={{ textAlign: "right" }}>{library.REDEEM}</p>
                    <p>
                      {data.quote}
                      <Image
                        src={`${S3_URL}/coins/${currentProduct.quote}.png`}
                        style={{ width: 30, height: 30, marginLeft: "0.5em" }}
                        inline
                      />
                    </p>
                  </div>
                </div>
                <div>
                  <p>{library.START_DATE}:</p>
                  <p>{formatStakingTime(now)}</p>
                </div>
                <div>
                  <p>{library.VALUE_DATE}:</p>
                  <p>{formatStakingTime(now)}</p>
                </div>
                <div>
                  <p>{library.INTEREST_PERIOD}:</p>
                  <p>
                    {currentProduct.duration} {library.DAYS}
                  </p>
                </div>
                <div>
                  <p>{library.INTEREST_END_DATE}:</p>
                  <p>
                    {formatStakingTime(
                      now + (currentProduct.duration + 1) * 8.64e7
                    )}
                  </p>
                </div>
                <div>
                  <p>{library.REDEMPTION_PERIOD}:</p>
                  <p>
                    {currentProduct.redeemPeriod} {library.DAYS}
                  </p>
                </div>
                {/* <div>
                  <p>Profit per Month:</p>
                  <p>{level[0] ? level[0].interestRate : 0}%</p>
                </div>
                <div>
                  <p>{library.ESTIMATE_INTEREST}:</p>
                  <p>
                    {formatAmount(
                      ((parseFloat(deleteText(amount) * currentProduct.price) *
                        ((level[0] ? level[0].interestRate : 0) / 100)) /
                        30) *
                      currentProduct.duration
                    )}{" "}
                    {currentProduct.quote}
                  </p>
                </div> */}
                <div>
                  <p>{library.RECEIVE}:</p>
                  <p>
                    {formatAmount(
                      parseFloat(deleteText(amount) * currentProduct.price)
                    )}{" "}
                    {LUSM_SYMBOL}
                  </p>
                </div>
                <Segment basic vertical textAlign="left" className="policy">
                  <Checkbox
                    checked={checked}
                    onChange={(e, { checked }) => setChecked(checked)}
                    disabled={handling || currentProduct.status !== "OPEN"}
                  />
                  <div style={{ color: "#fff", marginLeft: 10 }}>
                    {library.AGREE_TO}{" "}
                    <a href="/terms-of-service" target="_blank">
                      {library.SPWALLET_TOS}
                    </a>{" "}
                    {library.AND}{" "}
                    <Link to="#" onClick={showStakingPolicy}>
                      {library.STAKING_POLICY}
                    </Link>
                    .
                  </div>
                </Segment>
                <Segment basic vertical textAlign="left" className="policy">
                  <Checkbox
                    checked={lusChecked}
                    onChange={(e, { checked }) => setLusChecked(checked)}
                    disabled={handling || currentProduct.status !== "OPEN"}
                  />
                  <div style={{ color: "#fff", marginLeft: 10 }}>
                    {library.AGREE_TO}{" "}
                    <a href="/terms-of-service" target="_blank">
                      Luxury outlet street's Term
                    </a>
                  </div>
                </Segment>
              </Segment>
              <Button
                fluid
                onClick={_handleStaking}
                disabled={handling || currentProduct.status !== "OPEN"}
              >
                {library.CONFIRM_PURCHASE}
              </Button>
            </Grid.Column>
          </Grid>
        </Segment>
      </Segment>
      <Dimmer active={openConfirm} page>
        <Segment className="staking-detail custom">
          <Icon
            name="x"
            link
            onClick={() => {
              setOpenConfirm(false);
              onClose();
            }}
          />
          <Header>{library.CONFIRM_STAKING}</Header>
          <Segment vertical basic className="summary">
            <div>
              <div>
                <p>Stake</p>
                <p>
                  <Image
                    src={`${S3_URL}/coins/${currentProduct.base}.png`}
                    style={{ width: 30, height: 30, marginRight: "0.5em" }}
                    inline
                  />
                  {data.base}
                </p>
              </div>
              <div>
                <p style={{ textAlign: "right" }}>{library.REDEEM}</p>
                <p>
                  {data.quote}
                  <Image
                    src={`${S3_URL}/coins/${currentProduct.quote}.png`}
                    style={{ width: 30, height: 30, marginLeft: "0.5em" }}
                    inline
                  />
                </p>
              </div>
            </div>
            <div>
              <p>{library.START_DATE}:</p>
              <p>{formatStakingTime(now)}</p>
            </div>
            <div>
              <p>{library.VALUE_DATE}:</p>
              <p>{formatStakingTime(now)}</p>
            </div>
            <div>
              <p>{library.INTEREST_PERIOD}:</p>
              <p>
                {currentProduct.duration} {library.DAYS}
              </p>
            </div>
            <div>
              <p>{library.INTEREST_END_DATE}:</p>
              <p>
                {formatStakingTime(
                  now + (currentProduct.duration + 1) * 8.64e7
                )}
              </p>
            </div>
            <div>
              <p>{library.REDEMPTION_PERIOD}:</p>
              <p>
                {currentProduct.redeemPeriod} {library.DAYS}
              </p>
            </div>
            {/* <div>
              <p>Profit per Month:</p>
              <p>{level[0] ? level[0].interestRate : 0}%</p>
            </div>
            <div>
              <p>{library.ESTIMATE_INTEREST}:</p>
              <p>
                {formatAmount(
                  ((parseFloat(deleteText(amount) * currentProduct.price) *
                    ((level[0] ? level[0].interestRate : 0) / 100)) /
                    30) *
                  currentProduct.duration
                )}{" "}
                {currentProduct.quote}
              </p>
            </div> */}
          </Segment>
          <Button
            fluid
            onClick={_postStaking}
            disabled={handling || currentProduct.status !== "OPEN"}
          >
            {library.CONFIRM}
          </Button>
        </Segment>
      </Dimmer>
    </>
  );
}

function StakingItem({ item, showStakingPolicy }) {
  const [currentStaking, setCurrentStaking] = useState(item.list[0]);
  const { setting, user } = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { library } = setting;
  const { isLogin } = user;
  const history = useHistory();

  const _onComplete = () => {
    dispatch(_getStakingHistory({}));
    dispatch(_getFundList());
    dispatch(_getGift());
  };

  return (
    <>
      <Grid.Column
        widescreen={5}
        largeScreen={5}
        computer={8}
        tablet={8}
        mobile={16}
      >
        <Segment basic vertical style={{ paddingBottom: 0 }}>
          <div className="shadow">
            <Segment
              className="information"
              style={{
                backgroundImage: `url(${currentStaking.coverPhoto})`,
              }}
            ></Segment>
            <Segment className="parameter">
              <Segment vertical textAlign="left" basic>
                <div>
                  <Header>
                    {currentStaking.name}{" "}
                    <small>({currentStaking.quote})</small>
                  </Header>
                  <small>{currentStaking.title}</small>
                </div>
                <Label>{library[currentStaking.status].toUpperCase()}</Label>
              </Segment>
              <Segment basic vertical className="estApy">
                <div
                  style={{
                    justifyContent: "center",
                    marginBottom: "0.5em",
                    opacity: 0.6,
                  }}
                >
                  Est. Profit per Month
                </div>
                <Header as="h1">Up to 5%</Header>
              </Segment>
              <div
                style={{
                  justifyContent: "center",
                  marginBottom: "0.5em",
                  opacity: 0.6,
                }}
              >
                {library.DURATION}
              </div>
              <Segment vertical basic className="duration">
                {item.list.map((item, index) => (
                  <div
                    className={item.id === currentStaking.id ? "active" : ""}
                    key={index}
                    onClick={() => setCurrentStaking(item)}
                  >
                    {item.duration}
                  </div>
                ))}
              </Segment>
              {/* <Segment
                vertical
                basic
                style={{ textAlign: "center", display: "block", opacity: 1 }}
              >
                {formatStakingTime(currentStaking.startDate)} &nbsp;&nbsp; ~
                &nbsp;&nbsp;
                {formatStakingTime(currentStaking.endDate)}
              </Segment> */}
              <Segment basic vertical>
                {isLogin ? (
                  <Button
                    fluid
                    onClick={() => setOpen(true)}
                    // disabled={currentStaking.status !== "OPEN"}
                    disabled
                  >
                    Stake Now
                  </Button>
                ) : (
                  <Button
                    fluid
                    onClick={() =>
                      history.push({
                        pathname: "/login",
                        state: {
                          return: RETURN_TO_EASY_BUY,
                        },
                      })
                    }
                  >
                    Login for Stake
                  </Button>
                )}
              </Segment>
            </Segment>
          </div>
        </Segment>
      </Grid.Column>
      <Dimmer page active={open}>
        <LazyLoad>
          <StakingDetail
            currentProduct={currentStaking}
            data={item}
            onClose={() => setOpen(false)}
            _changeProduct={(e) => setCurrentStaking(e)}
            showStakingPolicy={showStakingPolicy}
            _onComplete={() => {
              _onComplete();
              setOpen(false);
            }}
          />
        </LazyLoad>
      </Dimmer>
    </>
  );
}

export default StakingItem;
