export const en = {
  HOMEPAGE_BANNER_HEADER: "The most trusted & secure crypto wallet",
  HOMEPAGE_SUB_BANNER_HEADER_1:
    "Storing, trading, digital assets and the simplest way ",
  HOMEPAGE_SUB_BANNER_HEADER_2: "to monitoring Crypto Currencies",
  HOMEPAGE_STRONG_HEADER: "You deserve easy access to Crypto Currencies",
  HOMEPAGE_STRONG_SUB_HEADER: "SPWALLET advantages",
  HOMEPAGE_STRONG_1: "Storing BTC and Crypto currencies in minutes",
  HOMEPAGE_STRONG_2:
    "Withdrawing, receiving and save Crypto Currencies fast and and simple",
  HOMEPAGE_STRONG_3: "Swapping between crypto currencies without an exchanges",
  HOMEPAGE_STRONG_4: "P2P trading, rapid and comfy",
  HOMEPAGE_STRONG_5: "Make more profit when holding Digital Assets",
  HOMEPAGE_STRONG_6: "Purchasing Crypto Currencies through Bank Account",
  HOMEPAGE_APPLICATION_HEADER: "ANY TIME, ANY WHERE 24/7",
  HOMEPAGE_APPLICATION_SUB_HEADER:
    "SPWALLET Platform and SPWALLET official website is the best way to start trading and holding digital assets.",
  INFORMATION: "Information",
  COMMUNITY: "Community",
  TERM_OF_USE: "Term of service",
  SUPPORT: "Support",
  LOGIN: "Login",
  REGISTER: "Register",
  PASSWORD: "Password",
  REFERRAL: "Referral",
  CONFIRM: "Confirm",
  PLEASE_ENTER_EMAIL: "Please enter your email",
  PLEASE_ENTER_PASSWORD: "Please enter your password",
  PLEASE_ENTER_REFERRAL: "Please enter your referral ID",
  CREATE_NEW_ACCOUNT: "Create a new account",
  HAVE_A_ACCOUNT: "Already have an account",
  INVALID_EMAIL: "Email is invalid ",
  PASSWORD_TOO_SHORT: "Password should be more than 8 character",
  NEW_PASSWORD_TOO_SHORT: "New password should be more than 8 character",
  EMAIL_ALREADY_EXIST: "Your register email already taken",
  REFERRAL_NOT_EXIST: "Referral not exist",
  INVALID_TOKEN: "Token is invalid",
  TOKEN_ALREADY_USED: "Token is already used",
  WRONG_CREDENTIALS: "Email and password are incorrect",
  PLEASE_ENTER_EMAIL_AND_PASSWORD: "Please enter your email and password",
  UNVERIFIED_DEVICE: "Please check email to verify this device",
  OTP_WRONG_OR_USED: "OTP is wrong or already used",
  PLEASE_CHECK_EMAIL_FOR_RESET_PASSWORD:
    "Please check your email for reset password",
  RESET_PASSWORD_SUCCESSFUL: "Reset password successful",
  TOKEN_EXPIRED: "Token is expired",
  PLEASE_ENTER_WALLET_ADDRESS: "Please enter wallet address",
  INVALID_WALLET_ADDRESS: "Wallet address is incorrect",
  PLEASE_ENTER_AMOUNT_WITHDRAW: "Please enter your withdrawal amount",
  WITHDRAW_BELOW_MINIMUM: "Withdraw amount is below minimum withdraw",
  OLD_PASSWORD: "Old password",
  NEW_PASSWORD: "New password",
  CHANGE_PASSWORD: "Change password",
  PLEASER_ENTER_BASE_AMOUNT: "Please enter amount of swap",
  BASE_AMOUNT_GREATER_MIN: "Please enter amount greater than minimum amount",
  BASE_AMOUNT_LESS_MAX: "Please enter amount less than maximum amount",
  INVALID_PARAMETERS: "Parameters is invalid",
  INVALID_OPERATION: "Operation is invalid",
  NOTIFICATION: "Notification",
  SWAP_SUCCESS: "Swap was successful",
  CONFIRM_SUCCESS: "Device confirmation is successful",
  REGISTER_SUCCESS:
    "Register was successful. Please check email for active account.",
  RESET_PASSWORD: "Reset password",
  ACTIVE_ACCOUNT: "The account has been activated. You can log in now",
  OTP_EXPIRED: "OTP is expired",
  KYC_SUCCESS: "Verify account was successful. Please waiting admin confirm.",
  KYC_NOTIFICATION: "This account is waiting verified confirm or verified",
  WITHDRAW_SUCCESSFUL: "Withdraw success",
  CONFIRM_WITHDRAW_SUCCESS: "Confirm withdraw was successful",
  CANCEL_WITHDRAW: "Withdraw was cancelled",
  WAITING_CONFIRM: "Waiting for confirm email",
  PENDING: "Pending",
  CANCELLED: "Canceled",
  CANCELED: "Canceled",
  EXTERNAL: "Withdraw",
  INTERNAL: "Transfer",
  CANCEL_STAKING_SUCCESS: "Staking was cancelled",
  CREATE_STAKING_SUCCESS: "Create staking was successful",
  EXTERNAL_WITHDRAW_BLOCKED: "External withdraw was blocked",
  USER_WAS_BLOCKED: "User is blocked. Please contact us to support.",
  USER_NOT_ACTIVE: "User is inactive or blocked",
  MISSING_GACODE: "Google authenticator code is missing",
  WRONG_PASSWORD: "Password is wrong",
  COIN_NOT_SUPPORT: "Coin is not support",
  NETWORK_NOT_SUPPORT: "Network is not support",
  DEPOSIT_DISABLE: "Deposit is disabled",
  WITHDRAW_DISABLE: "Withdraw is disabled",
  DISABLE_ACCOUNT_SUCCESS: "This account was disabled",
  PLEASE_ENTER_GA_CODE: "Please enter Google authenticator code",
  WRONG_GACODE: "Google authenticator code is incorrect",
  ENABLE_GA_CODE: "Google authenticator is enabled",
  DISABLE_GA_CODE: "Google authenticator is disabled",
  GACODE_REQUIRED: "Pease enter Google authenticator code",
  CONFIRMED: "Completed",
  PRICE_EXPIRED: "Price is expired",
  PLEASE_ENTER_AMOUNT_MORE_THAN: "Please enter amount greater than",
  UNAVAILABLE_BALANCE: "Unavailable balance",
  PLEASE_ENTER_AMOUNT_FOR_STAKING: "Please enter your staking amount",
  UNKNOWN: "Service unavailable",
  INVALID_ADDRESS: "Wallet address is invalid",
  PLEASE_ENTER_OLD_PASSWORD: "Please enter old password",
  PLEASE_ENTER_NEW_PASSWORD: "Please enter new password ",
  PLEASE_ENTER_CONFIRM_PASSWORD: "Please enter confirm password",
  CONFIRM_NEW_PASSWORD: "Confirm new password",
  CONFIRM_PASSWORD_VS_NEW_PASSWORD: "Confirm password not match new password",
  CHANGE_PASSWORD_SUCCESS: "Change password was successful",
  PORTFOLIO: "Portfolio",
  SWAP: "Swap",
  STAKING: "Staking",
  ACCOUNT: "Account",
  LOGOUT: "Logout",
  FORGOT_PASSWORD: "Forgot password",
  HOMEPAGE: "Homepage",
  BACK: "Back",
  PLEASE_ENTER_OTP: "Please enter your OTP from email",
  TOTAL: "Total",
  COIN: "Coin",
  PRICE: "Price",
  BALANCE: "Balance",
  VALUE: "Value",
  DEPOSIT: "Deposit",
  WITHDRAW: "Withdraw",
  ESTIMATED_BALANCE: "Estimated Balance",
  EXCHANGE: "Exchange",
  AMOUNT: "Amount",
  PLEASE_ENTER_AMOUNT: "Please enter amount",
  MIN: "Min",
  HALF: "Half",
  ALL: "All",
  RECEIVE: "Receive",
  SWAP_HISTORY: "Swap history",
  TIME: "Time",
  BASE_AMOUNT: "Base amount",
  QUOTE_AMOUNT: "Quote amount",
  DURATION: "Duration",
  DAYS: "days",
  MINIMUM_AMOUNT: "Minimum amount",
  HISTORY: "History",
  REDEEMS: "Redeems",
  TYPE: "Type",
  STATUS: "Status",
  STAKE: "Stake",
  LOCK: "Lock",
  MINIMUM_LOCKED_AMOUNT: "Minimum locked Amount",
  MAXIMUM_LOCKED_AMOUNT: "Maximum locked Amount",
  REDEMPTION_PERIOD: "Redemption period",
  LOCK_AMOUNT: "Lock amount",
  CONFIRM_PURCHASE: "Confirm purchase",
  CANCEL: "Cancel",
  GO_TO_DEPOSIT: "Go to deposit",
  NETWORK: "Network",
  ADDRESS: "Address",
  GO_TO_WITHDRAW: "Go to withdraw",
  MINIMUM_WITHDRAW_AMOUNT: "Minimum withdraw amount",
  TRANSACTION_FEE: "Transaction fee",
  YOU_WILL_GET: "You will get",
  PROFILE: "Profile",
  SETTING: "Setting",
  DEVICE_LIST: "Device list",
  LOGIN_ACTIVITY: "Login activity",
  TURN_OFF: "Turn off",
  TURN_ON: "Turn on",
  DISABLE: "Disable",
  IDENTITY_VERIFICATION: "Identity verification",
  ENABLE: "Enable",
  LOGIN_PASSWORD: "Login password",
  CHANGE: "Change",
  INVITED_FRIEND: "Invite Friends, Earn Crypto",
  INVITED_TIP:
    "Use your unique link to invite your friends over message or email. Your default invitation code can also be shared in real life or as a screenshot",
  COPIED: "Copied",
  REFERRAL_NUMBER: "Referral number",
  F1_REFERRAL_NUMBER: "F1 referral number",
  COMMISSION: "Commission",
  YOUR_REFERRAL_ACCOUNT: "Your F1 members",
  YOUR_COMMISSION_HISTORY: "Your commission history",
  AIRDROP: "Airdrop",
  AFFILIATE: "Affiliate",
  PHOTOS: "Photos",
  FIRST_NAME: "First name",
  LAST_NAME: "Last name",
  MIDDLE_NAME: "Middle name",
  DOB: "Date of birth",
  COUNTRY: "Country",
  SELECT_COUNTRY: "Select country",
  CITY: "City",
  POSTAL_CODE: "Postal code",
  NEXT: "Next",
  PREVIOUS: "Previous",
  UPLOAD: "Upload",
  FRONT_PAGE: "Front of card ID/Passport/Driver license",
  BACK_PAGE: "Back of card ID/Passport/Driver license",
  UPLOAD_SELFIE_PHOTO: "Upload selfie photo",
  FULL_NAME: "Full name",
  VERIFY: "Verify",
  LANGUAGE: "Language",
  DRIVER_LICENSE: "Driver license",
  PASSPORT: "Passport",
  USER_NOT_VERIFIED: "Please verify user to make the transaction",
  PLEASE_ENABLE_GA:
    "Please enable Google authenticator to make the transaction",
  REFERRAL_PARENT: "Referral",
  REFERRAL_ID: "Referral ID",
  ID_TYPE: "ID/Passport/Driver license",
  ID_CODE: "ID Code",
  ID_CARD: "ID Card",
  IMAGE_CORRECT: "Image is not correct",
  IMAGE_LARGE: "Image too large",
  PRIVACY_AND_POLICY: "Privacy and Policy",
  AGREE_TOS: "I have read and agree to the",
  SPWALLET_TOS: "SPWallet's Terms",
  PLEASER_CHECK_TOS: "Please read and agree to the SPWallet Term",
  WISH_YOU_LUCK: "Wish you luck next time",
  CONGRATULATION: "Congratulation",
  PRIZE_VALUE: "Prize value",
  LAST_LOGIN: "Last login time",
  CONTINUE: "Continue",
  INFO_BASIC: "INFO BASIC",
  VERIFY_REASON: "Why verify your identity",
  VERIFY_REASON_1: "To unlock the withdrawal function.",
  VERIFY_REASON_2: "To unlock the internal money transfer function.",
  VERIFY_REASON_3: "To ensure the security of your account.",
  VERIFY_NOTE:
    "This information is used for identity verification only, and will be kept secure by SPWallet",
  SELFIE: "Selfie photo",
  ARE_YOU_SURE: "Are you sure ?",
  GIFT: "Gift",
  NO_RECORDS_FOUND: "No records found",
  MEMBER: "Member",
  EMAIL_NOT_FOUND: "Email not found",
  MINIMUM_SWAP_AMOUNT: "Swap minimum amount",
  EST_APY: "Est. APY",
  GA_CODE: "Google authenticator code",
  GA: "Google authenticator",
  SECURITY: "Security",
  PLEASE_ENTER_YOUR_FULL_INFORMATION: "Please enter your full information",
  COIN_TOKEN: "Coin/Token",
  CHANGE_24H: "24h Change",
  VOLUME: "Volume",
  NOTE_UPLOAD_PHOTO:
    "We are only accept .png, .jpg, .jpeg, or .HEIC and not exceeding 5Mb",
  UPLOAD_PHOTO: "Upload photo",
  BLOCKED: "Blocked",
  LINK: "Link",
  UPLOAD_PHOTO_WITH: "Upload photo with",
  UPLOAD_PHOTO_NOTE: "Attach memo on your card.",
  UPLOAD_PHOTO_NOTE_1:
    "Write a memo including with the follow phrases that KYC Request, email, a Date of request, KYC for SPWallet, and sign.",
  UPLOAD_PHOTO_NOTE_2:
    "If it is not a valid or can't attack a memo, it can be reject.",
  KYC_REQUEST: "KYC request",
  SIGN: "Sign",
  WELL_DONE: "Well done!",
  I_AGREE_TO_THE: "I agree to the",
  AND: "and",
  AGREE_AFTER: "Purpose of Collecting Personal Information",
  KYC_CONFIRM_NOTE:
    "Purpose of Collecting Personal Information: identification, increase Verification level",
  KYC_CONFIRM_NOTE_1:
    "Required personal information: name, date of birth, Nationality, ID card | passport | driver license photo",
  KYC_CONFIRM_NOTE_2:
    "Retention Period: The time set by the Act when the member is withdrawn or until the contact is terminated",
  KYC_CONFIRM_NOTE_3:
    "KYC process will be done manually, so this process may takes time to be completed. 24h is the duration for this process in general, but if the amount of users increase rapidly, it's may takes more time. We really appreciate for your waiting. Please follow your Email to have more result information.",
  VERIFIED: "Verified",
  OPEN: "Open",
  CLOSED: "Closed",
  DEPOSIT_HISTORY: "Deposit history",
  WITHDRAW_HISTORY: "Withdraw history",
  SWAP_CONFIRM: "Swap confirm",
  RATIO: "Ratio",
  SWAP_FEE: "Swap fee",
  DEPOSIT_TIP_0_BEGIN: "Only send",
  DEPOSIT_TIP_0_END: "to this deposit address.",
  DEPOSIT_TIP_1:
    "Sending coins or tokens other than the one you choose to this address may risk losing money.",
  DEPOSIT_TIP_2:
    "If you have made a deposit, please pay attention to the text messages, website notifications and the email we sent you.",
  DEPOSIT_TIP_3: "Coins will be deposited after confirmed by the network.",
  DEPOSIT_TIP_4:
    "We are not responsible for assets for wrong wallet address or network platform when you mistakenly transfer.",
  WITHDRAW_TIP_0:
    "Do not withdraw directly to an ICO address or crowdfunding, because your account will not be credited with tokens from this activity.",
  WITHDRAW_TIP_1:
    "When transferring to a user in the SPwallet platform, the service will be processed at no cost.",
  WITHDRAW_TIP_2:
    "We waived liability for wrong transfer of wallet addresses or different blockchain platforms.  SPwallet does not yet support cross-chain.",
  SWAP_TIP_0:
    "The final price and amount is determined by the amount of tokens available in the pool at the time of your swap.",
  SWAP_TIP_1:
    "Swap transactions are only executed when the price slippage is within the allowed range.",
  SWAP_TIP_2:
    "Adding or withdrawing coins will not be restricted, but will depend on the number of tokens in the pool.",
  SWAP_TIP_3:
    "Swap is just a form of conversion between different asset classes but is still based on the market price and the amount of tokens in the pool.  SPwallet disclaims liability when the token slips price during swaps.",
  CHOOSE_NETWORK: "Choose network",
  WITHDRAW_CONFIRM: "Withdraw confirm",
  WALLET_ADDRESS: "Wallet address",
  WITHDRAW_FEE: "Withdraw fee",
  STAKING_CONFIRM: "Staking confirm",
  AGREE_TO: "I have read and agree to the",
  STAKING_POLICY: "Staking policy",
  PLEASER_CHECK_STAKING_POLICY:
    "Please read and agree to the SPWallet Term and Staking Policy",
  STAKING_POLICY_0:
    "Annual rate of return based on estimated historical market sales. Actual revenue depends on actual receipt.",
  STAKING_POLICY_1: "The minimum staking amount is 100 mUSD.",
  STAKING_POLICY_2:
    "During staking, the staking asset cannot be traded on the liquid market, and cannot be unlocked directly (Except for the Flexible Staking Package).",
  STAKING_POLICY_3:
    "When the length of your staking period meets the staking interval requirement, the staking package will be canceled automatically, the revenue and the staking principal amount will be transferred to your storage wallet.",
  STAKING_POLICY_4:
    "The staking amount will be refunded after 1-2 working days after your chosen deadline, the staking reward with the mined POL is paid interest on a daily basis",
  STAKING_POLICY_5:
    "Staking policy is not changed, but SPwallet is not responsible for property slippage in the market while customers are staking.",
  STAKING_POLICY_6:
    "Annual interest rate (APY), only to help members better understand the value of the profit.",
  STAKING_TIME: "Staking time",
  STAKING_START_DATE: "Staking start date",
  STAKING_END_DATE: "Staking end date",
  ESTIMATED_APY: "Estimated APY",
  START_TIME: "Start time",
  END_TIME: "End time",
  LOCKED: "Locked",
  YOUR_TOTAL_AFFILIATE: "Total affiliate",
  YOUR_TOTAL_AFFILIATE_F1: "Total affiliate 1",
  YOUR_AFFILIATE_F1: "Your affiliate 1",
  MAXIMUM: "Maximum",
  MINIMUM: "Minimum",
  USE_MAX_AMOUNT: "Use max amount",
  STAKING_TIP_0: "Make cryptocurrency anytime with SPExchange Staking",
  STAKING_TIP_1:
    "Generate passive income with SPExchange Staking by keeping cryptocurrencies in your wallet. Select the crypto you want to keep from SPExchange's cryptocurrency (mUSD). Take your crypto investment potential to new level and enjoy low fees with SPExchange.",
  DATE_REQUIRE: "Date require",
  KYC_FOR: "KYC for SPWallet",
  DOB_INVALID: "Invalid birthday",
  WITHDRAW_AMOUNT_TOO_SMALL: "Withdraw amount is too low",
  DISABLE_ACCOUNT: "Disable account",
  CLOSE: "Close",
  SCAN_QR_CODE: "Scan the QR code of the wallet address",
  DISABLE_ACCOUNT_NOTE:
    "This action will disable your SPWallet account. Please contact to SPWallet support to reactivate your account.",
  PLEASE_ENTER_PASSWORD_MORE_THAN:
    "Please enter a password more than 8 characters",
  PLEASE_ENTER_PASSWORD_LESS_THAN:
    "Please enter a new password less than 16 characters",
  PLEASE_ENTER_NEW_PASSWORD_MORE_THAN:
    "Please enter a new password more than 8 characters",
  PLEASE_ENTER_NEW_PASSWORD_LESS_THAN:
    "Please enter a new password less than 16 characters",
  FAILED: "Failed",
  SEARCH_COIN_TOKEN: "Search Coin/Token",
  SP_ACCOUNT_TITLE: "Support SPexchange.io account login.",
  SP_ACCOUNT:
    "SPwallet.org support log in with your SPexchange.io account. With your authorization, you can log in both SPexchange.io exchange and your SPwallet.org with one account and enjoy a one-stop solution from storage, transfer to exchange.",
  EASY_BUY: "Easy buy",
  YOU_HAVE: "You have",
  BUY: "Buy",
  AVAILABLE_BALANCE: "Available balance",
  COMPLETED: "Completed",
  SESSION_SUPPLY: "Session supply",
  SOLD: "Sold",
  BY: "by",
  START_DATE: "Start date",
  END_DATE: "End date",
  WEBSITE: "Website",
  WHITEPAPER: "Whitepaper",
  BUY_TOKEN_SUCCESS: "Buy token successful",
  FEE: "Fee",
  BUY_TOKEN_CONFIRM: "Buy token confirm",
  PROJECT_INTRODUCTION: "Project introduction",
  EASY_BUY_SLOGAN: `Easy exchange with "Easy-Buy"`,
  EASY_BUY_SUB_SLOGAN:
    "Support instant exchange of popular cryptocurrencies around the world",
  FAST: "Fast",
  EFFICIENT: "Efficient",
  ZERO_CHANGES: "0 Changes",
  USER_OVERVIEW_MEMBER: "Members of the SPwallet",
  USER_OVERVIEW_ASSETS: "Variety Digital Assets/Crypto management",
  USER_OVERVIEW_SOCIAL: "Social media followers",
  SPEXCHANGE_KYC_NOTE:
    "using this KYC document for SPWallet and also SPExchange.",
  YOU_PAY: "You pay",
  OPTION: "Option",
  MEMO_NOTE:
    "Please confirm if the receiving address requires a MEMO/ Tag. If it is not filled or filled incorrectly, the asset will be lost. Other exchanges or wallets also call Tag names Memo, digital ID, label, and notes.",
  TRANSACTION_HASH: "Transaction hash",
  EXCHANGE_DETAIL: "Exchange detail",
  TO: "to",
  FROM: "from",
  SEND: "Send",
  HOURS_AGO: "hours ago",
  MINUTES_AGO: "minutes ago",
  TRANSACTION_HISTORY: "Transaction history",
  PAY: "Pay",
  GET: "Get",
  STAKING_REFERRAL: "Commission",
  PRESALE_PRODUCT_OUT_OF_SUPPLY:
    "The purchase amount has exceeded the supply. Please try again.",
  PRESALE_PRODUCT_NOT_OPEN: "Coin/Token has not been opened for sale.",
  PRESALE_PRODUCT_ENDED: "Coin/Token has timed out to purchase",
  EASY_BUY_HISTORY: "Easy buy history",
  SELECT_COIN: "Select Coin/Token",
  COMING_SOON: "Upcoming",
  SUMMARY: "Summary",
  LOCK_AMOUNT_LIMITATION: "Lock amount limitation",
  VALUE_DATE: "Value date",
  INTEREST_PERIOD: "Interest period",
  INTEREST_END_DATE: "Interest end date",
  REDEMPTION_DATE: "Redemption date",
  ESTIMATE_INTEREST: "Estimate interest",
  INSUFFICIENT_FUNDS: "Unavailable balance",
  AMOUNT_TOO_LARGE: "Your amount of staking is too large",
  AMOUNT_TOO_LOW: "Your amount of staking is too low",
  STAKING_PRODUCT_NOT_OPEN: "Staking product is not open",
  STAKING_PRODUCT_ENDED: "Staking product is ended",
  PLEASE_TRY_AGAIN: "Please try again",
  STAKING_PRODUCT_OUT_OF_SUPPLY: "Your staking amount out of remain",
  REDEEM: "Redeem",
  STAKING_SUCCESS: "Staking success",
  PROFIT: "Profit",
  YEAR: "year",
  MONTH: "month",
  CONFIRM_STAKING: "Confirming for staking",
  PARTNER: "Partner",
  DISPLAY_NAME: "Display name",
  UPDATE: "Update",
  ITEM_ALREADY_EXIST: "Display name was existed",
  PLEASE_ENTER_YOUR_DISPLAY_NAME: "Please enter your display name",
  DISPLAY_NAME_WAS_UPDATED: "Display name was updated",
  MARCH_PROMOTION: "Promotion March",
  APRIL_REWARD: "April reward",
  ORDER_DETAIL: "Order detail",
  RECEIVER_ADDRESS: "Receiver's address",
  PHONE: "Phone",
  CHECKOUT_METHOD: "Checkout method",
  CHECKOUT_BY_LUS: "Payment by LuS on SPWallet (Free of charge).",
  PAYMENT_SUCCESS: "Payment success",
  DISCOUNT: "Discount",
  PRODUCT: "Product",
  SUBTOTAL: "Subtotal",
  BACK_TO_ORDER: "Back to my orders",
  MY_ORDERS: "My orders",
  PRODUCTS: "Products",
  SAVE: "Save",
  AVAILABLE: "Available",
  BUY_NOW: "Buy now",
  PRODUCT_DETAIL: "Product detail",
  LEARN_MORE: "learn more",
  SHOPPING_PRICE_NOTE:
    "Price is inclusive of duties and taxes for all US bound orders",
  SHOPPING_NOTE_1: "Verified & authenticated by our experts",
  SHOPPING_NOTE_2: "Delivery time : 2-4 business days",
  SHOPPING_NOTE_3: "30 days easy return",
  ORDER: "Order",
  SHIPPING_INFORMATION: "Shipping information",
  CHOSE_ADDRESS: "Chose address",
  ADD_NEW_ADDRESS: "Add new address",
  CHECKOUT: "Checkout",
  CONFIRM_INFORMATION: "Confirm information",
  SWAP_FROM: "From",
  CREATE_ORDER_DATE: "Created order date",
  BACK_TO_SPWALLET: "Back to SPwallet",
  NAME: "Name",
  NEW: "New",
  SHIPPING: "Shipping",
  INPROGRESS: "In progress",
  CANCEL_ORDER: "Cancel order",
  ARE_YOU_SURE_CANCEL_ORDER: "Are you sure you want cancel this order ?",
  ORDER_ID: "Order ID",
  SELECT_A_TOKEN: "Select a token",
  CANCEL_ORDER_SUCCESS: "Cancel order success",
  EDIT_PROFILE: "Edit profile",
  EDIT_NICKNAME_NOTE:
    "We accept names from 3 to 32 characters in length, without special characters and does not begin with a number.",
  PLEASE_TELL_US_YOUR_NICKNAME: "Please tell us your nickname",
  YOUR_NICKNAME: "Your nickname",
  PLEASE_CHECK_NICKNAME_AGAIN: "Please check your nickname again.",
  PLEASE_SELECT_COUNTRY: "Please select your country",
  UPDATE_COUNTRY_SUCCESS: "Update country success",
  UPDATE_NICKNAME_SUCCESS: "Update nickname success",
  CONFIRM_CANCEL_ORDER_ADDRESS: "Confirming for cancel order address",
  SHOPPING_ORDER_OUT_OF_STOCK: "Your order products out of remain",
  SOLD_OUT: "Sold out",
  RANKING_BONUS: "Ranking bonus",
  UNBLOCK: "Unlock",
  VERIFICATION: "Verification",
  CODE_WILL_BE_SENT_TO: "Code will be sent to",
  EMAIL_VERIFICATION_CODE: "Email verification code",
  ENTER_GA_CODE: "Enter Google verification code",
  SUBMIT: "Submit",
  SEND_CODE: "Send code",
  SP_INTERNAL: "Internal transfer",
  OR: "or",
  INTERNAL_WITHDRAW: "Internal withdraw",
  EXTERNAL_WITHDRAW: "External withdraw",
  AFFILIATE_COMMISSION: "Affiliate commission",
  DAILY_RELEASE: "Daily release",
  REWARD: "Pool reward",
  BA_REWARD: "Pool promotion reward",
  HOLDER_COMMISSION: "Pool holder",
  RELEASE: "Release",
  WITHDRAW_HOLDER_COMMISSION: "Profit recovery",
  WITHDRAW_REWARD: "Profit recovery",
};
