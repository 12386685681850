import { Grid, Link, Typography } from "@material-ui/core";
import React from "react";
import { Container } from "semantic-ui-react";
import { S3_URL } from "../../settings";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Footer() {
  return (
    <>
      <LazyLoadImage
        src={`${S3_URL}/spwallet/reward-banner.png`}
        alt="reward"
        width="100%"
        style={{
          margin: "-6px 0",
          maxHeight: 400,
          objectFit: "cover",
        }}
        effect="blur"
      />
      <div className="footer">
        <Container>
          <Grid container justify="space-between">
            <Grid container item xs={12} md={3} justify="center">
              <div>
                <img
                  src="https://s3.spexchange.io/lus-landing-page/footer-logo.png"
                  alt="luxury-outlet-street"
                  className="logo"
                />
                <div className="social-media">
                  <Link href="#">t.</Link>
                  <Link href="#">y.</Link>
                  <Link href="#">d.</Link>
                  <Link href="#">c</Link>
                </div>
              </div>
            </Grid>
            <Grid container item xs={12} md={3} justify="center">
              <div>
                <Typography className="hide-mobile">Support</Typography>
                <Link href="#">
                  <p>support@lus.shopping</p>
                </Link>
                <Link href="#">
                  <p>bussiness@lus.shopping</p>
                </Link>
              </div>
            </Grid>
            <Grid container item xs={6} md={3} justify="center">
              <div>
                <Typography>Service</Typography>
                <Link href="#">
                  <p>Shopping online</p>
                </Link>
                <Link href="#">
                  <p>Stake mining</p>
                </Link>
                <Link href="#">
                  <p>KOLs partnership</p>
                </Link>
              </div>
            </Grid>
            <Grid container item xs={6} md={3} justify="center">
              <div>
                <Typography>Legal</Typography>
                <Link href="#">
                  <p>Term & Conditions</p>
                </Link>
                <Link href="#">
                  <p>Privacy policy</p>
                </Link>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default Footer;
