import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Button,
  Container,
  Dimmer,
  Form,
  Header,
  Icon,
  Loader,
  Segment,
} from "semantic-ui-react";
import { SET_MODAL_CONTENT } from "../../redux/settingReducer";
import { _delete } from "../../utils/api";

function DisableAccount() {
  const { token } = useParams();
  const dispatch = useDispatch();
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const [loading, setLoading] = useState(false);

  const _handleDisableAccount = () => {
    setLoading(true);
    _delete(
      `/user-service/user/disable/${token}`,
      {},
      () => {
        dispatch({
          type: SET_MODAL_CONTENT,
          payload: {
            content: "DISABLE_ACCOUNT_SUCCESS",
            callback: () => {
              window.location.replace("/");
            },
          },
        });
      },
      (error) => {
        console.error(error);
        dispatch({
          type: SET_MODAL_CONTENT,
          payload: {
            content: error.code,
            callback: () => {
              window.location.replace("/");
            },
          },
        });
      }
    );
  };

  return (
    <>
      <LazyLoad>
        <Container className="custom-form">
          <Segment style={{ backgroundColor: "var(--light-blue)" }}>
            <Icon
              name="warning"
              size="huge"
              color="red"
              style={{ boxShadow: "0 0 0 0.1em #db2828 inset" }}
              circular
            />
            <Form error inverted onSubmit={_handleDisableAccount}>
              <Header as="h5">{library.DISABLE_ACCOUNT_NOTE}</Header>
              <Button fluid className="spwallet-button">
                {library.CONFIRM}
              </Button>
            </Form>
          </Segment>
        </Container>
      </LazyLoad>
      <Dimmer active={loading}>
        <Loader />
      </Dimmer>
    </>
  );
}

export default DisableAccount;
