import React, { useEffect } from "react";
import { Container } from "semantic-ui-react";
import "../../components/shopping/Shopping.scss";
import { S3_URL } from "../../settings";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Products from "../../components/shopping/Products";
import Footer from "../../components/shopping/Footer";
import LusHeader from "../../components/lus/SubComponent/LusHeader";
import { AddTitle } from "../../actions";
import { LazyLoadImage } from "react-lazy-load-image-component";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

function Shopping() {
  useEffect(() => {
    AddTitle("LuS Shopping");
    document.getElementsByClassName("custom-menu")[0].style.display = "none";
    document.getElementsByClassName("App")[0].style.marginTop = 0;
    return () => {
      document.getElementsByClassName("custom-menu")[0].style.display = "block";
      document.getElementsByClassName("App")[0].style.marginTop = "58px";
    };
  }, []);

  return (
    <div id="shopping">
      <LusHeader />
      <div className="banner">
        <Container>
          <Carousel
            responsive={responsive}
            infinite={true}
            showDots={true}
            autoPlay={true}
          >
            <div className="item">
              <LazyLoadImage
                src={`${S3_URL}/spwallet/shopping-lus-banner-2.png`}
                alt="banner"
                effect="blur"
              />
            </div>
            <div className="item">
              <LazyLoadImage
                src={`${S3_URL}/spwallet/shopping-lus-banner-2.png`}
                alt="banner"
                effect="blur"
              />
            </div>
            <div className="item">
              <LazyLoadImage
                src={`${S3_URL}/spwallet/shopping-lus-banner-2.png`}
                alt="banner"
                effect="blur"
              />
            </div>
            <div className="item">
              <LazyLoadImage
                src={`${S3_URL}/spwallet/shopping-lus-banner-2.png`}
                alt="banner"
                effect="blur"
              />
            </div>
          </Carousel>
        </Container>
      </div>
      <Products />
      <Footer />
    </div>
  );
}

export default Shopping;
