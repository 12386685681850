import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, List, Modal, Segment, Transition } from "semantic-ui-react";
import { _getStakingProduct } from "../../redux/walletReducer";
import StakingItem from "./StakingItem";
import "./Staking.scss";

function StakingList() {
  const { wallet, setting } = useSelector((state) => state);
  const { stakingProduct } = wallet;
  const dispatch = useDispatch();
  const [showStakingPolicy, setShowStakingPolicy] = useState(false);

  useEffect(() => dispatch(_getStakingProduct()), [dispatch]);

  const _data = [];
  if (stakingProduct) {
    stakingProduct.sort((a, b) => {
      return a.duration - b.duration;
    });
    stakingProduct.forEach((element) => {
      const _index = _data.findIndex(
        (item) => item.base === element.base && item.quote === element.quote
      );
      if (_index < 0) {
        _data.push({
          base: element.base,
          quote: element.quote,
          status: element.status,
          list: [element],
          lusRewardLevels: element.lusRewardLevels,
        });
      } else {
        _data[_index].list.push(element);
      }
    });
  }

  const { library } = setting;
  const {
    STAKING_POLICY_0,
    STAKING_POLICY_1,
    STAKING_POLICY_2,
    STAKING_POLICY_3,
    STAKING_POLICY_4,
    STAKING_POLICY_5,
    STAKING_POLICY_6,
  } = library;

  const tips = [
    STAKING_POLICY_0,
    STAKING_POLICY_1,
    STAKING_POLICY_2,
    STAKING_POLICY_3,
    STAKING_POLICY_4,
    STAKING_POLICY_5,
    STAKING_POLICY_6,
  ];

  return (
    stakingProduct && (
      <Segment>
        <Grid className="easy-buy-list" id="staking-list" stretched>
          {_data.map((item, index) => (
            <StakingItem
              item={item}
              key={index}
              showStakingPolicy={() => setShowStakingPolicy(true)}
            />
          ))}
        </Grid>
        <Transition
          visible={showStakingPolicy}
          animation="fade up"
          duration={500}
        >
          <Modal
            open={showStakingPolicy}
            size="small"
            onClose={() => setShowStakingPolicy(false)}
            closeIcon
            basic
          >
            <Modal.Header>{library.STAKING_POLICY}</Modal.Header>
            <Modal.Content>
              <List.Item as="ul">
                {tips.map((item, index) => (
                  <List.Item key={index} as="li">
                    {item}
                  </List.Item>
                ))}
              </List.Item>
            </Modal.Content>
          </Modal>
        </Transition>
      </Segment>
    )
  );
}

export default StakingList;
