import React from "react";
import { Container, Header, Segment } from "semantic-ui-react";

function TOS() {
  return (
    <Container textAlign="justified">
      <Segment>
        <Header as="h1">Terms Of Service</Header>
        <div>
          Welcome! SPExchange (“we,” “our,” or “us”) provides its services
          through its website located at spwallet.org and related mobile
          applications and products (collectively the “Services” or “SPWallet”).
          Before using our Services, please read the Terms of Service (the
          “Terms” or the “Agreement”) carefully, along with any other policies
          or notices on SPWallet’s website or mobile applications, and our
          [Privacy Policy](/privacy-policy). Together, these materials contain
          terms, rules, and guidelines related to your use of SPWallet.
        </div>
        <Header>OVERVIEW</Header>
        <div>
          We know that Terms can be long and confusing, so we will do our best
          to keep things as clear and concise as possible. This section provides
          a brief summary of the highlights of this Agreement. Please note that
          when you accept this Agreement, you are accepting all of the terms and
          conditions and not just this section. SPWallet provides self-hosted
          wallet services for Tron-based blockchains. By creating or importing a
          wallet, downloading or running our mobile application, or visiting our
          website, you are agreeing to our Terms, so please read carefully.
          These terms outline approved uses of SPWallet, various licenses that
          we grant to you, and licenses that you grant us. If you have any
          questions or comments related to this Agreement, please send us a
          message on our website at support@spwallet.org or contact us through
          Telegram, or Twitter. If you do not agree to this Agreement or any
          modifications to this Agreement, you should not use our Services.
        </div>
        <Header>HOW YOU ACCEPT THIS POLICY</Header>
        <div>
          By creating or importing a locally hosted wallet (a “Wallet”), or
          visiting our website, you acknowledge that you have read, understood,
          and agreed to these Terms, as well as the accompanying [Privacy
          Policy](/privacy-policy) We reserve the right to change these Terms at
          any time. Any such changes with respect to your use of SPWallet will
          take effect immediately when posted on our website or on our mobile
          application. Your continued use of SPWallet following any such change
          will signify your acceptance to be bound by the then current Terms.
          Please check the effective date above to determine if there have been
          any changes since you have last reviewed these Terms. If you do not
          agree to this Agreement or any modifications to this Agreement, you
          should not use SPWallet. Failure or delay by SPWallet in enforcing or
          partially enforcing any provision of these Terms shall not be
          construed as a waiver of any rights.
        </div>
        <Header>ELIGIBILITY</Header>
        <div>
          To be eligible to use SPWallet, you must be at least eighteen (18)
          years old and be able to form legally binding contracts. If you are
          using our Services on behalf of a legal entity, you further represent
          and warrant that: (a) the legal entity is duly organized and validly
          existing under the applicable laws of the jurisdiction of its
          organization; and (b) you are duly authorized by such legal entity to
          act on its behalf. You can only use our Services if permitted under
          the laws of your jurisdiction. Please make sure that these Terms are
          in compliance with all laws, rules, and regulations that apply to you.
          By using SPWallet, you represent and warrant that you meet all
          eligibility requirements that we outline in these Terms. We may still
          refuse to let certain people access or use SPWallet, however, and we
          reserve the right to change our eligibility criteria at any time.
        </div>
        <Header>THE BLOCKCHAIN SERVICES</Header>
        <div>
          SPWallet is software that (a) generates Wallet addresses and encrypted
          private keys that you may use to send and receive Tron and related
          cryptographically secured tokens (the “Virtual Currency”); (b) allows
          users to browse third party decentralized applications (“DApps” or
          “DApp”) through the mobile application’s DApp browser; and (c)
          facilitates the submission of Virtual Currency transaction data to
          Tron-based blockchains (the “Tron Networks”) without requiring you to
          download or install the associated Tron-based software to your local
          device. Wallet Address, Private Key, and Backup Capabilities. The
          private key is connected to the Wallet address and, together, they can
          be used to authorize the transfer of Virtual Currency to and from that
          Wallet address. You are solely responsible for maintaining the
          security of your private key and any password (backup) associated with
          your Wallet. You must keep your Wallet address, and private key access
          information secure. Failure to do so may result in the loss of control
          of Virtual Currency associated with the Wallet. SPWallet Cannot Assist
          With Password Retrieval. SPWallet stores your Wallet address but does
          not receive or store your Wallet password, encrypted private key,
          unencrypted private key, or password (backup) associated with your
          Wallet. We cannot, therefore, assist you with Wallet password
          retrieval. We cannot generate a new password for your Wallet if you
          fail to remember your original password. If you have not safely stored
          a backup of any Wallet address and private key pairs maintained in
          your Wallet, you accept and acknowledge that any Virtual Currency you
          have associated with such a Wallet address will become inaccessible if
          you do not have your Wallet password. Virtual Currency Transactions.
          In order to be completed, all proposed Virtual Currency transactions
          must be confirmed and recorded in the Virtual Currency’s associated
          public blockchain. Such networks are decentralized, peer-to-peer
          networks supported by independent third parties, which we do not own,
          control, or operate. We have no control over the Tron Networks and,
          therefore, cannot and do not ensure that any transaction details that
          you submit via our Services will be confirmed and processed. By using
          SPWallet, you acknowledge and agree that the transaction details you
          submit may not be completed, or may be substantially delayed, by the
          Tron Networks. SPWallet Does Not Store or Transmit Virtual Currency.
          We do not store, send, or receive Virtual Currency. Any transfer that
          occurs in any Virtual Currency occurs on the Tron-based blockchains
          and not on a network owned by us. We therefore do not guarantee that
          SPWallet can affect the transfer of title or right in any Virtual
          Currency. Accuracy of Information Provided by User. You represent and
          warrant that any information you provide via the Services is accurate
          and complete. You accept and acknowledge that we are not responsible
          for any errors or omissions that you make in connection with any
          Virtual Currency transaction initiated via the Services. We strongly
          encourage you to review your transaction details carefully before
          attempting to transfer a Virtual Currency.
        </div>
        <Header>WALLET REGISTRATION AND ACCOUNT INFORMATION</Header>
        <div>
          You must create a Wallet in order to use SPWallet. When you create a
          Wallet, you will be assigned 2FA key. You will be prompted to download
          and save 2FA Key - your private key encrypted with a password. You
          will be responsible for maintaining the confidentiality of your
          private key and keystore, and will be fully responsible for any and
          all activities that occur under your account. You agree to immediately
          notify us of any unauthorized use of your password, account, or any
          other breach of security. We will not be liable for any loss or damage
          arising from your failure to comply with this paragraph. When you
          create a Wallet, you are strongly advised to take precautions in order
          to avoid loss of access to and/or control over your Wallet. Suggested
          measures include, but are not limited to, the following: (a) creating
          a strong password that you do not use for any other website or online
          service; (b) safeguard your private 2FA key, all users, especially
          users who have more than $100,000 USD in assets, are encouraged to do
          even if they are utilizing the Services’ backup functionality; (c)
          maintaining the security of your Wallet by protecting the private key
          and password 2FA with your Wallet by, for example, limiting access to
          your computer and your Wallet; and (d) promptly notifying us if you
          discover or otherwise suspect any security breaches related to your
          Wallet. Push Notifications. You may agree to receive push
          notifications from SPWallet that will alert you when Tron-based
          blockchains are congested and when transactions involving your Wallet
          have been completed. Push notifications can be enabled to display
          information about Token Launches. If you would like to receive push
          notifications, you must opt in to the service by accessing “Settings”
          and enabling “Push Notifications”. Payment and Fees.SPWallet does not
          currently charge any fees for any of its Services. However, we reserve
          the right to do so in the future and, in such case, any applicable
          fees will be displayed prior to you using any service to which a fee
          applies. Transactions. Once transaction details have been submitted
          via the Services, we cannot assist you to cancel or otherwise modify
          your transaction.SPWallet has no control over any Ethereum-based
          blockchain and does not have the ability to facilitate any
          cancellation or modification requests. You must ensure that you have
          an adequate balance in your Wallet and/or gas to complete transactions
          before initiating a transaction. You acknowledge and agree that we
          will not be liable for any failed transactions due to insufficient
          funds or gas associated with yourSPWallet account. Taxes. It is your
          responsibility to determine what, if any, taxes apply to the
          transactions that you have submitted transaction details for via the
          Services, and it is your responsibility to report and remit the
          correct tax to the appropriate tax authority. You agree that we are
          not responsible for determining whether taxes apply to your Tron-based
          transactions or for collecting, reporting, withholding, or remitting
          any taxes arising from any Virtual Currency-related transactions.
        </div>
        <Header>THIRD PARTY SERVICES AND CONTENT</Header>
        <div>
          In no event shall a description or reference to a third party’s
          product or service (including, but not limited to, providing a
          description or reference via hyperlink) be construed as an endorsement
          or promotion of such third party products or services by us. We retain
          the exclusive right to add to, modify, or cancel the availability of
          any Third Party Service. You may agree to receive push notifications
          from Third Party Content providers. In order to receive push
          notifications, you must opt in to the service. Push notifications will
          not be automatically enabled on your device for Third Party Content.
          We do not control, endorse, or adopt any Third Party Content shared
          through push notifications, and will have no responsibility for Third
          Party Content including, but not limited to, token availability and/or
          sales. If, to the extent permitted by SPWallet, you grant express
          permission to a third party to access or connect to your SPWallet
          account, either through the third party’s product or service or
          through SPWallet, you acknowledge that granting permission to a third
          party to take specific actions on your behalf does not relieve you of
          any of your responsibilities under this Agreement. You are fully
          responsible for all acts or omissions of any third party with access
          to your SPWallet account.
        </div>
        <Header>INTELLECTUAL PROPERTY</Header>
        <div>
          All data on the spwallet.org website and SPWallet app, including
          price, data, and other information as well as existing software
          programs, are copyrighted and trademarked and other forms of ownership
          and ownership of SPExchange. The redistribution of the above data
          without the specific written consent of SPExchange is illegal. Users
          are only allowed to use this information for their own personal
          purposes.
        </div>
        <Header>YOUR USE OF SPWALLET</Header>
        <div>
          As a user of the Services, we grant you a limited, personal,
          non-commercial, non-exclusive, non-transferable, and revocable license
          to use SPWallet. When using our Services, we ask that you follow some
          basic rules: Don’t Use Our Services to Break the Law. You agree that
          you will not violate any laws when using our Services. This includes
          any local, provincial, state, federal, national, or international laws
          that may apply to you. You agree that you will not use our Services to
          pay for, support, or otherwise engage in any illegal activities
          including, but not limited to, illegal gambling, fraud, money
          laundering, or terrorist activities. If we discover that you have
          violated this Agreement or other regulatory requirements including,
          but not limited to, the Bank Secrecy Act, by participating in money
          laundering or by financing terrorist activities, we will take
          proportional disciplinary action. You further agree not to encourage
          or induce any third party to engage in any of the activities
          prohibited under this Section. Don’t Interfere With Other’s Use of the
          Services. You agree that you will not use or attempt to use another
          user’s Wallet without authorization or use our Services in any manner
          that could interfere, disrupt, negatively affect, or inhibit other
          users from fully enjoying it. Don’t Try to Harm Our System. You agree
          not to distribute any virus or other harmful computer code through
          SPWallet. You also agree to not take any action that may impose an
          unreasonable or disproportionately large load on our or any of our
          third party providers’ infrastructure. Don’t Attempt to Circumvent Our
          Security. You agree not to bypass, circumvent, or attempt to bypass or
          circumvent any measures that we may use to prevent or restrict access
          to the Services including, without limitation, other accounts,
          computer systems, or networks connected to the Services. Any use of
          SPWallet other than as specifically authorized in this Agreement,
          without our prior written permission, is strictly prohibited and will
          terminate your license to use SPWallet.
        </div>
        <Header>LIMITATION OF LIABILITY & DISCLAIMER OF WARRANTIES</Header>
        <div>
          You understand and agree that we have no control over, and no duty to
          take any action regarding: Failures, disruptions, errors, or delays in
          processing Virtual Currency that you may experience while using the
          Services; The risk of failure of hardware, software, and Internet
          connections; The risk of malicious software being introduced or found
          in the software underlying SPWallet; The risk that third parties may
          obtain unauthorized access to information stored within your Wallet,
          including, but not limited to your Wallet address, private key, and
          2FA Key; and The risk of unknown vulnerabilities in or unanticipated
          changes to the Tron Networks. You release us from all liability
          related to any losses, damages, or claims arising from: (a) user error
          such as forgotten passwords, incorrectly constructed transactions, or
          mistyped Virtual Currency addresses; (b) server failure or data loss;
          (c) unauthorized access to the SPWallet application; (d) bugs or other
          errors in the SPWallet software; and (e) any unauthorized third party
          activities, including, but not limited to, the use of viruses,
          phishing, brute forcing, or other means of attack against SPWallet. We
          make no representations concerning any Third Party Content contained
          in or accessed through our Services. Any other terms, conditions,
          warranties, or representations associated with such content, are
          solely between you and such organizations and/or individuals.
        </div>
        <Header>Limitation of Liability</Header>
        <div>
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL WE
          OR ANY OF OUR OFFICERS, DIRECTORS, REPRESENTATIVES, AGENTS, SERVANTS,
          COUNSEL, EMPLOYEES, CONSULTANTS, LAWYERS, AND OTHER PERSONNEL
          AUTHORIZED TO ACT, ACTING, OR PURPORTING TO ACT ON OUR BEHALF, BE
          LIABLE TO YOU UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE, OR
          ANY OTHER LEGAL OR EQUITABLE THEORY, FOR: (A) ANY LOST PROFITS, DATA
          LOSS, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR DIRECT,
          INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, COMPENSATORY, OR
          CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER RESULTING FROM: (I) YOUR
          USE OF, OR CONDUCT IN CONNECTION WITH, OUR SERVICES; (II) ANY
          UNAUTHORIZED USE OF YOUR WALLET ADDRESS AND/OR PRIVATE KEY DUE TO YOUR
          FAILURE TO MAINTAIN THE CONFIDENTIALITY OF YOUR WALLET; (III) ANY
          INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES; OR
          (IV) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT ARE FOUND IN
          THE SPWALLET SOFTWARE OR THAT MAY BE TRANSMITTED TO OR THROUGH OUR
          SERVICES BY ANY THIRD PARTY (REGARDLESS OF THE SOURCE OF ORIGINATION),
          OR (B) ANY DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE) OF THE
          GREATER OF: (I) FEES PAID TO US FOR THE APPLICABLE PRODUCTS; OR (II)
          $100.00. THESE LIMITATIONS APPLY REGARDLESS OF LEGAL THEORY, WHETHER
          BASED ON TORT, STRICT LIABILITY, BREACH OF CONTRACT, BREACH OF
          WARRANTY, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT WE WERE
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT
          ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR
          INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
        </div>
        <Header>Warranty Disclaimer</Header>
        <div>
          SPWALLET IS PROVIDED "AS IS" AND WITHOUT WARRANTY OF ANY KIND. TO THE
          MAXIMUM EXTENT PERMITTED BY LAW, WE DISCLAIM ALL REPRESENTATIONS AND
          WARRANTIES, EXPRESS OR IMPLIED, RELATING TO THE SERVICES AND
          UNDERLYING SOFTWARE OR ANY CONTENT ON THE SERVICES, WHETHER PROVIDED
          OR OWNED BY US OR BY ANY THIRD PARTY, INCLUDING WITHOUT LIMITATION,
          WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
          TITLE, NON-INFRINGEMENT, FREEDOM FROM COMPUTER VIRUS, AND ANY IMPLIED
          WARRANTIES ARISING FROM COURSE OF DEALING, COURSE OF PERFORMANCE, OR
          USAGE IN TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. IN ADDITION, WE
          DO NOT REPRESENT OR WARRANT THAT THE CONTENT ACCESSIBLE VIA THE
          SERVICES IS ACCURATE, COMPLETE, AVAILABLE, CURRENT, FREE OF VIRUSES OR
          OTHER HARMFUL COMPONENTS, OR THAT THE RESULTS OF USING THE SERVICES
          WILL MEET YOUR REQUIREMENTS. SOME STATES DO NOT ALLOW THE DISCLAIMER
          OF IMPLIED WARRANTIES, SO THE FOREGOING DISCLAIMERS MAY NOT APPLY TO
          YOU. THIS PARAGRAPH GIVES YOU SPECIFIC LEGAL RIGHTS AND YOU MAY ALSO
          HAVE OTHER LEGAL RIGHTS THAT VARY FROM STATE TO STATE.
        </div>
        <Header>INDEMNITY</Header>
        <div>
          To the extent permitted by applicable law, you agree to defend,
          indemnify, and hold harmless SPWallet Platform Parties from and
          against any and all claims, damages, obligations, losses, liabilities,
          costs or debt, and expenses (including, but not limited to, attorney’s
          fees) arising from: (a) your use of and access to the Services; (b)
          any feedback or submissions you provide to us concerning SPWallet; (c)
          your violation of any term of this Agreement; or (d) your violation of
          any law, rule, or regulation, or the rights of any third party.
        </div>
        <Header>TIME LIMITATION ON CLAIMS</Header>
        <div>
          You agree that any claim you may have arising out of or related to
          your relationship with us must be filed within one year after such
          claim arises, otherwise, your claim in permanently barred.
        </div>
        <Header>GOVERNING LAW</Header>
        <div>
          No matter where you’re located, the laws of England will govern these
          Terms and the parties’ relationship as if you signed these Terms in
          England, without regard to conflicts of laws rules. If any provisions
          of these Terms are inconsistent with any applicable law, those
          provisions will be superseded or modified only to the extent such
          provisions are inconsistent. The parties agree to submit to the
          federal or state courts in England for exclusive jurisdiction of any
          dispute arising out of or related to your use of the Services or your
          breach of these Terms. You waive any objection based on lack of
          personal jurisdiction, place of residence, improper venue, or forum
          non conveniens in any such action.
        </div>
        <Header>NOTE TO INTERNATIONAL USERS</Header>
        <div>
          If you are a user accessing or using our Services from a region with
          laws or regulations governing personal data collection, use, and
          disclosure that differ from England laws, please be advised that we do
          not collect or process your personal data, except as provided for in
          our Privacy Policy.
        </div>
        <Header>TERMINATION</Header>
        <div>
          In the event of termination concerning your license to use SPWallet,
          your obligations under this Agreement will still continue. Your access
          to the funds in your Wallet after termination will depend on your
          access to your backup of your Wallet address and private key.
        </div>
        <Header>DISCONTINUANCE OF SERVICES</Header>
        <div>
          We may, in our sole discretion and without cost to you, with or
          without prior notice, and at any time, modify or discontinue,
          temporarily or permanently, any portion of our Services. You are
          solely responsible for storing outside of the Services a backup of any
          Wallet address and private key pair that you maintain in your Wallet.
          Maintaining an external backup of any Wallet address and private key
          pairs associated with your Wallet will allow you to access the Tron
          Networks upon which your Wallet is secured. Such a backup will allow
          the user to fully restore their Wallet at any time without cost or
          loss of the user’s Virtual Currency. If you do not maintain a backup
          of your Wallet data outside of the Services, you will be not be able
          to access the Virtual Currency associated with your Wallet. SPWallet
          shall not be held responsible or liable for any loss of Virtual
          Currency in the event that we discontinue or depreciate the Services.
        </div>
        <Header>NO WAIVER</Header>
        <div>
          Our failure to exercise or delay in exercising any right, power, or
          privilege under this Agreement shall not operate as a waiver; nor
          shall any single or partial exercise of any right, power, or privilege
          preclude any other or further exercise thereof.
        </div>
        <Header>SEVERABILITY</Header>
        <div>
          If it turns out that any part of this Agreement is invalid, void, or
          for any reason unenforceable, that term will be deemed severable and
          limited or eliminated to the minimum extent necessary. The limitation
          or elimination of the term will not affect any other terms.
          ARBITRATION & WAIVER OF CLASS ACTION The parties agree to arbitrate
          any dispute arising from this Agreement or your use of the Services on
          an individual basis. ARBITRATION PREVENTS YOU FROM SUING IN COURT OR
          FROM HAVING A JURY TRIAL. THE PARTIES HEREBY EXPRESSLY WAIVE TRIAL BY
          JURY. The parties agree that: (a) any arbitration will occur in
          England; and (b) the arbitration will be conducted confidentially by a
          single arbitrator in accordance with the rules of England Arbitration
          Association for arbitration of consumer-related disputes, in the
          English language, and with limited discovery. At your request,
          hearings may be conducted in person or by telephone and the arbitrator
          may provide for submitting and determining motions on briefs, without
          oral hearings. Other than class procedures and remedies discussed
          below, the arbitrator has the authority to grant any remedy that would
          otherwise be available to a court or other tribunal. THE PREVAILING
          PARTY IN ANY ACTION OR PROCEEDING TO ENFORCE THESE TERMS SHALL BE
          ENTITLED TO COSTS AND ATTORNEYS' FEES. THE ARBITRAL DECISION MAY BE
          ENFORCED IN ANY COURT. WHETHER THE DISPUTE IS HEARD IN ARBITRATION OR
          IN COURT, YOU ANDSPWallet WILL NOT COMMENCE AGAINST THE OTHER A CLASS
          ACTION, CLASS ARBITRATION, OR REPRESENTATIVE ACTION OR PROCEEDING.
        </div>
        <Header>FORCE MAJEURE</Header>
        <div>
          We shall not be held liable for any delays, failure in performance, or
          interruptions of service which result directly or indirectly from any
          cause or condition beyond our reasonable control, including but not
          limited to: any delay or failure due to any act of God, act of civil
          or military authorities, act of terrorism, civil disturbance, war,
          strike or other labor dispute, fire, interruption in
          telecommunications or Internet services or network provider services,
          failure of equipment and/or software, other catastrophe, or any other
          occurrence which is beyond our reasonable control and shall not affect
          the validity and enforceability of any remaining provisions.
        </div>
        <Header>ASSIGNMENT</Header>
        <div>
          You agree that we may assign any of our rights and/or transfer,
          sub-contract, or delegate any of our obligations under these Terms.
          Your agreement to these Terms is personal to you and you may not
          transfer or assign it to any third party.
        </div>
        <Header>ENTIRE AGREEMENT</Header>
        <div>
          This Agreement sets forth the entire understanding and agreement as to
          the subject matter hereof and supersedes any and all prior
          discussions, agreements, and understandings of any kind (including,
          without limitation, any prior versions of this Agreement) and every
          nature between us. Except as provided for above, any modification to
          this Agreement must be in writing and must be signed by both parties.
        </div>
        <Header>QUESTIONS OR COMMENTS</Header>
        <div>
          We welcome comments, questions, concerns, or suggestions. Please send
          us a message on our contact page at support@spwallet.org via Telegram,
          or Twitter.
        </div>
      </Segment>
    </Container>
  );
}

export default TOS;
