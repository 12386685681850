import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Pagination, Segment, Table } from "semantic-ui-react";
import { formatUSD, formatTime } from "../../settings/format";
import { post } from "../../utils/api";

function LusBonus({ ver = 0 }) {
  const [redeems, setRedeems] = useState(null);
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const { TIME, AMOUNT, NO_RECORDS_FOUND, MEMBER } = library;

  useEffect(() => {
    setRedeems(null);
    post(
      `/fund-service/transaction/list`,
      {
        page: 1,
        pageSize: 10,
        type: "RANKING_BONUS",
      },
      (data) => {
        setRedeems(data);
      },
      (error) => console.error(error)
    );
  }, [ver]);

  return (
    <>
      <Segment basic vertical className="spwallet-history">
        <Table unstackable basic="very" compact="very" celled singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{TIME}</Table.HeaderCell>
              <Table.HeaderCell>{MEMBER}</Table.HeaderCell>
              <Table.HeaderCell>{AMOUNT}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {redeems &&
              (redeems.items.length > 0 ? (
                redeems.items.map((item, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{formatTime(item.time)}</Table.Cell>
                    <Table.Cell>{item.note}</Table.Cell>
                    <Table.Cell>
                      {formatUSD(item.amount)} {item.coin}
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={6}>
                    <Segment basic textAlign="center">
                      {NO_RECORDS_FOUND}
                    </Segment>
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </Segment>
      {redeems && redeems.pageCount > 1 && (
        <Segment vertical textAlign="center">
          <Pagination
            activePage={redeems.page}
            totalPages={redeems.pageCount}
            onPageChange={(e, { activePage }) => {
              post(
                `/fund-service/transaction/list`,
                {
                  page: activePage,
                  pageSize: 10,
                  type: "RANKING_BONUS",
                },
                (data) => {
                  setRedeems(data);
                },
                (error) => console.error(error)
              );
            }}
            secondary
            ellipsisItem={null}
            siblingRange={1}
            boundaryRange={0}
          />
        </Segment>
      )}
    </>
  );
}

export default LusBonus;
