/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import QrReader from "react-qr-reader";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import {
  Form,
  Loader,
  Segment,
  Dropdown,
  Icon,
  Button,
  Popup,
  Divider,
} from "semantic-ui-react";
import { S3_URL } from "../../settings";
import {
  deleteText,
  formatAmount,
  truncateNumber,
} from "../../settings/format";
import { get, post } from "../../utils/api";
import WithdrawConfirm from "./WithdrawConfirm";
import { checkEmpty } from "../others/Validate";
import WithdrawVerification from "./WithdrawVerification";
import { getDeviceSecret } from "../../utils/auth";
import { CustomToast } from "../../settings/actions";
import { _getFundList, _getWithdrawHistory } from "../../redux/walletReducer";
import { confirmAlert } from "react-confirm-alert";

function NewWithdrawForm() {
  const history = useHistory();
  const { setting, wallet, user } = useSelector((state) => state);
  const { coin } = useParams();
  const { fundList } = wallet;
  const { library } = setting;
  const [loading, setLoading] = useState(false);
  const [fund, setFund] = useState(null);
  const [network, setNetwork] = useState(null);
  const [address, setAddress] = useState("");
  const [walletError, setWalletError] = useState(null);
  const [showScanQrCode, setShowScanQrCode] = useState(false);
  const [addressTag, setAddressTag] = useState("");
  const [amount, setAmount] = useState("");
  const [error, setError] = useState(null);
  const { data, gaEnable } = user;
  const [isExternal, setIsExternal] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isWallet, setIsWallet] = useState(false);
  const [openVerification, setOpenVerification] = useState(false);
  const [isVerifying, setVerifying] = useState(false);
  const dispatch = useDispatch();

  let withdrawFee = 0;
  if (network && isWallet) {
    if (coin === "LuS" && isExternal) {
      withdrawFee = truncateNumber(parseFloat(deleteText(amount) * 4 / 100) );
    } else {
      withdrawFee = network.withdrawFee;
    }
  }
  const total = truncateNumber(parseFloat(deleteText(amount)) - withdrawFee);

  let checkWithdrawStatus = null;
  if (data) {
    if (data.verifyStatusLv2 !== "VERIFIED") {
      checkWithdrawStatus = library.USER_NOT_VERIFIED;
    } else {
      if (!gaEnable) {
        checkWithdrawStatus = library.PLEASE_ENABLE_GA;
      }
    }
  }

  useEffect(() => {
    setAddress("");
    setAddressTag("");
    setAmount("");
    setError(null);
    setWalletError(null);
    if (fundList) {
      const temp = fundList.find((f) => f.coin === coin);
      if (temp) {
        setFund(temp);
        setNetwork(temp.networks[0]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coin]);

  const selectFund = [];
  if (fundList) {
    fundList.forEach((element, index) => {
      selectFund.push({
        key: element.coin,
        value: element.coin,
        text:
          element.coin === coin
            ? element.coin
            : element.coin + " - " + formatAmount(element.amount),
        image: { avatar: true, src: `${S3_URL}/coins/${element.icon}` },
      });
    });
  }

  const networkList = [];
  if (fund) {
    fund.networks.forEach((element) => {
      networkList.push({
        key: element.network,
        value: element.network,
        text: element.network,
      });
    });
  }

  const _handleChange = (e, { value }) => {
    const network = fund.networks.find((f) => f.network === value);
    setNetwork(network);
  };

  const _checkAmount = () => {
    const tempAmount = truncateNumber(parseFloat(amount));
    if (amount.trim().length === 0 || parseFloat(amount) === 0) {
      setError(library.PLEASE_ENTER_AMOUNT_WITHDRAW);
    } else if (tempAmount <= 0 || tempAmount < network.minimumWithdraw) {
      setError(library.WITHDRAW_AMOUNT_TOO_SMALL);
    } else if (tempAmount > fund.amount) {
      setError(library.UNAVAILABLE_BALANCE);
    } else {
      setError(null);
      setOpenConfirm(true);
    }
  };

  const _checkAddress = (isSubmit) => {
    setWalletError(null);
    setLoading(true);
    setIsWallet(false);
    if (checkEmpty(address)) {
      setWalletError(library.PLEASE_ENTER_WALLET_ADDRESS);
      setLoading(false);
    } else {
      get(
        `/fund-service/address/validate?address=${address}&network=${network.network}`,
        (data) => {
          const { result, transferType } = data;
          if (result) {
            setIsWallet(true);
            setIsExternal(transferType === "EXTERNAL");
            if (isSubmit) {
              _checkAmount();
            }
          } else {
            setWalletError(library.INVALID_WALLET_ADDRESS);
          }
          setLoading(false);
        },
        () => {
          setLoading(false);
          setWalletError(library.INVALID_WALLET_ADDRESS);
        }
      );
    }
  };

  const _handleChangeAmount = (e) => {
    setAmount(deleteText(e.target.value));
  };

  const _handleVerification = (otpCode, gaCode) => {
    setVerifying(true);
    post(
      `/fund-service/withdraw`,
      {
        coin,
        network: network.network,
        address,
        amount: total,
        deviceSecret: getDeviceSecret(),
        gaCode,
        otpCode,
        addressTag: addressTag.trim === "" ? null : addressTag,
      },
      () => {
        setVerifying(false);
        setError(null);
        setAmount("");
        setAddress("");
        setOpenVerification(false);
        dispatch(_getWithdrawHistory(1, 10, coin));
        dispatch(_getFundList());
        confirmAlert({
          title: "",
          overlayClassName: "sdfsd",
          message: (
            <div className="d-flex-cbt">
              <div style={{ textAlign: "center", width: "100%" }}>
                <img
                  src={`${S3_URL}/spexchange-images/Untitled-1-min.png`}
                  style={{ width: 150, margin: "15px auto" }}
                />
                <h3 className="m-0 up">{library.WITHDRAW_SUCCESSFUL}</h3>
                <div>
                  <span className="font-size-15 d-flex-ec">
                    {formatAmount(total)}
                    <span
                      style={{
                        marginLeft: "2px",
                        opacity: 0.4,
                        fontSize: "1.2rem",
                      }}
                    >
                      {coin}
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  <span className="p-10">{library.ADDRESS}</span>
                  <span
                    className="p-10"
                    style={{
                      maxWidth: 200,
                      textAlign: "right",
                      whiteSpace: "pre-wrap",
                      wordBreak: "break-word",
                    }}
                  >
                    {address}
                  </span>
                </div>
              </div>
            </div>
          ),
          buttons: [
            {
              label: "OK",
              onClick: () => {
                setAddress("");
                setAddressTag("");
                setAmount("");
              },
            },
          ],
        });
      },
      (error) => {
        CustomToast("error", library[error.code]);
        setLoading(false);
        setVerifying(false);
      }
    );
  };

  if (fund) {
    if (fund.withdrawEnable) {
      return (
        <>
          <Segment textAlign="left" loading={loading}>
            <Form inverted onSubmit={() => _checkAddress(true)}>
              <Form.Field className="show-mobile">
                <Dropdown
                  options={selectFund}
                  selection
                  fluid
                  onChange={(e, { value }) =>
                    history.push(`/withdraw/${value}`)
                  }
                  value={history.location.pathname.replace("/withdraw/", "")}
                  translate="no"
                />
              </Form.Field>
              <Form.Group widths={2}>
                <Form.Field>
                  <label>{library.CHOOSE_NETWORK}</label>
                  {networkList && (
                    <Dropdown
                      options={networkList}
                      selection
                      onChange={_handleChange}
                      value={network.network}
                      translate="no"
                    />
                  )}
                </Form.Field>
                <Form.Field style={{ textAlign: "right" }}>
                  <label>&nbsp;</label>
                  <p>
                    {library.MINIMUM_WITHDRAW_AMOUNT}
                    <br />
                    {network.minimumWithdraw} {fund.coin}
                  </p>
                </Form.Field>
              </Form.Group>
              <Form.Field>
                <Form.Input
                  label={library.ADDRESS}
                  placeholder={library.PLEASE_ENTER_WALLET_ADDRESS}
                  onChange={(e) => setAddress(e.target.value)}
                  value={address}
                  icon={
                    <Icon
                      name="qrcode"
                      inverted
                      onClick={() => setShowScanQrCode(true)}
                      className="show-mobile"
                      link
                    />
                  }
                  maxLength="128"
                  onBlur={() => _checkAddress(false)}
                />
                <small className="error-message">{walletError}</small>
              </Form.Field>
              {network.hasAddressTag && (
                <Form.Field>
                  <Form.Input
                    label={
                      <label style={{ display: "flex", alignItems: "center" }}>
                        MEMO
                        <Popup
                          trigger={
                            <Icon
                              name="info"
                              circular
                              style={{
                                fontSize: "0.49em",
                                boxShadow: "0 0 0 0.1em var(--white) inset",
                                marginLeft: "0.5em",
                                cursor: "help",
                              }}
                            />
                          }
                          content={
                            <p style={{ fontSize: 12 }}>{library.MEMO_NOTE}</p>
                          }
                          basic
                          position="right center"
                        />
                      </label>
                    }
                    placeholder={library.OPTION}
                    value={addressTag}
                    onChange={(e, { value }) => {
                      setAddressTag(value);
                    }}
                    type="text"
                    maxLength="128"
                  />
                </Form.Field>
              )}
              <Form.Field>
                <Form.Input
                  label={library.AMOUNT}
                  placeholder={library.PLEASE_ENTER_AMOUNT}
                  value={amount}
                  onChange={(e, { value }) => {
                    if (value === ".") {
                      e.target.value = "";
                    }
                    const dotIndex = value.indexOf(".");
                    if (dotIndex > 0) {
                      value.replace(".", "");
                      e.target.value =
                        value.substring(0, dotIndex + 1) +
                        value
                          .substring(dotIndex, value.length)
                          .replace(/\./g, "")
                          .substring(0, 8);
                    }
                    _handleChangeAmount(e);
                  }}
                  type="text"
                />
                <small className="error-message">{error}</small>
              </Form.Field>
              <Divider />
              <Form.Field style={{ textAlign: "right" }}>
                <div className="withdraw-information">
                  <p>
                    <span className="key">{library.AMOUNT}:</span>{" "}
                    <span className="value">
                      {formatAmount(amount)} {coin}
                    </span>
                  </p>
                  <p>
                    <span className="key">{library.TRANSACTION_FEE}:</span>{" "}
                    <span className="value">
                      {formatAmount(withdrawFee)} {coin}
                    </span>
                  </p>
                  <p>
                    <span className="key">{library.YOU_WILL_GET}:</span>{" "}
                    <span className="value">
                      {formatAmount(total)} {coin}
                    </span>
                  </p>
                </div>
              </Form.Field>
              <Divider />
              <Form.Field>
                <Button
                  className="spwallet-button"
                  fluid
                  disabled={checkWithdrawStatus !== null}
                  type="submit"
                >
                  {library.WITHDRAW}
                </Button>
              </Form.Field>
              <Form.Field>
                {checkWithdrawStatus && (
                  <Segment basic vertical>
                    Tips: {checkWithdrawStatus}
                  </Segment>
                )}
              </Form.Field>
            </Form>
          </Segment>
          {showScanQrCode && (
            <div className="qr-code">
              <div>{library.SCAN_QR_CODE}</div>
              <div>
                <QrReader
                  delay={300}
                  onError={(data) => console.log(data)}
                  onScan={(data) => {
                    if (data) {
                      setAddress(data);
                      setShowScanQrCode(false);
                    }
                  }}
                  style={{ width: 300, margin: "2em 0" }}
                  facingMode="environment"
                />
              </div>
              <div>
                <Button
                  type="button"
                  className="spwallet-button"
                  onClick={() => setShowScanQrCode(false)}
                >
                  {library.CLOSE}
                </Button>
              </div>
            </div>
          )}
          <WithdrawConfirm
            openConfirm={openConfirm}
            amount={amount}
            withdrawFee={withdrawFee}
            coin={coin}
            total={total}
            address={address}
            addressTag={addressTag}
            _onClose={() => setOpenConfirm(false)}
            _handleConfirm={() => {
              setOpenConfirm(false);
              setOpenVerification(true);
            }}
          />
          <WithdrawVerification
            open={openVerification}
            close={() => setOpenVerification(false)}
            coin={coin}
            network={network.network}
            address={address}
            amount={total}
            deviceSecret={getDeviceSecret()}
            _handleVerification={_handleVerification}
            memo={addressTag}
            loading={isVerifying}
          />
        </>
      );
    } else {
      return (
        <Segment textAlign="center">
          {/* <Icon name="lock" color="yellow" /> */}
          <div> {library.WITHDRAW_DISABLE}</div>
        </Segment>
      );
    }
  } else {
    return <Loader active inverted />;
  }
}

export default NewWithdrawForm;
