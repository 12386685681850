import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, Header, Label, Progress, Segment } from "semantic-ui-react";
import { formatAmount, formatStakingTime } from "../../settings/format";

function EasyBuyList({ list, isOpen }) {
  const { setting } = useSelector((state) => state);
  const { library } = setting;

  if (isOpen !== undefined) {
    list = list.filter((element) => element.isOpen === isOpen);
  }

  return (
    <Grid className="easy-buy-list" stretched>
      {list &&
        list.map((item, index) => (
          <Grid.Column
            widescreen={5}
            largeScreen={5}
            computer={8}
            tablet={8}
            mobile={16}
            key={index}
          >
            <Link to={`/easy-buy/${item.slug}/${item.id}`}>
              <div className="shadow">
                <Segment
                  className="information"
                  style={{
                    backgroundImage: `url(${item.coverPhoto})`,
                  }}
                ></Segment>
                <Segment className="parameter">
                  <Segment vertical textAlign="left" basic>
                    <div>
                      <Header>
                        {item.name} <small>{item.base}</small>
                      </Header>
                      <small>{item.title}</small>
                    </div>
                    <Label>
                      {item.isOpen
                        ? library.OPEN.toUpperCase()
                        : library.CLOSED.toUpperCase()}
                    </Label>
                  </Segment>
                  <div>
                    <p>{library.PRICE}</p>
                    <p>
                      {formatAmount(item.price)} {item.quote}
                    </p>
                  </div>
                  <div>
                    <p>{library.SESSION_SUPPLY}</p>
                    <p>
                      {formatAmount(item.supply)} {item.base}
                    </p>
                  </div>
                  <div>
                    <p>{library.SOLD}</p>
                    <p>{parseInt((item.sold / item.supply) * 100)}%</p>
                  </div>
                  <Progress
                    percent={parseInt((item.sold / item.supply) * 100)}
                    size="tiny"
                  />
                  <div>
                    <p>
                      {formatStakingTime(item.startDate)} ~{" "}
                      {formatStakingTime(item.endDate)}
                    </p>
                  </div>
                </Segment>
              </div>
            </Link>
          </Grid.Column>
        ))}
    </Grid>
  );
}

export default EasyBuyList;
