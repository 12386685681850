import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import { SET_MODAL_CONTENT } from "../../redux/settingReducer";
import { get } from "../../utils/api";

function ConfirmDevice() {
  const { token } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    get(
      `/user-service/device/confirm/${token}`,
      () => {
        dispatch({
          type: SET_MODAL_CONTENT,
          payload: {
            content: "CONFIRM_SUCCESS",
            callback: () => {
              history.push("/");
            },
          },
        });
      },
      (error) => {
        dispatch({
          type: SET_MODAL_CONTENT,
          payload: {
            content: error.code,
            callback: () => {
              history.push("/");
            },
          },
        });
      }
    );
  }, [dispatch, history, token]);

  return (
    <Dimmer active>
      <Loader content="Waiting ..." />
    </Dimmer>
  );
}

export default ConfirmDevice;
