import { Grid } from "@material-ui/core";
import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Image } from "semantic-ui-react";
import { LUS_SYMBOL } from "../../../constant/CoinConstant";
import { S3_URL } from "../../../settings";

function LusHeader() {
  const history = useHistory();

  useEffect(() => {
    document.getElementsByClassName("custom-menu")[0].style.display = "none";
    document.getElementsByClassName("App")[0].style.marginTop = 0;
    return () => {
      document.getElementsByClassName("custom-menu")[0].style.display = "block";
      document.getElementsByClassName("App")[0].style.marginTop = "58px";
    };
  });

  return (
    <>
      <div
        style={{
          backgroundColor: "#000",
          color: "#fff",
          textAlign: "center",
          paddingTop: 10,
          paddingBottom: 10,
          fontWeight: "bold",
        }}
      >
        AUTHENTIC LUXURY BRANDS WITH EXCLUSIVELY V.I.P PRICE
      </div>
      <div className="title">
        <Container>
          <Grid container alignItems="center" justify="space-between">
            <Grid item xs={4} style={{ textAlign: "left" }}>
              <Link
                to="/lus/partner"
                className="black-link"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Image
                  src={`${S3_URL}/spwallet/menu-logo.png`}
                  style={{ height: 30 }}
                />
              </Link>
            </Grid>
            <Grid item xs={4}>
              <Link to="/lus/shopping" style={{ textDecoration: "unset" }}>
                <img src={`${S3_URL}/coins/${LUS_SYMBOL}.png`} alt="lus" />
                <p
                  className="hide-mobile"
                  style={{
                    color: "#000",
                    fontFamily: "Arizonia",
                    fontSize: "2em",
                    fontWeight: 100,
                  }}
                >
                  Luxury Outlet Shop
                </p>
              </Link>
            </Grid>
            <Grid item xs={4}>
              <div className="bar shopping-sub-menu">
                <img src={`${S3_URL}/spwallet/shopping-icon-1.png`} alt="lus" />
                <img
                  src={`${S3_URL}/spwallet/shopping-icon-2.png`}
                  alt="lus"
                  style={{ cursor: "pointer" }}
                  onClick={() => history.push("/lus/shopping/orders")}
                />
                <img src={`${S3_URL}/spwallet/shopping-icon-3.png`} alt="lus" />
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
}

export default LusHeader;
