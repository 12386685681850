export const ErrorCode = {
  EMAIL_OTP_REQUIRED: "EMAIL_OTP_REQUIRED",
  GACODE_REQUIRED: "GACODE_REQUIRED",
  WITHDRAW_SUCCESSFUL: "WITHDRAW_SUCCESSFUL",
};

export const EASY_BUY = "EASYBUY";
export const RETURN_TO_EASY_BUY =
  "aHR0cHM6Ly9sYXVuY2hwYWQu0083YmluYW5jZS5jb20vZW4vbGF1bmNocG9vbC9PR19DSFo";

export const ORDER_STATUS = {
  NEW: "New",
  INPROGRESS: "In Progress",
  SHIPPING: "Shipping",
  COMPLETED: "Completed",
  CANCELLED: "Canceled",
  CANCELED: "Canceled",
};

export const RANK_LEVEL = {
  NO_RANK: "No rank",
  BASIC: "Basic",
  PARTNER: "Partner",
  MEMBER: "Member",
  AGENCY: "Agency",
  SILVER: "Silver",
  GOLD: "Gold",
  DIAMOND: "Diamond",
  MASTER: "Master",
  VIP1: "VIP 1",
  VIP2: "VIP 2",
};

export const MenuOptions = [
  {
    name: "PORTFOLIO",
    link: "/",
  },
  {
    name: "SWAP",
    link: "/swap",
  },
  // {
  //   name: "STAKING",
  //   link: "/staking",
  // },
  // {
  //   name: "PARTNER",
  //   link: "/partner",
  //   sub: [],
  // },
  // {
  //   name: "PORTFOLIO",
  // },
];

export const _checkLocalhost = () => {
  if (window.location.hostname === "localhost") return true;
  return false;
};
