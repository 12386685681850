import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Header, Pagination, Segment, Table } from "semantic-ui-react";
import { _getSwapHistory } from "../../redux/walletReducer";
import { formatAmount, formatTime } from "../../settings/format";

function SwapHistory() {
  const { wallet, setting } = useSelector((state) => state);
  const { swapHistory } = wallet;
  const dispatch = useDispatch();
  const { library } = setting;
  const {
    TIME,
    PRICE,
    BASE_AMOUNT,
    QUOTE_AMOUNT,
    NO_RECORDS_FOUND,
    SWAP_HISTORY,
  } = library;
  useEffect(() => {
    dispatch(_getSwapHistory({}));
  }, [dispatch]);

  return (
    swapHistory && (
      <Segment textAlign="center" className="swap-history">
        <Header textAlign="left">{SWAP_HISTORY}</Header>
        <Segment className="spwallet-history hide-mobile" vertical basic>
          <Table
            unstackable
            basic="very"
            compact="very"
            celled
            inverted
            singleLine
            selectable
            textAlign="left"
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>{TIME}</Table.HeaderCell>
                <Table.HeaderCell className="hide-mobile">
                  {PRICE}
                </Table.HeaderCell>
                <Table.HeaderCell>{BASE_AMOUNT}</Table.HeaderCell>
                <Table.HeaderCell>{QUOTE_AMOUNT}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {swapHistory.items.length > 0 ? (
                swapHistory.items.map((s, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>#{s.id}</Table.Cell>
                    <Table.Cell>{formatTime(s.time)}</Table.Cell>
                    <Table.Cell className="hide-mobile">
                      {formatAmount(s.price)} {s.quote} / {s.base}
                    </Table.Cell>
                    <Table.Cell>
                      {formatAmount(s.amount)} {s.base}
                    </Table.Cell>
                    <Table.Cell>
                      {formatAmount(s.total)} {s.quote}
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={5}>
                    <Segment basic textAlign="center">
                      {NO_RECORDS_FOUND}
                    </Segment>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Segment>
        <Segment className="show-mobile swap-mobile-history" vertical>
          {swapHistory.items.length > 0
            ? swapHistory.items.map((item, index) => (
                <Grid key={index} className="item" columns={2}>
                  <Grid.Column textAlign="left">
                    <div className="id">#{item.id}</div>
                    <div className="time">{formatTime(item.time)}</div>
                  </Grid.Column>
                  <Grid.Column textAlign="right">
                    <div className="base">
                      - {formatAmount(item.amount)} {item.base}
                    </div>
                    <div className="quote">
                      + {formatAmount(item.total)} {item.quote}
                    </div>
                    <small>
                      {library.FEE}: {formatAmount(item.fee)} {item.base}
                    </small>
                  </Grid.Column>
                </Grid>
              ))
            : NO_RECORDS_FOUND}
        </Segment>
        {swapHistory.pageCount > 1 && (
          <Pagination
            totalPages={swapHistory.pageCount}
            activePage={swapHistory.page}
            onPageChange={(e, { activePage }) =>
              dispatch(_getSwapHistory({ page: activePage }))
            }
            pointing
            secondary
            inverted
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
            boundaryRange={0}
          />
        )}
      </Segment>
    )
  );
}

export default SwapHistory;
