/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Grid } from "@material-ui/core";
import { formatMoney, formatUSD } from "../../settings/format";
import { useSelector } from "react-redux";
import { S3_URL } from "../../settings";

const BTCIcon = "https://s3.spwallet.org/coins/BTC.png";
const ETHIcon = "https://s3.spwallet.org/coins/ETH.png";
const USDTIcon = "https://s3.spwallet.org/coins/USDT.png";
const SPCIcon = "https://s3.spwallet.org/coins/SPC.png";
const mUSDIcon = "https://s3.spwallet.org/coins/mUSD.png";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(icon, name, coin, price, change24h, volume) {
  return { icon, name, coin, price, change24h, volume };
}

function RenderMobile() {
  const [rows, setRows] = useState(null);

  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const { COIN_TOKEN, PRICE, CHANGE_24H } = library;
  const [mounted, setMounted] = useState(true);

  useEffect(() => {
    fetch(`https://api.spexchange.io/trade-service/market`)
      .then((res) => res.json())
      .then((json) => {
        let data = json.data;
        data = data.filter((item) =>
          ["BTC", "ETH", "SPC", "AiT", "TRX"].includes(item.base)
        );
        data.sort((a, b) => a.index - b.index);
        setRows(data);
      });
    setInterval(() => {
      fetch(`https://api.spexchange.io/trade-service/market`)
        .then((res) => res.json())
        .then((json) => {
          let data = json.data;
          data = data.filter((item) =>
            ["BTC", "ETH", "SPC", "AiT", "TRX"].includes(item.base)
          );
          data.sort((a, b) => a.index - b.index);
          setRows(data);
        });
    }, 10000);
  }, []);

  return (
    <React.Fragment>
      <Grid item xs={12} className="mb-1 p-2 text-black-50">
        <Grid container justify="center" spacing={2} className="hide-mobile">
          <Grid item xs={5} className="font-size-1 text-left">
            {COIN_TOKEN}
          </Grid>
          <Grid item xs={3} className="font-size-1 text-left">
            {PRICE}
          </Grid>
          <Grid item xs={4} className="font-size-1 text-right">
            {CHANGE_24H}
          </Grid>
        </Grid>
      </Grid>
      {rows &&
        rows.map((row, index) => (
          <Grid
            item
            xs={12}
            className=" rounded-lg backgound-dark-10 mb-2"
            key={index}
          >
            <Grid container justify="center" spacing={2} className="m-0 w-100">
              <Grid item xs={5} className="text-left d-flex align-items-center">
                <img
                  src={`${S3_URL}/coins/${row.base}.png`}
                  className="width-30 height-30 mr-2"
                />
                <div>
                  <span className="font-size-1 d-block mr-1">{row.base}</span>
                  <span className="font-size-1 d-block text-black-40 font-size-1">
                    {row.name}
                  </span>
                </div>
              </Grid>
              <Grid
                item
                xs={3}
                className="font-size-1 text-left d-flex align-items-center text-nowrap"
              >
                $ {formatUSD(row.lastPrice)}
              </Grid>
              <Grid
                item
                xs={4}
                className="font-size-1 text-right d-flex align-items-center justify-content-end"
                style={
                  row.priceChangePercent > 0
                    ? { color: "var(--green)" }
                    : { color: "#FF644E" }
                }
              >
                {parseFloat(row.priceChangePercent).toFixed(2) > 0 ? "+" : ""}
                {parseFloat(row.priceChangePercent).toFixed(2)}%
              </Grid>
            </Grid>
          </Grid>
        ))}
    </React.Fragment>
  );
}

function RenderDesktop() {
  const classes = useStyles();
  const [rows, setRows] = useState(null);

  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const { COIN_TOKEN, PRICE, CHANGE_24H, VOLUME } = library;

  useEffect(() => {
    fetch(`https://api.spexchange.io/trade-service/market`)
      .then((res) => res.json())
      .then((json) => {
        let data = json.data;
        data = data.filter((item) =>
          ["BTC", "ETH", "SPC", "AiT", "TRX"].includes(item.base)
        );
        data.sort((a, b) => a.index - b.index);
        setRows(data);
      });
    setInterval(() => {
      fetch(`https://api.spexchange.io/trade-service/market`)
        .then((res) => res.json())
        .then((json) => {
          let data = json.data;
          data = data.filter((item) =>
            ["BTC", "ETH", "SPC", "AiT", "TRX"].includes(item.base)
          );
          data.sort((a, b) => a.index - b.index);
          setRows(data);
        });
    }, 10000);
  }, []);

  return (
    <Table className={classes.table} aria-label="caption table">
      <TableHead>
        <TableRow>
          <TableCell
            align="left"
            className="font-size-1 text-black-30 font-weight-bold text-left"
          >
            {COIN_TOKEN}
          </TableCell>
          <TableCell
            align="left"
            className="font-size-1 text-black-30 font-weight-bold text-left "
          >
            {PRICE}
          </TableCell>
          <TableCell
            align="left"
            className="font-size-1 text-black-30 font-weight-bold text-left "
          >
            {CHANGE_24H}
          </TableCell>
          <TableCell
            align="right"
            className="font-size-1 text-black-30 font-weight-bold text-right"
          >
            {VOLUME}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows &&
          rows.map((row) => (
            <TableRow key={row.coin}>
              <TableCell align="left" className="d-flex align-items-center">
                <img
                  src={`${S3_URL}/coins/${row.base}.png`}
                  className="width-30 mr-3"
                />
                <span className="font-size-11 font-weight-bold mr-1">
                  {row.base}
                </span>
                {/* <span className="font-size-11 text-black-40">{row.quote}</span> */}
              </TableCell>
              <TableCell align="left" className="font-size-11 color-primary">
                $ {row.lastPrice}
              </TableCell>
              <TableCell
                align="left"
                className="font-size-11"
                style={
                  row.priceChangePercent > 0
                    ? { color: "var(--green)" }
                    : { color: "#FF644E" }
                }
              >
                {parseFloat(row.priceChangePercent).toFixed(2) > 0 ? "+" : ""}
                {parseFloat(row.priceChangePercent).toFixed(2)}%
              </TableCell>
              <TableCell align="right" className="font-size-11 color-primary">
                $ {formatUSD(parseFloat(row.quoteVolume).toFixed(2))}
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}

export default function ProductList() {
  const isMobile = window.innerWidth < 800;
  return (
    <TableContainer
      component={Paper}
      className={"shadow-none" + (isMobile ? " p-2" : "")}
    >
      {isMobile === true ? <RenderMobile /> : <RenderDesktop />}
    </TableContainer>
  );
}
