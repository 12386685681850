import { Drawer } from "@material-ui/core";
import React from "react";
import { useSelector } from "react-redux";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
import { formatAmount } from "../../settings/format";

function WithdrawConfirm({
  openConfirm,
  amount,
  coin,
  address,
  withdrawFee,
  total,
  addressTag,
  _onClose,
  _handleConfirm,
}) {
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  return (
    <Drawer anchor={"left"} open={openConfirm} className={"custom-modal-vk"}>
      <Segment
        style={{
          backgroundColor: "var(--light-blue)",
          maxWidth: 450,
          color: "#fff",
          minWidth: 300,
        }}
      >
        <Segment vertical>
          <Header style={{ color: "var(--green)" }}>
            {library.WITHDRAW_CONFIRM}
          </Header>
          <Icon
            name="x"
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              cursor: "pointer",
            }}
            size="large"
            onClick={_onClose}
            inverted
            color="grey"
          />
        </Segment>
        <Segment vertical>
          <Segment style={{ backgroundColor: "var(--gray)" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p style={{ opacity: 0.6 }}>{library.AMOUNT}</p>
              <p>
                {formatAmount(amount)} {coin}
              </p>
            </div>
            <div>
              <p style={{ opacity: 0.6, marginBottom: 15 }}>
                {library.WALLET_ADDRESS}
              </p>
            </div>
            <p style={{ textAlign: "right" }}>
              {address} {addressTag ? "- Memo: " + addressTag : null}
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p style={{ opacity: 0.6 }}>{library.WITHDRAW_FEE}</p>
              <p>
                {formatAmount(withdrawFee)} {coin}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <p style={{ opacity: 0.6 }}>{library.YOU_WILL_GET}</p>
              <p>
                {formatAmount(total)} {coin}
              </p>
            </div>
          </Segment>
        </Segment>
        <Button
          onClick={_handleConfirm}
          fluid
          className="spwallet-button"
          type="button"
        >
          {library.CONFIRM}
        </Button>
      </Segment>
    </Drawer>
  );
}

export default WithdrawConfirm;
