import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Container,
  Form,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import { deviceInfo } from "../../components/others/OtherFunction";
import { checkEmpty, checkLength } from "../../components/others/Validate";
import { SET_MODAL_CONTENT } from "../../redux/settingReducer";
import { ACCESS_TOKEN_KEY } from "../../settings";
import { put } from "../../utils/api";

function ChangePassword() {
  const { setting, user } = useSelector((state) => state);
  const { gaEnable } = user;
  const { library } = setting;
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const _handleChangePassword = (e) => {
    const oldPassword = e.target.oldPassword.value;
    const newPassword = e.target.newPassword.value;
    const confirmPassword = e.target.confirmPassword.value;
    const gaCode = gaEnable ? e.target.gaCode.value : "";
    setError(null);
    if (checkEmpty(oldPassword)) {
      setError("PLEASE_ENTER_OLD_PASSWORD");
    } else if (checkEmpty(newPassword)) {
      setError("PLEASE_ENTER_NEW_PASSWORD");
    } else if (checkLength(newPassword, 8)) {
      setError("PLEASE_ENTER_NEW_PASSWORD_MORE_THAN");
    } else if (newPassword.length > 16) {
      setError("PLEASE_ENTER_NEW_PASSWORD_LESS_THAN");
    } else if (checkEmpty(confirmPassword)) {
      setError("PLEASE_ENTER_CONFIRM_PASSWORD");
    } else if (newPassword !== confirmPassword) {
      setError("CONFIRM_PASSWORD_VS_NEW_PASSWORD");
    } else if (gaEnable && checkEmpty(gaCode)) {
      setError("PLEASE_ENTER_GA_CODE");
    } else if (gaEnable && gaCode.length !== 6) {
      setError("WRONG_GACODE");
    } else {
      setLoading(true);
      put(
        `/user-service/user/change-pwd`,
        {
          oldPassword,
          password: newPassword,
          gaCode,
          device: deviceInfo(),
        },
        () => {
          dispatch({
            type: SET_MODAL_CONTENT,
            payload: {
              content: "CHANGE_PASSWORD_SUCCESS",
              callback: () => {
                localStorage.removeItem(ACCESS_TOKEN_KEY);
                window.location.replace("/login");
              },
            },
          });
        },
        (error) => {
          setError(error.code);
          setLoading(false);
        }
      );
    }
  };

  return (
    <Container>
      <LazyLoad>
        <Segment
          loading={loading}
          style={{ maxWidth: 350, margin: "auto", padding: 20 }}
          textAlign="left"
        >
          <Header as="h1" textAlign="center">
            {library.CHANGE_PASSWORD}
          </Header>
          <Form inverted error onSubmit={_handleChangePassword}>
            <Form.Input
              label={library.OLD_PASSWORD}
              placeholder={library.PLEASE_ENTER_PASSWORD}
              id="oldPassword"
              type={showOldPassword ? "input" : "password"}
              action={{
                icon: showOldPassword ? "eye" : "eye slash",
                onClick: () => setShowOldPassword(!showOldPassword),
                type: "button",
              }}
              maxLength="128"
            />
            <Form.Input
              label={library.NEW_PASSWORD}
              placeholder={library.PLEASE_ENTER_NEW_PASSWORD}
              id="newPassword"
              type={showNewPassword ? "input" : "password"}
              action={{
                icon: showNewPassword ? "eye" : "eye slash",
                onClick: () => setShowNewPassword(!showNewPassword),
                type: "button",
              }}
              maxLength="128"
            />
            <Form.Input
              label={library.CONFIRM_NEW_PASSWORD}
              placeholder={library.PLEASE_ENTER_CONFIRM_PASSWORD}
              id="confirmPassword"
              type={showConfirmPassword ? "input" : "password"}
              action={{
                icon: showConfirmPassword ? "eye" : "eye slash",
                onClick: () => setShowConfirmPassword(!showConfirmPassword),
                type: "button",
              }}
              maxLength="128"
            />
            {gaEnable && (
              <Form.Input
                label={library.GA_CODE}
                type="input"
                placeholder={library.PLEASE_ENTER_GA_CODE}
                id="gaCode"
                maxLength="6"
              />
            )}
            <Message error content={library[error]} />
            <Button className="spwallet-button" fluid>
              {library.CONFIRM}
            </Button>
          </Form>
        </Segment>
      </LazyLoad>
    </Container>
  );
}

export default ChangePassword;
