import React, { useEffect, useState } from "react";
import {
  Container,
  Header,
  Loader,
  Pagination,
  Segment,
  Table,
  Dropdown,
  Grid,
  Button,
} from "semantic-ui-react";
import "../../../components/shopping/Shopping.scss";
import { post } from "../../../utils/api";
import { formatStakingTime, formatTotal } from "../../../settings/format";
import LusHeader from "../../../components/lus/SubComponent/LusHeader";
import { Link, useHistory } from "react-router-dom";
import { AddTitle } from "../../../actions";
import { useSelector } from "react-redux";
import { LUS_SYMBOL } from "../../../constant/CoinConstant";
import {
  CancelRounded,
  CheckCircleRounded,
  WatchLaterRounded,
} from "@material-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { API_GET_LUS_SHOPPING_ORDERS } from "../../../settings/apiEndpoint";

const statusList = [
  { key: 1, value: 1, text: "New" },
  { key: 2, value: 2, text: "Inprogress" },
  { key: 3, value: 3, text: "Shipping" },
  { key: 4, value: 4, text: "Completed" },
  { key: 5, value: 5, text: "Canceled" },
];

function MobileItem({ data }) {
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const history = useHistory();
  return (
    <div
      className="item"
      onClick={() => history.push(`/lus/shopping/orders/${data.id}`)}
    >
      <div>{data.firstProductName}</div>
      <div>
        {library.ORDER_ID}: #{data.id}
      </div>
      <div>
        {library.CREATE_ORDER_DATE}: {formatStakingTime(data.createdDate)}
      </div>
      <div>
        {library.STATUS}: {library[data.status]}
      </div>
      {data.status === "COMPLETED" || data.status === "CANCELED" ? (
        <div className="status">
          {data.status === "COMPLETED" ? (
            <CheckCircleRounded style={{ fill: "var(--green)" }} />
          ) : (
            <CancelRounded style={{ fill: "#ff644e" }} />
          )}
        </div>
      ) : (
        <div className="status">
          <WatchLaterRounded style={{ fill: "#efb909" }} />
        </div>
      )}
    </div>
  );
}

function Item({ data }) {
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const history = useHistory();

  return (
    <Table.Row
      style={{ cursor: "pointer" }}
      onClick={() => history.push(`/lus/shopping/orders/${data.id}`)}
    >
      <Table.Cell>
        <Link
          to={`/lus/shopping/orders/${data.id}`}
          style={{ color: "#0076ba", fontWeight: 600 }}
        >
          #{data.id}
        </Link>
      </Table.Cell>
      <Table.Cell>{formatStakingTime(data.createdDate)}</Table.Cell>
      <Table.Cell>{data.firstProductName}</Table.Cell>
      <Table.Cell>
        {formatTotal(data.totalAmount)} {LUS_SYMBOL}
      </Table.Cell>
      <Table.Cell>{library[data.status]}</Table.Cell>
    </Table.Row>
  );
}

function OrderHistory() {
  const [data, setData] = useState(null);
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const [status, setStatus] = useState("");
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);

  const _handleSearch = (activePage) => {
    setData(null);
    const filters = {};
    if (status) filters.status = status;
    post(
      API_GET_LUS_SHOPPING_ORDERS,
      {
        page: activePage,
        pageSize: 10,
        search: "",
        orderBy: "Id",
        isDesc: true,
        filters,
        fromDate: from ? moment(from).format() : null,
        toDate: to ? moment(to).format() : null,
      },
      (data) => setData(data)
    );
  };

  useEffect(() => {
    AddTitle("My LuS Shopping Orders");
    post(
      API_GET_LUS_SHOPPING_ORDERS,
      {
        page: 1,
        pageSize: 10,
        search: "",
        orderBy: "Id",
        isDesc: true,
        filters: {},
        // fromDate: from ? from.toISOString() : null,
        // toDate: to ? to.toISOString() : null,
      },
      (data) => setData(data)
    );
  }, []);

  return (
    <div id="order-history">
      <LusHeader />
      <Container>
        <Header as="h1" style={{ color: "#000" }} textAlign="left">
          {library.MY_ORDERS}
        </Header>
        <Segment vertical basic textAlign="left" className="filter">
          <div>
            <div>Time</div>
            <div style={{ display: "flex" }}>
              <DatePicker
                selected={from}
                onChange={(date) => setFrom(date)}
                isClearable
                placeholderText="From date"
                dateFormat="yyyy-MM-dd"
              />
              <DatePicker
                selected={to}
                onChange={(date) => setTo(date)}
                isClearable
                placeholderText="To date"
                dateFormat="yyyy-MM-dd"
              />
            </div>
          </div>
          <div>
            <div>Status</div>
            <div>
              <Dropdown
                selection
                options={statusList}
                placeholder="Select status"
                value={status}
                onChange={(e, { value }) => setStatus(value)}
                clearable
              />
              <Button onClick={() => _handleSearch(1)}>Search</Button>
            </div>
          </div>
        </Segment>
        <div className="show-mobile list-order">
          {data ? (
            data.items.length > 0 ? (
              data.items.map((s, index) => <MobileItem data={s} key={index} />)
            ) : (
              <Segment basic textAlign="center">
                {library.NO_RECORDS_FOUND}
              </Segment>
            )
          ) : (
            <Loader active />
          )}
        </div>
        <Segment className="spwallet-history hide-mobile">
          <Table
            unstackable
            basic="very"
            compact="very"
            singleLine
            selectable
            textAlign="left"
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{library.ORDER} ID</Table.HeaderCell>
                <Table.HeaderCell>{library.TIME}</Table.HeaderCell>
                <Table.HeaderCell>{library.PRODUCT}</Table.HeaderCell>
                <Table.HeaderCell>{library.TOTAL}</Table.HeaderCell>
                <Table.HeaderCell>{library.STATUS}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data ? (
                data.items.length > 0 ? (
                  data.items.map((s, index) => <Item data={s} key={index} />)
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={5} textAlign="center">
                      {library.NO_RECORDS_FOUND}
                    </Table.Cell>
                  </Table.Row>
                )
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={5} textAlign="center">
                    <Loader active />
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Segment>
        <Segment vertical basic>
          {data && data.pageCount > 1 && (
            <Pagination
              totalPages={data.pageCount}
              activePage={data.page}
              onPageChange={(e, { activePage }) => _handleSearch(activePage)}
              ellipsisItem={null}
              lastItem={null}
              firstItem={null}
            />
          )}
        </Segment>
      </Container>
    </div>
  );
}

export default OrderHistory;
