import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Dimmer, Icon, Image, Segment } from "semantic-ui-react";
import { S3_URL } from "../../settings";
// import { ADS_EN, ADS_VI } from "../../settings/links";
import { LazyLoadImage } from "react-lazy-load-image-component";

function Advertisement() {
  const [show, setShow] = useState(!window.localStorage.getItem("SHOW_ADS"));
  const { setting } = useSelector((state) => state);
  const { lang } = setting;

  const _hideAds = () => {
    setShow(false);
    // window.localStorage.setItem("SHOW_ADS", "HIDE");
  };

  return (
    <Dimmer active={show} page>
      <Segment basic className="spwallet-animation">
        <a
          href="https://thongbao.lus.shopping/promotions/promotion-thang-04"
          // eslint-disable-next-line react/jsx-no-target-blank
          target="_blank"
        >
          {lang === "vi" ? (
            <LazyLoadImage
              src={`${S3_URL}/banners/lus-april-event.jpg`}
              width={300}
              effect="blur"
            />
          ) : (
            <LazyLoadImage
              src={`${S3_URL}/banners/lus-april-event.jpg`}
              width={300}
              effect="blur"
            />
          )}
        </a>
        <Icon
          name="x"
          size="large"
          style={{
            position: "absolute",
            top: "-0.5em",
            right: "-0.5em",
            cursor: "pointer",
            opacity: 0.7,
          }}
          onClick={_hideAds}
        />
      </Segment>
    </Dimmer>
  );
}

export default Advertisement;
