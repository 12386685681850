import React, { useState } from "react";
import LazyLoad from "react-lazyload";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Form,
  Header,
  Message,
  Segment,
} from "semantic-ui-react";
import { checkEmail } from "../../components/others/Validate";
import { SET_MODAL_CONTENT } from "../../redux/settingReducer";
import { get } from "../../utils/api";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const dispatch = useDispatch();

  const _handleForgotPassword = () => {
    setLoading(true);
    setError(null);
    if (checkEmail(email)) {
      setError(library.INVALID_EMAIL);
      setLoading(false);
    } else {
      get(
        `/user-service/user/forgot-pwd/${email}`,
        () => {
          // setSuccess(true);
          dispatch({
            type: SET_MODAL_CONTENT,
            payload: {
              content: "PLEASE_CHECK_EMAIL_FOR_RESET_PASSWORD",
              callback: () => {
                setLoading(false);
                setError(null);
              },
            },
          });
        },
        (error) => {
          setLoading(false);
          setError(library[error.code]);
        }
      );
    }
  };

  return (
    <LazyLoad>
      <Container className="custom-form">
        <Segment textAlign="left" loading={loading}>
          <Form
            onSubmit={_handleForgotPassword}
            error={error !== null}
            inverted
          >
            <Header as="h1" textAlign="center" className="blue-color">
              {library.FORGOT_PASSWORD.toUpperCase()}
            </Header>
            <Form.Input
              label="Email"
              placeholder={library.PLEASE_ENTER_EMAIL}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Message error content={error} />
            <Button type="submit" fluid className="spwallet-button">
              {library.CONFIRM}
            </Button>
            <br />
            <Link to="/login">{library.BACK}</Link>
            <br />
            <Link to="/">{library.HOMEPAGE}</Link>
          </Form>
        </Segment>
      </Container>
    </LazyLoad>
  );
}

export default ForgotPassword;
