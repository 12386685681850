import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Header, Pagination, Segment, Table } from "semantic-ui-react";
import { _getDepositHistory } from "../../redux/walletReducer";
import { formatAmount, formatTime } from "../../settings/format";
import TransactionHash from "../others/TransactionHash";
import WalletAddress from "../others/WalletAddress";

function DepositHistory({ coin }) {
  const { wallet, setting } = useSelector((state) => state);
  const { depositHistory } = wallet;
  const { library } = setting;
  const { TIME, ADDRESS, AMOUNT, NO_RECORDS_FOUND, DEPOSIT_HISTORY } = library;
  const [mounted, setMounted] = useState(true);

  const dispatch = useDispatch();
  useEffect(() => {
    if (mounted) {
      dispatch(_getDepositHistory({ coin }));
    }
    return () => setMounted(!mounted);
  }, [coin, dispatch, mounted]);

  return (
    depositHistory && (
      <Segment textAlign="center" className="deposit-history">
        <Header textAlign="left">{DEPOSIT_HISTORY}</Header>
        <Segment vertical basic className="spwallet-history hide-mobile">
          <Table
            unstackable
            basic="very"
            compact="very"
            celled
            inverted
            singleLine
            textAlign="left"
          >
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{TIME}</Table.HeaderCell>
                <Table.HeaderCell>{ADDRESS}</Table.HeaderCell>
                <Table.HeaderCell>Hash</Table.HeaderCell>
                <Table.HeaderCell>{AMOUNT}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {depositHistory.items.length > 0 ? (
                depositHistory.items.map((h, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>{formatTime(h.time)}</Table.Cell>
                    <Table.Cell>
                      <WalletAddress data={h} />
                    </Table.Cell>
                    <Table.Cell>
                      <TransactionHash data={h} />
                    </Table.Cell>
                    <Table.Cell>
                      {formatAmount(h.amount)} {h.coin}
                    </Table.Cell>
                  </Table.Row>
                ))
              ) : (
                <Table.Row>
                  <Table.Cell colSpan={4}>
                    <Segment basic textAlign="center">
                      {NO_RECORDS_FOUND}
                    </Segment>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Segment>
        <Segment vertical basic className="mobile-deposit-history show-mobile">
          {depositHistory.items.length > 0
            ? depositHistory.items.map((item, index) => (
                <div
                  key={index}
                  className="item"
                  style={{
                    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                    paddingBottom: "1em",
                    paddingTop: "1em",
                  }}
                >
                  <Grid>
                    <Grid.Column width={4} textAlign="left">
                      <div>{library.ADDRESS}</div>
                      <div>Hash</div>
                    </Grid.Column>
                    <Grid.Column width={12} textAlign="right">
                      <WalletAddress data={item} />
                      <TransactionHash data={item} />
                    </Grid.Column>
                  </Grid>
                  <Grid columns={2} style={{ margin: "-1em" }}>
                    <Grid.Column textAlign="left">
                      <small>{formatTime(item.time)}</small>
                    </Grid.Column>
                    <Grid.Column
                      textAlign="right"
                      style={{ color: "var(--green)", fontWeight: 600 }}
                    >
                      {formatAmount(item.amount)} {item.coin}
                    </Grid.Column>
                  </Grid>
                </div>
              ))
            : NO_RECORDS_FOUND}
        </Segment>
        {depositHistory.pageCount > 1 && (
          <Pagination
            totalPages={depositHistory.pageCount}
            activePage={depositHistory.page}
            onPageChange={(e, { activePage }) =>
              dispatch(_getDepositHistory({ page: activePage, coin }))
            }
            pointing
            secondary
            inverted
          />
        )}
      </Segment>
    )
  );
}

export default DepositHistory;
