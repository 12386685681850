import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import { useSelector } from "react-redux";
import { Container, Header, Icon, Segment, Tab } from "semantic-ui-react";
import EasyBuyList from "../../components/easybuy/EasyBuyList";
import { Link } from "react-router-dom";
import { post } from "../../utils/api";
import { EASY_BUY } from "../../settings/constant";
import { BANNER } from "../../settings/links";
import "../../components/easybuy/EasyBuy.scss";
import CustomMenu from "../../components/others//CustomMenu";
const IMG_0263 = BANNER;

function EasyBuy() {
  const { setting, user } = useSelector((state) => state);
  const { library } = setting;
  const { isLogin } = user;
  const [list, setList] = useState(null);
  const [mounted, setMounted] = useState(true);

  useEffect(() => {
    if (mounted) {
      if (!list) {
        post("/presale-service/product/list", { type: EASY_BUY }, (data) => {
          setList(data);
        });
      }
    }
    return () => setMounted(!mounted)
  }, [list, mounted]);

  const panes = [
    {
      menuItem: library.ALL,
      render: () => <EasyBuyList list={list} />,
    },
    {
      menuItem: library.OPEN,
      render: () => <EasyBuyList list={list} isOpen={true} />,
    },
    {
      menuItem: library.CLOSED,
      render: () => <EasyBuyList list={list} isOpen={false} />,
    },
  ];

  return (
    <>
      {!isLogin && <CustomMenu />}
      <LazyLoad>
        <div
          className={`easy-buy ${!isLogin && "no-login"}`}
          style={{
            background: `url(${IMG_0263})`,
          }}
        >
          <Container>
            <Header as="h1">{library.EASY_BUY_SLOGAN}</Header>
            <Header>{library.EASY_BUY_SUB_SLOGAN}</Header>
            <div className="feature">
              <Header as="h3">{library.FAST}</Header>
              <Header as="h3">{library.EFFICIENT}</Header>
              <Header as="h3">{library.ZERO_CHANGES}</Header>
            </div>
          </Container>
        </div>
      </LazyLoad>
      <LazyLoad>
        <Container textAlign="left">
          <Segment className="easy-buy-tab">
            <Tab
              panes={panes}
              menu={{ secondary: true, pointing: true, inverted: true }}
            />
            {isLogin && (
              <Link className="easy-buy-history-link" to="/easy-buy/history">
                <Icon name="file alternate outline" size="big" />
              </Link>
            )}
          </Segment>
        </Container>
      </LazyLoad>
    </>
  );
}

export default EasyBuy;
