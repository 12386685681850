/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Container } from "@material-ui/core";
import { useSelector } from "react-redux";
import { BANNER, BANNER_LOGO } from "../../settings/links";

const IMG_0263 = BANNER;
const logoSPwalletCenter = BANNER_LOGO;

MainBanner.propTypes = {};

function RenderMobile() {
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const {
    HOMEPAGE_BANNER_HEADER,
    HOMEPAGE_SUB_BANNER_HEADER_1,
    HOMEPAGE_SUB_BANNER_HEADER_2,
  } = library;
  return (
    <div
      className="d-flex justify-content-center align-items-center height-500"
      style={{
        background: `url(${IMG_0263})`,
        backgroundSize: "cover",
        paddingTop: 57,
      }}
    >
      <div className="text-center">
        <img src={logoSPwalletCenter} className="width-130" />
        <span className="font-size-15 font-weight-bolder d-block text-white mt-3">
          {HOMEPAGE_BANNER_HEADER}
        </span>
        <span className="d-inline-block font-size-1 text-white-70 w-85 p-3">
          {HOMEPAGE_SUB_BANNER_HEADER_1}
          {HOMEPAGE_SUB_BANNER_HEADER_2}
        </span>
        <div className="rounded-pill overflow-hidden height-1 mt-4 w-85 ml-auto mr-auto">
          <div className="input-group rounded-pill">
            <input
              type="text"
              className="font-size-1 form-control rounded-0 border-0 font-size-1 height-40 pl-4"
              placeholder="Your email address"
              aria-label="Your email address"
              aria-describedby="basic-addon2"
            />
            <div className="input-group-append rounded-0 border-0">
              <span
                className="font-size-1 input-group-text rounded-0 border-0 btn-hover font-size-1 pt-2 pb-2 pl-4 pr-4 bg-dark text-white"
                id="basic-addon2"
              >
                Sign up
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function RenderDesktop() {
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const {
    HOMEPAGE_BANNER_HEADER,
    HOMEPAGE_SUB_BANNER_HEADER_1,
    HOMEPAGE_SUB_BANNER_HEADER_2,
  } = library;
  return (
    <div
      className="d-flex justify-content-center align-items-center height-500"
      style={{
        background: `url(${IMG_0263})`,
        backgroundSize: "cover",
        // marginTop: 10,
      }}
    >
      <div className="text-center">
        <img src={logoSPwalletCenter} className="width-130" />
        <span className="font-size-22 font-weight-bolder d-block text-white mt-3">
          {HOMEPAGE_BANNER_HEADER}
        </span>
        <span className="font-size-11 text-center text-white-70">
          {HOMEPAGE_SUB_BANNER_HEADER_1} <br />
          {HOMEPAGE_SUB_BANNER_HEADER_2}
        </span>
        <div className="rounded-pill overflow-hidden height-1 mt-4 w-60 ml-auto mr-auto">
          <div className="input-group rounded-pill">
            <input
              type="text"
              className="font-size-11 form-control rounded-0 border-0 font-size-09 height-40 pl-4"
              placeholder="Your email address"
              aria-label="Your email address"
              aria-describedby="basic-addon2"
            />
            <div className="input-group-append rounded-0 border-0">
              <a href="/register">
                <span
                  className="font-size-11 input-group-text rounded-0 border-0 btn-hover font-size-09 pt-2 pb-2 pl-4 pr-4 bg-dark text-white"
                  id="basic-addon2"
                >
                  Sign up
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function MainBanner(props) {
  const isMobile = window.innerWidth < 800;
  return (
    <Container maxWidth={false} className="h-50 p-0">
      {isMobile === true ? <RenderMobile /> : <RenderDesktop />}
    </Container>
  );
}

export default MainBanner;
