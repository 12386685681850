import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Grid, Header, Icon, Popup, Segment } from "semantic-ui-react";
import { get } from "../../utils/api";

function Info() {
  const { user, setting } = useSelector((state) => state);
  const [statistic, setStatistic] = useState(null);
  const { library } = setting;
  const {
    INVITED_FRIEND,
    INVITED_TIP,
    COPIED,
    AFFILIATE,
    YOUR_TOTAL_AFFILIATE,
    YOUR_TOTAL_AFFILIATE_F1,
  } = library;

  useEffect(() => {
    !statistic &&
      get(
        `/user-service/referral/statistic`,
        (data) => {
          setStatistic(data);
        },
        (error) => console.error(error)
      );
  }, [statistic]);

  return (
    statistic &&
    user.data && (
      <Grid stretched>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Segment textAlign="left">
            <Header>{INVITED_FRIEND}</Header>
            <p>{INVITED_TIP}</p>
            <Form>
              <Form.Input
                action={{
                  color: "teal",
                  labelPosition: "right",
                  content: (
                    <Popup
                      trigger={
                        <Segment compact basic>
                          <Icon name="copy" />
                        </Segment>
                      }
                      content={COPIED}
                      on="click"
                      position="right center"
                    />
                  ),
                  onClick: () => navigator.clipboard.writeText(user.data.id),
                }}
                value={user.data.id}
                fluid
                inverted
              />
              <Form.Input
                action={{
                  color: "teal",
                  labelPosition: "right",
                  content: (
                    <Popup
                      trigger={
                        <Segment compact basic>
                          <Icon name="copy" />
                        </Segment>
                      }
                      content={COPIED}
                      on="click"
                      position="right center"
                    />
                  ),
                  onClick: () =>
                    navigator.clipboard.writeText(
                      `${window.location.origin.toString()}/register/${
                        user.data.id
                      }`
                    ),
                }}
                value={`${window.location.origin.toString()}/register/${
                  user.data.id
                }`}
                fluid
                inverted
              />
            </Form>
          </Segment>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Segment>
            <Grid columns={2} stretched>
              <Grid.Column>
                <Segment className="spwallet-tips">
                  <div>{AFFILIATE}</div>
                  <div>
                    #{user.data.referralId ? user.data.referralId : "0"}
                  </div>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment className="spwallet-tips">
                  <div>{YOUR_TOTAL_AFFILIATE}</div>
                  <div>{statistic.referralCount}</div>
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment className="spwallet-tips">
                  <div>{YOUR_TOTAL_AFFILIATE_F1}</div>
                  <div> {statistic.referralLevel1Count}</div>
                </Segment>
              </Grid.Column>
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid>
    )
  );
}

export default Info;
