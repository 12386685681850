import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Container,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Loader,
  Message,
  Modal,
  Popup,
  Segment,
  Transition,
} from "semantic-ui-react";
import { SET_MODAL_CONTENT } from "../../redux/settingReducer";
import { _checkEnableGA, _getProfile } from "../../redux/userReducer";
import { S3_URL } from "../../settings";
import { get, put } from "../../utils/api";
import { checkEmpty } from "../others/Validate";

function Setting() {
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const { setting, user } = useSelector((state) => state);
  const { gaEnable } = user;
  const { library } = setting;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  // const [showChangeDisplayName, setShowChangeDisplayName] = useState(false);
  // const [displayName, setDisplayName] = useState("");
  const [isLoadedGA, setIsLoadedGA] = useState(false);

  useEffect(() => {
    if (!gaEnable) {
      get(
        `/user-service/user/ga`,
        (data) => setData(data),
        () => null
      );
    }
  }, [gaEnable]);

  const _disableGA = (e) => {
    const password = e.target.password.value;
    const gaCode = e.target.gaCode.value;
    setError(null);
    if (checkEmpty(password)) setError(library.PLEASE_ENTER_PASSWORD);
    else if (checkEmpty(gaCode)) setError(library.PLEASE_ENTER_GA_CODE);
    else {
      setLoading(true);
      put(
        `/user-service/user/ga`,
        {
          gaCode: gaCode,
          password,
          enable: false,
        },
        () => {
          dispatch({
            type: SET_MODAL_CONTENT,
            payload: {
              content: "DISABLE_GA_CODE",
              callback: () => {
                setOpen(false);
                dispatch(_getProfile());
                setError(null);
                setLoading(false);
                dispatch(_checkEnableGA());
              },
            },
          });
        },
        (error) => {
          setError(library[error.code]);
          setLoading(false);
        }
      );
    }
  };

  const _enableGA = (e) => {
    const password = e.target.password.value;
    const gaCode = e.target.gaCode.value;
    setError(null);
    if (checkEmpty(password)) setError(library.PLEASE_ENTER_PASSWORD);
    else if (checkEmpty(gaCode)) setError(library.PLEASE_ENTER_GA_CODE);
    else {
      setLoading(true);
      put(
        `/user-service/user/ga`,
        {
          gaCode: gaCode,
          password,
          enable: true,
        },
        () => {
          dispatch({
            type: SET_MODAL_CONTENT,
            payload: {
              content: "ENABLE_GA_CODE",
              callback: () => {
                setOpen(false);
                dispatch(_getProfile());
                setError(null);
                setLoading(false);
                dispatch(_checkEnableGA());
              },
            },
          });
        },
        (error) => {
          setError(library[error.code]);
          setLoading(false);
        }
      );
    }
  };

  // const _updateDisplayName = () => {
  //   put(
  //     `/user-service/user/display-name`,
  //     {
  //       displayName,
  //     },
  //     (data) => {
  //       const { success } = data;
  //       if (success) {
  //         toast(
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "center",
  //               alignContent: "center",
  //             }}
  //           >
  //             <div style={{ color: "var(--green)" }}>
  //               <Icon name="checkmark" size="large" />
  //             </div>
  //             <div>
  //               <label className="title">{library.DISPLAY_NAME_WAS_UPDATED}.</label>
  //             </div>
  //           </div>
  //         );
  //       } else {
  //         toast(
  //           <div
  //             style={{
  //               display: "flex",
  //               justifyContent: "center",
  //               alignContent: "center",
  //             }}
  //           >
  //             <div style={{ color: "#fe644e" }}>
  //               <Icon name="x" size="large" />
  //             </div>
  //             <div>
  //               {library[error.code]}. {library.PLEASE_TRY_AGAIN}.
  //             </div>
  //           </div>
  //         );
  //       }
  //     }
  //   );
  // };

  const {
    TURN_OFF,
    TURN_ON,
    CANCEL,
    IDENTITY_VERIFICATION,
    LOGIN_PASSWORD,
    CHANGE,
    PLEASE_ENTER_GA_CODE,
    PLEASE_ENTER_PASSWORD,
    PASSWORD,
    GA_CODE,
    GA,
    SECURITY,
    VERIFIED,
    VERIFY,
    PENDING,
  } = library;

  let tempVerifyStatus = VERIFY;
  if (user.data) {
    const { verifyStatusLv2 } = user.data;
    if (verifyStatusLv2 === "VERIFIED") {
      tempVerifyStatus = VERIFIED;
    }
    if (verifyStatusLv2 === "PENDING") {
      tempVerifyStatus = PENDING;
    }
  }

  return (
    <Segment>
      <Header>{SECURITY}</Header>
      <Grid>
        <Grid.Column width={16}>
          <Grid columns={2} verticalAlign="middle">
            <Grid.Column>{GA}</Grid.Column>
            <Grid.Column textAlign="right">
              <Button
                className="spwallet-button"
                style={{ width: 120 }}
                onClick={() => {
                  setOpen(true);
                  setIsLoadedGA(false);
                }}
              >
                {gaEnable ? TURN_OFF : TURN_ON}
              </Button>
            </Grid.Column>
          </Grid>
          <Grid columns={2} verticalAlign="middle">
            <Grid.Column>{IDENTITY_VERIFICATION}</Grid.Column>
            <Grid.Column textAlign="right">
              <Button
                className="spwallet-button"
                onClick={() => history.push("/verify")}
                disabled={
                  user.data &&
                  (user.data.verifyStatusLv2 === "VERIFIED" ||
                    user.data.verifyStatusLv2 === "PENDING")
                }
                style={{ width: 120 }}
              >
                {tempVerifyStatus}
              </Button>
            </Grid.Column>
          </Grid>
          <Grid columns={2} verticalAlign="middle">
            <Grid.Column>
              {/* <Icon name="key" /> */}
              {LOGIN_PASSWORD}
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Button
                className="spwallet-button"
                onClick={() => history.push("/change-password")}
                style={{ width: 120 }}
              >
                {CHANGE}
              </Button>
            </Grid.Column>
          </Grid>
          {/* <Grid columns={2} verticalAlign="middle">
            <Grid.Column>{library.DISPLAY_NAME}</Grid.Column>
            <Grid.Column textAlign="right">
              <Button
                className="spwallet-button"
                style={{ width: 120 }}
                onClick={() => setShowChangeDisplayName(true)}
              >
                {library.UPDATE}
              </Button>
            </Grid.Column>
          </Grid> */}
        </Grid.Column>
      </Grid>
      <Transition visible={open} duration={500} animation="fade up">
        <Modal open={open} onOpen={() => setOpen(true)} size="mini">
          <Modal.Header>
            {gaEnable ? TURN_OFF + " " + GA : TURN_ON + " " + GA}
          </Modal.Header>
          <Modal.Content>
            {gaEnable ? (
              <Form inverted onSubmit={_disableGA} error>
                <Form.Input
                  placeholder={PLEASE_ENTER_PASSWORD}
                  label={PASSWORD}
                  type="password"
                  id="password"
                  name="password"
                />
                <Form.Input
                  placeholder={PLEASE_ENTER_GA_CODE}
                  label={GA_CODE}
                  type="input"
                  id="gaCode"
                  name="gaCode"
                />
                <Message content={error} hidden={error === null} error />
                <Container textAlign="right">
                  <Button
                    onClick={() => setOpen(false)}
                    type="button"
                    disabled={loading}
                  >
                    {CANCEL}
                  </Button>
                  <Button
                    className="spwallet-button"
                    type="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    {library.CONFIRM}
                  </Button>
                </Container>
              </Form>
            ) : (
              data && (
                <Container>
                  <Segment basic vertical textAlign="center">
                    <Segment loading={!isLoadedGA} basic>
                      <Image
                        src={`${S3_URL}/spwallet/spwallet-qrcode.png`}
                        size="small"
                        centered
                        style={{ display: !isLoadedGA ? "block" : "none" }}
                      />
                      <Image
                        src={data.qrCodeSetupImageUrl}
                        size="small"
                        centered
                        style={{ display: isLoadedGA ? "block" : "none" }}
                        onLoad={() => setIsLoadedGA(true)}
                      />
                    </Segment>
                    <br />
                    <Popup
                      content="Copied"
                      on="click"
                      basic
                      position="bottom center"
                      hideOnScroll
                      trigger={
                        <p
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            navigator.clipboard.writeText(data.manualEntryKey)
                          }
                        >
                          {data.manualEntryKey}
                          <Icon name="copy outline" size="large" />
                        </p>
                      }
                    />
                    {/* <p>{data.manualEntryKey}</p> */}
                  </Segment>
                  <Form inverted onSubmit={_enableGA} error>
                    <Form.Input
                      placeholder={PLEASE_ENTER_PASSWORD}
                      label={PASSWORD}
                      type="password"
                      id="password"
                      name="password"
                    />
                    <Form.Input
                      placeholder={PLEASE_ENTER_GA_CODE}
                      label={GA_CODE}
                      type="input"
                      id="gaCode"
                      name="gaCode"
                    />
                    <Message content={error} hidden={error === null} error />
                    <Container textAlign="right">
                      <Button
                        onClick={() => {
                          setError(null);
                          setOpen(false);
                        }}
                        type="button"
                        disabled={loading}
                      >
                        {CANCEL}
                      </Button>
                      <Button
                        className="spwallet-button"
                        type="submit"
                        loading={loading}
                        disabled={loading}
                      >
                        {library.CONFIRM}
                      </Button>
                    </Container>
                  </Form>
                </Container>
              )
            )}
          </Modal.Content>
        </Modal>
      </Transition>
      {/* <Transition
        visible={showChangeDisplayName}
        duration={500}
        animation="fade up"
      >
        <Modal
          open={showChangeDisplayName}
          onOpen={() => setShowChangeDisplayName(true)}
          size="mini"
        >
          <Modal.Header>
            {library.UPDATE} {library.DISPLAY_NAME.toLowerCase()}
          </Modal.Header>
          <Modal.Content>
            <Container>
              <Form inverted onSubmit={_updateDisplayName} error>
                <Form.Input
                  placeholder={library.PLEASE_ENTER_YOUR_DISPLAY_NAME}
                  id="display-name"
                  name="display-name"
                  onChange={(e, { value }) => setDisplayName(value)}
                  value={displayName}
                />
                <Container textAlign="right">
                  <Button
                    onClick={() => {
                      setDisplayName("");
                      setShowChangeDisplayName(false);
                    }}
                    type="button"
                    disabled={loading}
                  >
                    {CANCEL}
                  </Button>
                  <Button
                    className="spwallet-button"
                    type="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    {library.UPDATE}
                  </Button>
                </Container>
              </Form>
            </Container>
          </Modal.Content>
        </Modal>
      </Transition> */}
    </Segment>
  );
}

export default Setting;
