import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Container,
  Dropdown,
  Grid,
  Icon,
  List,
  Segment,
  Tab,
} from "semantic-ui-react";
import DepositForm from "../../components/deposit/DepositForm";
import DepositHistory from "../../components/deposit/DepositHistory";
import MainBar from "../../components/portfolio/MainBar";
import { S3_URL } from "../../settings";
import { formatAmount } from "../../settings/format";

function Deposit() {
  const { coin } = useParams();
  const { wallet, api, user, setting } = useSelector((state) => state);
  const { fundList } = wallet;
  const fund = fundList.find((f) => f.coin === coin);
  const [panes, setPanes] = useState(null);
  const [oldCoin, setOldCoin] = useState(null);
  const history = useHistory();

  useEffect(() => {
    if (fund) {
      const temp = [];
      fund.networks.forEach((element) => {
        temp.push({
          menuItem: element.network,
          render: () => <DepositForm coin={coin} network={element.network} />,
        });
      });
      if (coin !== oldCoin) {
        setPanes(temp);
        setOldCoin(coin);
      }
    }
  }, [fund, panes, coin, api, user.accessToken, oldCoin, setOldCoin]);

  const { library } = setting;
  const {
    DEPOSIT_TIP_0_BEGIN,
    DEPOSIT_TIP_0_END,
    DEPOSIT_TIP_1,
    DEPOSIT_TIP_2,
    DEPOSIT_TIP_3,
    DEPOSIT_TIP_4,
  } = library;

  const tips = [
    DEPOSIT_TIP_0_BEGIN + " " + coin + " " + DEPOSIT_TIP_0_END,
    DEPOSIT_TIP_1,
    DEPOSIT_TIP_2,
    DEPOSIT_TIP_3,
    DEPOSIT_TIP_4,
  ];

  const selectFund = [];
  if (fundList) {
    fundList.forEach((element) => {
      selectFund.push({
        key: element.coin,
        value: element.coin,
        text:
          element.coin === coin
            ? element.coin
            : element.coin + " - " + formatAmount(element.amount),
        image: { avatar: true, src: `${S3_URL}/coins/${element.icon}` },
      });
    });
  }

  if (fund === undefined && fundList.length > 0) history.push("/");

  return fund ? (
    <Container
      textAlign="left"
      className="spwallet-deposit spwallet-container"
      id="portfolio"
    >
      <Segment textAlign="center">
        <MainBar coin={coin} fund={fund} />
      </Segment>
      <LazyLoad>
        <Grid stretched>
          <Grid.Column computer={8} tablet={8} only="computer">
            <Segment>
              {selectFund.length > 0 && (
                <Dropdown
                  options={selectFund}
                  selection
                  fluid
                  onChange={(e, { value }) => history.push(`/deposit/${value}`)}
                  value={history.location.pathname.replace("/deposit/", "")}
                  translate="no"
                />
              )}
              <Segment className="spwallet-tips">
                <Segment vertical basic>
                  <Icon name="lightbulb" size="large" />
                  Tips:
                </Segment>
                <List.Item as="ul">
                  {tips.map((item, index) => (
                    <List.Item key={index} as="li">
                      {item}
                    </List.Item>
                  ))}
                </List.Item>
              </Segment>
            </Segment>
          </Grid.Column>
          <Grid.Column computer={8} tablet={8} mobile={16}>
            <Segment textAlign="center" className="spwallet-deposit_form">
              <div className="show-mobile">
                {selectFund.length > 0 && (
                  <Dropdown
                    options={selectFund}
                    selection
                    fluid
                    onChange={(e, { value }) =>
                      history.push(`/deposit/${value}`)
                    }
                    value={history.location.pathname.replace("/deposit/", "")}
                    translate="no"
                  />
                )}
              </div>
              <Tab
                panes={panes}
                menu={{ secondary: true, pointing: true, inverted: true }}
              />
            </Segment>
          </Grid.Column>
        </Grid>
      </LazyLoad>
      <br />
      <LazyLoad>
        <DepositHistory coin={coin} />
      </LazyLoad>
    </Container>
  ) : null;
}

export default Deposit;
