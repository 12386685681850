import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  Container,
  Header,
  Grid,
  Segment,
  Table,
  GridColumn,
  Icon,
} from "semantic-ui-react";
import LusHeader from "../../../components/lus/SubComponent/LusHeader";
import { ORDER_STATUS } from "../../../settings/constant";
import { LUS_SYMBOL } from "../../../constant/CoinConstant";
import { post, put } from "../../../utils/api";
import { formatTime, formatUSD } from "../../../settings/format";
import { useSelector } from "react-redux";
import { ArrowBack } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { Drawer, Button } from "@material-ui/core";
import { CustomToast } from "../../../settings/actions";

function OrderDetail() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [products, setProducts] = useState([]);
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const [showConfirmCancelOrder, setShowConfirmCancelOrder] = useState(false);

  useEffect(() => {
    post(
      `/shopping-service/lus-shopping/product/getorderitems`,
      {
        orderId: id,
        page: 1,
        pageSize: 20,
        search: "",
        orderBy: "Id",
        isDesc: true,
        filters: {},
      },
      (data) => {
        setData(data.items[0]);
        setProducts(data.items);
      }
    );
  }, [id]);

  const _handleCancelOrder = () => {
    setShowConfirmCancelOrder(!showConfirmCancelOrder);
  };

  const _cancelOder = () => {
    setShowConfirmCancelOrder(!showConfirmCancelOrder);
    put(
      `/shopping-service/lus-shopping/product/updateorder`,
      {
        id: data.orderId,
        Status: 5,
      },
      () => {
        CustomToast("success", library.CANCEL_ORDER_SUCCESS);
        post(
          `/shopping-service/lus-shopping/product/getorderitems`,
          {
            orderId: id,
            page: 1,
            pageSize: 20,
            search: "",
            orderBy: "Id",
            isDesc: true,
            filters: {},
          },
          (data) => {
            setData(data.items[0]);
            setProducts(data.items);
          }
        );
      },
      (error) => {
        setShowConfirmCancelOrder(!showConfirmCancelOrder);
        CustomToast("error", library[error.code]);
      }
    );
  };

  return (
    <div id="order-detail">
      <LusHeader />
      {data && (
        <Container style={{ color: "#000", textAlign: "left" }}>
          <div className="hide-mobile">
            <Header style={{ color: "#000" }} as="h1">
              {library.ORDER_DETAIL} #{id} -{" "}
              <span>{ORDER_STATUS[data.status]}</span>
            </Header>
            <div style={{ textAlign: "right" }}>
              {library.CREATE_ORDER_DATE}: {formatTime(data.createdDate)}{" "}
            </div>
          </div>
          <Segment className="show-mobile status">
            <p style={{ fontWeight: 600, fontSize: "1.1em" }}>
              {library.ORDER_DETAIL} #{id}
            </p>
            <p>
              {library.CREATE_ORDER_DATE}: {formatTime(data.createdDate)}
            </p>
            <p>
              {library.STATUS}:{" "}
              <span style={{ fontWeight: 600 }}>
                {ORDER_STATUS[data.status]}
              </span>
            </p>
          </Segment>
          <div className="flex-2-col">
            <Segment basic vertical>
              <Header className="hide-mobile">
                {library.RECEIVER_ADDRESS}
              </Header>{" "}
              <Segment className="information">
                <p
                  className="show-mobile"
                  style={{ fontWeight: 600, fontSize: "1.1em" }}
                >
                  {library.RECEIVER_ADDRESS}
                </p>
                <p style={{ fontWeight: 600 }}>{data.customerName}</p>
                <p>
                  {library.ADDRESS}: {data.customerAddress}
                </p>
                <p>
                  {library.PHONE}: {data.customerPhone}
                </p>
              </Segment>
            </Segment>
            <Segment basic vertical>
              <Header className="hide-mobile">{library.CHECKOUT_METHOD}</Header>
              <Segment className="information">
                <p
                  className="show-mobile"
                  style={{ fontWeight: 600, fontSize: "1.1em" }}
                >
                  {library.CHECKOUT_METHOD}
                </p>
                <p>{library.CHECKOUT_BY_LUS}</p>
                <p style={{ color: "#0076ba", fontStyle: "italic" }}>
                  {library.PAYMENT_SUCCESS}
                </p>
              </Segment>
            </Segment>
          </div>
          <Segment className="spwallet-history hide-mobile" textAlign="center">
            <Table basic="very" unstackable singleLine>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell colSpan={2}>
                    {library.PRODUCT}
                  </Table.HeaderCell>
                  <Table.HeaderCell>{library.PRICE}</Table.HeaderCell>
                  <Table.HeaderCell>{library.DISCOUNT}</Table.HeaderCell>
                  <Table.HeaderCell>{library.TOTAL}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {products.map((item, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>
                      <img
                        src={item.imageList.split(",")[0]}
                        style={{ width: 60, height: 60 }}
                        alt=""
                      />
                    </Table.Cell>
                    <Table.Cell>{item.productName}</Table.Cell>
                    <Table.Cell>
                      {formatUSD(item.price)} {LUS_SYMBOL}
                    </Table.Cell>
                    <Table.Cell>
                      {formatUSD(item.price - item.promotionPrice)} {LUS_SYMBOL}
                    </Table.Cell>
                    <Table.Cell>
                      {formatUSD(item.promotionPrice)} {LUS_SYMBOL}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.Cell
                    colSpan={4}
                    textAlign="right"
                    style={{ borderTop: "1px solid rgba(34,36,38,.1)" }}
                  >
                    {library.SUBTOTAL}:
                  </Table.Cell>
                  <Table.Cell
                    textAlign="right"
                    style={{ borderTop: "1px solid rgba(34,36,38,.1)" }}
                  >
                    {formatUSD(data.price)} {LUS_SYMBOL}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell
                    style={{ border: "none" }}
                    colSpan={4}
                    textAlign="right"
                  >
                    {library.DISCOUNT}:
                  </Table.Cell>
                  <Table.Cell style={{ border: "none" }} textAlign="right">
                    {formatUSD(data.price - data.promotionPrice)} {LUS_SYMBOL}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell
                    style={{ border: "none" }}
                    colSpan={4}
                    textAlign="right"
                  >
                    {library.TOTAL}:
                  </Table.Cell>
                  <Table.Cell style={{ border: "none" }} textAlign="right">
                    {formatUSD(data.promotionPrice)} {LUS_SYMBOL}
                  </Table.Cell>
                </Table.Row>
                {data.status === "NEW" && (
                  <Table.Row className="hide-mobile">
                    <Table.Cell colSpan={5} textAlign="right">
                      <Button
                        style={{ backgroundColor: "#dacab1", color: "#fff" }}
                        onClick={_handleCancelOrder}
                      >
                        {library.CANCEL_ORDER}
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Footer>
            </Table>
          </Segment>
          <Segment className="show-mobile products">
            <p style={{ fontWeight: 600, fontSize: "1.1em" }}>
              {library.PRODUCTS}
            </p>
            {products.map((item, index) => (
              <div className="item" key={index}>
                <img
                  src={item.imageList.split(",")[0]}
                  style={{ width: 80, height: 80 }}
                  alt=""
                />
                <div>
                  <p>{item.productName}</p>
                  <p>{item.brandName}</p>
                  <p className="price">
                    {formatUSD(data.promotionPrice)} {LUS_SYMBOL} x{" "}
                    {data.quantity}
                  </p>
                </div>
              </div>
            ))}
          </Segment>
          <Segment className="show-mobile status">
            <div className="item">
              <p>{library.SUBTOTAL}</p>
              <p>
                {formatUSD(data.price)} {LUS_SYMBOL}
              </p>
            </div>
            <div className="item">
              <p>{library.DISCOUNT}</p>
              <p>
                {formatUSD(data.price - data.promotionPrice)} {LUS_SYMBOL}
              </p>
            </div>
            <div className="item">
              <p>{library.TOTAL}</p>
              <p>
                {formatUSD(data.promotionPrice)} {LUS_SYMBOL}
              </p>
            </div>
          </Segment>
          {data.status === "NEW" && (
            <Segment className="show-mobile status">
              <Button
                style={{ backgroundColor: "#dacab1", color: "#fff" }}
                onClick={_handleCancelOrder}
                fullWidth
              >
                {library.CANCEL_ORDER}
              </Button>
            </Segment>
          )}
          <Segment basic vertical>
            <Link
              to="/lus/shopping/orders"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <ArrowBack /> {library.BACK_TO_ORDER}
            </Link>
          </Segment>
        </Container>
      )}
      <Drawer
        anchor={"left"}
        open={showConfirmCancelOrder}
        className={"custom-modal-vk"}
      >
        <Segment
          style={{
            minWidth: 300,
          }}
          className="spwallet-container"
        >
          <Segment vertical>
            <Grid columns={2}>
              <GridColumn>{library.CANCEL_ORDER}</GridColumn>
              <GridColumn textAlign="right">
                <Icon name="x" link onClick={_handleCancelOrder} />
              </GridColumn>
            </Grid>
          </Segment>
          <Segment vertical>{library.ARE_YOU_SURE_CANCEL_ORDER}</Segment>
          <Segment vertical textAlign="right">
            <Button onClick={_handleCancelOrder} className="btn-border">
              {library.CLOSE}
            </Button>
            <Button className="btn-bg" onClick={_cancelOder}>
              {library.CONFIRM}
            </Button>
          </Segment>
        </Segment>
      </Drawer>
    </div>
  );
}

export default OrderDetail;
