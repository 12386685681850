import React from "react";
import LazyLoad from "react-lazyload";
import { useSelector } from "react-redux";
import { Container, Header, Segment, Tab } from "semantic-ui-react";
import ListRedeems from "../../components/staking/ListRedeems";
import StakingHistory from "../../components/staking/StakingHistory";
import StakingList from "../../components/staking/StakingList";

function Stacking() {
  const { setting, user } = useSelector((state) => state);
  const { library } = setting;
  const { isLogin } = user;
  const { HISTORY, REDEEMS } = library;
  const panes = [
    {
      menuItem: HISTORY,
      render: () => <StakingHistory />,
    },
    {
      menuItem: REDEEMS,
      render: () => <ListRedeems ver={1} />,
    },
    // {
    //   menuItem: REDEEMS,
    //   render: () => <ListRedeems />,
    // },
  ];

  return (
    <Container textAlign="left">
      <LazyLoad>
        <Header as="h1">{library.STAKING}</Header>
        <StakingList />
      </LazyLoad>
      {isLogin && (
        <Segment>
          <LazyLoad>
            <Tab
              panes={panes}
              menu={{ secondary: true, pointing: true, inverted: true }}
            />
          </LazyLoad>
        </Segment>
      )}
    </Container>
  );
}

export default Stacking;
