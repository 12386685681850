import { Grid, Typography } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Dimmer,
  Header,
  Pagination,
  Segment,
} from "semantic-ui-react";
import { post } from "../../utils/api";
import { formatTotal } from "../../settings/format";
import { useSelector } from "react-redux";
import { LUS_SYMBOL } from "../../constant/CoinConstant";

function Product({ data }) {
  const save = Math.round(
    ((data.price - data.promotionPrice) / data.price) * 100
  );

  return (
    <Grid item md={4} xs={12} sm={6} style={{ position: "relative" }}>
      <Link to={data.stockQty > 0 ? `/lus/shopping/products/${data.id}` : "#"}>
        <div className="item">
          <div
            className="sold-out"
            style={{ display: data.stockQty > 0 ? "none" : "flex" }}
          >
            <Header>SOLD OUT</Header>
          </div>
          <div className="sale-off">{save}%</div>
          <div style={{ minHeight: 400 }}>
            <img
              src={data.imageList && data.imageList.split(",")[0]}
              alt="product"
              className="top"
            />
            <img
              src={data.imageList && data.imageList.split(",")[1]}
              alt="product"
              className="bottom"
            />
          </div>
          <p className="brand">{data.brandName}</p>
          <p className="name">{data.productName}</p>
          <p className="price">
            <span style={{ textDecoration: "line-through" }}>
              ${formatTotal(data.price)}
            </span>{" "}
            <span
              style={{ color: "#EE220C", fontSize: "1.3em", fontWeight: 600 }}
            >
              <span style={{fontSize: "0.8em"}}>$</span>{formatTotal(data.promotionPrice)}
            </span>
          </p>
        </div>
      </Link>
    </Grid>
  );
}

function Products() {
  const [products, setProducts] = useState(null);
  const { setting } = useSelector((state) => state);
  const { library } = setting;

  useEffect(() => {
    post(
      "/shopping-service/lus-shopping/product/list",
      {
        page: 1,
        pageSize: 6,
        search: "",
        orderBy: "id",
        isDesc: true,
        filters: {},
      },
      (data) => setProducts(data)
    );
  }, []);

  return (
    <div className="products">
      <Container>
        <Typography variant="h3">
          <span>{library.PRODUCTS}</span>
        </Typography>
        <Grid container spacing={4}>
          {products &&
            products.items.map(
              (item, index) =>
                item.isActive && <Product data={item} key={index} />
            )}
        </Grid>
        <br />
        <br />
        <br />
        <br />
        {products && products.pageCount > 1 && (
          <Segment vertical basic textAlign="center">
            <Pagination
              activePage={products.page}
              totalPages={products.pageCount}
              onPageChange={(e, { activePage }) => {
                post(
                  "/shopping-service/lus-shopping/product/list",
                  {
                    page: activePage,
                    pageSize: 6,
                    search: "",
                    orderBy: "CreatedDate",
                    isDesc: true,
                    filters: {},
                  },
                  (data) => {
                    setProducts(data);
                  },
                  (error) => console.error(error)
                );
              }}
              ellipsisItem={null}
              lastItem={null}
              firstItem={null}
            />
          </Segment>
        )}
      </Container>
    </div>
  );
}

export default Products;
