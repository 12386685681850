import React from "react";
import LazyLoad from "react-lazyload";
import { useSelector } from "react-redux";
import { Container, Header } from "semantic-ui-react";
import CommissionHistory from "../../components/referral/CommissionHistory";
import Info from "../../components/referral/Info";
import Member from "../../components/referral/Member";

function Referral() {
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  return (
    <LazyLoad>
      <Container>
        <Header as="h1" textAlign="left">
          {library.AFFILIATE}
        </Header>
        <Info />
        <CommissionHistory />
        <Member />
      </Container>
    </LazyLoad>
  );
}

export default Referral;
