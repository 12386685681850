import { isProduction } from "../settings";
import { get, post } from "../utils/api";
import { logout } from "../utils/auth";
const FETCH_FUND_LIST = "FETCH_FUND_LIST";
const FETCH_SWAP_PRODUCT_LIST = "FETCH_SWAP_PRODUCT_LIST";
const FETCH_SWAP_HISTORY = "FETCH_SWAP_HISTORY";
const FETCH_STAKING_PRODUCT_LIST = "FETCH_STACKING_PRODUCT_LIST";
const FETCH_STAKING_HISTORY = "FETCH_STAKING_HISTORY";
const FETCH_DEPOSIT_HISTORY = "FETCH_DEPOSIT_HISTORY";
const FETCH_WITHDRAW_HISTORY = "FETCH_WITHDRAW_HISTORY";
const GET_DATA_FOR_COIN_CHART = "GET_DATA_FOR_COIN_CHART";
const FETCH_EASY_BUY_HISTORY = "FETCH_EASY_BUY_HISTORY";
const FETCH_REDEEM_LIST = "FETCH_REDEEM_LIST";
const FETCH_TRANSACTION_HISTORY = "FETCH_TRANSACTION_HISTORY";
const FETCH_LUS_STAKING_PRODUCTS = "FETCH_LUS_STAKING_PRODUCTS";
const FETCH_LUS_STAKING_HISTORY = "FETCH_LUS_STAKING_HISTORY";

const initialState = {
  fundList: [],
  swapProduct: [],
  swapHistory: null,
  stakingProduct: null,
  stakingHistory: null,
  depositHistory: null,
  withdrawHistory: null,
  fundListForCoinChart: [],
  easyBuyHistory: null,
  redeemHistory: null,
  _transactionHistory: null,
  lusStakingProducts: null,
  lusStakingHistory: null,
};

var intervalFund;
function getFund(dispatch) {
  get(
    "/fund-service/fund/list",
    (data) => {
      data.sort((a, b) => b.usdAmount - a.usdAmount);
      dispatch({ type: FETCH_FUND_LIST, payload: data });
    },
    (error) => {
      console.log(error);
    }
  );
}

export const _getFundList = () => (dispatch) => {
  get(
    "/fund-service/fund/list",
    (data) => {
      data.sort((a, b) => b.usdAmount - a.usdAmount);
      dispatch({ type: FETCH_FUND_LIST, payload: data });
      dispatch({ type: GET_DATA_FOR_COIN_CHART, payload: data });
      clearInterval(intervalFund);
      if (isProduction) intervalFund = setInterval(getFund, 10000, dispatch);
    },
    (error) => {
      clearInterval(intervalFund);
      logout();
      dispatch({ type: "LOGOUT" });
    }
  );
};

export const _getSwapList = () => (dispatch) => {
  get(
    "/swap-service/product/list",
    (data) => {
      dispatch({ type: FETCH_SWAP_PRODUCT_LIST, payload: data });
    },
    (error) => console.log(error)
  );
};

export const _getTransactionHistory =
  ({ page = 1, pageSize = 10, coin, from = 0, to = 0 }) =>
  (dispatch) => {
    post(
      "/fund-service/transaction/list",
      {
        page,
        pageSize,
        coin,
        from,
        to,
      },
      (data) => {
        dispatch({ type: FETCH_TRANSACTION_HISTORY, payload: data });
      },
      (error) => console.log(error)
    );
  };

export const _getRedeemHistory =
  ({ page = 1, pageSize = 10, coin, from = 0, to = 0 }) =>
  (dispatch) => {
    post(
      `/staking-service/staking/transaction/list`,
      {
        page,
        pageSize,
        coin,
        from,
        to,
      },
      (data) => {
        dispatch({ type: FETCH_REDEEM_LIST, payload: data });
      },
      (error) => console.log(error)
    );
  };

export const _getSwapHistory =
  ({ page = 1, pageSize = 10, coin, from = 0, to = 0 }) =>
  (dispatch) => {
    post(
      "/swap-service/swap/list",
      {
        page,
        pageSize,
        coin,
        from,
        to,
      },
      (data) => {
        dispatch({ type: FETCH_SWAP_HISTORY, payload: data });
      },
      (error) => console.log(error)
    );
  };

export const _getStakingProduct = () => (dispatch) => {
  get(
    "/staking-service/product/list",
    (data) => dispatch({ type: FETCH_STAKING_PRODUCT_LIST, payload: data }),
    (error) => console.log(error)
  );
};

export const _getStakingHistory =
  ({ page = 1, pageSize = 100, coin, from = 0, to = 0 }) =>
  (dispatch) => {
    post(
      `/staking-service/staking/list`,
      {
        page,
        pageSize,
        coin,
        from,
        to,
      },
      (data) => {
        dispatch({
          type: FETCH_STAKING_HISTORY,
          payload: data,
        });
      },
      (error) => console.log(error)
    );
  };

export const _getDepositHistory =
  ({ page = 1, pageSize = 10, coin, from = 0, to = 0 }) =>
  (dispatch) => {
    post(
      "/fund-service/deposit/list",
      { coin, from, to, pageSize, page },
      (data) => {
        dispatch({
          type: FETCH_DEPOSIT_HISTORY,
          payload: data,
        });
      },
      (error) => console.log(error)
    );
  };

export const _getWithdrawHistory =
  ({ page = 1, pageSize = 10, coin, from = 0, to = 0, status = "" }) =>
  (dispatch) => {
    post(
      "/fund-service/withdraw/list",
      { page, pageSize, coin, from, to, status },
      (data) => {
        dispatch({
          type: FETCH_WITHDRAW_HISTORY,
          payload: data,
        });
      },
      (error) => console.log(error)
    );
  };

export const _getEasyBuyHistory =
  ({ page = 1, pageSize = 10, coin, from = 0, to = 0, type = "EASYBUY" }) =>
  (dispatch) => {
    post(
      "/presale-service/transaction/list",
      { page, pageSize, coin, from, to, type },
      (data) => {
        dispatch({ type: FETCH_EASY_BUY_HISTORY, payload: data });
      },
      (error) => console.log(error)
    );
  };

export const _getLusStakingProduct = () => (dispatch) => {
  get(
    "/staking-service/lus-staking/product/list",
    (data) => dispatch({ type: FETCH_LUS_STAKING_PRODUCTS, payload: data }),
    (error) => console.log(error)
  );
};

export const _getLusStakingHistory =
  ({ page = 1, pageSize = 100, coin, from = 0, to = 0 }) =>
  (dispatch) => {
    post(
      `/staking-service/lus-staking/staking/list`,
      {
        page,
        pageSize,
        coin,
        from,
        to,
      },
      (data) => {
        dispatch({
          type: FETCH_LUS_STAKING_HISTORY,
          payload: data,
        });
      },
      (error) => console.log(error)
    );
  };

export const WalletReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LUS_STAKING_HISTORY:
      return { ...state, lusStakingHistory: action.payload };
    case FETCH_LUS_STAKING_PRODUCTS:
      return { ...state, lusStakingProducts: action.payload };
    case FETCH_TRANSACTION_HISTORY:
      return { ...state, _transactionHistory: action.payload };
    case FETCH_REDEEM_LIST:
      return { ...state, redeemHistory: action.payload };
    case FETCH_EASY_BUY_HISTORY:
      return {
        ...state,
        easyBuyHistory: action.payload,
      };
    case FETCH_FUND_LIST:
      return { ...state, fundList: action.payload };
    case FETCH_SWAP_PRODUCT_LIST:
      return { ...state, swapProduct: action.payload };
    case FETCH_SWAP_HISTORY:
      return { ...state, swapHistory: action.payload };
    case FETCH_STAKING_PRODUCT_LIST:
      return { ...state, stakingProduct: action.payload };
    case FETCH_STAKING_HISTORY:
      return { ...state, stakingHistory: action.payload };
    case FETCH_DEPOSIT_HISTORY:
      return { ...state, depositHistory: action.payload };
    case FETCH_WITHDRAW_HISTORY:
      return { ...state, withdrawHistory: action.payload };
    case GET_DATA_FOR_COIN_CHART: {
      return { ...state, fundListForCoinChart: action.payload };
    }
    default:
      return { ...state };
  }
};
