import React from "react";
import { useSelector } from "react-redux";
import { Grid, Image } from "semantic-ui-react";
import { SPEXCHANGE_LOGO } from "../../settings/links";

function SPExchange() {
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  return (
    <div className="color-primary" style={{ padding: "4em 0" }}>
      <Grid>
        <Grid.Column computer={8} tablet={8} mobile={16} textAlign="center">
          <Image
            src={SPEXCHANGE_LOGO}
            centered
            size="large"
            style={{ padding: "0 1em" }}
          />
        </Grid.Column>
        <Grid.Column
          computer={8}
          tablet={8}
          mobile={16}
          style={{ textAlign: "left", padding: "0 2em", fontSize: 16 }}
        >
          <p>{library.SP_ACCOUNT_TITLE}</p>
          <p>{library.SP_ACCOUNT}</p>
        </Grid.Column>
      </Grid>
    </div>
  );
}

export default SPExchange;
