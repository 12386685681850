import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Grid, Image } from "semantic-ui-react";
import {
  OVERVIEW_ASSETS_IMAGE,
  OVERVIEW_MEMBER_IMAGE,
  OVERVIEW_SOCIAL_IMAGE,
} from "../../settings/links";
import { formatAmount } from "../../settings/format";
import { get } from "../../utils/api";
import "./home.scss";

function Overview() {
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const [userCount, setUserCount] = useState(null);
  const [coinCount, setCoinCount] = useState(null);
  const [mounted, setMounted] = useState(true);

  useEffect(() => {
    if (mounted) {
      if (!userCount)
        get(`/user-service/statistic`, (data) => {
          setUserCount(data.userCount);
        });
      if (!coinCount)
        get(`/fund-service/statistic`, (data) => setCoinCount(data.coinCount));
    }
    return () => setMounted(!mounted);
  }, [coinCount, mounted, userCount]);

  return (
    <div style={{ backgroundColor: "#F6F8FB" }} id="user-overview">
      <Container>
        <Grid padded>
          <Grid.Column computer={5} tablet={8} mobile={16}>
            <Image src={OVERVIEW_MEMBER_IMAGE} centered />
            <div className="statistic">
              {formatAmount(userCount)}
              <small>+</small>
            </div>
            <div className="title">
              <span className="ml-1 font-size-11">
                {library.USER_OVERVIEW_MEMBER}
              </span>
            </div>
          </Grid.Column>
          <Grid.Column computer={6} tablet={8} mobile={16}>
            <Image src={OVERVIEW_ASSETS_IMAGE} centered />
            <div className="statistic">
              {formatAmount(coinCount)}
              <small>+</small>
            </div>
            <div className="title">
              <span className="ml-1 font-size-11">
                {library.USER_OVERVIEW_ASSETS}
              </span>
            </div>
          </Grid.Column>
          <Grid.Column computer={5} tablet={16} mobile={16}>
            <Image src={OVERVIEW_SOCIAL_IMAGE} centered />
            <div className="statistic">
              30.000<small>+</small>
            </div>
            <div className="title">
              <span className="ml-1 font-size-11">
                {library.USER_OVERVIEW_SOCIAL}
              </span>
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  );
}

export default Overview;
