import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Header, Image, Segment, Button } from "semantic-ui-react";
import { S3_URL } from "../../settings";
import { formatAmount, formatMoney, formatUSD } from "../../settings/format";

function MainBar({ fund, coin }) {
  const history = useHistory();
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  
  return (
    <>
      <div className="coin-logo">
        <Segment vertical basic>
          <Image
            src={`${S3_URL}/coins/${coin.replace(" ", "_")}.png`}
            centered
            onClick={() => history.push(`/portfolio/${coin}`)}
            style={{ cursor: "pointer" }}
          />
        </Segment>
        <Segment vertical basic>
          <div>
            <h1 style={{ color: fund.color }}>
              {formatAmount(fund.amount)}{" "}
              <small translate="no">{fund.coin}</small>
            </h1>
          </div>
          <div>
            <Header>
              ~{formatUSD(fund.usdAmount)}
              <small>$</small>
            </Header>
          </div>
        </Segment>
      </div>
      <Segment vertical basic className="main-bar">
        <Button
          onClick={() => history.push(`/deposit/${fund.coin}`)}
          className={
            history.location.pathname.includes("deposit") ? "active" : ""
          }
        >
          {library.DEPOSIT}
        </Button>
        <Button
          onClick={() => history.push(`/withdraw/${fund.coin}`)}
          className={
            history.location.pathname.includes("withdraw") ? "active" : ""
          }
        >
          {library.WITHDRAW}
        </Button>
      </Segment>
    </>
  );
}

export default MainBar;
