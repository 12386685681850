import React from "react";
import LazyLoad from "react-lazyload";
import { useSelector } from "react-redux";
import { Container, Grid, GridColumn, Segment, Tab } from "semantic-ui-react";
import DeviceList from "../../components/profile/DeviceList";
import Info from "../../components/profile/Info";
import LoginActivity from "../../components/profile/LoginActivity";
import Setting from "../../components/profile/Setting";
import "../../components/profile/Profile.scss";

function Profile() {
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const { DEVICE_LIST, LOGIN_ACTIVITY } = library;

  const panes = [
    {
      menuItem: DEVICE_LIST,
      render: () => <DeviceList />,
    },
    {
      menuItem: LOGIN_ACTIVITY,
      render: () => <LoginActivity />,
    },
  ];

  return (
    <Container textAlign="left" className="profile">
      <LazyLoad>
        {/* <Header as="h1">{PROFILE}</Header> */}
        <Info />
        <Grid stretched>
          <GridColumn computer={8} tablet={8} mobile={16}>
            <Segment>
              <Tab
                panes={panes}
                menu={{ secondary: true, pointing: true, inverted: true }}
              />
            </Segment>
          </GridColumn>
          <GridColumn computer={8} tablet={8} mobile={16}>
            <Setting />
          </GridColumn>
        </Grid>
      </LazyLoad>
    </Container>
  );
}

export default Profile;
