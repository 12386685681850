/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import MainBanner from "./MainBanner.js";
import ProductList from "./product-list.js";
import { Container, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import {
  ADVANTAGES_ICON_01,
  ADVANTAGES_ICON_02,
  ADVANTAGES_ICON_03,
  ADVANTAGES_ICON_04,
  ADVANTAGES_ICON_05,
  ADVANTAGES_ICON_06,
  APPLE_STORE_LOGO,
  GOOGLE_PLAY_LOGO,
  UI_APP_IMAGE,
} from "../../settings/links";
import Overview from "./Overview.js";
import LazyLoad from "react-lazyload";

const image = UI_APP_IMAGE;
const AppleStore = APPLE_STORE_LOGO;
const GooglePlay = GOOGLE_PLAY_LOGO;
const Clock = ADVANTAGES_ICON_01;
const Wallet = ADVANTAGES_ICON_02;
const Change = ADVANTAGES_ICON_03;
const Group = ADVANTAGES_ICON_04;
const Box = ADVANTAGES_ICON_05;
const Card = ADVANTAGES_ICON_06;
Home.propTypes = {};

function Home(props) {
  const isMobile = window.innerWidth < 800;

  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const {
    HOMEPAGE_STRONG_SUB_HEADER,
    HOMEPAGE_STRONG_HEADER,
    HOMEPAGE_STRONG_1,
    HOMEPAGE_STRONG_2,
    HOMEPAGE_STRONG_3,
    HOMEPAGE_STRONG_4,
    HOMEPAGE_STRONG_5,
    HOMEPAGE_STRONG_6,
    HOMEPAGE_APPLICATION_SUB_HEADER,
    HOMEPAGE_APPLICATION_HEADER,
  } = library;

  return (
    <div>
      <LazyLoad height={300} offset={-200}>
        <MainBanner />
      </LazyLoad>
      <LazyLoad height={300} offset={-200}>
        <Overview />
      </LazyLoad>
      <Container
        maxWidth={"md"}
        className={
          isMobile === true
            ? "p-0 color-primary text-center"
            : "p-0 color-primary mt-5 mb-5 pt-5 pb-5 text-center"
        }
      >
        <LazyLoad height={300} offset={-200}>
          <ProductList />
        </LazyLoad>
        <div
          className={
            " p-2 mt-3" + (isMobile === true ? "backgound-dark-20" : "")
          }
        >
          <LazyLoad height={300} offset={-200}>
            <span
              className={
                " font-weight-bolder d-block color-primary " +
                (isMobile === true
                  ? "font-size-15 mt-3 p-3 "
                  : "font-size-22 mt-100px")
              }
            >
              {HOMEPAGE_STRONG_HEADER}
            </span>
            <span
              className={
                " d-block color-primary " +
                (isMobile === true
                  ? "font-size-1 mt-3 mb-3"
                  : "font-size-11 mt-3 mb-3")
              }
              style={{ fontWeight: 900 }}
            >
              {HOMEPAGE_STRONG_SUB_HEADER}
            </span>
            <Grid container spacing={2} className="p-2">
              <Grid item xs={isMobile === false ? 6 : 12}>
                <div
                  className={
                    isMobile === true
                      ? "font-size-1 text-right rounded-lg pt-4 pl-5 pb-4 pr-5 d-flex align-items-center bg-white shadow-xxl"
                      : "font-size-11 min-height-90 border text-right rounded-lg border-primary border-width-1 pt-4 pl-5 pb-4 pr-5 d-flex align-items-center"
                  }
                >
                  <span className=" m-0">{HOMEPAGE_STRONG_1}</span>
                  <img src={Clock} className="width-40 ml-4" />
                </div>
              </Grid>
              <Grid item xs={isMobile === false ? 6 : 12}>
                <div
                  className={
                    isMobile === true
                      ? "font-size-1 text-right rounded-lg pt-4 pl-5 pb-4 pr-5 d-flex align-items-center bg-white shadow-xxl"
                      : "font-size-11 min-height-90 border text-right rounded-lg border-primary border-width-1 pt-4 pl-5 pb-4 pr-5 d-flex align-items-center"
                  }
                >
                  <img src={Wallet} className="width-40 mr-4" />
                  <span className="m-0 text-left">{HOMEPAGE_STRONG_2}</span>
                </div>
              </Grid>
              <Grid item xs={isMobile === false ? 6 : 12}>
                <div
                  className={
                    isMobile === true
                      ? "font-size-1 text-right rounded-lg pt-4 pl-5 pb-4 pr-5 d-flex align-items-center bg-white shadow-xxl"
                      : "font-size-11 min-height-90 border text-right rounded-lg border-primary border-width-1 pt-4 pl-5 pb-4 pr-5 d-flex align-items-center"
                  }
                >
                  <span className=" m-0">{HOMEPAGE_STRONG_3}</span>
                  <img src={Change} className="width-40 ml-4" />
                </div>
              </Grid>
              <Grid item xs={isMobile === false ? 6 : 12}>
                <div
                  className={
                    isMobile === true
                      ? "font-size-1 text-left rounded-lg pt-4 pl-5 pb-4 pr-5 d-flex align-items-center bg-white shadow-xxl"
                      : "font-size-11 min-height-90 border text-right rounded-lg border-primary border-width-1 pt-4 pl-5 pb-4 pr-5 d-flex align-items-center"
                  }
                >
                  <img src={Group} className="width-40 mr-4 " />
                  <span className="m-0">{HOMEPAGE_STRONG_4}</span>
                </div>
              </Grid>
              <Grid item xs={isMobile === false ? 6 : 12}>
                <div
                  className={
                    isMobile === true
                      ? "font-size-1 text-right rounded-lg pt-4 pl-5 pb-4 pr-5 d-flex align-items-center bg-white shadow-xxl"
                      : "font-size-11 min-height-90 border text-right rounded-lg border-primary border-width-1 pt-4 pl-5 pb-4 pr-5 d-flex align-items-center"
                  }
                >
                  <span className="font-size-11 m-0">{HOMEPAGE_STRONG_5}</span>
                  <img src={Box} className="width-40 ml-4" />
                </div>
              </Grid>
              <Grid item xs={isMobile === false ? 6 : 12}>
                <div
                  className={
                    isMobile === true
                      ? "font-size-1 text-left rounded-lg pt-4 pl-5 pb-4 pr-5 d-flex align-items-center bg-white shadow-xxl"
                      : "font-size-11 min-height-90 border text-left rounded-lg border-primary border-width-1 pt-4 pl-5 pb-4 pr-5 d-flex align-items-center"
                  }
                >
                  <img src={Card} className="width-40 mr-4" />
                  <span className=" m-0">{HOMEPAGE_STRONG_6}</span>
                </div>
              </Grid>
            </Grid>
          </LazyLoad>
        </div>
      </Container>
      <LazyLoad height={300} offset={-200}>
        <Container
          maxWidth={false}
          className={isMobile === true ? "p-0 mb-0" : "p-0 mb-5"}
        >
          <div className="height-400 w-100 background-gradient-primary">
            <Container maxWidth={"md"} className="p-0 h-100">
              {isMobile === true ? (
                <Grid container className="h-100">
                  <Grid item xs={12} className="h-100 overlay-bg-dark">
                    <img src={image} className="h-100" />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className="position-absolute mt-5 pt-5 pl-3 pr-3"
                  >
                    <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                      <div className="text-center">
                        <span className="font-size-13 font-weight-bolder d-block text-white">
                          {HOMEPAGE_APPLICATION_HEADER}
                        </span>
                        <span className="font-size-1 d-block text-white mt-3">
                          {HOMEPAGE_APPLICATION_SUB_HEADER}
                        </span>
                        <div className="d-flex mt-4 justify-content-center ">
                          <img
                            src={AppleStore}
                            className="height-40 mr-3 btn-hover"
                          ></img>
                          <img
                            src={GooglePlay}
                            className="height-40 btn-hover"
                          ></img>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              ) : (
                <Grid container className="h-100">
                  <Grid item xs={5} className="h-100">
                    <img src={image} className="h-100" />
                  </Grid>
                  <Grid item xs={7}>
                    <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                      <div className="text-center">
                        <span className="font-size-17 font-weight-bolder d-block text-white">
                          {HOMEPAGE_APPLICATION_HEADER}
                        </span>
                        <span className="font-size-11 d-block text-white mt-3">
                          {HOMEPAGE_APPLICATION_SUB_HEADER}
                        </span>
                        <div className="d-flex mt-4 justify-content-center">
                          <img
                            src={AppleStore}
                            className="height-50 mr-3 btn-hover"
                          ></img>
                          <img
                            src={GooglePlay}
                            className="height-50 btn-hover"
                          ></img>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              )}
            </Container>
          </div>
        </Container>
      </LazyLoad>
    </div>
  );
}

export default Home;
