import { formatAddress } from "./format";
export const isBeta = window.location.hostname === "beta.spwallet.org";
export const isProduction = window.location.hostname === "spwallet.org";
export const isDev = window.location.hostname === "spwallet.spdev.co";
export const S3_URL = "https://s3.spwallet.org";
export const API =
  isProduction || isBeta
    ? "https://api.spwallet.org"
    : "https://spwalletapi.spdev.co";
export const ACCESS_TOKEN_KEY = "CBt3gpbqeMdWPNG1";
export const DEVICE_KEY = "uU5tEUmAgvBWArsv";
export const SCOPES_KEY = "AhBcmvr1EkMdPnL5";
export const RETURN_LUS_SHOPPING_KEY =
  "17lVCSDKjBrgRsaQFqpnZYY45Ea1DqWDU3JtblCSgSsaYrOG15Y1wMaBczjRRtLs";
export const RETURN_SAFE_LUS_KEY =
  "XjBWmk6sBBUYdbb6smww1TMyLSC0YrEq8vCpcTSG1xcT7RDCpZyfDZzLedqNSaA8";

export let SAFE_LUS_URL = "http://localhost:3004";
if (isDev) {
  SAFE_LUS_URL = "https://lusv2.spdev.co";
}
if (isBeta) {
  SAFE_LUS_URL = "https://beta.safelus.finance";
}
if (isProduction) {
  SAFE_LUS_URL = "https://safelus.finance";
}

export function getLinkHash(data) {
  if (data) {
    const { type, network, txId } = data;
    if (txId) {
      if (type === "EXTERNAL") {
        let address = null;
        if (network === "TRC20" || network === "TRX") {
          address = `https://tronscan.org/#/transaction/${txId}`;
        }
        if (network === "ERC20" || network === "ETH") {
          address = `https://etherscan.io/tx/${txId}`;
        }
        if (network === "EOS") {
          address = `https://bloks.io/transaction/${txId}`;
        }
        if (network === "BTC") {
          address = `https://www.blockchain.com/btc/tx/${txId}`;
        }
        return (
          <a href={address} target="_blank" rel="noreferrer">
            {formatAddress(data.txId)}
          </a>
        );
      } else {
        return formatAddress(txId);
      }
    } else return null;
  } else {
    return null;
  }
}

console.log("Version: 0.0.2-beta")