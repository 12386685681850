import { RETURN_SAFE_LUS_KEY, SAFE_LUS_URL } from "../settings";
import { get, post } from "../utils/api";
import { getAccessToken, logout, logoutReturnTo } from "../utils/auth";

const LOGIN = "LOGIN";
const LOGOUT = "LOGOUT";
const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
const FETCH_DEVICE = "FETCH_DEVICE";
const FETCH_LOGIN_ACTIVITY = "FETCH_LOGIN_ACTIVITY";
const CHECK_GA_STATUS = "CHECK_GA_STATUS";
export const SET_INFO_VERIFICATION = "SET_INFO_VERIFICATION";
export const SET_LOCAL_IMAGE = "SET_LOCAL_IMAGE";
export const GET_GIFT_INFORMATION = "GET_GIFT_INFORMATION";
export const GET_GIFT_REWARDS = "GET_GIFT_REWARDS";
export const GET_STAKING_REFERRAL_REWARDS = "GET_STAKING_REFERRAL_REWARDS";
export const GET_AIRDROP = "GET_AIRDROP";

const initialState = {
  isLogin: getAccessToken() ? true : false,
  data: null,
  device: null,
  activity: null,
  gaEnable: false,
  verification: {
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    country: "vn",
    postalCode: "",
    city: "",
    idCode: "",
    idType: 0,
    frontPhoto: "",
    backPhoto: "",
    selfiePhoto: "",
  },
  verificationPhotos: {
    front: "",
    back: "",
    selfie: "",
  },
  gift: null,
  giftRewards: null,
  stakingReferralRewards: null,
  airdropRewards: null,
};

export const _getProfile = () => (dispatch) => {
  const checkReturnSafeLus =
    window.location.search.replace("?return=", "") === RETURN_SAFE_LUS_KEY;
  dispatch({ type: "SET_LOADING" });
  get(
    "/user-service/user/profile",
    (data) => {
      dispatch({ type: FETCH_USER_SUCCESS, payload: data });
    },
    (error) => {
      console.error(error);
      if (checkReturnSafeLus) {
        logoutReturnTo();
      } else {
        logout();
      }
      dispatch({ type: LOGOUT });
    }
  );
};

export const _getDeviceList = () => (dispatch) => {
  get(
    "/user-service/device",
    (data) => dispatch({ type: FETCH_DEVICE, payload: data }),
    (error) => console.error(error)
  );
};

export const _getLoginActivity = () => (dispatch) => {
  post(
    "/user-service/login-activity",
    {
      page: 1,
      pageSize: 10,
    },
    (data) => dispatch({ type: FETCH_LOGIN_ACTIVITY, payload: data }),
    (error) => console.error(error)
  );
};

export const _checkEnableGA = () => (dispatch) => {
  get(
    `/user-service/user/security`,
    (data) => {
      dispatch({ type: CHECK_GA_STATUS, payload: data.gaEnable });
    },
    (err) => console.error(err)
  );
};

export const _getGift = () => (dispatch) => {
  get(
    `/staking-service/gift/single`,
    (data) => dispatch({ type: GET_GIFT_INFORMATION, payload: data }),
    (error) => console.error(error)
  );
};

export const _getGiftRewards =
  (page = 1, pageSize = 10) =>
  (dispatch) => {
    post(
      `/fund-service/transaction/list`,
      {
        page,
        pageSize,
        type: "GIFT",
      },
      (data) => {
        dispatch({ type: GET_GIFT_REWARDS, payload: data });
      },
      (error) => console.error(error)
    );
  };

export const _getStakingReferralRewards =
  (page = 1, pageSize = 10) =>
  (dispatch) => {
    post(
      `/fund-service/transaction/list`,
      {
        page,
        pageSize,
        type: "STAKING_REFERRAL",
      },
      (data) => {
        dispatch({ type: GET_STAKING_REFERRAL_REWARDS, payload: data });
      },
      (error) => console.error(error)
    );
  };

export const _getAirdrop =
  (page = 1, pageSize = 10) =>
  (dispatch) => {
    post(
      `/fund-service/transaction/list`,
      {
        page,
        pageSize,
        type: "AIRDROP",
      },
      (data) => {
        dispatch({ type: GET_AIRDROP, payload: data });
      },
      (error) => console.error(error)
    );
  };

export const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, isLogin: true };
    case LOGOUT:
      return { ...state, isLogin: false };
    case FETCH_DEVICE:
      return { ...state, device: action.payload };
    case FETCH_LOGIN_ACTIVITY:
      return { ...state, activity: action.payload };
    case FETCH_USER_SUCCESS:
      return { ...state, data: action.payload };
    case CHECK_GA_STATUS:
      return { ...state, gaEnable: action.payload };
    case SET_LOCAL_IMAGE:
      return {
        ...state,
        verificationPhotos: Object.assign(
          state.verificationPhotos,
          action.payload
        ),
      };
    case SET_INFO_VERIFICATION:
      return {
        ...state,
        verification: Object.assign(state.verification, action.payload),
      };
    case GET_GIFT_INFORMATION:
      return { ...state, gift: action.payload };
    case GET_GIFT_REWARDS:
      return { ...state, giftRewards: action.payload };
    case GET_STAKING_REFERRAL_REWARDS:
      return { ...state, stakingReferralRewards: action.payload };
    case GET_AIRDROP:
      return { ...state, airdropRewards: action.payload };
    default:
      return { ...state };
  }
};
