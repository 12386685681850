import React from "react";
import { Grid, Header, Icon, Image, Segment } from "semantic-ui-react";
import { S3_URL } from "../../settings";

const packages = [
  {
    level: "MEMBER",
    condition: "$100 ~ $300",
    profit: "1% - 2% Profit",
    commission: "NO COMMISSION",
    shipping: "Free shipping",
    color: "#d5d5d5",
  },
  {
    level: "AGENCY",
    condition: "$301 ~ $1,000",
    profit: "2% - 3% Profit",
    commission: "100% COMMISSION",
    shipping: "Free shipping",
    color: "#fffff",
  },
  {
    level: "PARTNER",
    condition: "$1,001 ~ $10,000",
    profit: "3% - 4% Profit",
    commission: "100% COMMISSION",
    shipping: "Free shipping",
    color: "#56C1FF",
  },
  {
    level: "VIP 1",
    condition: "$10,001 ~ $50,000",
    profit: "4% - 5% Profit",
    commission: "100% COMMISSION",
    shipping: "Free shipping",
    color: "#FF968D",
  },
  {
    level: "VIP 2",
    condition: "$50,001 ~ ",
    profit: "5% - 6% Profit",
    commission: "100% COMMISSION",
    shipping: "Free shipping",
    color: "#D0BA95",
  },
];

function LusLevelDetail({ onClose }) {
  return (
    <Segment id="level-detail">
      <Icon name="x" link onClick={onClose} />
      <Segment vertical>
        <Header>Account level achievements </Header>
        <br />
        <Grid stretched style={{ justifyContent: "center" }}>
          {packages.map((item, index) => (
            <Grid.Column
              computer={3}
              tablet={5}
              mobile={16}
              className="item"
              key={index}
              verticalAlign="middle"
              style={{ padding: 0 }}
            >
              <Image
                src={`${S3_URL}/spwallet/level-${item.level
                  .replace(" ", "-")
                  .toLowerCase()}.png`}
              />
              <div className="info">
                <div className="container">
                  <p
                    style={{
                      backgroundColor: item.color,
                    }}
                  >
                    {item.level}
                  </p>
                  <p>{item.condition}</p>
                  <p>{item.profit}</p>
                  <p>{item.commission}</p>
                  <p>{item.shipping}</p>
                </div>
              </div>
            </Grid.Column>
          ))}
        </Grid>
      </Segment>
    </Segment>
  );
}

export default LusLevelDetail;
