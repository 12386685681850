import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Confirm,
  Divider,
  Header,
  Icon,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import { SET_MODAL_CONTENT } from "../../redux/settingReducer";
import { _getFundList, _getWithdrawHistory } from "../../redux/walletReducer";
import { _delete } from "../../utils/api";
import { formatAmount, formatTime } from "../../settings/format";
import TransactionHash from "../others/TransactionHash";
import WalletAddress from "../others/WalletAddress";
import { Drawer } from "@material-ui/core";

function WithdrawHistory() {
  const { wallet, setting } = useSelector((state) => state);
  const { library } = setting;
  const { withdrawHistory } = wallet;
  const dispatch = useDispatch();
  const { coin } = useParams();
  const [activePage, setActivePage] = useState(1);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [id, setId] = useState(null);
  const [mounted, setMounter] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (mounted) {
      dispatch(_getWithdrawHistory({ coin }));
    }
    return () => setMounter(!mounted);
  }, [coin, dispatch, mounted]);

  const _handleCancel = () => {
    setOpenConfirm(false);
    _delete(`/fund-service/withdraw/${id}`, {}, (data) => {
      dispatch({
        type: SET_MODAL_CONTENT,
        payload: {
          content: "CANCEL_WITHDRAW",
          callback: () => {
            dispatch(_getWithdrawHistory({ page: activePage, coin }));
            dispatch(_getFundList());
          },
        },
      });
    });
  };

  const {
    TIME,
    TYPE,
    ADDRESS,
    AMOUNT,
    STATUS,
    CANCEL,
    NO_RECORDS_FOUND,
    WITHDRAW_HISTORY,
  } = library;

  console.log(selectedItem);

  return (
    withdrawHistory && (
      <>
        <Segment textAlign="center">
          <Header textAlign="left">{WITHDRAW_HISTORY}</Header>
          <Segment
            className="spwallet-history"
            textAlign="center"
            vertical
            basic
          >
            <Table
              unstackable
              basic="very"
              compact="very"
              celled
              inverted
              singleLine
              selectable
              textAlign="left"
            >
              <Table.Header>
                <Table.Row className="hide-mobile">
                  <Table.HeaderCell>ID</Table.HeaderCell>
                  <Table.HeaderCell>{TIME}</Table.HeaderCell>
                  <Table.HeaderCell>{TYPE}</Table.HeaderCell>
                  <Table.HeaderCell>{ADDRESS}</Table.HeaderCell>
                  <Table.HeaderCell>Hash</Table.HeaderCell>
                  <Table.HeaderCell>{AMOUNT}</Table.HeaderCell>
                  <Table.HeaderCell>{STATUS}</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {withdrawHistory.items.length > 0 ? (
                  withdrawHistory.items.map((h, index) => (
                    <React.Fragment key={index}>
                      <Table.Row className="hide-mobile">
                        <Table.Cell>#{h.id}</Table.Cell>
                        <Table.Cell>{formatTime(h.time)}</Table.Cell>
                        <Table.Cell>{library[h.type]}</Table.Cell>
                        <Table.Cell>
                          <WalletAddress data={h} />
                        </Table.Cell>
                        <Table.Cell>
                          <TransactionHash data={h} />
                        </Table.Cell>
                        <Table.Cell>
                          {formatAmount(h.amount)} {h.coin}
                        </Table.Cell>
                        <Table.Cell>{library[h.status]}</Table.Cell>
                        <Table.Cell>
                          {(h.status === "WAITING_CONFIRM" ||
                            h.status === "PENDING") && (
                            <Link
                              to="#"
                              onClick={() => {
                                setId(h.id);
                                setOpenConfirm(true);
                              }}
                            >
                              {CANCEL}
                            </Link>
                          )}
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row
                        className="show-mobile"
                        colSpan={8}
                        style={{ padding: 5 }}
                        onClick={() => setSelectedItem(h)}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>#{h.id}</span>
                          <span>
                            {formatAmount(h.amount)} {coin}
                          </span>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <small>{formatTime(h.time)}</small>
                          <small>{library[h.status]}</small>
                        </div>
                      </Table.Row>
                    </React.Fragment>
                  ))
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan={8}>
                      <Segment basic textAlign="center">
                        {NO_RECORDS_FOUND}
                      </Segment>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Segment>
          {withdrawHistory.pageCount > 1 && (
            <Pagination
              totalPages={withdrawHistory.pageCount}
              activePage={withdrawHistory.page}
              onPageChange={(e, { activePage }) => {
                setActivePage(activePage);
                dispatch(_getWithdrawHistory({ coin, page: activePage }));
              }}
              pointing
              secondary
              inverted
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              siblingRange={1}
              boundaryRange={0}
            />
          )}
          <Confirm
            open={openConfirm}
            onCancel={() => setOpenConfirm(false)}
            onConfirm={_handleCancel}
            content={library.ARE_YOU_SURE}
            confirmButton={<Button className="spwallet-button">OK</Button>}
            cancelButton={library.CANCEL}
            size="mini"
            className="spwallet-animation"
          />
        </Segment>
        <Drawer
          anchor={"left"}
          open={selectedItem}
          className={"custom-modal-vk"}
        >
          {selectedItem && (
            <Segment
              style={{
                backgroundColor: "var(--light-blue)",
                maxWidth: 450,
                color: "#fff",
                minWidth: 300,
              }}
            >
              <Segment vertical>
                <Header>
                  #{selectedItem.id} -{" "}
                  <small>{library[selectedItem.status]}</small>
                </Header>
                <Icon
                  name="x"
                  style={{
                    position: "absolute",
                    right: 0,
                    top: 0,
                    cursor: "pointer",
                  }}
                  size="large"
                  onClick={() => setSelectedItem(null)}
                  inverted
                  color="grey"
                />
              </Segment>
              <Segment
                vertical
                style={{
                  wordBreak: "break-all",
                }}
              >
                <div style={{ opacity: 0.6 }}>{library.AMOUNT}</div>
                <div style={{ textAlign: "right" }}>
                  {formatAmount(selectedItem.amount)} {selectedItem.coin}
                </div>
                <div style={{ opacity: 0.6 }}>{library.FEE}</div>
                <div style={{ textAlign: "right" }}>
                  {formatAmount(selectedItem.fee)} {selectedItem.coin}
                </div>
                <div style={{ opacity: 0.6 }}>{library.ADDRESS}</div>
                <div style={{ textAlign: "right" }}>{selectedItem.address}</div>
                {selectedItem.addressTag && (
                  <>
                    <div style={{ opacity: 0.6 }}>Memo</div>
                    <div style={{ textAlign: "right" }}>
                      {selectedItem.addressTag}
                    </div>
                  </>
                )}
                <div style={{ opacity: 0.6 }}>{library.TYPE}</div>
                <div style={{ textAlign: "right" }}>
                  {" "}
                  {library[selectedItem.type]} - {selectedItem.network}
                </div>
                {selectedItem.txId && (
                  <>
                    <div style={{ opacity: 0.6 }}>Hash</div>
                    <div style={{ textAlign: "right" }}>
                      {selectedItem.txId}
                    </div>
                  </>
                )}
                <div style={{ opacity: 0.6 }}>{library.TIME}</div>
                <div style={{ textAlign: "right" }}>
                  {formatTime(selectedItem.time)}
                </div>
                <Divider />
                {(selectedItem.status === "WAITING_CONFIRM" ||
                  selectedItem.status === "PENDING") && (
                  <Button
                    to="#"
                    onClick={() => {
                      setId(selectedItem.id);
                      setOpenConfirm(true);
                      setSelectedItem(null);
                    }}
                    style={{ float: "right" }}
                    color="google plus"
                  >
                    {CANCEL}
                  </Button>
                )}
              </Segment>
            </Segment>
          )}
        </Drawer>
      </>
    )
  );
}

export default WithdrawHistory;
