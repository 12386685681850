import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  Container,
  Grid,
  Header,
  Icon,
  Image,
  Progress,
  Segment,
  Label,
} from "semantic-ui-react";
import "../../components/easybuy/EasyBuy.scss";
import EasyBuyForm from "../../components/easybuy/EasyBuyForm";
import { post } from "../../utils/api";
import { S3_URL } from "../../settings";
import { useSelector } from "react-redux";
import { formatAmount, formatStakingTime } from "../../settings/format";
import LazyLoad from "react-lazyload";
import {
  ICON_FACEBOOK,
  ICON_TWITTER,
  ICON_TELEGRAM,
} from "../../settings/links";
import CustomMenu from "../../components/others/CustomMenu";

const facebook = ICON_FACEBOOK;
const twitter = ICON_TWITTER;
const telegram = ICON_TELEGRAM;

function EasyBuyItem() {
  const { token } = useParams();
  const [data, setData] = useState(null);
  const { setting, user } = useSelector((state) => state);
  const { library } = setting;
  const history = useHistory();
  const { isLogin } = user;

  useEffect(() => {
    if (!data) {
      post(
        "/presale-service/product/single",
        { id: token },
        (data) => setData(data),
        () => history.push("/easy-buy")
      );
    }
  }, [data, history, token]);

  return (
    <>
      {!isLogin && <CustomMenu />}
      {data && (
        <Container className={`easy-buy-item ${!isLogin && "no-login"}`}>
          <LazyLoad>
            <Segment textAlign="left" padded>
              <div style={{ display: "flex" }}>
                <Image
                  src={`${S3_URL}/coins/${data.base}.png`}
                  size="mini"
                  verticalAlign="middle"
                  id="token-logo"
                />
                <div>
                  <Header style={{ margin: 0 }}>
                    {data.name} <small>({data.base})</small>
                  </Header>
                  <p style={{ opacity: 0.9 }}>{data.title}</p>
                </div>
                <Label style={{ alignSelf: "flex-start" }}>
                  {data.isOpen ? library.OPEN : library.CLOSED}
                </Label>
                {isLogin && (
                  <Link
                    className="easy-buy-history-link"
                    to="/easy-buy/history"
                  >
                    <Icon name="file alternate outline" size="big"/>
                  </Link>
                )}
              </div>
              <Segment vertical basic>
                <Grid stretched relaxed="very">
                  <Grid.Column computer={8} tablet={8} mobile={16}>
                    <Segment vertical basic>
                      <Segment
                        style={{
                          backgroundImage: `url(${data.coverPhoto})`,
                        }}
                        className="cover-image"
                      ></Segment>
                      <div className="social-link">
                        <a
                          target="_blank"
                          rel="noReferrer"
                          href={data.redirects[0].link}
                        >
                          <Icon name="globe" /> {library.WEBSITE}
                        </a>
                        <a
                          target="_blank"
                          rel="noReferrer"
                          href={data.redirects[1].link}
                        >
                          <Icon name="file alternate outline" />{" "}
                          {library.WHITEPAPER}
                        </a>
                        <a
                          target="_blank"
                          rel="noReferrer"
                          href={data.redirects[2].link}
                        >
                          <Image src={facebook} />
                        </a>
                        <a
                          target="_blank"
                          rel="noReferrer"
                          href={data.redirects[3].link}
                        >
                          <Image src={twitter} />
                        </a>
                        <a
                          target="_blank"
                          rel="noReferrer"
                          href={data.redirects[4].link}
                        >
                          <Image src={telegram} />
                        </a>
                      </div>
                    </Segment>
                    <div>
                      <p>
                        <span>{library.PRICE}</span>
                        <span>
                          1 {data.base} = {formatAmount(data.price)}{" "}
                          {data.quote}
                        </span>
                      </p>
                      <p>
                        <span>{library.SESSION_SUPPLY}</span>
                        <span>
                          {formatAmount(data.supply)} {data.base}
                        </span>
                      </p>
                      <p>
                        <span>{library.SOLD}</span>
                        <span>
                          {formatAmount(data.sold)} {data.base} (
                          {parseInt((data.sold / data.supply) * 100)}%)
                        </span>
                      </p>
                      <Progress
                        percent={parseInt((data.sold / data.supply) * 100)}
                        size="tiny"
                      />
                      <p>
                        <span>{library.START_DATE}</span>
                        <span>{formatStakingTime(data.startDate)}</span>
                      </p>
                      <p>
                        <span>{library.END_DATE}</span>
                        <span>{formatStakingTime(data.endDate)}</span>
                      </p>
                    </div>
                  </Grid.Column>
                  <Grid.Column computer={8} tablet={8} mobile={16}>
                    <EasyBuyForm data={data} />
                  </Grid.Column>
                </Grid>
              </Segment>
              <Segment vertical basic textAlign="left">
                <Header>{library.PROJECT_INTRODUCTION}</Header>
                {data.contentPhotos.map((item, index) => (
                  <Image src={item} style={{ width: "100%" }} key={index} />
                ))}
              </Segment>
            </Segment>
          </LazyLoad>
        </Container>
      )}
    </>
  );
}

export default EasyBuyItem;
