import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "semantic-ui-react";
import { SET_MODAL_CONTENT } from "../../redux/settingReducer";

function CustomModal() {
  const { setting } = useSelector((state) => state);
  const { library, modalContent } = setting;
  const dispatch = useDispatch();

  const _handleClick = () => {
    modalContent.callback();
    dispatch({ type: SET_MODAL_CONTENT, payload: null });
  };

  return (
    modalContent && (
      <Modal
        open={modalContent !== null}
        size="mini"
        className="spwallet-modal spwallet-animation"
      >
        <Modal.Header>{library.NOTIFICATION}</Modal.Header>
        <Modal.Content>{library[modalContent.content]}</Modal.Content>
        <Modal.Actions>
          <Button onClick={_handleClick} className="spwallet-button">
            OK
          </Button>
        </Modal.Actions>
      </Modal>
    )
  );
}

export default CustomModal;
