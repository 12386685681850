import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, Segment, Table } from "semantic-ui-react";
import { _getListF1Affiliate } from "../../redux/LusReducer";
import { RANK_LEVEL } from "../../settings/constant";
import { formatUSD } from "../../settings/format";

function LusF1Affiliate() {
  const { setting, lus } = useSelector((state) => state);
  const { library } = setting;
  const { ListF1Affiliate } = lus;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(_getListF1Affiliate({ pageSize: 10 }));
  }, [dispatch]);

  const { NO_RECORDS_FOUND } = library;

  return (
    ListF1Affiliate && (
      <>
        <Table
          unstackable
          basic="very"
          compact="very"
          celled
          textAlign="left"
          singleLine
          selectable
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              <Table.HeaderCell>Level</Table.HeaderCell>
              <Table.HeaderCell>Ranking</Table.HeaderCell>
              <Table.HeaderCell>Staked</Table.HeaderCell>
              <Table.HeaderCell>Total revenue</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {ListF1Affiliate.items.length > 0 ? (
              ListF1Affiliate.items.map((item, index) => (
                <Table.Row key={index}>
                  <Table.Cell className="id">#{item.userId}</Table.Cell>
                  <Table.Cell>{item.email}</Table.Cell>
                  <Table.Cell>{RANK_LEVEL[item.memberLevel]}</Table.Cell>
                  <Table.Cell>{RANK_LEVEL[item.rankingLevel]}</Table.Cell>
                  <Table.Cell>${formatUSD(item.staked)}</Table.Cell>
                  <Table.Cell>${formatUSD(item.totalRevenue)}</Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={6}>
                  <Segment basic textAlign="center">
                    {NO_RECORDS_FOUND}
                  </Segment>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        {ListF1Affiliate.pageCount > 1 && (
          <Segment vertical textAlign="center">
            <Pagination
              activePage={ListF1Affiliate.page}
              totalPages={ListF1Affiliate.pageCount}
              onPageChange={(e, { activePage }) =>
                dispatch(
                  _getListF1Affiliate({ page: activePage, pageSize: 10 })
                )
              }
              secondary
              ellipsisItem={null}
              firstItem={null}
              lastItem={null}
              siblingRange={1}
              boundaryRange={0}
            />
          </Segment>
        )}
      </>
    )
  );
}

export default LusF1Affiliate;
