import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Dimmer,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
  Button,
  Checkbox,
} from "semantic-ui-react";
import { S3_URL } from "../../settings";
import { LUSM_SYMBOL, LUS_SYMBOL, M_USD } from "../../constant/CoinConstant";
import {
  deleteText,
  formatAmount,
  formatStakingTime,
} from "../../settings/format";
import { post } from "../../utils/api";
import AmountInput from "../others/AmountInput";
import { ArrowDownward, ArrowRightAlt } from "@material-ui/icons";
import { en } from "../../languages/en";
import { NEW_PRICE_LUSM } from "../../constant/PriceConstants";

function LusStakingForm({
  data,
  onClose,
  showStakingPolicy,
  _onComplete,
  totalStaked,
}) {
  const { setting } = useSelector((state) => state);
  // const { library } = setting;
  const library = en;
  const [amount, setAmount] = useState(0);
  const [checked, setChecked] = useState(false);
  const [lusChecked, setLusChecked] = useState(false);
  const [now] = useState(Date.now());
  const [handling, setHandling] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(data.list[0]);

  const _changeAmount = (e) => {
    let { value } = e.target;
    if (value.trim() !== "") {
      value = parseInt(deleteText(value));
      if (parseFloat(value) > currentProduct.maxAmount) {
        setAmount(currentProduct.maxAmount);
      } else {
        setAmount(value);
      }
    } else {
      setAmount(0);
    }
  };

  const _handleStaking = () => {
    let _error = null;
    if (!amount) {
      _error = "PLEASE_ENTER_AMOUNT_FOR_STAKING";
    } else if (parseFloat(amount) < currentProduct.minAmount) {
      _error = "AMOUNT_TOO_LOW";
    } else if (parseFloat(amount) > currentProduct.maxAmount) {
      _error = "AMOUNT_TOO_LARGE";
    } else if (!checked || !lusChecked) {
      _error = "PLEASER_CHECK_TOS";
    } else {
      _error = null;
    }
    if (_error) {
      toast(
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <div style={{ color: "#fe644e" }}>
            <Icon name="x" size="large" />
          </div>
          <div>
            {en[_error]}. {en.PLEASE_TRY_AGAIN}.
          </div>
        </div>
      );
    } else {
      setOpenConfirm(true);
    }
  };

  const _postStaking = () => {
    setHandling(true);
    post(
      `/staking-service/lus-staking/staking`,
      {
        productId: currentProduct.id,
        amount: amount,
      },
      () => {
        toast(
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <div style={{ color: "var(--green)" }}>
              <Icon name="checkmark" size="large" />
            </div>
            <div>
              <label className="title">{library.CREATE_STAKING_SUCCESS}.</label>
            </div>
          </div>
        );
        setHandling(false);
        setOpenConfirm(false);
        _onComplete();
      },
      (error) => {
        setOpenConfirm(false);
        setHandling(false);
        onClose();
        toast(
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <div style={{ color: "#fe644e" }}>
              <Icon name="x" size="large" />
            </div>
            <div>
              {library[error.code]}. {library.PLEASE_TRY_AGAIN}.
            </div>
          </div>
        );
      }
    );
  };

  const level = data.lusRewardLevels.filter(
    (element) =>
      parseFloat(deleteText(amount)) > element.minAmount &&
      (parseFloat(deleteText(amount)) <= element.maxAmount ||
        element.maxAmount === 0)
  );

  const totalLevel = data.lusRewardLevels.filter(
    (element) =>
      parseFloat(deleteText(amount)) + totalStaked > element.minAmount &&
      (parseFloat(deleteText(amount)) + totalStaked <= element.maxAmount ||
        element.maxAmount === 0)
  );

  let levelText = "BASIC";
  if (totalLevel.length > 0) {
    switch (totalLevel[0].level) {
      case 1:
        levelText = "MEMBER";
        break;
      case 2:
        levelText = "AGENCY";
        break;
      case 3:
        levelText = "PARTNER";
        break;
      case 4:
        levelText = "VIP 1";
        break;
      case 5:
        levelText = "VIP 2";
        break;
      default:
        levelText = "BASIC";
        break;
    }
  }

  return (
    <>
      <Segment className="staking-detail lus-staking" textAlign="left">
        <Icon name="x" link onClick={onClose} />
        <Segment vertical>
          <Grid>
            <Grid.Column computer={9} tablet={9} mobile={16}>
              <Header>
                {library.LOCKED} {library.STAKING}
              </Header>
              <Segment vertical basic className="name">
                <Image src={`${S3_URL}/coins/${currentProduct.base}.png`} />
                <Header>{currentProduct.base}</Header>
                <ArrowRightAlt className="down" />
                <Header>{LUSM_SYMBOL}</Header>
                <Image
                  src={`${S3_URL}/coins/${LUSM_SYMBOL}.png`}
                  style={{ marginLeft: "0.5em" }}
                />
              </Segment>
              <Segment basic vertical>
                <p>Type</p>
                <div className="type">{currentProduct.type.toUpperCase()}</div>
              </Segment>
              <p>Duration (days)</p>
              <Segment vertical basic className="duration">
                {data.list.map((item, index) => (
                  <div
                    className={item.id === currentProduct.id ? "active" : ""}
                    key={index}
                    onClick={() => setCurrentProduct(item)}
                  >
                    {item.duration}
                  </div>
                ))}
              </Segment>
              <Segment vertical basic>
                <p>Amount</p>
                <AmountInput
                  placeholder={library.PLEASE_ENTER_AMOUNT_FOR_STAKING}
                  fluid
                  onChange={_changeAmount}
                  value={amount}
                  action={
                    <div className="">
                      <div>{currentProduct.base}</div>
                      <div onClick={() => setAmount(currentProduct.maxAmount)}>
                        MAX
                      </div>
                    </div>
                  }
                  className="amount-group"
                />
              </Segment>
              <Segment basic vertical>
                <p>{library.LOCK_AMOUNT_LIMITATION}</p>
                <div>
                  <span style={{ opacity: 0.6 }}>Minimum: </span>
                  {formatAmount(currentProduct.minAmount)} {currentProduct.base}{" "}
                  ~ <span style={{ opacity: 0.6 }}>Maximum: </span>
                  {formatAmount(currentProduct.maxAmount)} {currentProduct.base}
                </div>
              </Segment>
            </Grid.Column>
            <Grid.Column computer={7} tablet={7} mobile={16}>
              <Header>{library.SUMMARY}</Header>
              <Segment vertical basic className="summary">
                <div>
                  <p>Start date:</p>
                  <p>{formatStakingTime(now)}</p>
                </div>
                <div>
                  <p>{library.VALUE_DATE}:</p>
                  <p>{formatStakingTime(now)}</p>
                </div>
                <div>
                  <p>{library.INTEREST_PERIOD}:</p>
                  <p>{currentProduct.duration} days</p>
                </div>
                <div>
                  <p>{library.INTEREST_END_DATE}:</p>
                  <p>
                    {formatStakingTime(
                      now + (currentProduct.duration + 1) * 8.64e7
                    )}
                  </p>
                </div>
                <div>
                  <p>{library.REDEMPTION_PERIOD}:</p>
                  <p>{currentProduct.redeemPeriod} days</p>
                </div>
                <div>
                  <p>Profit per Month:</p>
                  <p>{level[0] ? level[0].interestRate : 0}%</p>
                </div>
                <div>
                  <p>{library.ESTIMATE_INTEREST}:</p>
                  <p>
                    {formatAmount(
                      ((parseFloat(deleteText(amount)) *
                        ((level[0] ? level[0].interestRate : 0) / 100)) /
                        30) *
                        currentProduct.duration
                    )}{" "}
                    {M_USD}
                  </p>
                </div>
                <div>
                  <p>Receive:</p>
                  <p>
                    {formatAmount(
                      parseFloat(deleteText(amount) / NEW_PRICE_LUSM)
                    )}{" "}
                    {LUSM_SYMBOL}
                  </p>
                </div>
                <div>
                  <p>Level up:</p>
                  <p>
                    {levelText}{" "}
                    {levelText !== "BASIC" && (
                      <Image
                        src={`${S3_URL}/spwallet/level-${levelText
                          .replace(" ", "-")
                          .toLowerCase()}.png`}
                        style={{ width: 30, height: 30, marginLeft: "0.5em" }}
                        inline
                      />
                    )}
                  </p>
                </div>
                <Segment basic vertical textAlign="left" className="policy">
                  <Checkbox
                    checked={checked}
                    onChange={(e, { checked }) => setChecked(checked)}
                    disabled={handling || currentProduct.status !== "OPEN"}
                  />
                  <div style={{ color: "#000", marginLeft: 10 }}>
                    I have read and agree to the{" "}
                    <a href="/terms-of-service" target="_blank">
                      SPWallet's Terms
                    </a>{" "}
                    and <a href="/lus/partner">Staking policy</a>.
                  </div>
                </Segment>
                <Segment basic vertical textAlign="left" className="policy">
                  <Checkbox
                    checked={lusChecked}
                    onChange={(e, { checked }) => setLusChecked(checked)}
                    disabled={handling || currentProduct.status !== "OPEN"}
                  />
                  <div style={{ color: "#000", marginLeft: 10 }}>
                    I have read and agree to the{" "}
                    <a href="/terms-of-service" target="_blank">
                      Luxury outlet street's Term
                    </a>
                  </div>
                </Segment>
              </Segment>
              <Button
                fluid
                onClick={_handleStaking}
                disabled={handling || currentProduct.status !== "OPEN"}
              >
                {library.CONFIRM_PURCHASE}
              </Button>
            </Grid.Column>
          </Grid>
        </Segment>
      </Segment>
      <Dimmer active={openConfirm} page>
        <Segment className="staking-detail custom lus-staking">
          <Icon
            name="x"
            link
            onClick={() => {
              setOpenConfirm(false);
              onClose();
            }}
          />
          <Header>Confirming for staking</Header>
          <Segment vertical basic className="summary">
            <div>
              <p>Amount:</p>
              <p>
                {formatAmount(amount)} {data.base}
              </p>
            </div>
            <div>
              <p>Start date:</p>
              <p>{formatStakingTime(now)}</p>
            </div>
            <div>
              <p>{library.VALUE_DATE}:</p>
              <p>{formatStakingTime(now)}</p>
            </div>
            <div>
              <p>{library.INTEREST_PERIOD}:</p>
              <p>{currentProduct.duration} days</p>
            </div>
            <div>
              <p>{library.INTEREST_END_DATE}:</p>
              <p>
                {formatStakingTime(
                  now + (currentProduct.duration + 1) * 8.64e7
                )}
              </p>
            </div>
            <div>
              <p>{library.REDEMPTION_PERIOD}:</p>
              <p>{currentProduct.redeemPeriod} days</p>
            </div>
            <div>
              <p>Profit per Month:</p>
              <p>{level[0] ? level[0].interestRate : 0}%</p>
            </div>
            <div>
              <p>{library.ESTIMATE_INTEREST}:</p>
              <p>
                {formatAmount(
                  ((parseFloat(deleteText(amount)) *
                    ((level[0] ? level[0].interestRate : 0) / 100)) /
                    30) *
                    currentProduct.duration
                )}{" "}
                {M_USD}
              </p>
            </div>
            <div>
              <p>Receive:</p>
              <p>
                {formatAmount(parseFloat(deleteText(amount) / NEW_PRICE_LUSM))}{" "}
                {LUSM_SYMBOL}
              </p>
            </div>
            <div>
              <p>Level up:</p>
              <p>
                {levelText}{" "}
                {levelText !== "BASIC" && (
                  <Image
                    src={`${S3_URL}/spwallet/level-${levelText
                      .replace(" ", "-")
                      .toLowerCase()}.png`}
                    style={{ width: 30, height: 30, marginLeft: "0.5em" }}
                    inline
                  />
                )}
              </p>
            </div>
          </Segment>
          <Button
            fluid
            onClick={_postStaking}
            disabled={handling || currentProduct.status !== "OPEN"}
          >
            Confirm
          </Button>
        </Segment>
      </Dimmer>
    </>
  );
}

export default LusStakingForm;
