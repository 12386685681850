export const ICON_FACEBOOK =
  "https://s3.spexchange.io/spwallet/icon-facebook.png";
export const ICON_TWITTER =
  "https://s3.spexchange.io/spwallet/icon-twitter.png";
export const ICON_TELEGRAM =
  "https://s3.spexchange.io/spwallet/icon-telegram.png";
export const ICON_YOUTUBE =
  "https://s3.spexchange.io/spwallet/icon-youtube.png";
export const UI_APP_IMAGE = "https://s3.spexchange.io/spwallet/UI-APP.png";
export const OVERVIEW_MEMBER_IMAGE =
  "https://s3.spexchange.io/spwallet/overview_members.png";
export const OVERVIEW_ASSETS_IMAGE =
  "https://s3.spexchange.io/spwallet/overview_assets.png";
export const OVERVIEW_SOCIAL_IMAGE =
  "https://s3.spexchange.io/spwallet/overview_social.png";
export const FOOTER_LOGO = "https://s3.spexchange.io/spwallet/logo_footer.png";
export const SPEXCHANGE_LOGO =
  "https://s3.spexchange.io/spwallet/spexchange-logo.png";
export const BANNER = "https://s3.spexchange.io/spwallet/banner.png";
export const BANNER_LOGO =
  "https://s3.spexchange.io/spwallet/spwallet-banner-logo.png";
export const MENU_LOGO = "https://s3.spexchange.io/spwallet/menu-logo.png";
export const APPLE_STORE_LOGO =
  "https://s3.spexchange.io/spwallet/apple-store.png";
export const GOOGLE_PLAY_LOGO =
  "https://s3.spexchange.io/spwallet/google-play.png";
export const ADVANTAGES_ICON_01 =
  "https://s3.spexchange.io/spwallet/advantages_icon_01.png";
export const ADVANTAGES_ICON_02 =
  "https://s3.spexchange.io/spwallet/advantages_icon_02.png";
export const ADVANTAGES_ICON_03 =
  "https://s3.spexchange.io/spwallet/advantages_icon_03.png";
export const ADVANTAGES_ICON_04 =
  "https://s3.spexchange.io/spwallet/advantages_icon_04.png";
export const ADVANTAGES_ICON_05 =
  "https://s3.spexchange.io/spwallet/advantages_icon_05.png";
export const ADVANTAGES_ICON_06 =
  "https://s3.spexchange.io/spwallet/advantages_icon_06.png";
export const USER_ICON = "https://s3.spexchange.io/spwallet/user-icon.png";
export const ADS_VI = "https://s3.spexchange.io/spwallet/promo-vi.png";
export const ADS_EN = "https://s3.spexchange.io/spwallet/promo-en.png";
export const KYC_STEP_01_IMAGE =
  "https://s3.spexchange.io/spwallet/kyc_step_01.png";
export const KYC_STEP_02_IMAGE =
  "https://s3.spexchange.io/spwallet/kyc_step_02.png";
export const KYC_STEP_03_IMAGE =
  "https://s3.spexchange.io/spwallet/kyc_step_03.png";
export const KYC_STEP_04_IMAGE =
  "https://s3.spexchange.io/spwallet/kyc_step_04.png";
export const KYC_STEP_05_IMAGE =
  "https://s3.spexchange.io/spwallet/kyc_step_05.png";
