import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Container, Header, Segment, Loader, Icon } from "semantic-ui-react";
import {
  _getDepositHistory,
  // _getRedeemHistory,
  _getSwapHistory,
  _getWithdrawHistory,
  _getTransactionHistory,
} from "../../redux/walletReducer";
import { formatAmount, formatTime, formatDate } from "../../settings/format";
import "../../components/portfolio/portfolio.scss";
import WalletAddress from "../../components/others/WalletAddress";
import TransactionHash from "../../components/others/TransactionHash";
import MainBar from "../../components/portfolio/MainBar";
import { get, post } from "../../utils/api";
import { LUS_SYMBOL } from "../../constant/CoinConstant";

function Detail({ data, coin }) {
  const [visible, setVisible] = useState(false);
  const { setting, wallet } = useSelector((state) => state);
  const { library } = setting;
  const { fundList } = wallet;
  var { time, createdDate } = data;
  if (!time) {
    time = createdDate;
  }
  var secondTime = parseInt((Date.now() - time) / 1000);
  var days = Math.floor(secondTime / 24 / 60 / 60);
  var hoursLeft = Math.floor(secondTime - days * 86400);
  var hours = Math.floor(hoursLeft / 3600);
  var minutesLeft = Math.floor(hoursLeft - hours * 3600);
  var minutes = Math.floor(minutesLeft / 60);

  let displayTime;
  if (parseInt(days) > 0) {
    displayTime = formatDate(time);
  } else {
    if (hours > 0) {
      displayTime = hours + " " + library.HOURS_AGO;
    } else {
      if (minutes > 0) {
        displayTime = minutes + " " + library.MINUTES_AGO;
      } else {
        displayTime = "1 " + library.MINUTES_AGO;
      }
    }
  }

  const Withdraw = () => {
    return (
      <>
        <div>
          <div>
            <div className="title">{library.TIME.toUpperCase()}:</div>
            <div className="amount">{formatTime(data.time)}</div>
          </div>
          <div>
            <div className="title">
              {library.TRANSACTION_HASH.toUpperCase()}:
            </div>
            <div className="amount">
              <TransactionHash data={data} />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className="title">
              {library.EXCHANGE_DETAIL.toUpperCase()}:
            </div>
            <div className="amount">
              {library.SEND}{" "}
              <span style={{ color: coin.color }} className="amount">
                {formatAmount(data.amount)} {data.coin}
              </span>{" "}
              {library.TO} <WalletAddress data={data} /> | Network:{" "}
              {data.network}
            </div>
          </div>
          <div>
            <div className="title">{library.FEE.toUpperCase()}:</div>
            <div className="amount">
              {formatAmount(data.fee)} {data.coin}
            </div>
          </div>
        </div>
      </>
    );
  };

  const Deposit = () => {
    return (
      <>
        <div>
          <div>
            <div className="title">{library.TIME.toUpperCase()}:</div>
            <div className="amount">{formatTime(data.time)}</div>
          </div>
          <div>
            <div className="title">
              {library.TRANSACTION_HASH.toUpperCase()}:
            </div>
            <div className="amount">
              <TransactionHash data={data} />
            </div>
          </div>
        </div>
        <div>
          <div>
            <div className="title">
              {library.EXCHANGE_DETAIL.toUpperCase()}:
            </div>
            <div className="amount">
              {library.GET}{" "}
              <span style={{ color: coin.color }} className="amount">
                {formatAmount(data.amount)} {data.coin}
              </span>{" "}
              {library.FROM} <WalletAddress data={data} /> ({data.network})
            </div>
          </div>
        </div>
      </>
    );
  };

  const Staking = () => {
    return (
      <>
        <div>
          <div>
            <div className="title">{library.TIME.toUpperCase()}:</div>
            <div className="amount">{formatTime(data.time)}</div>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <div className="title">
              {library.EXCHANGE_DETAIL.toUpperCase()}:
            </div>
            <div className="amount">
              {library.SEND}{" "}
              <span style={{ color: coin.color }} className="amount">
                {formatAmount(data.amount)} {data.coin}
              </span>{" "}
              {library.TO} {library.STAKING}{" "}
              <span style={{ color: coin.color }} className="amount">
                {data.coin}
              </span>
            </div>
            <div></div>
          </div>
        </div>
      </>
    );
  };

  const Redeem = () => {
    return (
      <>
        <div>
          <div>
            <div className="title">{library.TIME.toUpperCase()}:</div>
            <div className="amount">{formatTime(data.time)}</div>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <div className="title">
              {library.EXCHANGE_DETAIL.toUpperCase()}:
            </div>
            <div className="amount">
              {library.RECEIVE}{" "}
              <span style={{ color: coin.color }} className="amount">
                {formatAmount(data.amount)} {data.quote}
              </span>{" "}
              {library.FROM} {library.STAKING}{" "}
              <span
                style={{
                  color: data.base
                    ? fundList.filter((e) => e.coin === data.base)[0].color
                    : coin.color,
                }}
                className="amount"
              >
                {data.base}
              </span>
            </div>
            <div></div>
          </div>
        </div>
      </>
    );
  };

  const Swap = () => {
    return (
      <>
        <div>
          <div>
            <div className="title">{library.TIME.toUpperCase()}:</div>
            <div className="amount">{formatTime(data.time)}</div>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <div className="title">
              {library.EXCHANGE_DETAIL.toUpperCase()}:
            </div>
            <div className="amount">
              {library.SWAP} {library.FROM}{" "}
              <span
                style={{
                  color: fundList.filter(
                    (element) => element.coin === data.base
                  )[0]
                    ? fundList.filter(
                        (element) => element.coin === data.base
                      )[0].color
                    : "#fff",
                }}
                className="amount"
              >
                {formatAmount(data.amount)} {data.base}
              </span>{" "}
              {library.TO}{" "}
              <span
                style={{
                  color: fundList.filter(
                    (element) => element.coin === data.quote
                  )[0]
                    ? fundList.filter(
                        (element) => element.coin === data.quote
                      )[0].color
                    : "#fff",
                }}
                className="amount"
              >
                {formatAmount(data.total)} {data.quote}
              </span>
            </div>
            <div>
              <div className="title">{library.FEE.toUpperCase()}:</div>
              <div>
                {formatAmount(data.fee)} {data.base}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const EasyBy = () => {
    return (
      <>
        <div>
          <div>
            <div className="title">{library.TIME.toUpperCase()}:</div>
            <div className="amount">{formatTime(data.time)}</div>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <div className="title">
              {library.EXCHANGE_DETAIL.toUpperCase()}:
            </div>
            <div className="amount">
              {library.RECEIVE}{" "}
              <span
                style={{
                  color: fundList.filter(
                    (element) => element.coin === data.base
                  )[0].color,
                }}
                className="amount"
              >
                {formatAmount(data.amount)} {data.base}
              </span>{" "}
              {library.FROM}{" "}
              <span
                style={{
                  color: fundList.filter(
                    (element) => element.coin === data.quote
                  )[0].color,
                }}
                className="amount"
              >
                {formatAmount(data.total)} {data.quote}
              </span>
            </div>
            <div></div>
          </div>
        </div>
      </>
    );
  };

  const Transaction = () => {
    return (
      <>
        <div>
          <div>
            <div className="title">{library.TIME.toUpperCase()}:</div>
            <div className="amount">{formatTime(data.time)}</div>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <div className="title">
              {library.EXCHANGE_DETAIL.toUpperCase()}:
            </div>
            <div className="amount">
              {library.GET}{" "}
              <span style={{ color: coin.color }} className="amount">
                {formatAmount(data.amount)} {data.coin}
              </span>{" "}
              {library.FROM} {library[data.type]}{" "}
            </div>
            <div></div>
          </div>
        </div>
      </>
    );
  };

  const SafeLusTransaction = () => {
    return (
      <>
        <div>
          <div>
            <div className="title">{library.TIME.toUpperCase()}:</div>
            <div className="amount">{formatTime(data.createdDate)}</div>
          </div>
          <div></div>
        </div>
        <div>
          <div>
            <div className="title">
              {library.EXCHANGE_DETAIL.toUpperCase()}:
            </div>
            <div className="amount">
              {library.GET}{" "}
              <span style={{ color: coin.color }} className="amount">
                {formatAmount(data.amount)} {data.coin}
              </span>{" "}
              {library.FROM} {library[data.type]}{" "}
            </div>
            <div></div>
          </div>
        </div>
      </>
    );
  };

  var display = null;
  var icon = null;
  var amount = null;
  var type = null;
  var status = null;

  switch (data.transactionType) {
    case "SAFE_LUS_TRANSACTION":
      display = <SafeLusTransaction />;
      icon = <Icon name="share alternate" />;
      amount = (
        <span style={{ color: data.amount >= 0 ? "var(--green)" : "#ff644e" }}>
          {data.amount >= 0 ? "+" : "-"} {formatAmount(data.amount)}
        </span>
      );
      type = library[data.type];
      break;
    case "TRANSACTION":
      display = <Transaction />;
      icon = <Icon name="sign-in" rotated="clockwise" />;
      amount = (
        <span style={{ color: "var(--green)" }}>
          +{formatAmount(data.amount)}
        </span>
      );
      type = library[data.type];
      break;
    case "EASY_BUY":
      display = <EasyBy />;
      icon = <Icon name="exchange" />;
      amount =
        data.quote === coin.coin ? (
          <span style={{ color: "#ff644e" }}>-{formatAmount(data.total)}</span>
        ) : (
          <span style={{ color: "var(--green)" }}>
            +{formatAmount(data.amount)}
          </span>
        );
      type = library.EASY_BUY;
      break;
    case "WITHDRAW":
      display = <Withdraw />;
      icon = <Icon name="paper plane outline" />;
      amount = (
        <span style={{ color: "#ff644e" }}>
          -{formatAmount(data.amount + data.fee)}
        </span>
      );
      type = library.WITHDRAW;
      if (data.status === "CONFIRMED") {
        status = <small size="small">{library.COMPLETED}</small>;
      } else {
        status = <small size="small">{library[data.status]}</small>;
      }
      break;
    case "DEPOSIT":
      display = <Deposit />;
      icon = <Icon name="sign-in" rotated="clockwise" />;
      amount = (
        <span style={{ color: "var(--green)" }}>
          +{formatAmount(data.amount)}
        </span>
      );
      type = library.DEPOSIT;
      break;
    case "REDEEM":
      display = <Redeem />;
      icon = <Icon name="chart line" />;
      amount = (
        <span style={{ color: "var(--green)" }}>
          +{formatAmount(data.amount)}
        </span>
      );
      type = library.STAKING;
      break;
    case "SWAP":
      display = <Swap />;
      icon = <Icon name="refresh" />;
      amount =
        data.quote === coin.coin ? (
          <span style={{ color: "var(--green)" }}>
            +{formatAmount(data.total)}
          </span>
        ) : (
          <span style={{ color: "#ff644e" }}>-{formatAmount(data.amount)}</span>
        );
      type = library.SWAP;
      break;
    default:
      display = <Staking />;
      icon = <Icon name="chart line" />;
      amount = (
        <span style={{ color: "#ff644e" }}>-{formatAmount(data.amount)}</span>
      );
      type = library.STAKING;
      break;
  }

  return (
    <>
      <div className="history-item" onClick={() => setVisible(!visible)}>
        <div className="left">
          <div className="type">
            <div>{icon}</div>
            <div>{type}</div>
          </div>
          <div>
            <small>{displayTime}</small>
          </div>
        </div>
        <div className="right">
          {status}
          <div className="amount">
            {amount}{" "}
            <Icon name={!visible ? "chevron down" : "chevron up"} link />
          </div>
        </div>
      </div>
      <Segment vertical basic className={visible ? "detail show" : "detail"}>
        {display}
      </Segment>
    </>
  );
}

function Portfolio() {
  const { coin } = useParams();
  const { wallet, setting } = useSelector((state) => state);
  const { library } = setting;
  const {
    fundList,
    depositHistory,
    withdrawHistory,
    swapHistory,
    // stakingHistory,
    // redeemHistory,
    // easyBuyHistory,
    _transactionHistory,
  } = wallet;
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(true);
  const [newStakingHistory, setNewStakingHistory] = useState(null);
  const [newRedeemHistory, setNewRedeemHistory] = useState(null);
  // const [stakingHistory, setStakingHistory] = useState(null);
  const [safeLusTransaction, setSafeLusTransaction] = useState(null);

  let fund;
  if (fundList.length > 0) {
    const temp = fundList.filter((element) => element.coin === coin)[0];
    if (!temp) {
      history.push("/");
    } else {
      fund = temp;
    }
  }

  const pageSize = 500;
  const page = 1;

  useEffect(() => {
    setLoading(true);
    if (mounted) {
      const toDate = Date.now();
      const fromDate = new Date(toDate - 7776000000).getTime(); //90 days
      const status = "CONFIRMED";
      dispatch(_getDepositHistory({ page, pageSize, coin, fromDate, toDate }));
      dispatch(
        _getWithdrawHistory({ page, pageSize, coin, fromDate, toDate, status })
      );
      dispatch(_getSwapHistory({ page, pageSize, coin, fromDate, toDate }));
      // dispatch(_getStakingHistory({ page, pageSize, coin, fromDate, toDate }));
      // dispatch(_getRedeemHistory({ page, pageSize, coin, fromDate, toDate }));
      // dispatch(_getEasyBuyHistory({ page, pageSize, coin, fromDate, toDate }));
      dispatch(
        _getTransactionHistory({ page, pageSize, coin, fromDate, toDate })
      );
      // post(
      //   `/staking-service/staking/list`,
      //   {
      //     page,
      //     pageSize,
      //     coin,
      //     from: fromDate,
      //     to: toDate,
      //   },
      //   (data) => setStakingHistory(data),
      //   (error) => console.log(error)
      // );
      post(
        `/staking-service/lus-staking/staking/list`,
        {
          page,
          pageSize,
          coin,
          from: fromDate,
          to: toDate,
        },
        (data) => setNewStakingHistory(data),
        (error) => console.log(error)
      );
      post(
        `/staking-service/lus-staking/staking/transaction/list`,
        {
          page,
          pageSize,
          coin,
          from: fromDate,
          to: toDate,
        },
        (data) => setNewRedeemHistory(data),
        (error) => console.log(error)
      );
      if (coin === LUS_SYMBOL) {
        get(
          `/lus-staking-service/v2/transaction/list?from=${0}&to=${0}&page=${page}&pageSize=${pageSize}`,
          (data) => {
            setSafeLusTransaction(data.items);
          }
        );
      } else {
        setSafeLusTransaction([]);
      }
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    }
    return () => setMounted(false);
  }, [coin, dispatch, mounted]);

  if (loading) {
    var transactionHistory = [];
  } else {
    if (
      depositHistory &&
      withdrawHistory &&
      swapHistory &&
      // stakingHistory &&
      // redeemHistory &&
      // easyBuyHistory &&
      _transactionHistory &&
      newStakingHistory &&
      newRedeemHistory &&
      safeLusTransaction
    ) {
      safeLusTransaction.map(
        (element) => (element.transactionType = "SAFE_LUS_TRANSACTION")
      );
      depositHistory.items.map(
        (element) => (element.transactionType = "DEPOSIT")
      );
      withdrawHistory.items.map(
        (element) => (element.transactionType = "WITHDRAW")
      );
      swapHistory.items.map((element) => (element.transactionType = "SWAP"));
      // stakingHistory.items.map(
      //   (element) => (element.transactionType = "STAKING")
      // );
      newStakingHistory.items.map(
        (element) => (element.transactionType = "STAKING")
      );
      // redeemHistory.items.map(
      //   (element) => (element.transactionType = "REDEEM")
      // );
      newRedeemHistory.items.map(
        (element) => (element.transactionType = "REDEEM")
      );
      // easyBuyHistory.items.map(
      //   (element) => (element.transactionType = "EASY_BUY")
      // );
      _transactionHistory.items.map(
        (element) => (element.transactionType = "TRANSACTION")
      );
      transactionHistory = depositHistory.items.concat(
        withdrawHistory.items,
        swapHistory.items,
        // stakingHistory.items,
        // redeemHistory.items,
        // easyBuyHistory.items,
        _transactionHistory.items,
        newStakingHistory.items,
        newRedeemHistory.items,
        safeLusTransaction
      );
      transactionHistory.sort(function (a, b) {
        let time_1 = a.time;
        let time_2 = b.time;
        if (a.transactionType === "SAFE_LUS_TRANSACTION") {
          time_1 = a.createdDate;
        }
        if (b.transactionType === "SAFE_LUS_TRANSACTION") {
          time_2 = b.createdDate;
        }
        return time_2 - time_1;
      });
    }
  }

  return fund ? (
    <Container id="portfolio">
      <Segment>
        <MainBar coin={coin} fund={fund} />
      </Segment>
      <LazyLoad>
        <Segment>
          <Header textAlign="left">{library.TRANSACTION_HISTORY}</Header>
          <Segment
            basic
            vertical
            style={{ minHeight: 300 }}
            className="transaction-history"
          >
            <Loader active={loading} />
            {!loading &&
              (transactionHistory && transactionHistory.length > 0
                ? transactionHistory.map((item, index) => (
                    <Segment key={index} vertical>
                      <Detail data={item} coin={fund} />
                    </Segment>
                  ))
                : library.NO_RECORDS_FOUND)}
          </Segment>
        </Segment>
      </LazyLoad>
    </Container>
  ) : null;
}

export default Portfolio;
