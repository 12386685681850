/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import {
  Container,
  Grid,
  Collapse,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { useSelector } from "react-redux";
import {
  ICON_FACEBOOK,
  ICON_TELEGRAM,
  ICON_TWITTER,
  ICON_YOUTUBE,
  FOOTER_LOGO,
} from "../../settings/links";
const logo = FOOTER_LOGO;

const facebook = ICON_FACEBOOK;
const twitter = ICON_TWITTER;
const telegram = ICON_TELEGRAM;
const youtube = ICON_YOUTUBE;

Footer.propTypes = {};

function RenderMobile() {
  const [expandedIndex, setExpandedIndex] = useState(0);
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const {
    SWAP,
    STAKING,
    INFORMATION,
    TERM_OF_USE,
    SUPPORT,
    PRIVACY_AND_POLICY,
  } = library;
  return (
    <div style={{ backgroundColor: "#14263B", paddingTop: "2em" }}>
      <Container maxWidth={"md"} className="p-3 pt-3 shadow-0 ">
        <Grid container item xs={12} className="pb-5">
          <Grid item xs={12} className="mb-4">
            <div className="w-100 height-35 text-left d-flex justify-content-between align-items-center">
              <img src={logo} className="height-40 m-auto" />
            </div>
            <div
              className="d-flex"s
              style={{
                justifyContent: `space-around`,
                width: 300,
                margin: "10px auto",
              }}
            >
              <div className="width-30 height-30 mr-2">
                <img src={twitter} className="h-100 h-100" />
              </div>
              <div className="width-30 height-30 mr-2">
                <a
                  href="https://www.facebook.com/spwallet.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={facebook} className="h-100 h-100" />
                </a>
              </div>
              <div className="width-30 height-30 mr-2">
                <a
                  href="https://t.me/spwalletorg"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={telegram} className="h-100 h-100" />
                </a>
              </div>
              <div className="width-30 height-30 mr-2">
                <img src={youtube} className="h-100 h-100" />
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <ListItem
              button
              onClick={() => setExpandedIndex(expandedIndex === 1 ? 0 : 1)}
              className="rounded-lg "
            >
              <ListItemText
                primary={<span className="font-weight-bold">SPwallet</span>}
              />
              {expandedIndex === 1 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={expandedIndex === 1}
              timeout="auto"
              unmountOnExit
              onClick={() => setExpandedIndex(expandedIndex === 1 ? 0 : 1)}
              className="pl-3"
            >
              <List component="div" disablePadding>
                <ListItem button>
                  <ListItemText primary={<span>{SWAP}</span>} />
                </ListItem>
              </List>
              <ListItem button>
                <ListItemText primary={<span>{STAKING}</span>} />
              </ListItem>
              <ListItem button>
                <ListItemText primary={<span>P2P</span>} />
              </ListItem>
              <ListItem button>
                <ListItemText primary={<span>mUSD</span>} />
              </ListItem>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <ListItem
              button
              onClick={() => setExpandedIndex(expandedIndex === 2 ? 0 : 2)}
              className="rounded-lg "
            >
              <ListItemText
                primary={
                  <span className="font-weight-bold">{INFORMATION}</span>
                }
              />
              {expandedIndex === 2 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={expandedIndex === 2}
              timeout="auto"
              unmountOnExit
              onClick={() => setExpandedIndex(expandedIndex === 2 ? 0 : 2)}
              className="pl-3"
            >
              <List component="div" disablePadding>
                <a href="/policy" target="_blank">
                  <ListItem button>
                    <ListItemText
                      primary={
                        <span className="text-white">{PRIVACY_AND_POLICY}</span>
                      }
                    />
                  </ListItem>
                </a>
              </List>
              <a href="/terms-of-service" target="_blank">
                <ListItem button>
                  <ListItemText
                    primary={<span className="text-white">{TERM_OF_USE}</span>}
                  />
                </ListItem>
              </a>
            </Collapse>
          </Grid>
          <Grid item xs={12}>
            <ListItem
              button
              onClick={() => setExpandedIndex(expandedIndex === 3 ? 0 : 3)}
              className="rounded-lg "
            >
              <ListItemText
                primary={<span className="font-weight-bold">{SUPPORT}</span>}
              />
              {expandedIndex === 3 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse
              in={expandedIndex === 3}
              timeout="auto"
              unmountOnExit
              onClick={() => setExpandedIndex(expandedIndex === 3 ? 0 : 3)}
              className="pl-3"
            >
              <List component="div" disablePadding>
                <ListItem button>
                  <ListItemText primary={<span>support@spwallet.org</span>} />
                </ListItem>
              </List>
              <ListItem button>
                <ListItemText primary={<span>info@spwallet.org</span>} />
              </ListItem>
            </Collapse>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

function RenderDesktop() {
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const {
    SWAP,
    STAKING,
    INFORMATION,
    TERM_OF_USE,
    SUPPORT,
    PRIVACY_AND_POLICY,
  } = library;
  return (
    <div style={{ backgroundColor: "#14263B", paddingTop: "2em" }}>
      <Container maxWidth={"md"} className="p-0 shadow-0">
        <Grid container item xs={12} className="pb-5">
          <Grid item xs={5}>
            <div className="w-100 height-35 text-left">
              <img src={logo} className="h-100" />
              <div className="d-flex mt-3">
                <div className="width-30 height-30 mr-2">
                  <img src={twitter} className="h-100 h-100" />
                </div>
                <div className="width-30 height-30 mr-2">
                  <a
                    href="https://www.facebook.com/spwallet.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={facebook} className="h-100 h-100" />
                  </a>
                </div>
                <div className="width-30 height-30 mr-2">
                  <a
                    href="https://t.me/spwalletorg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={telegram} className="h-100 h-100" />
                  </a>
                </div>
                <div className="width-30 height-30 mr-2">
                  <img src={youtube} className="h-100 h-100" />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={2} className="pl-3">
            <span className="font-size-1 font-weight-bolder d-block mb-3 text-left">
              SPwallet
            </span>
            <span className="font-size-1 d-block w-1 btn-hover rounded-pill pt-1 pb-1 pl-3 pr-3 ml-n3 ">
              {SWAP}
            </span>
            <span className="font-size-1 d-block w-1 btn-hover rounded-pill pt-1 pb-1 pl-3 pr-3 ml-n3 ">
              {STAKING}
            </span>
            <span className="font-size-1 d-block w-1 btn-hover rounded-pill pt-1 pb-1 pl-3 pr-3 ml-n3 ">
              P2P
            </span>
            <span className="font-size-1 d-block w-1 btn-hover rounded-pill pt-1 pb-1 pl-3 pr-3 ml-n3 ">
              mUSD
            </span>
          </Grid>
          <Grid item xs={2} className="pl-3 text-left">
            <span className="font-size-1 font-weight-bolder d-block mb-3 text-left">
              {INFORMATION}
            </span>
            <a
              href="/policy"
              target="_blank"
              style={{ textDecoration: "unset" }}
            >
              <span className="font-size-1 text-white d-block w-1 btn-hover rounded-pill pt-1 pb-1 pl-3 pr-3 ml-n3 color-primary">
                {PRIVACY_AND_POLICY}
              </span>
            </a>
            <a
              href="/terms-of-service"
              target="_blank"
              style={{ textDecoration: "unset" }}
            >
              <span className="font-size-1 text-white d-block w-1 btn-hover rounded-pill pt-1 pb-1 pl-3 pr-3 ml-n3 color-primary">
                {TERM_OF_USE}
              </span>
            </a>
          </Grid>
          <Grid item xs={2} className="pl-3">
            <span className="font-size-1 font-weight-bolder d-block mb-3 text-left">
              {SUPPORT}
            </span>
            <span className="font-size-1 d-block w-1 btn-hover rounded-pill pt-1 pb-1 pl-3 pr-3 ml-n3 ">
              support@spwallet.org
            </span>
            <span className="font-size-1 d-block w-1 btn-hover rounded-pill pt-1 pb-1 pl-3 pr-3 ml-n3 ">
              info@spwallet.org
            </span>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

function Footer(props) {
  const isMobile = window.innerWidth < 800;
  return isMobile === true ? <RenderMobile /> : <RenderDesktop />;
}

export default Footer;
