import React from "react";
import PropTypes from "prop-types";
import {
  Toolbar,
  CssBaseline,
  useScrollTrigger,
  makeStyles,
  Container,
  Fab,
  Zoom,
} from "@material-ui/core";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Home from "../../components/homepage";
import Footer from "../../components/templates/footer.js";
import "../../assets/style/common.scss";
import SPExchange from "../../components/homepage/SPExchange";
import LazyLoad from "react-lazyload";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function Homepage(props) {
  const isMobile = window.innerWidth < 800;
  return (
    <Container
      id="homepage"
      maxWidth={false}
      className={isMobile ? "mobile-mode" : ""}
      style={{
        fontSize: "17px!important",
      }}
    >
      <CssBaseline />
      <Container maxWidth={false} className="pt-0 pl-0 pr-0  bg-white">
        <Toolbar id="back-to-top-anchor" style={{ minHeight: "auto" }} />
        <Home />
        <LazyLoad height={300} offset={-200}>
          <Container maxWidth={"md"} className="p-0">
            <SPExchange />
          </Container>
        </LazyLoad>
        <Footer />
      </Container>
      <ScrollTop {...props}>
        <Fab
          size="small"
          aria-label="scroll back to top"
          className="background-primary text-white border"
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </Container>
  );
}
