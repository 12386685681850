import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Header, Pagination, Segment, Table } from "semantic-ui-react";
import { post } from "../../utils/api";

function Member() {
  const [data, setData] = useState(null);
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const { NO_RECORDS_FOUND, YOUR_AFFILIATE_F1 } = library;
  useEffect(() => {
    !data &&
      post(
        `/user-service/referral/list`,
        {
          page: 1,
          pageSize: 10,
        },
        (data) => setData(data),
        (error) => console.error(error)
      );
  });

  return (
    data && (
      <Segment className="spwallet-history">
        <Header textAlign="left">{YOUR_AFFILIATE_F1}</Header>
        <Table
          unstackable
          basic="very"
          compact="very"
          celled
          inverted
          singleLine
          selectable
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ID</Table.HeaderCell>
              <Table.HeaderCell>Email</Table.HeaderCell>
              {/* <Table.HeaderCell>Level</Table.HeaderCell> */}
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.items.length > 0 ? (
              data.items.map((item, index) => (
                <Table.Row key={index} textAlign="left">
                  <Table.Cell>#{item.id}</Table.Cell>
                  <Table.Cell>{item.referralEmail}</Table.Cell>
                  {/* <Table.Cell>1</Table.Cell> */}
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={3}>
                  <Segment basic textAlign="center">
                    {NO_RECORDS_FOUND}
                  </Segment>
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        {data.pageCount > 1 && (
          <Pagination
            activePage={data.page}
            totalPages={data.pageCount}
            onPageChange={(e, { activePage }) =>
              post(
                `/user-service/referral/list`,
                {
                  page: activePage,
                  pageSize: 10,
                },
                (data) => {
                  setData(data);
                },
                (error) => console.error(error)
              )
            }
            pointing
            secondary
            inverted
            ellipsisItem={null}
            firstItem={null}
            lastItem={null}
            siblingRange={1}
            boundaryRange={0}
          />
        )}
      </Segment>
    )
  );
}

export default Member;
