import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Dropdown, Icon, Image, Menu } from "semantic-ui-react";
import { RETURN_SAFE_LUS_KEY } from "../../settings";
import { MENU_LOGO, USER_ICON } from "../../settings/links";
import { logout } from "../../utils/auth";

const logo = MENU_LOGO;

const languageOptions = [
  { key: "en", text: "English", value: "en" },
  { key: "vi", text: "Tiếng Việt", value: "vi" },
];

function CustomMenu({ onShowSidebar }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, setting } = useSelector((state) => state);
  const { library, lang } = setting;
  const isMobile = window.innerWidth < 767;

  return (
    <Container fluid className="custom-menu">
      <Container>
        <Menu pointing secondary widths={4}>
          <Menu.Item
            onClick={() => {
              history.push("/");
            }}
            style={{ justifyContent: "flex-start" }}
          >
            <Image src={logo} size="small" />
          </Menu.Item>
          <Menu.Item className="features-menu">
            {user.isLogin && (
              <>
                <Menu.Item
                  onClick={() => history.push("/")}
                  className="hide-mobile"
                >
                  {library.PORTFOLIO}
                </Menu.Item>
                <Menu.Item
                  onClick={() => history.push("/swap")}
                  className="hide-mobile"
                >
                  {library.SWAP}
                </Menu.Item>
                {/* <Menu.Item
                  onClick={() => history.push("/staking")}
                  className="hide-mobile"
                >
                  {library.STAKING}
                </Menu.Item> */}
                <Dropdown
                  item
                  text={library.PARTNER}
                  floating
                  labeled
                  className="hide-mobile"
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      text="Luxury Outlet Street"
                      onClick={() => history.push("/lus/partner")}
                    />
                    <Dropdown.Item
                      text="SAFE LuS"
                      onClick={() =>
                        history.push(`/login?return=${RETURN_SAFE_LUS_KEY}`)
                      }
                    />
                  </Dropdown.Menu>
                </Dropdown>
                {/* <Menu.Item
                  onClick={() => history.push("/easy-buy")}
                  className="hide-mobile"
                >
                  {library.EASY_BUY}
                </Menu.Item> */}
              </>
            )}
          </Menu.Item>
          <Menu.Item style={{ justifyContent: "flex-end" }}>
            {user.isLogin ? (
              <>
                <Dropdown
                  pointing="top right"
                  options={languageOptions}
                  value={lang}
                  onChange={(e, { value }) =>
                    dispatch({ type: "CHANGE_LANGUAGE", payload: value })
                  }
                  style={{ color: "#fff", marginRight: "1em" }}
                  icon={null}
                  className="language hide-mobile"
                />
                <Icon
                  name="sidebar"
                  style={{ fontSize: "2em" }}
                  link
                  onClick={onShowSidebar}
                  className="show-mobile"
                  inverted
                />
                <Dropdown
                  trigger={<Image src={USER_ICON} size="mini" />}
                  icon={null}
                  style={{ width: "fit-content" }}
                  pointing="top right"
                  // className="hide-mobile"
                >
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => history.push("/me")}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {user.data && (
                        <>
                          <div>
                            <p>{user.data.email}</p>
                            <p style={{ color: "#fbbd08" }}>
                              <Icon name="gem outline" />
                              VIP{user.data.level}
                            </p>
                          </div>
                          <Icon
                            name="chevron right"
                            style={{ paddingLeft: "2em" }}
                          />
                        </>
                      )}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => history.push("/affiliate")}>
                      {library.AFFILIATE}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        window.localStorage.removeItem("SHOW_ADS");
                        logout();
                        dispatch({ type: "LOGOUT" });
                      }}
                    >
                      {library.LOGOUT}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                {/* <Image src={USER_ICON} size="mini" className="show-mobile" /> */}
              </>
            ) : (
              <Menu.Item
                className="features-menu"
                style={!isMobile ? { width: "210%", minWidth: 300 } : null}
              >
                <>
                  {/* <Menu.Item
                    onClick={() => history.push("/easy-buy")}
                    className="hide-mobile"
                  >
                    {library.EASY_BUY}
                  </Menu.Item> */}
                  {/* <Menu.Item
                    onClick={() => history.push("/staking")}
                    className="hide-mobile"
                  >
                    {library.STAKING}
                  </Menu.Item> */}
                  <Menu.Item
                    onClick={() => history.push("/register")}
                    className="hide-mobile"
                  >
                    {library.REGISTER}
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => history.push("/login")}
                    className="hide-mobile"
                  >
                    {library.LOGIN}
                  </Menu.Item>
                  <Menu.Item className="hide-mobile">
                    <Dropdown
                      className="icon"
                      floating
                      labeled
                      options={languageOptions}
                      value={lang}
                      onChange={(e, { value }) =>
                        dispatch({ type: "CHANGE_LANGUAGE", payload: value })
                      }
                    />
                  </Menu.Item>
                  <Menu.Item>
                    <Icon
                      name="sidebar"
                      style={{ fontSize: "2em" }}
                      link
                      onClick={onShowSidebar}
                      className="show-mobile"
                    />
                  </Menu.Item>
                </>
              </Menu.Item>
            )}
          </Menu.Item>
        </Menu>
      </Container>
    </Container>
  );
}

export default CustomMenu;
