import { Drawer } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Form,
  Header,
  Icon,
  Image,
  Message,
  Segment,
} from "semantic-ui-react";
import {
  _getFundList,
  _getSwapHistory,
  _getSwapList,
} from "../../redux/walletReducer";
import { S3_URL } from "../../settings";
import { CustomToast } from "../../settings/actions";
import {
  deleteText,
  formatAmount,
  truncateNumber,
} from "../../settings/format";
import { post } from "../../utils/api";
import AmountInput from "../others/AmountInput";
import { checkEmpty } from "../others/Validate";
import SelectCoin from "./SelectCoin";

function ConfirmForm({
  _handleClose,
  _handleSwap,
  quote,
  base,
  amount,
  price,
}) {
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const [time, setTime] = useState(30);
  const dispatch = useDispatch();

  const {
    RATIO,
    SWAP_FEE,
    SWAP_CONFIRM,
    YOU_WILL_GET,
    // SWAP,
    CONFIRM,
  } = library;

  useEffect(() => dispatch(_getSwapList()), [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(time - 1);
    }, 1000);
    if (time === 0) clearInterval(interval);
    return () => clearInterval(interval);
  }, [time]);

  return (
    <Segment
      style={{
        backgroundColor: "var(--light-blue)",
        minWidth: 300,
        color: "#fff",
        textAlign: "center",
      }}
      className="spwallet-container"
    >
      <Segment vertical>
        <Header style={{ color: "var(--green)" }}>{SWAP_CONFIRM}</Header>
        <Icon
          name="x"
          style={{ position: "absolute", right: 0, top: 0, cursor: "pointer" }}
          size="large"
          onClick={_handleClose}
          inverted
        />
      </Segment>
      <Segment vertical>
        <div>{YOU_WILL_GET}</div>
        <Header as="h2">
          {formatAmount(parseFloat(amount * price))} {quote}
        </Header>
        <Segment style={{ backgroundColor: "var(--gray)" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1em",
            }}
          >
            <div>{library.FROM}: </div>
            <div>
              {formatAmount(amount)} {base}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1em",
            }}
          >
            <div>{RATIO}:</div>
            <div>
              1 {base} = {formatAmount(price)} {quote}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>{SWAP_FEE}:</div>
            <div>0 {base}</div>
          </div>
        </Segment>
      </Segment>
      <Form onSubmit={_handleSwap}>
        <Button className="spwallet-button" fluid disabled={!time}>
          {CONFIRM} ({time}s)
        </Button>
      </Form>
    </Segment>
  );
}

function SwapForm() {
  const { wallet, setting } = useSelector((state) => state);
  const { library } = setting;
  const { swapProduct } = wallet;
  const [base, setBase] = useState("USDT");
  const [quote, setQuote] = useState("LuS");
  const dispatch = useDispatch();
  const [bases, setBases] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [baseAmount, setBaseAmount] = useState(0);
  const [quoteAmount, setQuoteAmount] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { fundList } = wallet;
  const [openConfirm, setOpenConfirm] = useState(false);
  const [showSelectBase, setShowSelectBase] = useState(false);
  const [showSelectQuote, setShowSelectQuote] = useState(false);

  const _handleChangeBase = (value) => {
    setShowSelectBase(false);
    const newBases = [];
    const newQuotes = [];
    setError(null);
    swapProduct.forEach((element, index) => {
      if (newBases.findIndex((i) => i.value === element.base) < 0) {
        newBases.push({
          key: index,
          value: element.base,
          text: element.base,
        });
      }
      if (element.base === value) {
        newQuotes.push({
          key: index,
          value: element.quote,
          text: (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: 70,
              }}
            >
              <Image
                src={`${S3_URL}/coins/${element.quote}.png`}
                style={{ width: 20, height: 20 }}
              />
              {element.quote}
            </div>
          ),
        });
      }
    });
    setBases(newBases);
    setQuotes(newQuotes);
    setBase(value);
    let newQuote = quote;
    if (newQuotes.findIndex((item) => item.value === quote) < 0) {
      setQuote(newQuotes[0].value);
      newQuote = newQuotes[0].value;
    }
    const selectedProduct = swapProduct.find(
      (element) => element.base === value && element.quote === newQuote
    );
    setQuoteAmount(selectedProduct.price * baseAmount);
  };

  const _handleChangeQuote = (value) => {
    setShowSelectQuote(false);
    const selectedProduct = swapProduct.find(
      (element) => element.base === base && element.quote === value
    );
    setQuote(value);
    setQuoteAmount(selectedProduct.price * baseAmount);
  };

  const selectedProduct = swapProduct.find(
    (element) => element.base === base && element.quote === quote
  );

  useEffect(() => {
    if (swapProduct.length === 0) {
      dispatch(_getSwapList());
    } else {
      _handleChangeBase(base);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, swapProduct]);

  const _onChangeBaseAmount = (e) => {
    let { value } = e.target;
    value = deleteText(value);
    setBaseAmount(value);
    if (value > 0) {
      setQuoteAmount(truncateNumber(parseFloat(value) * selectedProduct.price));
    } else {
      setQuoteAmount(0);
    }
  };

  const _onChangeQuoteAmount = (e) => {
    let { value } = e.target;
    value = deleteText(value);
    setQuoteAmount(value);
    if (value) {
      setBaseAmount(
        truncateNumber(parseFloat(value) / selectedProduct.price).toString()
      );
    } else {
      setBaseAmount(0);
    }
  };

  const _checkSwap = () => {
    setError(null);
    if (checkEmpty(baseAmount)) {
      setError("PLEASER_ENTER_BASE_AMOUNT");
    } else if (baseAmount < selectedProduct.minAmount) {
      setError("BASE_AMOUNT_GREATER_MIN");
    } else if (baseAmount > fund.amount) {
      setError("UNAVAILABLE_BALANCE");
    } else {
      dispatch(_getSwapList());
      setOpenConfirm(true);
    }
  };

  const fund = fundList && fundList.find((e) => e.coin === base);
  // const fundQuote = fundList && fundList.find((e) => e.coin === base);

  // const { SWAP_TIP_0, SWAP_TIP_1, SWAP_TIP_2, SWAP_TIP_3 } = library;
  // const tips = [SWAP_TIP_0, SWAP_TIP_1, SWAP_TIP_2, SWAP_TIP_3];

  const _handleSwap = () => {
    setLoading(true);
    setOpenConfirm(false);
    post(
      `/swap-service/swap`,
      { base, quote, price: selectedProduct.price, amount: baseAmount },
      () => {
        CustomToast("success", library.SWAP_SUCCESS);
        dispatch(_getFundList());
        dispatch(_getSwapHistory({}));
        setLoading(false);
      },
      (error) => {
        CustomToast("error", library[error.code]);
        setLoading(false);
      }
    );
  };

  return swapProduct.length > 0 ? (
    <>
      <Segment
        loading={loading}
        style={{ maxWidth: 420, margin: "auto", borderRadius: 30, zIndex: 1 }}
      >
        <Header as="h1">{library.SWAP}</Header>
        {swapProduct.length > 0 && (
          <Form error inverted onSubmit={_checkSwap}>
            <div className="input-group">
              <div>{library.SWAP}</div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <AmountInput
                  fluid
                  placeholder={library.PLEASE_ENTER_AMOUNT}
                  value={baseAmount}
                  onChange={_onChangeBaseAmount}
                  style={{ flex: 1 }}
                />
                <div
                  className="select-coin"
                  onClick={() => setShowSelectBase(true)}
                >
                  <Image
                    src={`${S3_URL}/coins/${base}.png`}
                    className="coin-logo"
                  />
                  <span>{base}</span>
                  <div>
                    <Icon name="angle down" />
                  </div>
                </div>
              </div>
              <div style={{ textAlign: "right" }}>
                {library.BALANCE}: {fund && formatAmount(fund.amount)}
              </div>
            </div>
            <Segment vertical textAlign="center">
              <Icon name="arrow down" />
            </Segment>
            <div className="input-group">
              <div>{library.RECEIVE}</div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <AmountInput
                  fluid
                  placeholder={library.PLEASE_ENTER_AMOUNT}
                  value={quoteAmount}
                  onChange={_onChangeQuoteAmount}
                  style={{ flex: 1 }}
                />
                <div
                  className="select-coin"
                  onClick={() => setShowSelectQuote(true)}
                >
                  <Image
                    src={`${S3_URL}/coins/${quote}.png`}
                    className="coin-logo"
                  />
                  <span>{quote}</span>
                  <div>
                    <Icon name="angle down" />
                  </div>
                </div>
              </div>
            </div>
            <Segment vertical style={{ height: 80 }}>
              <Message
                error
                content={
                  <div style={{ display: "flex", padding: "0 1em" }}>
                    <Icon
                      name="x"
                      size="small"
                      circular
                      inverted
                      className="error-icon"
                    />
                    {library[error]}
                  </div>
                }
                floating
                hidden={error === null}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "#fe644e",
                  boxShadow: "none",
                  paddingLeft: 0,
                  paddingRight: 0,
                }}
              />
            </Segment>
            {selectedProduct && (
              <Button
                // style={{ marginTop: "2em" }}
                className="spwallet-button"
                type="submit"
                fluid
              >
                {library.SWAP}
              </Button>
            )}
          </Form>
        )}
      </Segment>
      <Segment className="swap-information">
        <div>
          <p>1 {base}</p>
          <p>
            {formatAmount(selectedProduct.price)} {quote}
          </p>
        </div>
        <div>
          <p>{library.MINIMUM}</p>
          <p>
            {selectedProduct.minAmount} {base}
          </p>
        </div>
        <div>
          <p>{library.FEE}</p>
          <p>
            {selectedProduct.fee} {base}
          </p>
        </div>
      </Segment>
      {selectedProduct && (
        <Drawer
          anchor={"left"}
          open={openConfirm}
          className={"custom-modal-vk"}
        >
          <ConfirmForm
            _handleClose={() => setOpenConfirm(false)}
            _handleSwap={_handleSwap}
            price={selectedProduct.price}
            amount={baseAmount}
            base={base}
            quote={quote}
          />
        </Drawer>
      )}
      <Drawer
        anchor={"left"}
        open={showSelectBase}
        className={"custom-modal-vk"}
      >
        <SelectCoin
          coins={bases}
          changeBase={_handleChangeBase}
          close={() => setShowSelectBase(false)}
        />
      </Drawer>
      <Drawer
        anchor={"left"}
        open={showSelectQuote}
        className={"custom-modal-vk"}
      >
        <SelectCoin
          coins={quotes}
          changeBase={_handleChangeQuote}
          close={() => setShowSelectQuote(false)}
        />
      </Drawer>
    </>
  ) : null;
}

export default SwapForm;
