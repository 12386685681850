import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  Button,
  Dimmer,
  Form,
  Header,
  Icon,
  Image,
  Message,
  Segment,
  Checkbox,
} from "semantic-ui-react";
import { SET_MODAL_CONTENT } from "../../redux/settingReducer";
import { _getFundList } from "../../redux/walletReducer";
import { S3_URL } from "../../settings";
import { RETURN_TO_EASY_BUY } from "../../settings/constant";
import {
  deleteText,
  formatAmount,
  formatRoundAmount,
} from "../../settings/format";
import { post } from "../../utils/api";
import AmountInput from "../others/AmountInput";
import { checkEmpty } from "../others/Validate";

function ConfirmForm({
  _handleClose,
  _handleSwap,
  quote,
  base,
  amount,
  price,
}) {
  const { setting } = useSelector((state) => state);
  const { library } = setting;
  const [time, setTime] = useState(30);

  const { YOU_WILL_GET, CONFIRM } = library;

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(time - 1);
    }, 1000);
    if (time === 0) clearInterval(interval);
    return () => clearInterval(interval);
  }, [time]);

  return (
    <Segment
      style={{ backgroundColor: "var(--light-blue)", width: 350 }}
      className="spwallet-container"
    >
      <Segment vertical>
        <Header style={{ color: "var(--green)" }}>
          {library.BUY_TOKEN_CONFIRM}
        </Header>
        <Icon
          name="close"
          style={{ position: "absolute", right: 0, top: 0, cursor: "pointer" }}
          size="large"
          onClick={_handleClose}
        />
      </Segment>
      <Segment vertical>
        <div>{YOU_WILL_GET}</div>
        <Header as="h2">
          {formatAmount(parseFloat(amount))} {base}
        </Header>
        <Segment style={{ backgroundColor: "var(--gray)" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1em",
            }}
          >
            <div>{library.YOU_PAY}</div>
            <div>
              {formatAmount(amount * price)} {quote}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "1em",
            }}
          >
            <div>{library.PRICE}</div>
            <div>
              1 {base} = {formatAmount(price)} {quote}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>{library.FEE}</div>
            <div>0 {base}</div>
          </div>
        </Segment>
      </Segment>
      <Form onSubmit={_handleSwap}>
        <Button className="spwallet-button" fluid disabled={!time}>
          {CONFIRM} ({time}s)
        </Button>
      </Form>
    </Segment>
  );
}

function EasyBuyForm({ data }) {
  const { wallet, setting, user } = useSelector((state) => state);
  const { library } = setting;
  const { isLogin } = user;
  const dispatch = useDispatch();
  let [baseAmount, setBaseAmount] = useState(0);
  const [quoteAmount, setQuoteAmount] = useState(0);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { fundList } = wallet;
  const [openConfirm, setOpenConfirm] = useState(false);
  const [policyCheck, setPolicyCheck] = useState(false);
  const history = useHistory();

  const _onChangeBaseAmount = (value) => {
    value = deleteText(value);
    setBaseAmount(value);
    if (value > 0) {
      setQuoteAmount(formatRoundAmount(parseFloat(value) * data.price));
    } else {
      setQuoteAmount(0);
    }
  };

  const _onChangeQuoteAmount = (value) => {
    value = deleteText(value);
    setQuoteAmount(value);
    if (value > 0) {
      setBaseAmount(
        formatRoundAmount(parseFloat(value) / data.price).toString()
      );
    } else {
      setBaseAmount(0);
    }
  };

  const _checkSwap = () => {
    setError(null);
    baseAmount = parseFloat(baseAmount);
    console.log(baseAmount, data.maxAmount);
    if (checkEmpty(baseAmount.toString())) {
      setError("PLEASER_ENTER_BASE_AMOUNT");
    } else if (baseAmount < data.minAmount) {
      setError("BASE_AMOUNT_GREATER_MIN");
    } else if (baseAmount > data.maxAmount) {
      setError("BASE_AMOUNT_LESS_MAX");
    } else if (quoteAmount > fund.amount) {
      setError("UNAVAILABLE_BALANCE");
    } else if (!policyCheck) {
      setError("PLEASER_CHECK_TOS");
    } else {
      setOpenConfirm(true);
    }
  };

  const fund = fundList && fundList.find((e) => e.coin === data.quote);

  const _handleSwap = () => {
    setLoading(true);
    setOpenConfirm(false);
    post(
      `/presale-service/transaction`,
      { productId: data.id, amount: parseFloat(baseAmount) },
      () => {
        dispatch({
          type: SET_MODAL_CONTENT,
          payload: {
            content: "BUY_TOKEN_SUCCESS",
            callback: () => {
              setLoading(false);
              setOpenConfirm(false);
            },
          },
        });
        dispatch(_getFundList());
      },
      (error) => {
        dispatch({
          type: SET_MODAL_CONTENT,
          payload: {
            content: error.code,
            callback: () => {
              setLoading(false);
              setOpenConfirm(false);
            },
          },
        });
      }
    );
  };

  return (
    <>
      <Segment loading={loading} basic vertical style={{ paddingBottom: 0 }}>
        <Form error inverted onSubmit={_checkSwap}>
          <Segment className="easy-buy-form">
            <Form.Field>
              <label>{library.YOU_WILL_GET}</label>
              <AmountInput
                action={
                  <Button type="button">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: 80,
                      }}
                    >
                      <Image src={`${S3_URL}/coins/${data.base}.png`} />
                      {data.base}
                    </div>
                  </Button>
                }
                placeholder={library.PLEASE_ENTER_AMOUNT}
                value={baseAmount}
                onChange={(e) => _onChangeBaseAmount(e.target.value)}
                fluid
              />
              <small>
                {library.MINIMUM}: {formatAmount(data.minAmount)} {data.base} ~{" "}
                {library.MAXIMUM}: {formatAmount(data.maxAmount)} {data.base}
              </small>
            </Form.Field>
            <Form.Field>
              <Form.Field>
                <label
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  {library.YOU_PAY}
                </label>
                <AmountInput
                  action={
                    <Button type="button">
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: 80,
                        }}
                      >
                        <Image src={`${S3_URL}/coins/${data.quote}.png`} />
                        {data.quote}
                      </div>
                    </Button>
                  }
                  fluid
                  placeholder={library.PLEASE_ENTER_AMOUNT}
                  value={quoteAmount}
                  onChange={(e) => _onChangeQuoteAmount(e.target.value)}
                />
                <small>
                  {fund &&
                    isLogin &&
                    library.AVAILABLE_BALANCE +
                      " : " +
                      formatAmount(fund.amount) +
                      " " +
                      data.quote}
                </small>
              </Form.Field>
            </Form.Field>
          </Segment>
          {isLogin ? (
            <>
              <Segment className="easy-buy-options">
                <div onClick={() => _onChangeBaseAmount(data.minAmount)}>
                  MIN
                </div>
                <div onClick={() => _onChangeQuoteAmount(fund.amount / 2)}>
                  HALF
                </div>
                <div onClick={() => _onChangeQuoteAmount(fund.amount)}>ALL</div>
              </Segment>
              <Segment basic vertical>
                <div style={{ display: "flex" }}>
                  <Checkbox
                    checked={policyCheck}
                    onChange={(e, { checked }) => setPolicyCheck(checked)}
                    disabled={!data.isOpen}
                  />
                  <div style={{ color: "#fff", marginLeft: 10 }}>
                    {library.AGREE_TO}{" "}
                    <a href="/terms-of-service" target="_blank">
                      {library.SPWALLET_TOS}
                    </a>{" "}
                    {library.AND} <Link to="#">{library.STAKING_POLICY}</Link>.
                  </div>
                </div>{" "}
              </Segment>
              <Message error content={library[error]} floating />
              <Button
                type="submit"
                fluid
                className="submit"
                disabled={!data.isOpen}
              >
                {library.BUY} {data.base}
              </Button>
            </>
          ) : (
            <Button
              fluid
              className="submit"
              onClick={() =>
                history.push({
                  pathname: "/login",
                  state: {
                    return: RETURN_TO_EASY_BUY,
                  },
                })
              }
            >
              {library.LOGIN}
            </Button>
          )}
        </Form>
      </Segment>
      <Dimmer active={openConfirm} page>
        <ConfirmForm
          _handleClose={() => setOpenConfirm(false)}
          _handleSwap={_handleSwap}
          price={data.price}
          amount={baseAmount}
          base={data.base}
          quote={data.quote}
        />
      </Dimmer>
    </>
  );
}

export default EasyBuyForm;
